/*!

=========================================================
* Black Dashboard PRO React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
/*eslint-disable*/
import { isIOS, isMac } from 'actions/referrer';
import { getSelfDetails } from 'api/userApi';
import mixpanel from 'mixpanel-browser';
import PropTypes from 'prop-types';
import React from 'react';
// javascript plugin used to create scrollbars on windows
// import PerfectScrollbar from "perfect-scrollbar";
// reactstrap components
import { withRouter } from 'react-router-dom';
import { Button, Nav } from 'reactstrap';
import { ReactComponent as Kandor } from '../assets/icons/kandor.svg';
import { ReactComponent as LogOut } from '../assets/icons/logOut.svg';
import DownloadCard from './components/DownloadCard/DownloadCard';
import './newChanges/sidebar.scss';
// import clevertap from 'clevertap-web-sdk';

var ps;

class Sidebar extends React.Component {
  constructor(props) {
    super(props);
    this.state = this.getCollapseStates(props.routes);
    this.state = { isUpgraded: false, review: null, isLoadTier: false };
    this.getReview();
  }

  getReview = () => {
    getSelfDetails({})
      .then(res => res.data)
      .then(qdata => {
        this.setState(state => ({
          ...state,
          review: qdata.review,
          tier: qdata.tier,
          isLoadTier: true,
        }));
      })
      .catch(res => console.log(res));
  };

  // this creates the intial state of this component based on the collapse routes
  // that it gets through this.props.routes
  getCollapseStates = routes => {
    let initialState = {};
    routes.map((prop, key) => {
      if (prop.collapse) {
        initialState = {
          [prop.state]: this.getCollapseInitialState(prop.views),
          ...this.getCollapseStates(prop.views),
          ...initialState,
        };
        // this.setState({isUpgraded: true})     change this to upgraded the upgrade card will be hidden
      }
      return null;
    });
    return initialState;
  };
  // this verifies if any of the collapses should be default opened on a rerender of this component
  // for example, on the refresh of the page,
  // while on the src/views/forms/RegularForms.js - route /admin/regular-forms
  getCollapseInitialState(routes) {
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse && this.getCollapseInitialState(routes[i].views)) {
        return true;
      } else if (window.location.href.indexOf(routes[i].path) !== -1) {
        return true;
      }
    }
    return false;
  }

  renderSwitch(param) {
    switch (param) {
      case 'YVACE':
        return <img alt="..." src={require('assets/kandor-img/yvaceLogo.png')} height="30px" />;
      case 'LEARNEX':
        return <img alt="..." src={require('assets/kandor-img/learnex.png')} height="25px" />;
      case 'SEP':
        return <img alt="..." src={require('assets/kandor-img/SEP.jpg')} height="32px" />;
      case 'free':
        return (
          <Button
            size="sm"
            color="black"
            style={{
              color: '#FFFFFF',
              'margin-left': '-0.00rem',
              padding: '0.25rem',
            }}
            onClick={e => {
              e.preventDefault();
              mixpanel.track(`Go to Pricing Page`, {});
              this.props.history.push('/admin/plan-information');
            }}>
            <img
              src={require('assets/kandor-img/free-plan.png')}
              style={{
                height: '1rem',
                width: '1rem',
                'margin-top': '-0.25rem',
              }}
              alt="..."
            />{' '}
            Trial
          </Button>
        );
      default:
        return (
          <Button
            size="sm"
            color="black"
            style={{
              color: '#000000',
              'margin-left': '-0.0rem',
              padding: '0.25rem',
            }}
            onClick={e => {
              e.preventDefault();
              mixpanel.track(`Go to Pricing Page`, {});
              this.props.history.push('/admin/plan-information');
            }}>
            <img
              src={require('assets/kandor-img/paid-plan.png')}
              style={{
                height: '1rem',
                width: '1rem',
                'margin-top': '-0.25rem',
              }}
              alt="..."
            />{' '}
            Pro
          </Button>
        );
    }
  }

  // this function creates the links and collapses that appear in the sidebar (left menu)
  createLinks = routes => {
    const { rtlActive, closeSidebar } = this.props;

    const handleClick = (e, prop) => {
      mixpanel.track(`Opened Page in Sidebar`, { page: prop.name });
      closeSidebar({ e, layout: prop.layout, path: prop.path });
    };

    return routes.map((prop, key) => {
      const isActive = this.activeRoute(prop.layout + prop.path);
      const activeIcon = isActive ? prop.activeIcon : prop.icon;

      const rtlName = rtlActive ? prop.rtlName : prop.name;

      return (
        <li className={isActive ? 'active' : ''} key={key}>
          <a href="#" onClick={e => handleClick(e, prop)} className={`nav__link ${rtlName}`}>
            <span
              style={{
                width: '30px',
                height: '30px',
                alignItems: 'center',
                display: 'flex',
                justifyContent: 'center',
              }}
              className={isActive}>
              {activeIcon}
            </span>
            <span className="sidebar-normal" style={{ marginLeft: '15px' }}>
              {rtlName}
            </span>
          </a>
        </li>
      );
    });
  };
  // verifies if routeName is the one active (in browser input)
  // activeRoute = routeName => {
  //   return this.props.location.pathname.indexOf(routeName) > -1 ? "active" : "";
  // };

  activeRoute = routeName => {
    const { pathname } = this.props.location;
    // console.log(this.props.location);

    if (pathname.includes('/admin/sopchat/') && routeName === '/admin/aisop') {
      return 'active';
    }

    if (pathname.includes('/admin/sopchat/visa') && routeName === '/admin/aitools') {
      return 'active';
    }

    if (pathname.includes('/admin/sopchat/college') && routeName === '/admin/aitools') {
      return 'active';
    }

    if (pathname.includes('/admin/aisop') && routeName === '/admin/aitools') {
      return 'active';
    }

    if (pathname.includes('/admin/newuser') && routeName === '/admin/myIelts') {
      return 'active';
    }

    if (pathname.includes('/admin/shortlister') && routeName === '/admin/aitools') {
      return 'active';
    }

    if (pathname.includes('/admin/list/') && routeName === '/admin/aitools') {
      return 'active';
    }

    return pathname.indexOf(routeName) > -1 ? 'active' : '';
  };
  // componentDidMount() {
  //   // if you are using a Windows Machine, the scrollbars will have a Mac look
  //   if (navigator.platform.indexOf("Win") > -1) {
  //     ps = new PerfectScrollbar(this.refs.sidebar, {
  //       suppressScrollX: true,
  //       suppressScrollY: false,
  //     });
  //   }
  // }
  componentWillUnmount() {
    // we need to destroy the false scrollbar when we navigate
    // to a page that doesn't have this component rendered
    if (navigator.platform.indexOf('Win') > -1 && ps) {
      ps.destroy();
    }
  }
  render() {
    const { activeColor, logo } = this.props;
    let logoImg = null;
    let logoText = null;

    return (
      <div className="sidebar" style={{ height: '100vh' }} data={activeColor}>
        <div
          className="sidebar-wrapper"
          ref="sidebar"
          // style={{ paddingTop: "0px" }}
        >
          {logoImg !== null || logoText !== null ? (
            <div className="logo">
              {logoImg}
              {logoText}
            </div>
          ) : null}

          <div className="kandor_select">
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-around',
                alignItems: 'center',
              }}>
              <Kandor style={{ marginRight: '15px' }} />
              IELTS
            </div>
          </div>

          <Nav>{this.createLinks(this.props.routes)}</Nav>
          <div className="side__bar-action">
            <>
              {isIOS() || isMac() ? (
                <DownloadCard
                  handleClick={() => {
                    // clevertap.event.push('Download App on Dashboard');
                    mixpanel.track('Download App on Dashboard', {});
                  }}
                  url="https://apps.apple.com/in/app/kandor-ielts/id6446410798"
                  type="ios"
                />
              ) : (
                <DownloadCard
                  handleClick={() => {
                    // clevertap.event.push('Download App on Dashboard');
                    mixpanel.track('Download App on Dashboard', {});
                  }}
                  url="https://play.google.com/store/apps/details?id=com.trykandor.twa"
                  type="android"
                />
              )}
            </>
            <a onClick={() => this.props.LoggingOut()} className="side__bar-link">
              <LogOut />
              <span style={{ marginLeft: '10px' }}> Logout</span>
            </a>
          </div>
        </div>
      </div>
    );
  }
}

Sidebar.propTypes = {
  activeColor: PropTypes.oneOf(['primary', 'blue', 'green', 'orange', 'red', 'black']),
  rtlActive: PropTypes.bool,
  routes: PropTypes.array.isRequired,
  logo: PropTypes.oneOfType([
    PropTypes.shape({
      innerLink: PropTypes.string.isRequired,
      imgSrc: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired,
    }),
    PropTypes.shape({
      outterLink: PropTypes.string.isRequired,
      imgSrc: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired,
    }),
  ]),
  // this is used on responsive to close the sidebar on route navigation
  closeSidebar: PropTypes.func,
};

export default withRouter(Sidebar);

/*
{this.props.isComplete && <>{this.renderSwitch(this.props.plan)}</>}
*/
