const userDeviceArray = [
  { device: 'Android', type: 'mobile-browser', platform: /Android/ },
  { device: 'iOS', type: 'twa', platform: /iOSApp/ },
  { device: 'iPhone', type: 'mobile-browser', platform: /iPhone/ },
  { device: 'iPad', type: 'mobile-browser', platform: /iPad/ },
  { device: 'Symbian', type: 'mobile-browser', platform: /Symbian/ },
  {
    device: 'Windows Phone',
    type: 'mobile-browser',
    platform: /Windows Phone/,
  },
  { device: 'Tablet OS', type: 'mobile-browser', platform: /Tablet OS/ },
  { device: 'Linux', type: 'browser', platform: /Linux/ },
  { device: 'Windows', type: 'browser', platform: /Windows NT/ },
  { device: 'Macintosh', type: 'browser', platform: /Macintosh/ },
];

const getDeviceInfo = () => {
  let device = null;

  const platform = navigator.userAgent;

  function getInfo() {
    for (var i in userDeviceArray) {
      if (userDeviceArray[i].platform.test(platform)) {
        return userDeviceArray[i];
      }
    }
    return null;
  }

  const userPlatform = getInfo();

  if (userPlatform) device = userPlatform;

  return device;
};

export function getDisplayMode(deviceInfo = null) {
  const isStandalone = window.matchMedia('(display-mode: standalone)').matches;
  if (!deviceInfo) {
    deviceInfo = getDeviceInfo();
  }

  if (deviceInfo.type == 'twa') {
    return 'twa';
  }

  const width = window.innerWidth > 0 ? window.innerWidth : window.screen.width;

  // console.log('document.referrer', document.referrer);

  if (document.referrer.startsWith('android-app://')) {
    return 'twa';
  } else if (navigator.userAgent.includes('SamsungBrowser/') && width <= 768) {
    return 'twa';
  } else if (navigator.standalone || isStandalone) {
    return 'standalone';
  } else if (deviceInfo) {
    if (deviceInfo.device === 'iPhone' || deviceInfo.device === 'iPad' || deviceInfo.device === 'Tablet OS') {
      return 'iPhone';
    } else {
      return deviceInfo.type;
    }
  } else if (width <= 768) {
    return 'mobile-browser';
  }

  if (document.referrer.match('iosapp')) {
    return 'twa';
  }

  return 'browser';
}

export function getDeviceIdInfo() {
  let deviceIdInfo = getDeviceInfo();
  if (deviceIdInfo) {
    return deviceIdInfo;
  }

  return '';
}

export function isApp(deviceInfo = null) {
  return getDisplayMode(deviceInfo) === 'twa';
}

export function isPwa(deviceInfo = null) {
  return getDisplayMode(deviceInfo) === 'standalone';
}

export function isBrowser(deviceInfo = null) {
  return getDisplayMode(deviceInfo) === 'browser';
}

export function isMobileBrowser(deviceInfo = null) {
  return getDisplayMode(deviceInfo) === 'mobile-browser' || getDisplayMode() === 'iPhone';
}

export function isIOS(deviceInfo = null) {
  return getDisplayMode(deviceInfo) === 'iPhone';
}

export function isIOSApp() {
  let deviceInfo = getDeviceInfo();
  if (deviceInfo) {
    if (deviceInfo.device === 'iOS') {
      return true;
    }
  }

  return false;
}

export function isMac() {
  let deviceInfo = getDeviceInfo();
  if (deviceInfo) {
    if (deviceInfo.device === 'Macintosh') {
      return true;
    }
  }

  return false;
}
