import { getAllUserSops } from 'api/userApi';
import ProgressBar from 'kandor-components/components/ProgressBar/ProgressBar';
import Explore from 'kandor-components/components/Sop/Explore/Explore';
import GenerationCard from 'kandor-components/components/Sop/Generation/GenerationCard';
import GenerationYour from 'kandor-components/components/Sop/Generation/GenerationYour';
import SopCards from 'kandor-components/components/Sop/SopCards/SopCards';
import React, { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { useSelector } from 'react-redux';
import Slider from 'react-slick';
import Ai from '../../../assets/img/Kando AI.png';
import First from '../../../assets/img/quiz/FirstTest.png';
import Fourth from '../../../assets/img/quiz/FourthTest.png';
import Second from '../../../assets/img/quiz/SecondTest.png';
import Third from '../../../assets/img/quiz/ThirdTest.png';
import { filteredSopsSelector } from '../../../selectors/filteredSopsSelector';
import s from './Sop.module.scss';
import Quiz from './SopSlider/Quiz/Quiz';
import SopSlider from './SopSlider/SopSlider';
import LoaderImg from '../LoaderImg/LoaderImg';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
const Sop = () => {
  const history = useHistory();
  const filteredSops = useSelector(filteredSopsSelector);

  const personality = useSelector(state => state.sops.personality);

  const [show, setShow] = useState(!personality ? true : false);

  const [test, setTest] = useState(true);

  const [currentSlide, setCurrentSlide] = useState(0);

  const [userSop, setUserSop] = useState([]);

  const [isLoadedImg, setIsLoadedImg] = useState(false);

  const images = [First, Second, Third, Fourth];

  useEffect(() => {
    getAllUserSops()
      .then(res => res.data)
      .then(jsonr => {
        setUserSop(jsonr);
      });
  }, []);

  const settings = {
    slidesToShow: 4,
    slidesToScroll: 1,
    arrows: false,
    infinite: false,
    initialSlide: 1,
    autoplay: false,
    swipeToSlide: true,

    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 1600,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1310,
        settings: {
          slidesToShow: 3.7,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 809,
        settings: {
          slidesToShow: 1.7,
          slidesToScroll: 1,
        },
      },

      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2.2,
          slidesToScroll: 1,
          arrows: false,
        },
      },
      {
        breakpoint: 573,
        settings: {
          slidesToShow: 1.7,
          slidesToScroll: 1,
          arrows: false,
        },
      },
      {
        breakpoint: 535,
        settings: {
          slidesToShow: 1.2,
          slidesToScroll: 1,
          arrows: false,
        },
      },
      {
        breakpoint: 345,
        settings: {
          slidesToShow: 1.1,
          slidesToScroll: 1,
          arrows: false,
        },
      },
    ],
  };

  const onLoadImg = () => {
    setIsLoadedImg(true);
  };

  const goBack = () => {
    history.push('/admin/aitools');
  };

  return (
    <>
      <div className="content-myielts content content--footer">
        <div className="left__side">
          <div className="left__side-container">
            <div className="sop_block">
              <div className="hello">
                <button onClick={() => goBack()} className={s.shortlister_page__btn}>
                  AI Tools > SOP
                </button>

                <h3 className="hello__user">SOP Generator</h3>
                <p className="hello__desc">Generate your SOP, LOR and more</p>
              </div>
              <div>
                <div className={s.message__container}>
                  <div className={s.ai_sop}>
                    <img src={Ai} />
                  </div>
                  <div className={s.ai_sop_img}>
                    <h3 className={s.message}>Hey! What do you wanna generate today?</h3>
                  </div>
                </div>
                <SopCards />
                <h4 style={{ marginBottom: '10px', marginTop: '40px' }} className="sub__title">
                  Your SOP Vault
                </h4>
                <div className="generation__container">
                  <Slider className="generation__slider" {...settings}>
                    {userSop && userSop.map((el, id) => <GenerationYour key={id} {...el} />)}
                  </Slider>
                </div>
                <ProgressBar margin={0} />
                <h4 style={{ marginBottom: '23px', marginTop: '13px' }} className="sub__title">
                  Explore Examples
                </h4>
                <Explore />
                <div className={s.explore__container}>
                  {filteredSops && filteredSops.map((el, id) => <GenerationCard key={id} {...el} />)}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal
        dialogClassName={s.myModalStyle}
        contentClassName={s.custom_modal}
        show={show}
        animation={true}
        centered={true}
        onHide={() => {
          setShow(false);
          setTest(false);
        }}>
        {show && (
          <div>
            <div className={s.modal_sop__container}>
              {test ? (
                <>
                  <div className={s.modal_sop__tests}>
                    <SopSlider
                      test={test}
                      setTest={setTest}
                      setShow={setShow}
                      currentSlide={currentSlide}
                      setCurrentSlide={setCurrentSlide}
                    />
                  </div>
                  <div className={s.modal_sop__img}>
                    {!isLoadedImg && (
                      <div className={s.sop_gradient__skeleton}>
                        <LoaderImg color={'violet'} />
                      </div>
                    )}
                    <div className={s.sop_gradient}>
                      <img className={s.sop__modal} alt="" src={images[currentSlide]} onLoad={onLoadImg} />
                    </div>
                  </div>
                </>
              ) : (
                <Quiz setShow={setShow} />
              )}
            </div>
          </div>
        )}
      </Modal>
    </>
  );
};

export default Sop;
