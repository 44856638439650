import { filterByCategory } from "actions/sopsAction";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import s from "./Explore.module.scss";

const Explore = () => {
  const categories = useSelector(state => state.sops.categories);
  const category = useSelector(state => state.sops.category);
  const dispatch = useDispatch();

  const onSetCategory = category => {
    dispatch(filterByCategory(category));
  };

  return (
    <div className={s.explore_panel} style={{ marginBottom: "24px" }}>
      <div className={s.explore_panel_container}>
        <div className={s.articles_filter}>
          {categories.length > 0
            ? categories.map((el, idx) => (
                <div
                  key={idx}
                  onClick={() => onSetCategory(el)}
                  className={
                    category === el
                      ? `${s.active_filter}`
                      : `${s.articles_filter_block}`
                  }
                >
                  <p className={s.articles_filter_title}>{el}</p>
                </div>
              ))
            : ""}
        </div>
      </div>
    </div>
  );
};

export default Explore;
