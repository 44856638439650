import {
  SET_ONBOARDING_STATE,
  UPDATE_STEP_INDEX,
  UPDATE_STEPS_GUIDE,
  UPDATE_RUN,
  ADDED_STEPS,
} from '../actions/onBoardingAction';
import { useMediaQuery } from 'react-responsive';

const initialState = {
  runGuide: false,
  stepIndex: 0,
  stepsGuide: [
    // {
    //   target: '.practice-guide',
    //   content: 'Practise cue cards and get AI powered feedback',
    //   disableBeacon: true,
    //   placement: 'top',
    // },
    // {
    //   placement: 'top',
    //   target: '.classes-guide',
    //   content:
    //     'Utilise the first 5 free classes to get better at the section that’s troubling you. Or choose to follow the first of it’s kind rolling classes through our Premium plan and get the most out of it',
    // },
    // {
    //   target: '.nav__link.SOP',
    //   content:
    //     'When you’re ready to sail the ship, generate a statement of purpose or VISA essay from Kandor’s AI SOP writer with just a small q&a',
    //   placement: 'top',
    // },
    // {
    //   target: '.nav__link.Abroad',
    //   content: 'At any time book a session with a study abroad guide near you to clear any type of doubts',
    //   placement: 'top',
    // },
  ],
};

const OnBoardingReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADDED_STEPS:
      return {
        ...state,
        stepsGuide: action.payload,
      };

    case SET_ONBOARDING_STATE:
      return {
        ...state,
        stepIndex: action.payload.stepIndex,
        runGuide: action.payload.runGuide,
      };
    case UPDATE_STEP_INDEX:
      return {
        ...state,
        stepIndex: action.payload,
      };
    case UPDATE_STEPS_GUIDE:
      return {
        ...state,
        stepsGuide: action.payload,
      };

    case UPDATE_RUN:
      return {
        ...state,
        runGuide: action.payload.runGuide,
      };
    default:
      return state;
  }
};

export default OnBoardingReducer;
