import { getLimitClasses, getSchedule } from 'api/classesApi';
import {
  getLastListeningAttempts,
  getLastReadingAttempts,
  getListeningPractice,
  getQuestionsWriting,
  getReadingPractice,
  getRounds,
  getSelfAssesmentsWriting,
  getSpeakingMockTests,
  getSpeakingMockTestsAttempts,
  getStudyCards,
} from 'api/ieltsApi';
import { getSelfActivity, getSelfDetails } from 'api/userApi';
import Classes from 'kandor-components/components/Classes/Classes';
import Live from 'kandor-components/components/Classes/Live/Live';
import Next from 'kandor-components/components/Classes/Next/Next';
import LearnScreen from 'kandor-components/LearnScreen';
import LimitAlert from 'kandor-components/LimitAlert';
import LimitRecordingsAlert from 'kandor-components/LimitRecordingsAlert';
import PracticeScreen from 'kandor-components/PracticeScreen';
import mixpanel from 'mixpanel-browser';
import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { useParams } from 'react-router-dom';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import { ReactComponent as ListeningIcon } from '../assets/img/listening-icon.svg';
import { ReactComponent as ReadingIcon } from '../assets/img/reading-icon.svg';
import { ReactComponent as SpeakingIcon } from '../assets/img/speaking-icon.svg';
import { ReactComponent as WritingIcon } from '../assets/img/writing-icon.svg';
import ProfileInfo from '../kandor-components/components/Profile/Profile.jsx';
import '../kandor-components/newChanges/TabSwitch.scss';
import './styles/Question.scss';
import clevertap from 'clevertap-web-sdk';
//import Joyride, { ACTIONS, EVENTS, STATUS, Step } from 'react-joyride';
//import GuideModal from 'kandor-components/components/GuideModal/GuideModal';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

const PracticePage = () => {
  const isMobile = useMediaQuery({ query: '(max-width: 992px)' });

  const params = useParams();
  const pageId = params.id;
  const tabId = params.type;
  const [tabIndex, setTabIndex] = useState(
    pageId == 'speaking' ? 0 : pageId == 'writing' ? 1 : pageId == 'reading' ? 2 : pageId == 'listening' ? 3 : 0
  );
  const [isPremium, setIsPremium] = useState(false);
  const [isLoadingDetails, setIsLoadingDetails] = useState(true);
  const TokenId = useSelector(state => state.auth.TokenId);
  const [isPro, setIsPro] = useState(null);
  //Tabs
  useEffect(() => {
    const lastTabIndex = localStorage.getItem('lastTabIndex');
    if (lastTabIndex !== null) {
      setTabIndex(parseInt(lastTabIndex));
    }
  }, []);

  useEffect(() => {
    localStorage.setItem('lastTabIndex', tabIndex);
  }, [tabIndex]);

  //Speaking
  const [tabIndexSpeaking, setTabIndexSpeaking] = useState(0);
  const [recordingsLeftSpeaking, setRecordingsLeftSpeaking] = useState(600);
  const [visibleSpeaking, setVisibleSpeaking] = useState(false);
  const [idataSpeaking, setIData] = useState({
    questions: [],
    isFetching: false,
  });
  const [idataFreeSpeaking, setIDataFreeSpeaking] = useState([]);
  const [idataPremiumSpeaking, setIDataPremiumSpeaking] = useState([]);
  const [iquestiontypeSpeaking, setiQuestiontypeSpeaking] = useState('2');
  const [roundAttemptsSpeaking, setRoundAttemptsSpeaking] = useState([]);
  const [roundAttemptsCompleteSpeaking, setRoundAttemptsCompleteSpeaking] = useState(false);
  const [practiceAttemptsLeftSpeaking, setPracticeAttemptsLeftSpeaking] = useState('-');

  const [mockListSpeaking, setMockListSpeaking] = useState([]);
  const [mockListFreeSpeaking, setMockListFreeSpeaking] = useState([]);
  const [mockListPremiumSpeaking, setMockListPremiumSpeaking] = useState([]);
  const [mockAttemptsSpeaking, setMockAttemptsSpeaking] = useState([]);
  const [mockAttemptsCompleteSpeaking, setMockAttemptsCompleteSpeaking] = useState(false);

  //Writing
  const [tabIndexWriting, setTabIndexWriting] = useState(0);
  const [practiceAttemptsLeftWriting, setPracticeAttemptsLeftWriting] = useState('-');
  const [iquestiontypeWriting, setiQuestiontypeWriting] = useState('1');
  const [dataWriting, setDataWriting] = useState({
    questions: [],
    isFetching: true,
  });
  const [dataFreeWriting, setDataFreeWriting] = useState([]);
  const [dataPremiumWriting, setDataPremiumWriting] = useState([]);
  const [freePContinueWriting, setFreePContinueWriting] = useState([]);
  const [premiumPContinueWriting, setPremiumPContinueWriting] = useState([]);
  const [continueAttemptsWriting, setContinueAttemptsWriting] = useState([]);
  const [loadedContinueWriting, setLoadedContinueWriting] = useState(false);
  const [currentPracticeAttemptsWriting, setCurrentPracticeAttemptsWriting] = useState([]);
  const [attemptsPracticeCompleteWriting, setAttemptsPracticeCompleteWriting] = useState(false);
  const [practiceIsCompleteWriting, setPracticeIsCompleteWriting] = useState(false);
  const [continuePracticeCompleteWriting, setContinuePracticeCompleteWriting] = useState(true);
  const [PreviousDataWriting, setPreviousDataWriting] = useState([]);

  //Reading
  const [tabIndexReading, setTabIndexReading] = useState(0);
  const [practiceAttemptsLeftReading, setPracticeAttemptsLeftReading] = useState('-');
  const [continueAttemptsReading, setContinueAttemptsReading] = useState([]);
  const [practiceContinueReading, setPracticeContinueReading] = useState([]);
  const [practiceListReading, setPracticeListReading] = useState([]);
  const [currentPracticeAttemptsReading, setCurrentPracticeAttemptsReading] = useState([]);
  const [continuePracticeCompleteReading, setContinuePracticeCompleteReading] = useState(false);

  const [attemptsPracticeCompleteReading, setAttemptsPracticeCompleteReading] = useState(false);

  const [dataFreeReading, setDataFreeReading] = useState([]);
  const [dataPremiumReading, setDataPremiumReading] = useState([]);
  const [loadedContinueReading, setLoadedContinueReading] = useState(false);
  const [practiceIsCompleteReading, setPracticeIsCompleteReading] = useState(false);
  const [lastPracticeAttemptsReading, setLastPracticeAttemptsReading] = useState(10);

  const [mockAttemptsLeftReading, setMockAttemptsLeftReading] = useState('-');
  const [mockContinueReading, setMockContinueReading] = useState([]);
  const [mockListReading, setMockListReading] = useState([]);
  const [mockFreeReading, setMockFreeReading] = useState([]);
  const [mockPremiumReading, setMockPremiumReading] = useState([]);
  const [mockAttemptsReading, setMockAttemptsReading] = useState([]);
  const [currentMockAttemptsReading, setCurrentMockAttemptsReading] = useState([]);
  const [continueMockCompleteReading, setContinueMockCompleteReading] = useState(false);
  const [attemptsMockCompleteReading, setAttemptsMockCompleteReading] = useState(false);
  const [lastMockAttemptsReading, setLastMockAttemptsReading] = useState(10);
  const [mockIsCompleteReading, setMockIsCompleteReading] = useState(false);

  //Listening
  const [tabIndexListening, setTabIndexListening] = useState(0);
  const [practiceAttemptsLeftListening, setPracticeAttemptsLeftListening] = useState('-');
  const [practiceContinueListening, setPracticeContinueListening] = useState([]);
  const [practiceAttemptsListening, setPracticeAttemptsListening] = useState([]);
  const [currentPracticeAttemptsListening, setCurrentPracticeAttemptsListening] = useState([]);
  const [lastPracticeAttemptsListening, setLastPracticeAttemptsListening] = useState(10);
  const [continuePracticeCompleteListening, setContinuePracticeCompleteListening] = useState(false);

  const [attemptsPracticeCompleteListening, setAttemptsPracticeCompleteListening] = useState(false);
  const [loadedContinueListening, setLoadedContinueListening] = useState(false);
  const [practiceFreeListening, setPracticeFreeListening] = useState([]);
  const [practicePremiumListening, setPracticePremiumListening] = useState([]);
  const [practiceListListening, setPracticeListListening] = useState([]);
  const [practiceIsCompleteListening, setPracticeIsCompleteListening] = useState(false);

  const [mockAttemptsLeftListening, setMockAttemptsLeftListening] = useState('-');
  const [mockListListening, setMockListListening] = useState([]);
  const [mockFreeListening, setMockFreeListening] = useState([]);
  const [mockPremiumListening, setMockPremiumListening] = useState([]);
  const [mockContinueListening, setMockContinueListening] = useState([]);
  const [currentMockAttemptsListening, setCurrentMockAttemptsListening] = useState([]);
  const [mockAttemptsListening, setMockAttemptsListening] = useState([]);
  const [lastMockAttemptsListening, setLastMockAttemptsListening] = useState(10);
  const [continueMockCompleteListening, setContinueMockCompleteListening] = useState(false);
  const [attemptsMockCompleteListening, setAttemptsMockCompleteListening] = useState(false);
  const [mockIsCompleteListening, setMockIsCompleteListening] = useState(false);

  useEffect(() => {
    if (pageId && tabId) {
      if (pageId == 'speaking') {
        if (tabId == 'practice') {
          setTabIndexSpeaking(0);
        } else if (tabId == 'mock') {
          setTabIndexSpeaking(1);
        } else if (tabId == 'classes') {
          setTabIndexSpeaking(2);
        }
      } else if (pageId == 'writing') {
        if (tabId == 'practice') {
          setTabIndexWriting(0);
        } else if (tabId == 'mock') {
          setTabIndexWriting(1);
        } else if (tabId == 'classes') {
          setTabIndexWriting(2);
        }
      } else if (pageId == 'reading') {
        if (tabId == 'practice') {
          setTabIndexReading(0);
        } else if (tabId == 'mock') {
          setTabIndexReading(1);
        } else if (tabId == 'classes') {
          setTabIndexReading(2);
        }
      } else if (pageId == 'listening') {
        if (tabId == 'practice') {
          setTabIndexListening(0);
        } else if (tabId == 'mock') {
          setTabIndexListening(1);
        } else if (tabId == 'classes') {
          setTabIndexListening(2);
        }
      }
    }
  }, [pageId, tabId]);

  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    document.querySelector('.main-panel').scrollTop = 0;
  }, [tabIndex, tabIndexListening, tabIndexReading, tabIndexSpeaking, tabIndexWriting]);

  useEffect(() => {
    getSelfDetails({})
      .then(res => res.data)
      .then(qdata => {
        setIsPremium(qdata.tier != 'free');
        setIsPro(qdata.tier);
        var seconds_left = qdata.limits.find(el => el.type === 'seconds');
        var seconds_left_value =
          seconds_left !== undefined && parseInt(seconds_left.value) > 0 ? parseInt(seconds_left.value) : 0;
        setVisibleSpeaking(seconds_left_value < 599 ? true : false);
        if (qdata?.freeLimits?.speakingPracticeAttemptsLeft || qdata?.freeLimits?.speakingPracticeAttemptsLeft == 0)
          setPracticeAttemptsLeftSpeaking(qdata.freeLimits.speakingPracticeAttemptsLeft);

        if (qdata?.freeLimits?.writingPracticeAttemptsLeft || qdata?.freeLimits?.writingPracticeAttemptsLeft == 0)
          setPracticeAttemptsLeftWriting(qdata.freeLimits.writingPracticeAttemptsLeft);

        if (qdata?.freeLimits?.readingPracticeAttemptsLeft || qdata?.freeLimits?.readingPracticeAttemptsLeft == 0)
          setPracticeAttemptsLeftReading(qdata.freeLimits.readingPracticeAttemptsLeft);
        if (qdata?.freeLimits?.readingMockAttemptsLeft || qdata?.freeLimits?.readingMockAttemptsLeft == 0)
          setMockAttemptsLeftReading(qdata.freeLimits.readingMockAttemptsLeft);
        if (qdata?.freeLimits?.listeningPracticeAttemptsLeft || qdata?.freeLimits?.listeningPracticeAttemptsLeft == 0)
          setPracticeAttemptsLeftListening(qdata.freeLimits.listeningPracticeAttemptsLeft);
        if (qdata?.freeLimits?.listeningMockAttemptsLeft || qdata?.freeLimits?.listeningMockAttemptsLeft == 0)
          setMockAttemptsLeftListening(qdata.freeLimits.listeningMockAttemptsLeft);
        setIsLoadingDetails(false);
      });
  }, [TokenId]);

  //Speaking

  useEffect(() => {
    setRoundAttemptsSpeaking(roundAttemptsSpeaking);
    getSelfActivity({ last: 10 })
      .then(res => res.data)
      .then(qdata => {
        setRoundAttemptsSpeaking(qdata);
        setRoundAttemptsCompleteSpeaking(false);
      });
  }, [TokenId]);

  useEffect(() => {
    setIDataFreeSpeaking([]);
    setIDataPremiumSpeaking([]);
    getRounds({ order: 'usage' })
      .then(res => res.data)
      .then(qdata => {
        const freeArr = qdata[iquestiontypeSpeaking].filter(el => el.free);
        const premiumArr = qdata[iquestiontypeSpeaking].filter(el => !el.free);

        setIData({
          questions: qdata[iquestiontypeSpeaking],
          isFetching: false,
        });
        setIDataFreeSpeaking(freeArr);
        setIDataPremiumSpeaking(premiumArr);
      });
  }, [TokenId, iquestiontypeSpeaking]);

  useEffect(() => {
    getSpeakingMockTests({})
      .then(res => res.data)
      .then(data => {
        const freeArr = data.filter(el => el.free);
        const premiumArr = data.filter(el => !el.free);

        setMockListFreeSpeaking(freeArr);
        setMockListPremiumSpeaking(premiumArr);
        setMockListSpeaking(data);
      });

    getSpeakingMockTestsAttempts({})
      .then(res => res.data)
      .then(data => {
        setMockAttemptsSpeaking(data);
        setMockAttemptsCompleteSpeaking(false);
      });
  }, [TokenId]);

  useEffect(() => {
    if (mockListSpeaking.length && mockAttemptsSpeaking.length && !mockAttemptsCompleteSpeaking) {
      let newData = JSON.parse(JSON.stringify(mockAttemptsSpeaking));

      newData.forEach(data => {
        const index = mockListSpeaking.findIndex(el => el.id == data.testid);

        if (index != -1) {
          data.free = mockListSpeaking[index].free;
        } else {
          data.free = '';
        }
      });

      setMockAttemptsSpeaking(newData);
      setMockAttemptsCompleteSpeaking(true);
    }
  }, [mockListSpeaking, mockAttemptsCompleteSpeaking, mockAttemptsSpeaking]);

  //Writing

  useEffect(() => {
    setDataFreeWriting([]);
    setDataPremiumWriting([]);
    getQuestionsWriting({ order: 'usage' })
      .then(res => res.data)
      .then(qdata => {
        const freeArr = qdata[iquestiontypeWriting].filter(el => el.free);
        const premiumArr = qdata[iquestiontypeWriting].filter(el => !el.free);

        setDataWriting({
          questions: qdata[iquestiontypeWriting],
          isFetching: false,
        });
        setDataFreeWriting(freeArr);
        setDataPremiumWriting(premiumArr);
      });
  }, [TokenId, iquestiontypeWriting, isPremium]);

  useEffect(() => {
    if (TokenId === null || TokenId === '') {
      return;
    }
    let timeout = null;

    getSelfAssesmentsWriting()
      .then(res => res.data)
      .then(qdata => {
        const data = qdata.slice(0, 10);
        const previousData = data.filter(data => !data.in_progress);
        const continueData = data.filter(data => data.in_progress);

        setCurrentPracticeAttemptsWriting(data);
        setPreviousDataWriting(previousData);
        setContinueAttemptsWriting(continueData);
        setAttemptsPracticeCompleteWriting(false);
        setLoadedContinueWriting(true);
      });

    timeout = setTimeout(() => {
      getSelfAssesmentsWriting()
        .then(res => res.data)
        .then(qdata => {
          const data = qdata.slice(0, 10);
          const previousData = data.filter(data => !data.in_progress);
          const continueData = data.filter(data => data.in_progress);

          setCurrentPracticeAttemptsWriting(data);
          setPreviousDataWriting(previousData);
          setContinueAttemptsWriting(continueData);
          setAttemptsPracticeCompleteWriting(false);
          setLoadedContinueWriting(true);
        });
    }, 2000);

    return () => clearTimeout(timeout);
  }, [TokenId, isPremium]);

  useEffect(() => {
    if (continueAttemptsWriting.length && dataWriting.questions.length && !practiceIsCompleteWriting) {
      const oldData = JSON.parse(JSON.stringify(dataWriting.questions));

      const newData = oldData.filter(el => {
        const id = el.id;
        const index = continueAttemptsWriting.findIndex(dataEl => dataEl.id === id);

        return index == -1;
      });

      const freeArr = newData.filter(el => el.free);
      const premiumArr = newData.filter(el => !el.free);

      setDataFreeWriting(freeArr);
      setDataPremiumWriting(premiumArr);
      setPracticeIsCompleteWriting(true);
    }
  }, [continueAttemptsWriting, dataWriting, practiceIsCompleteWriting, continuePracticeCompleteWriting, isPremium]);

  useEffect(() => {
    if (continuePracticeCompleteWriting && attemptsPracticeCompleteWriting) return;

    if (continueAttemptsWriting.length && dataWriting.questions.length && !continuePracticeCompleteWriting) {
      let freeContinue = [];
      let premiumContinue = [];

      freeContinue = continueAttemptsWriting.filter(el => {
        const cardIndex = dataWriting?.questions?.findIndex(sdata => sdata.id == el.testid);

        if (cardIndex != -1) {
          return dataWriting?.questions[cardIndex]?.free;
        } else {
          return false;
        }
      });

      premiumContinue = continueAttemptsWriting.filter(el => {
        const cardIndex = dataWriting?.questions?.findIndex(sdata => sdata.id == el.testid);

        if (cardIndex != -1) {
          return !dataWriting?.questions[cardIndex]?.free;
        } else {
          return false;
        }
      });

      setFreePContinueWriting(freeContinue);
      setPremiumPContinueWriting(premiumContinue);
      setContinuePracticeCompleteWriting(true);
    }

    if (currentPracticeAttemptsWriting.length && dataWriting.questions.length && !attemptsPracticeCompleteWriting) {
      let newData = JSON.parse(JSON.stringify(currentPracticeAttemptsWriting));

      newData.forEach(el => {
        const index = dataWriting?.questions?.findIndex(sdata => sdata.id == el.testid);

        if (index != -1) {
          el.free = dataWriting?.questions[index]?.free;
        } else {
          el.free = '';
        }
      });

      setCurrentPracticeAttemptsWriting(newData);
      setAttemptsPracticeCompleteWriting(true);
    }
  }, [
    continueAttemptsWriting,
    currentPracticeAttemptsWriting,
    dataWriting,
    continuePracticeCompleteWriting,
    attemptsPracticeCompleteWriting,
    isPremium,
  ]);

  useEffect(() => {
    if (continueAttemptsWriting.length && dataWriting.questions.length && !practiceIsCompleteWriting) {
      const oldData = JSON.parse(JSON.stringify(dataWriting.questions));

      const newData = oldData.filter(el => {
        const id = el.id;
        const index = continueAttemptsWriting.findIndex(dataEl => dataEl.id === id);

        return index == -1;
      });

      const freeArr = newData.filter(el => el.free);
      const premiumArr = newData.filter(el => !el.free);

      setDataFreeWriting(freeArr);
      setDataPremiumWriting(premiumArr);
      setPracticeIsCompleteWriting(true);
    }
  }, [continueAttemptsWriting, dataWriting, practiceIsCompleteWriting, continuePracticeCompleteWriting, isPremium]);

  //Reading
  const filterData = data => {
    const completeData = data.filter(el => el.status == 'COMPLETE');
    const progressData = data.filter(el => el.status == 'IN PROGRESS');

    return {
      completeData,
      progressData,
    };
  };

  const setTestAttempts = (data, section = '') => {
    const dataAttempts = JSON.parse(JSON.stringify(data));
    const dataMock = dataAttempts.filter(el => el.type == 'Mock');
    const mockAttempts = filterData(dataMock);

    if (section == 'Reading') {
      setMockAttemptsReading(mockAttempts.completeData);
      setMockContinueReading(mockAttempts.progressData);
      setCurrentMockAttemptsReading(mockAttempts.completeData.slice(0, lastMockAttemptsReading));
      setContinueMockCompleteReading(false);
      setAttemptsMockCompleteReading(false);
    } else if (section == 'Listening') {
      setMockAttemptsListening(mockAttempts.completeData);
      setMockContinueListening(mockAttempts.progressData);
      setCurrentMockAttemptsListening(mockAttempts.completeData.slice(0, lastMockAttemptsListening));
      setContinueMockCompleteListening(false);
      setAttemptsMockCompleteListening(false);
    }

    const dataPractice = dataAttempts.filter(el => el.type != 'Mock');
    const practiceAttempts = filterData(dataPractice);

    if (section == 'Reading') {
      setPracticeContinueReading(practiceAttempts.progressData);
      setCurrentPracticeAttemptsReading(practiceAttempts.completeData.slice(0, lastPracticeAttemptsReading));
      setContinuePracticeCompleteReading(false);
      setAttemptsPracticeCompleteReading(false);
    } else if (section == 'Listening') {
      setPracticeAttemptsListening(practiceAttempts.completeData);
      setPracticeContinueListening(practiceAttempts.progressData);
      setCurrentPracticeAttemptsListening(practiceAttempts.completeData.slice(0, lastPracticeAttemptsListening));
      setContinuePracticeCompleteListening(false);
      setAttemptsPracticeCompleteListening(false);
    }
  };

  useEffect(() => {
    getLastReadingAttempts({})
      .then(res => res.data)
      .then(data => {
        setTestAttempts(data, 'Reading');
        setLoadedContinueReading(true);
      });

    getReadingPractice({})
      .then(res => res.data)
      .then(data => {
        var practice = [];
        var mock = [];
        data.forEach(element => {
          if (element.type === 'Practice') {
            practice.push(element);
          } else {
            mock.push(element);
          }
        });
        const freeP = practice.filter(el => el.free);
        const premiumP = practice.filter(el => !el.free);
        setPracticeListReading(practice);
        setDataFreeReading(freeP);
        setDataPremiumReading(premiumP);
        setPracticeIsCompleteReading(false);

        const freeM = mock.filter(el => el.free);
        const premiumM = mock.filter(el => !el.free);
        setMockListReading(mock);
        setMockFreeReading(freeM);
        setMockPremiumReading(premiumM);
        setMockIsCompleteReading(false);
      });
  }, []);

  useEffect(() => {
    if (continuePracticeCompleteReading && attemptsPracticeCompleteReading) return;

    if (practiceContinueReading.length && practiceListReading.length && !continuePracticeCompleteReading) {
      setContinuePracticeCompleteReading(true);
    }

    if (currentPracticeAttemptsReading.length && practiceListReading.length && !attemptsPracticeCompleteReading) {
      let newData = JSON.parse(JSON.stringify(currentPracticeAttemptsReading));

      newData.forEach(data => {
        const index = practiceListReading.findIndex(el => el.id == data.testid);

        if (index != -1) {
          data.free = practiceListReading[index].free;
        } else {
          data.free = '';
        }
      });

      setContinuePracticeCompleteReading(true);
      setCurrentPracticeAttemptsReading(newData);
      setAttemptsPracticeCompleteReading(true);
    }
  }, [
    practiceContinueReading,
    currentPracticeAttemptsReading,
    practiceListReading,
    continuePracticeCompleteReading,
    attemptsPracticeCompleteReading,
    isPremium,
  ]);

  useEffect(() => {
    if (!practiceIsCompleteReading && practiceContinueReading.length) {
      const oldData = JSON.parse(JSON.stringify(practiceListReading));

      const newData = oldData.filter(el => {
        const id = el.id;
        const index = practiceContinueReading.findIndex(dataEl => dataEl.testid == id);

        return index == -1;
      });

      const freeP = newData.filter(el => el.free);
      const premiumP = newData.filter(el => !el.free);

      setDataFreeReading(freeP);
      setDataPremiumReading(premiumP);

      setPracticeIsCompleteReading(true);
    }
  }, [
    practiceListReading,
    practiceContinueReading,
    practiceIsCompleteReading,
    continuePracticeCompleteReading,
    loadedContinueReading,
    isPremium,
  ]);

  useEffect(() => {
    if (continueMockCompleteReading && attemptsMockCompleteReading) return;

    if (mockContinueReading.length && mockListReading.length && !continueMockCompleteReading) {
      setContinueMockCompleteReading(true);
    }

    if (currentMockAttemptsReading.length && mockListReading.length && !attemptsMockCompleteReading) {
      let newData = JSON.parse(JSON.stringify(currentMockAttemptsReading));

      newData.forEach(data => {
        const index = mockListReading.findIndex(el => el.id == data.testid);

        if (index != -1) {
          data.free = mockListReading[index].free;
        } else {
          data.free = '';
        }
      });

      setContinueMockCompleteReading(true);
      setCurrentMockAttemptsReading(newData);
      setAttemptsMockCompleteReading(true);
    }
  }, [
    mockContinueReading,
    currentMockAttemptsReading,
    mockListReading,
    continueMockCompleteReading,
    attemptsMockCompleteReading,
    isPremium,
  ]);

  useEffect(() => {
    if (!mockIsCompleteReading && mockContinueReading.length) {
      const oldData = JSON.parse(JSON.stringify(mockListReading));

      const newData = oldData.filter(el => {
        const id = el.id;
        const index = mockContinueReading.findIndex(dataEl => dataEl.testid === id);

        return index == -1;
      });

      const freeM = newData.filter(el => el.free);
      const premiumM = newData.filter(el => !el.free);

      setMockFreeReading(freeM);
      setMockPremiumReading(premiumM);

      setMockIsCompleteReading(true);
    }
  }, [mockListReading, mockIsCompleteReading, mockContinueReading, continueMockCompleteReading, isPremium]);

  //Listening

  useEffect(() => {
    getLastListeningAttempts({})
      .then(res => res.data)
      .then(data => {
        setTestAttempts(data, 'Listening');
        setLoadedContinueListening(true);
      });

    getListeningPractice({})
      .then(res => res.data)
      .then(data => {
        var practice = [];
        var mock = [];
        data.forEach(element => {
          if (element.type === 'Practice') {
            practice.push(element);
          } else {
            mock.push(element);
          }
        });
        const freeP = practice.filter(el => el.free);
        const premiumP = practice.filter(el => !el.free);
        setPracticeListListening(practice);
        setPracticeFreeListening(freeP);
        setPracticePremiumListening(premiumP);
        setPracticeIsCompleteListening(false);
        const freeM = mock.filter(el => el.free);
        const premiumM = mock.filter(el => !el.free);
        setMockListListening(mock);
        setMockFreeListening(freeM);
        setMockPremiumListening(premiumM);
        setMockIsCompleteListening(false);
      });
  }, []);

  useEffect(() => {
    if (continueMockCompleteListening && attemptsMockCompleteListening) return;

    if (mockContinueListening.length && mockListListening.length && !continueMockCompleteListening) {
      setContinueMockCompleteListening(true);
    }

    if (currentMockAttemptsListening.length && mockListListening.length && !attemptsMockCompleteListening) {
      let newData = JSON.parse(JSON.stringify(currentMockAttemptsListening));

      newData.forEach(data => {
        const index = mockListListening.findIndex(el => el.id == data.testid);

        if (index != -1) {
          data.free = mockListListening[index].free;
        } else {
          data.free = '';
        }
      });

      setContinueMockCompleteListening(true);
      setCurrentMockAttemptsListening(newData);
      setAttemptsMockCompleteListening(true);
    }
  }, [
    mockContinueListening,
    currentMockAttemptsListening,
    mockListListening,
    continueMockCompleteListening,
    attemptsMockCompleteListening,
    isPremium,
  ]);

  useEffect(() => {
    if (!mockIsCompleteListening && mockContinueListening.length) {
      const oldData = JSON.parse(JSON.stringify(mockListListening));

      const newData = oldData.filter(el => {
        const id = el.id;
        const index = mockContinueListening.findIndex(dataEl => dataEl.testid === id);

        return index == -1;
      });

      const freeM = newData.filter(el => el.free);
      const premiumM = newData.filter(el => !el.free);

      setMockFreeListening(freeM);
      setMockPremiumListening(premiumM);

      setMockIsCompleteListening(true);
    }
  }, [mockListListening, mockIsCompleteListening, mockContinueListening, continueMockCompleteListening, isPremium]);

  useEffect(() => {
    if (continuePracticeCompleteListening && attemptsPracticeCompleteListening) return;

    if (practiceContinueListening.length && practiceListListening.length && !continuePracticeCompleteListening) {
      setContinuePracticeCompleteListening(true);
    }

    if (currentPracticeAttemptsListening.length && practiceListListening.length && !attemptsPracticeCompleteListening) {
      let newData = JSON.parse(JSON.stringify(currentPracticeAttemptsListening));

      newData.forEach(data => {
        const index = practiceListListening.findIndex(el => el.id == data.testid);

        if (index != -1) {
          data.free = practiceListListening[index].free;
        } else {
          data.free = '';
        }
      });

      setContinuePracticeCompleteListening(true);
      setCurrentPracticeAttemptsListening(newData);
      setAttemptsPracticeCompleteListening(true);
    }
  }, [
    practiceContinueListening,
    currentPracticeAttemptsListening,
    practiceListListening,
    continuePracticeCompleteListening,
    attemptsPracticeCompleteListening,
    isPremium,
  ]);

  useEffect(() => {
    if (!practiceIsCompleteListening && practiceContinueListening.length) {
      const oldData = JSON.parse(JSON.stringify(practiceListListening));

      const newData = oldData.filter(el => {
        const id = el.id;
        const index = practiceContinueListening.findIndex(dataEl => dataEl.testid == id);

        return index == -1;
      });

      const freeP = newData.filter(el => el.free);
      const premiumP = newData.filter(el => !el.free);

      setPracticeFreeListening(freeP);
      setPracticePremiumListening(premiumP);

      setPracticeIsCompleteListening(true);
    }
  }, [
    practiceListListening,
    practiceIsCompleteListening,
    practiceContinueListening,
    continuePracticeCompleteListening,
    isPremium,
  ]);

  const [studyData, setStudyData] = useState([]);

  useEffect(() => {
    getStudyCards({})
      .then(res => res.data)
      .then(data => {
        const newData = JSON.parse(JSON.stringify(data));
        newData.forEach(el => {
          el.metadata = JSON.parse(el.metadata);
        });
        setStudyData(newData);
      });
  }, []);

  const [activeClasses, setActiveClasses] = useState([]);
  const [nextClass, setNextClass] = useState([]);
  const [upcomingClasses, setUpcomingClasses] = useState([]);
  const [nextClassTime, setNextClassTime] = useState('');
  const [refreshClasses, setRefreshClasses] = useState(false);
  // Added typeFilter state
  const [freeLimit, setFreeLimit] = useState(0);
  const [premium, setPremium] = useState(false);
  const [timeDifference, setTimeDifference] = useState('');

  useEffect(() => {
    getLimitClasses()
      .then(res => res.data)
      .then(res => {
        if (res.limit === 'Premium') {
          setPremium(true);
        } else {
          setFreeLimit(res.limit);
        }
      })
      .catch(e => {
        console.log(e);
      });
    // Simulate API call to fetch classes
    getSchedule()
      .then(res => res.data)
      .then(res => {
        filterClasses(res);
      })
      .catch(error => {});

    // Refresh active classes every 20 minutes
    const interval = setInterval(() => {
      setRefreshClasses(!refreshClasses);
    }, 20 * 60 * 1000);

    // Cleanup interval on component unmount
    return () => clearInterval(interval);
  }, []);

  function filterClasses(resClasses) {
    const now = moment().tz('Asia/Kolkata');
    const active = [];
    const next = [];
    const upcoming = [];
    let foundNextClass = false; // Flag to track if we've found the next class

    resClasses.forEach(cls => {
      // Convert the received time to IST
      const startTime = moment.tz(
        cls.startTime.replace('GMT', '+0530'),
        'ddd, DD MMM YYYY HH:mm:ss ZZ',
        'Asia/Kolkata'
      );
      const endTime = startTime.clone().add(1, 'hours');
      const bufferTime = startTime.clone().subtract(2, 'minutes');

      if (now.isBetween(bufferTime, endTime)) {
        active.push(cls);
      } else {
        // Check if the class starts within the next 4 hours only if it's not active
        const fourHoursFromNow = now.clone().add(4, 'hours');
        if (startTime.isBefore(fourHoursFromNow)) {
          // Add the first class that meets the condition to the 'next' array
          if (!foundNextClass) {
            next.push(cls);
            setNextClassTime(next[0].startTime);
            foundNextClass = true; // Set the flag to true
          } else {
            upcoming.push(cls); // Add the rest to the 'upcoming' array
          }
        } else {
          upcoming.push(cls);
        }
      }
    });

    setActiveClasses(active);
    setNextClass(next);
    setUpcomingClasses(upcoming);
  }
  function updateTimeDifference() {
    // Convert the start time to IST timezone

    const startTimeF = moment.tz(
      nextClassTime && nextClassTime.replace('GMT', '+0530'),
      'ddd, DD MMM YYYY HH:mm:ss ZZ',
      'Asia/Kolkata'
    );

    // Get the current time in IST timezone

    const currentTimeIST = moment().tz('Asia/Kolkata');

    const duration = moment.duration(currentTimeIST.diff(startTimeF));

    const hours = Math.abs(duration.hours());
    const minutes = Math.abs(duration.minutes());
    const seconds = Math.abs(duration.seconds());

    let timeDifferenceString = '';

    if (hours > 0) {
      timeDifferenceString += `${hours}h `;
    }

    if (minutes > 0 || hours > 0) {
      timeDifferenceString += `${minutes}m `;
    }

    timeDifferenceString += `${seconds}s`;

    setTimeDifference(timeDifferenceString);
    if (timeDifferenceString === '0s') {
      getSchedule()
        .then(res => res.data)
        .then(res => {
          filterClasses(res);
        })
        .catch(error => {});
    }
  }

  useEffect(() => {
    if (nextClassTime) {
      const interval = setInterval(updateTimeDifference, 1000);

      return () => clearInterval(interval);
    }
  }, [nextClassTime]);

  const activeClassesMemo = useMemo(() => activeClasses, [activeClasses]);
  const activeClassesLength = activeClassesMemo.length;

  //const onboarding = useSelector(state => state.onBoarding);

  //const history = useHistory();
  // const [stateOnboarding, setStateOnboarding] = useState({
  //   stepIndex: 0,
  //   runGuide: true,
  //   stepsGuide: [
  //     {
  //       target: '.question-tabs-wrap',
  //       content: 'Practise cue cards and get AI powered feedback',
  //       disableBeacon: true,
  //       placement: 'top',
  //     },
  //     {
  //       target: '.tab-switch',
  //       content: 'Practise cue cards and get AI powered feedback',
  //       disableBeacon: true,
  //       placement: 'top',
  //     },
  //   ],
  // });
  //const { stepIndex, runGuide, stepsGuide } = stateOnboarding;

  // const handleJoyrideCallback = ({ action, index, status, type }) => {
  //   if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status) || ACTIONS.CLOSE.includes(action)) {
  //     setStateOnboarding(prevState => ({ ...prevState, stepIndex: 0, runGuide: false }));
  //   } else if ([EVENTS.STEP_AFTER].includes(type)) {
  //     setStateOnboarding(prevState => ({ ...prevState, stepIndex: index + (action === ACTIONS.PREV ? -1 : 1) }));
  //     if (stepIndex === 1) {
  //       history.push('/admin/myIelts');
  //     }
  //   }
  // };
  return (
    <div className="content test-content content--footer">
      {/* will add */}
      {/* {onboarding.runGuide && (
        <Joyride
          steps={stepsGuide}
          stepIndex={stepIndex}
          continuous
          run={runGuide}
          showSkipButton={true}
          callback={handleJoyrideCallback}
          spotlightClicks={true}
          tooltipComponent={GuideModal}
          styles={{
            options: {
              zIndex: 10000,
            },
            overlay: {
              height: '100%',
            },
          }}
        />
      )} */}
      <div style={isMobile ? { paddingLeft: '15px', paddingRight: '15px', paddingTop: '0' } : { padding: '24px' }}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}>
          <div className="hello">
            {/* <h3 className="hello__user">Practice!</h3>
            <p className="hello__desc">Check in your progress. everyday</p> */}
          </div>
          <ProfileInfo />
        </div>
        <Tabs selectedIndex={tabIndex} onSelect={index => setTabIndex(index)}>
          <div className="question-tabs-wrap question-tabs-wrap--fixed">
            {/* TAB FOR CATEGORY */}
            <TabList className="question-tabs question-tabs--fixed">
              <Tab
                className="question-tabs__item"
                onClick={() => {
                  clevertap.event.push('Clicked on Speaking tab on Practice');
                  mixpanel.track('Clicked on Speaking tab on Practice', {});
                }}>
                <div className="question-tabs__item-wrap">
                  <span className="question-tabs__item-icon question-tabs__item-icon--hide">
                    <SpeakingIcon />
                  </span>
                  <span className="question-tabs__item-text">Speaking</span>
                </div>
              </Tab>
              <Tab
                className="question-tabs__item"
                onClick={() => {
                  clevertap.event.push('Clicked on Writing tab on Practice');
                  mixpanel.track('Clicked on Writing tab on Practice', {});
                }}>
                <div className="question-tabs__item-wrap">
                  <span className="question-tabs__item-icon question-tabs__item-icon--hide">
                    <WritingIcon />
                  </span>
                  <span className="question-tabs__item-text">Writing</span>
                </div>
              </Tab>
              <Tab
                className="question-tabs__item"
                onClick={() => {
                  clevertap.event.push('Clicked on Reading tab on Practice');
                  mixpanel.track('Clicked on Reading tab on Practice', {});
                }}>
                <div className="question-tabs__item-wrap">
                  <span className="question-tabs__item-icon question-tabs__item-icon--hide">
                    <ReadingIcon />
                  </span>
                  <span className="question-tabs__item-text">Reading</span>
                </div>
              </Tab>
              <Tab
                className="question-tabs__item"
                onClick={() => {
                  clevertap.event.push('Clicked on Listening tab on Practice');
                  mixpanel.track('Clicked on Listening tab on Practice', {});
                }}>
                <div className="question-tabs__item-wrap">
                  <span className="question-tabs__item-icon question-tabs__item-icon--hide">
                    <ListeningIcon />
                  </span>
                  <span className="question-tabs__item-text">Listening</span>
                </div>
              </Tab>
            </TabList>
          </div>
          <TabPanel>
            {/* SPEAKING*/}

            <Tabs selectedIndex={tabIndexSpeaking} onSelect={index => setTabIndexSpeaking(index)}>
              <div className="tab-switch-wrap tab-switch-wrap--fixed">
                <div className="tab-switch-fixed">
                  <TabList className={`tab-switch tab-switch--two tab-switch--${tabIndexSpeaking + 1}`}>
                    <Tab
                      className="tab-switch__item"
                      onClick={() => {
                        mixpanel.track('Clicked on Speaking Practice tab on Practice', {});
                      }}>
                      <div className="tab-switch__item-wrap">
                        <span className="tab-switch__item-text">Practice</span>
                      </div>
                    </Tab>
                    <Tab
                      className="tab-switch__item"
                      onClick={() => {
                        mixpanel.track('Clicked on Speaking Mock tab on Practice', {});
                      }}>
                      <div className="tab-switch__item-wrap">
                        <span className="tab-switch__item-text">Mock</span>
                      </div>
                    </Tab>
                    <Tab
                      className="tab-switch__item"
                      onClick={() => {
                        mixpanel.track('Clicked on Speaking Study tab on Practice', {});
                      }}>
                      <div className="tab-switch__item-wrap">
                        <span className="tab-switch__item-text">Classes</span>
                      </div>
                    </Tab>
                  </TabList>
                </div>
              </div>
              <TabPanel>
                <LimitAlert
                  isPremium={isPremium}
                  isLoadingDetails={isLoadingDetails}
                  practiceAttemptsLeft={practiceAttemptsLeftSpeaking}
                  section={'Speaking'}
                />
                <LimitRecordingsAlert
                  recordingsLeft={recordingsLeftSpeaking}
                  visible={visibleSpeaking}
                  setVisible={setVisibleSpeaking}
                />
                <PracticeScreen
                  isPremium={isPremium}
                  isLoadingDetails={isLoadingDetails}
                  dataFree={idataFreeSpeaking}
                  dataPremium={idataPremiumSpeaking}
                  section={'Speaking'}
                  isMock={false}
                  withContinue={false}
                  attemptsList={roundAttemptsSpeaking}
                  withSelect={true}
                  iquestiontype={iquestiontypeSpeaking}
                  setiQuestiontype={setiQuestiontypeSpeaking}
                  isPro={isPro}
                />
              </TabPanel>
              <TabPanel>
                <PracticeScreen
                  isPremium={isPremium}
                  isLoadingDetails={isLoadingDetails}
                  dataFree={mockListFreeSpeaking}
                  dataPremium={mockListPremiumSpeaking}
                  section={'Speaking'}
                  isMock={true}
                  withContinue={false}
                  attemptsList={mockAttemptsSpeaking}
                  withSelect={false}
                  isPro={isPro}
                />
              </TabPanel>
              <TabPanel>
                <>
                  {activeClassesLength > 0 && (
                    <>
                      <h4 className="live__title">LIVE Now</h4>
                      {activeClassesMemo.map(el => (
                        <Live key={el.id} limit={freeLimit} setFreeLimit={setFreeLimit} premium={premium} {...el} />
                      ))}
                    </>
                  )}
                </>
                <>
                  {nextClass.length > 0 && (
                    <>
                      <h4 className="live__title">Next Class</h4>

                      {nextClass.map(el => (
                        <Next key={el.id} {...el} timeDifference={timeDifference} />
                      ))}
                    </>
                  )}
                </>
                <Classes upcomingClasses={upcomingClasses} tabIndex={tabIndex} />
                <LearnScreen section={'Speaking'} studyData={studyData} />
              </TabPanel>
            </Tabs>
          </TabPanel>
          <TabPanel>
            {/* WRITING */}

            <Tabs selectedIndex={tabIndexWriting} onSelect={index => setTabIndexWriting(index)}>
              <div className="tab-switch-wrap tab-switch-wrap--fixed">
                <div className="tab-switch-fixed">
                  <TabList className={`tab-switch tab-switch--two tab-switch--${tabIndexWriting + 1}`}>
                    <Tab
                      className="tab-switch__item"
                      onClick={() => {
                        mixpanel.track('Clicked on Writing Practice tab on Practice', {});
                      }}>
                      <div className="tab-switch__item-wrap">
                        <span className="tab-switch__item-text">Practice</span>
                      </div>
                    </Tab>

                    <Tab
                      className="tab-switch__item"
                      onClick={() => {
                        mixpanel.track('Clicked on Writing Study tab on Practice', {});
                      }}>
                      <div className="tab-switch__item-wrap">
                        <span className="tab-switch__item-text">Classes</span>
                      </div>
                    </Tab>
                  </TabList>
                </div>
              </div>
              <TabPanel>
                <LimitAlert
                  isPremium={isPremium}
                  isLoadingDetails={isLoadingDetails}
                  practiceAttemptsLeft={practiceAttemptsLeftWriting}
                  section={'Writing'}
                />
                <PracticeScreen
                  isPremium={isPremium}
                  isLoadingDetails={isLoadingDetails}
                  dataFree={dataFreeWriting}
                  dataPremium={dataPremiumWriting}
                  section={'Writing'}
                  isMock={false}
                  withContinue={true}
                  continueAttempts={continueAttemptsWriting}
                  loadedContinue={loadedContinueWriting}
                  attemptsList={PreviousDataWriting}
                  withSelect={true}
                  iquestiontype={iquestiontypeWriting}
                  setiQuestiontype={setiQuestiontypeWriting}
                  isPro={isPro}
                />
              </TabPanel>
              <TabPanel>
                {/* TIPS */}
                <>
                  {activeClassesLength > 0 && (
                    <>
                      <h4 className="live__title">LIVE Now</h4>
                      {activeClassesMemo.map(el => (
                        <Live key={el.id} limit={freeLimit} setFreeLimit={setFreeLimit} premium={premium} {...el} />
                      ))}
                    </>
                  )}
                </>
                <>
                  {nextClass.length > 0 && (
                    <>
                      <h4 className="live__title">Next Class</h4>

                      {nextClass.map(el => (
                        <Next key={el.id} {...el} timeDifference={timeDifference} />
                      ))}
                    </>
                  )}
                </>
                <Classes upcomingClasses={upcomingClasses} tabIndex={tabIndex} />
                <LearnScreen section={'Writing'} studyData={studyData} />
              </TabPanel>
            </Tabs>
          </TabPanel>
          <TabPanel>
            <Tabs selectedIndex={tabIndexReading} onSelect={index => setTabIndexReading(index)}>
              <div className="tab-switch-wrap tab-switch-wrap--fixed">
                <div className="tab-switch-fixed">
                  <TabList className={`tab-switch tab-switch--two tab-switch--${tabIndexReading + 1}`}>
                    <Tab
                      className="tab-switch__item"
                      onClick={() => {
                        mixpanel.track('Clicked on Reading Practice tab on Practice', {});
                      }}>
                      <div className="tab-switch__item-wrap">
                        <span className="tab-switch__item-text">Practice</span>
                      </div>
                    </Tab>
                    <Tab
                      className="tab-switch__item"
                      onClick={() => {
                        mixpanel.track('Clicked on Reading Mock tab on Practice', {});
                      }}>
                      <div className="tab-switch__item-wrap">
                        <span className="tab-switch__item-text">Mock</span>
                      </div>
                    </Tab>
                    <Tab
                      className="tab-switch__item"
                      onClick={() => {
                        mixpanel.track('Clicked on Reading Study tab on Practice', {});
                      }}>
                      <div className="tab-switch__item-wrap">
                        <span className="tab-switch__item-text">Classes</span>
                      </div>
                    </Tab>
                  </TabList>
                </div>
              </div>
              <TabPanel>
                <LimitAlert
                  isPremium={isPremium}
                  isLoadingDetails={isLoadingDetails}
                  practiceAttemptsLeft={practiceAttemptsLeftReading}
                  section={'Reading'}
                />
                <PracticeScreen
                  isPremium={isPremium}
                  isLoadingDetails={isLoadingDetails}
                  dataFree={dataFreeReading}
                  dataPremium={dataPremiumReading}
                  section={'Reading'}
                  isMock={false}
                  withContinue={true}
                  continueAttempts={practiceContinueReading}
                  loadedContinue={continuePracticeCompleteReading}
                  attemptsList={currentPracticeAttemptsReading}
                  isPro={isPro}
                />
              </TabPanel>
              <TabPanel>
                <PracticeScreen
                  isPremium={isPremium}
                  isLoadingDetails={isLoadingDetails}
                  dataFree={mockFreeReading}
                  dataPremium={mockPremiumReading}
                  section={'Reading'}
                  isMock={true}
                  withContinue={true}
                  continueAttempts={mockContinueReading}
                  loadedContinue={continueMockCompleteReading}
                  attemptsList={currentMockAttemptsReading}
                  isPro={isPro}
                />
              </TabPanel>
              <TabPanel>
                <>
                  {activeClassesLength > 0 && (
                    <>
                      <h4 className="live__title">LIVE Now</h4>
                      {activeClassesMemo.map(el => (
                        <Live key={el.id} limit={freeLimit} setFreeLimit={setFreeLimit} premium={premium} {...el} />
                      ))}
                    </>
                  )}
                </>
                <>
                  {nextClass.length > 0 && (
                    <>
                      <h4 className="live__title">Next Class</h4>

                      {nextClass.map(el => (
                        <Next key={el.id} {...el} timeDifference={timeDifference} />
                      ))}
                    </>
                  )}
                </>
                <Classes upcomingClasses={upcomingClasses} tabIndex={tabIndex} />
                <LearnScreen section={'Reading'} studyData={studyData} />
              </TabPanel>
            </Tabs>
          </TabPanel>
          <TabPanel>
            <Tabs selectedIndex={tabIndexListening} onSelect={index => setTabIndexListening(index)}>
              <div className="tab-switch-wrap tab-switch-wrap--fixed">
                <div className="tab-switch-fixed">
                  <TabList className={`tab-switch tab-switch--two tab-switch--${tabIndexListening + 1}`}>
                    <Tab
                      className="tab-switch__item"
                      onClick={() => {
                        mixpanel.track('Clicked on Listening Practice tab on Practice', {});
                      }}>
                      <div className="tab-switch__item-wrap">
                        <span className="tab-switch__item-text">Practice</span>
                      </div>
                    </Tab>
                    <Tab
                      className="tab-switch__item"
                      onClick={() => {
                        mixpanel.track('Clicked on Listening Mock tab on Practice', {});
                      }}>
                      <div className="tab-switch__item-wrap">
                        <span className="tab-switch__item-text">Mock</span>
                      </div>
                    </Tab>
                    <Tab
                      className="tab-switch__item"
                      onClick={() => {
                        mixpanel.track('Clicked on Reading Study tab on Practice', {});
                      }}>
                      <div className="tab-switch__item-wrap">
                        <span className="tab-switch__item-text">Classes</span>
                      </div>
                    </Tab>
                  </TabList>
                </div>
              </div>
              <TabPanel>
                <LimitAlert
                  isPremium={isPremium}
                  isLoadingDetails={isLoadingDetails}
                  practiceAttemptsLeft={practiceAttemptsLeftListening}
                  section={'Listening'}
                />
                <PracticeScreen
                  isPremium={isPremium}
                  isLoadingDetails={isLoadingDetails}
                  dataFree={practiceFreeListening}
                  dataPremium={practicePremiumListening}
                  section={'Listening'}
                  isMock={false}
                  withContinue={true}
                  continueAttempts={practiceContinueListening}
                  loadedContinue={continuePracticeCompleteListening}
                  attemptsList={currentPracticeAttemptsListening}
                  isPro={isPro}
                />
              </TabPanel>
              <TabPanel>
                <PracticeScreen
                  isPremium={isPremium}
                  isLoadingDetails={isLoadingDetails}
                  dataFree={mockFreeListening}
                  dataPremium={mockPremiumListening}
                  section={'Listening'}
                  isMock={true}
                  withContinue={true}
                  continueAttempts={mockContinueListening}
                  loadedContinue={continueMockCompleteListening}
                  attemptsList={currentMockAttemptsListening}
                  isPro={isPro}
                />
              </TabPanel>
              <TabPanel>
                <>
                  {activeClassesLength > 0 && (
                    <>
                      <h4 className="live__title">LIVE Now</h4>
                      {activeClassesMemo.map(el => (
                        <Live key={el.id} limit={freeLimit} setFreeLimit={setFreeLimit} premium={premium} {...el} />
                      ))}
                    </>
                  )}
                </>
                <>
                  {nextClass.length > 0 && (
                    <>
                      <h4 className="live__title">Next Class</h4>

                      {nextClass.map(el => (
                        <Next key={el.id} {...el} timeDifference={timeDifference} />
                      ))}
                    </>
                  )}
                </>
                <Classes upcomingClasses={upcomingClasses} tabIndex={tabIndex} />
                <LearnScreen section={'Listening'} studyData={studyData} />
              </TabPanel>
            </Tabs>
          </TabPanel>
        </Tabs>
      </div>
    </div>
  );
};

export default PracticePage;
