import * as Actions from "../actions/sopsAction";

const initialState = {
  sops: [],
  categories: ["All"],
  category: "All",
  status: "idle",
  personality: null,
};

const sopsReducer = (state = initialState, action) => {
  switch (action.type) {
    case Actions.LOADING_SOPS_START:
      return {
        ...state,
        status: "idle",
      };

    case Actions.LOADING_SOPS_SUCCESS:
      return {
        ...state,
        sops: action.payload,
        status: "success",
      };

    case Actions.LOADING_SOPS_FAILED:
      return {
        ...state,
        status: "error",
      };

    case Actions.SET_CATEGORIES:
      return {
        ...state,
        categories: [state.category, ...action.payload],
      };

    case Actions.FILTER_BY_CATEGORY:
      return {
        ...state,
        category: action.payload,
      };

    case Actions.LOADING_PERSONALITY_START:
      return {
        ...state,
        status: "idle",
      };

    case Actions.LOADING_PERSONALITY_SUCCESS:
      return {
        ...state,
        personality: action.payload,
        status: "success",
      };

    case Actions.LOADING_PERSONALITY_FAILED:
      return {
        ...state,
        status: "error",
      };

    case Actions.SET_PERSONALITY:
      return {
        ...state,
        personality: action.payload,
      };

    default:
      return state;
  }
};

export default sopsReducer;
