import { getWebinars } from '../api/webinarApi';

export const LOADING_WEBINARS_START = 'LOADING_WEBINARS_START';
export const LOADING_WEBINARS_SUCCESS = 'LOADING_WEBINARS_SUCCESS';
export const LOADING_WEBINARS_FAILED = 'LOADING_WEBINARS_FAILED';

export const loadingWebinarsStartAction = () => {
  return {
    type: LOADING_WEBINARS_START,
  };
};

export const loadingWebinarsSuccessAction = webinars => {
  return {
    type: LOADING_WEBINARS_SUCCESS,
    payload: webinars,
  };
};

export const loadingWebinarsFailedAction = e => {
  return {
    type: LOADING_WEBINARS_FAILED,
    payload: e,
  };
};

export const getAllWebinars = () => async dispatch => {
  try {
    dispatch(loadingWebinarsStartAction());
    const res = await getWebinars();
    dispatch(loadingWebinarsSuccessAction(res.data));
  } catch (error) {
    dispatch(loadingWebinarsFailedAction(error));
  }
};
