// ProgressBar.js
import React from "react";
import s from "./ProgressBar.module.scss";
const ProgressBar = ({ percentage }) => {
  return (
    <div className={s.progress__bar_container}>
      <div className={s.progress_bar} style={{ width: `${percentage}%` }}></div>
    </div>
  );
};

export default ProgressBar;
