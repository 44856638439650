import { getPersonaliy, getSampleSop } from 'api/sopApi';
// sops
export const LOADING_SOPS_START = 'LOADING_SOPS_START';
export const LOADING_SOPS_SUCCESS = 'LOADING_SOPS_SUCCESS';
export const LOADING_SOPS_FAILED = 'LOADING_SOPS_FAILED';
// personality
export const LOADING_PERSONALITY_START = 'LOADING_PERSONALITY_START';
export const LOADING_PERSONALITY_SUCCESS = 'LOADING_PERSONALITY_SUCCESS';
export const LOADING_PERSONALITY_FAILED = 'LOADING_PERSONALITY_FAILED';

// category
export const SET_CATEGORIES = 'SET_CATEGORIES';

// filter

export const FILTER_BY_CATEGORY = 'FILTER_BY_CATEGORY';

export const SET_PERSONALITY = 'SET_PERSONALITY';

export const loadingSopsStartAction = () => {
  return {
    type: LOADING_SOPS_START,
  };
};

export const loadingSopsSuccessAction = sops => {
  return {
    type: LOADING_SOPS_SUCCESS,
    payload: sops,
  };
};

export const loadingSopsFailedAction = e => {
  return {
    type: LOADING_SOPS_FAILED,
    payload: e,
  };
};

export const setCategories = data => ({
  type: SET_CATEGORIES,
  payload: data,
});

export const filterByCategory = selectedCategory => ({
  type: FILTER_BY_CATEGORY,
  payload: selectedCategory,
});

export const loadingPersonalityStartAction = () => {
  return {
    type: LOADING_PERSONALITY_START,
  };
};
export const loadingPersonalitySuccessAction = sops => {
  return {
    type: LOADING_PERSONALITY_SUCCESS,
    payload: sops,
  };
};

export const loadingPersonalityFailedAction = e => {
  return {
    type: LOADING_PERSONALITY_FAILED,
    payload: e,
  };
};

export const setPersonality = data => ({
  type: SET_PERSONALITY,
  payload: data,
});

export const getAllSops = () => async dispatch => {
  try {
    dispatch(loadingSopsStartAction());
    const res = await getSampleSop();
    dispatch(loadingSopsSuccessAction(res.data));

    const resCategory = Array.from(new Set(res.data.map(sop => sop.category)));

    dispatch(setCategories(resCategory));
  } catch (error) {
    dispatch(loadingSopsFailedAction(error));
  }
};

export const getPersonalData = () => async dispatch => {
  try {
    dispatch(loadingPersonalityStartAction());

    const res = await getPersonaliy();

    dispatch(loadingPersonalitySuccessAction(res.data.personality));
  } catch (error) {
    dispatch(loadingPersonalityFailedAction(error));
  }
};
