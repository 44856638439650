import api from "services/api";
export function getSampleSop() {
  return api.get("sop");
}

export function savePersonality(objArgs) {
  if (typeof objArgs !== "object" || !objArgs.hasOwnProperty("personality")) {
    throw new Error("missing required parameters");
  }
  return api.post("user/aigenerator/personality", JSON.stringify(objArgs));
}

export function getPersonaliy() {
  return api.get("user/aigenerator/personality");
}

export function editSop({ sopId, sop }) {
  return api.put(`/user/aigenerator/${sopId}`, { sop: sop });
}
