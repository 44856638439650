import { getMessaging, getToken, onMessage } from 'firebase/messaging'
// import { initializeApp, getApps, getApp } from "firebase/app";
import { myFirebase } from './firebase'
import { addNotificationConfiguration } from 'api/userApi'
import { refreshFCMToken } from '../actions/auth'
import mixpanel from 'mixpanel-browser'

// Initialize Firebase Cloud Messaging and get a reference to the service
export function firebaseInit(store) {
	const { dispatch } = store
	let messaging;
	try {
		messaging = getMessaging(myFirebase);
		onMessage(messaging, (payload) => {
			mixpanel.track("Received push notification")
			console.log('Received foreground message : ', payload)
			const notification = new Notification(payload.notification.title, {
				body: payload.notification.body,
				icon: payload.notification.image,
				data: payload.data,
			})
			notification.onclick = (e) => {
				e.preventDefault()
				console.log('Click occurred : ', e)
				const { data } = e.currentTarget
				notification.close()
				window.open(data.click_action, '_self')
				var redirect_url = data.click_action
			}
		})
	} catch (err) {
		console.error('Failed to initialize Firebase Messaging', err);
	}
	// const messaging = getMessaging(myFirebase)
}
