import { useState } from "react";

import { useMediaQuery } from "react-responsive";
import Slider from "react-slick";
import College from "../../../../assets/img/collegeSOP.png";
import Letter from "../../../../assets/img/letter.png";
import Visa from "../../../../assets/img/VISASOP.png";
import { ReactComponent as Clock } from "../../../../assets/icons/chat/clock.svg";
import mixpanel from 'mixpanel-browser'

import s from "./SopCard.module.scss";
import Modal from "react-bootstrap/Modal";
import { Link, useHistory } from "react-router-dom/cjs/react-router-dom.min";
import ProgressBar from "kandor-components/components/ProgressBar/ProgressBar";
const settings = {
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: false,
  infinite: false,
  initialSlide: 1,
  autoplay: false,
  autoplaySpeed: 3000,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        infinite: false,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        infinite: false,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        infinite: false,
      },
    },
    {
      breakpoint: 375,
      settings: {
        slidesToShow: 1.5,
        slidesToScroll: 1,
        infinite: false,
      },
    },
  ],
};

const SopCards = () => {
  const [show, setShow] = useState(false);
  const history = useHistory();

  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });
  const data = {
    data: "chat",
  };

  const goToChat = route => {
    mixpanel.track("Open SOP Chat from dashboard", {'route': route});
    history.push(`/admin/sopchat/${route}`);
  };
  return (
    <>
      {!isMobile ? (
        <>
          <div className={s.sop_container}>
            <div className={s.sop_card}>
              <div className={s.sop_card_img}>
                <img src={College} alt="" />
              </div>
              <h5 className={s.sop_card_title}>College SOP</h5>
              <p className={s.sop_card_time}>
                <Clock style={{ marginRight: "5px" }} />
                20 min
              </p>
              <p className={s.sop_card_desc}>
                Craft a compelling statement that showcases your academic
                journey and goals for admission success.
              </p>
              <div className={s.sop_card_content}>
                <button
                  onClick={() => goToChat("college")}
                  className={s.sop_generate}
                >
                  Generate Now!
                </button>
              </div>
            </div>
            <div className={s.sop__line} />
            <div className={s.sop_card}>
              <div className={s.sop_card_img}>
                <img src={Visa} alt="" />
              </div>
              <h5 className={s.sop_card_title}>VISA SOP</h5>
              <p className={s.sop_card_time}>
                {" "}
                <Clock style={{ marginRight: "5px" }} />
                20 min
              </p>
              <p className={s.sop_card_desc}>
                Explain your intentions and ties to your home country for a
                successful study visa application.
              </p>
              <div className={s.sop_card_content}>
                <button
                  onClick={() => goToChat("visa")}
                  className={s.sop_generate}
                >
                  Generate Now!{" "}
                </button>
              </div>
            </div>
            <div className={s.sop__line} />
            <div className={s.sop_card}>
              <div className={s.sop_card_img}>
                <img src={Letter} alt="" />
              </div>
              <h5 className={s.sop_card_title}>Letter Of Recommendation</h5>
              <p className={s.sop_card_time}>
                <Clock style={{ marginRight: "5px" }} /> 20 min
              </p>
              <p className={s.sop_card_desc}>
                Request and manage impactful letters that highlight your
                qualities and support your application.
              </p>
              <div className={s.sop_card_content}>
                <button
                  onClick={() => goToChat("letter")}
                  className={s.sop_generate}
                  disabled
                >
                  Coming Soon
                </button>
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <Slider className={s.sop__slider} {...settings}>
            <div className={s.sop_card}>
              <div className={s.sop_card_img}>
                <img src={College} alt="" />
              </div>
              <h5 className={s.sop_card_title}>College SOP</h5>
              <p className={s.sop_card_time}>
                <Clock style={{ marginRight: "5px" }} />
                20 min
              </p>
              <p className={s.sop_card_desc}>
                Craft a compelling statement that showcases your academic
                journey and goals for admission success.
              </p>
              <div className={s.sop_card_content}>
                <button
                  onClick={() => goToChat("college")}
                  className={s.sop_generate}
                >
                  Generate Now!
                </button>
              </div>
            </div>

            <div className={s.sop_card}>
              <div className={s.sop_card_img}>
                <img src={Visa} alt="" />
              </div>
              <h5 className={s.sop_card_title}>VISA SOP</h5>
              <p className={s.sop_card_time}>
                <Clock style={{ marginRight: "5px" }} />
                20 min
              </p>
              <p className={s.sop_card_desc}>
                Explain your intentions and ties to your home country for a
                successful study visa application.
              </p>
              <div className={s.sop_card_content}>
                <button
                  onClick={() => goToChat("visa")}
                  className={s.sop_generate}
                >
                  Generate Now!{" "}
                </button>
              </div>
            </div>

            {/* <div className={s.sop_card}>
              <div className={s.sop_card_img}>
                <img src={Letter} alt="" />
              </div>
              <h5 className={s.sop_card_title}>Letter Of Recommendation</h5>
              <p className={s.sop_card_time}>
                <Clock style={{ marginRight: "5px" }} />
                20 min
              </p>
              <p className={s.sop_card_desc}>
                Request and manage impactful letters that highlight your qualities and support your application.
              </p>
              <div className={s.sop_card_content}>
                <button
                  onClick={() => goToChat("letter")}
                  className={s.sop_generate}
                >
                  Generate Now!{" "}
                </button>
              </div>
            </div> */}
          </Slider>
          {/* <ProgressBar margin={25} /> */}
        </>
      )}
    </>
  );
};

export default SopCards;
