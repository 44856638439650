import CounselingAdminPage from 'pages/CounselingAdminPage';
import Marketplace from 'pages/Marketplace/Marketplace';
import MyIelts from 'pages/MyIelts.js';
import PracticePage from 'pages/PracticePage.js';
import React from 'react';
import { ReactComponent as ActiveCommunityIcon } from '../assets/icons/sidebar/New/Community/Active_Community.svg';
import { ReactComponent as CommunityIconForMobile } from '../assets/icons/sidebar/New/Community/CommunityForMobile.svg';
import { ReactComponent as CommunityIconForPC } from '../assets/icons/sidebar/New/Community/CommunityForPC.svg';
import { ReactComponent as ActiveCounselorsIcon } from '../assets/icons/sidebar/New/Counselors/Active_Counselors.svg';
import { ReactComponent as CounselorsIcon } from '../assets/icons/sidebar/New/Counselors/Counselors.svg';
import { ReactComponent as ActiveHomeIcon } from '../assets/icons/sidebar/New/Home/Active_Home.svg';
import { ReactComponent as HomeIcon } from '../assets/icons/sidebar/New/Home/Home.svg';
import { ReactComponent as ActiveIELTSIcon } from '../assets/icons/sidebar/New/IELTS/Active_IELTS.svg';
import { ReactComponent as IELTSIcon } from '../assets/icons/sidebar/New/IELTS/IELTS.svg';
import { ReactComponent as ActiveSOPIcon } from '../assets/icons/sidebar/New/SOP/Active_Sop.svg';
import { ReactComponent as ActiveSOPMobileIcon } from '../assets/icons/sidebar/New/SOP/Active_Sop_mobile.svg';
import { ReactComponent as SOPIcon } from '../assets/icons/sidebar/New/SOP/Sop.svg';
import Sop from '../kandor-components/components/Sop/Sop';

const icons = {
  Home: HomeIcon,
  IELTS: IELTSIcon,
  SOP: SOPIcon,
  Counselors: CounselorsIcon,
  Community: CommunityIconForPC,
};

const activeIcons = {
  Home: ActiveHomeIcon,
  IELTS: ActiveIELTSIcon,
  SOP: ActiveSOPIcon,
  Counselors: ActiveCounselorsIcon,
  Community: ActiveCommunityIcon,
};

const routesConfig = [
  {
    path: '/myIelts',
    name: 'Home',
    icon: <icons.Home />,
    iconForMobile: <icons.Home />,

    activeIcon: <activeIcons.Home />,
    activeIconMobile: <activeIcons.Home />,
    component: MyIelts,
    layout: '/admin',
  },
  {
    path: '/practice',
    name: 'IELTS',
    icon: <icons.IELTS />,
    iconForMobile: <icons.IELTS />,
    activeIcon: <activeIcons.IELTS />,
    activeIconMobile: <activeIcons.IELTS />,
    component: PracticePage,
    layout: '/admin',
  },
  {
    name: 'AI Tools',
    path: '/aitools',
    icon: <icons.SOP />,
    iconForMobile: <icons.SOP />,
    activeIcon: <activeIcons.SOP />,
    activeIconMobile: <ActiveSOPMobileIcon />,
    component: Sop,
    layout: '/admin',
  },
  {
    name: 'Study Abroad',
    path: '/counsellors',
    icon: <icons.Counselors />,
    iconForMobile: <icons.Counselors />,
    activeIcon: <activeIcons.Counselors />,
    activeIconMobile: <activeIcons.Counselors />,
    component: Marketplace,
    layout: '/admin',
  },
  {
    name: 'Community',
    path: '/community',
    icon: <CommunityIconForPC />,
    iconForMobile: <CommunityIconForMobile />,
    activeIcon: <activeIcons.Community />,
    activeIconMobile: <activeIcons.Community />,
    component: CounselingAdminPage,
    layout: '/admin',
  },
];

export const routes = routesConfig.map(route => ({
  ...route,
  icon: route.icon.props.children, // Extracting the icon component's children
  activeIcon: route.activeIcon.props.children, // Extracting the active icon component's children
}));

export default routesConfig;
