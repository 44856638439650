import React, { useState, useEffect } from "react";
import CounselingHome from "landing-pages/counseling-page/index.js";
import { CounselingModal } from "kandor-components/CounselingButton";
import { Button } from "reactstrap";

import "./styles/CounselingPage.scss";
import mixpanel from "mixpanel-browser";

const CounselingAdminPage = () => {
  const [isOpenCounseling, setIsOpenCounseling] = useState(false);

  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    document.querySelector(".main-panel").scrollTop = 0;
  }, []);

  return (
    <div
      // className="content-myielts content content--counseling"
      style={{
        minHeight: "calc(var(--vh, 1vh) * 100)",
      }}
    >
      <CounselingHome isBigFooter={true} withLogIn={false} />

      <div className="counseling-footer">
        <Button
          color="info"
          className="counseling-footer__btn"
          onClick={() => {
            mixpanel.track(
              `Book Counseling from Footer button on Counseling Page`,
              {}
            );
            setIsOpenCounseling(true);
          }}
        >
          Book counselling session
        </Button>
      </div>

      <CounselingModal
        isOpen={isOpenCounseling}
        onClose={() => setIsOpenCounseling(false)}
      />
    </div>
  );
};

export default CounselingAdminPage;
