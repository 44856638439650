import * as Actions from '../actions/wishlistActions';

const initialState = {
  wishlist: [],
  status: 'idle',
};

const WishlistReducer = (state = initialState, action) => {
  switch (action.type) {
    case Actions.LOADING_WISHLIST_START: {
      return {
        ...state,
        status: 'idle',
      };
    }

    case Actions.LOADING_WISHLIST_SUCCESS: {
      return {
        ...state,
        wishlist: action.payload,
        status: 'success',
      };
    }
    case Actions.LOADING_WISHLIST_FAILED: {
      return {
        ...state,
        status: 'error',
      };
    }

    case Actions.ADD_TO_WISHLIST: {
      return {
        ...state,
        wishlist: [...state.wishlist, action.payload],
      };
    }

    case Actions.REMOVE_FROM_WISHLIST: {
      return {
        ...state,
        wishlist: state.wishlist.filter(course => course.course_id !== action.payload),
      };
    }

    default:
      return state;
  }
};

export default WishlistReducer;
