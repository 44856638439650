import * as Actions from '../actions/autoFillAction';

const initialState = {
  autofillUniversity: [],
  autoFillShortlister: [],
  autoFillCourses: [],
  autoFillUndergraduate: [],

  autoFillPostgraduate: [],
  status: 'idle',
};
const AutoFillReducer = (state = initialState, action) => {
  switch (action.type) {
    case Actions.LOADING_AUTOFILL_START: {
      return {
        ...state,
        status: 'idle',
      };
    }

    case Actions.LOADING_AUTOFILL_SUCCESS: {
      return {
        ...state,
        autofillUniversity: action.payload,
        status: 'success',
      };
    }

    case Actions.LOADING_AUTOFILL_SHORTLISTER_SUCCESS: {
      return {
        ...state,
        autoFillShortlister: action.payload,
        status: 'success',
      };
    }

    case Actions.LOADING_AUTOFILL_UNDER_SUCCESS: {
      return {
        ...state,
        autoFillUndergraduate: action.payload,
        status: 'success',
      };
    }

    case Actions.LOADING_AUTOFILL_POST_SUCCESS: {
      return {
        ...state,
        autoFillPostgraduate: action.payload,
        status: 'success',
      };
    }

    case Actions.LOADING_AUTOFILL_FAILED: {
      return {
        ...state,
        status: 'error',
      };
    }

    default:
      return state;
  }
};

export default AutoFillReducer;
