import React, { Suspense } from "react";
import ImproveSection from "./ImproveSection/ImproveSection";
import "./../index.scss";
import lazyRetry from "./../../utils/lazyRetry";

const UniversitiesSection = React.lazy(() =>
  lazyRetry(
    () => import("./UniversitiesSection/UniversitiesSection"),
    "universitiesSection"
  )
);
const InfoContent = React.lazy(() =>
  lazyRetry(() => import("./InfoSection/InfoSections"), "InfoContent")
);
const InfoSectionTrust = React.lazy(() =>
  lazyRetry(() => import("./InfoSection/InfoSectionTrust"), "InfoSectionTrust")
);
const GuidanceSection = React.lazy(() =>
  lazyRetry(
    () => import("./GuidanceSection/GuidanceSection"),
    "GuidanceSection"
  )
);
const StoriesSection = React.lazy(() =>
  lazyRetry(() => import("./StoriesSection/StoriesSection"), "StoriesSection")
);
const CounselorsSection = React.lazy(() =>
  lazyRetry(
    () => import("./CounselorsSection/CounselorsSection"),
    "CounselorsSection"
  )
);
const ProcessSection = React.lazy(() =>
  lazyRetry(() => import("./ProcessSection/ProcessSection"), "ProcessSection")
);
const BookSection = React.lazy(() =>
  lazyRetry(() => import("./BookSection/BookSection"), "BookSection")
);
const QuestionsSection = React.lazy(() =>
  lazyRetry(
    () => import("./QuestionsSection/QuestionsSection"),
    "QuestionsSection"
  )
);

const CounselingHome = ({ isBigFooter = false, withLogIn = true }) => {
  return (
    <div className="body-style-akbar landing-wrap">
      <ImproveSection isBigFooter={isBigFooter} withLogIn={withLogIn} />
      <UniversitiesSection />
      <InfoContent />
      <GuidanceSection />
      <InfoSectionTrust />
      <StoriesSection />
      <CounselorsSection />
      <ProcessSection />
      <BookSection />
      <QuestionsSection isBigFooter={isBigFooter} />
    </div>
  );
};

export default CounselingHome;
