import React from 'react';
import s from './BannerContent.module.scss';
const BannerContent = ({ title, desc = null, descSpan }) => {
  return (
    <div className={s.content}>
      <h1 className={s.title}>{title}</h1>
      {desc && <p className={s.desc}>{desc}</p>}

      {descSpan && descSpan}
    </div>
  );
};

export default BannerContent;
