import React, {useState} from "react";

import s from './CopyReferral.module.scss'
import { Tooltip } from 'antd';
import { useMediaQuery } from 'react-responsive';
import { ReactComponent as Copy } from '../../../assets/icons/price/copy.svg';
import { ReactComponent as CopyMobile } from '../../../assets/icons/price/copyMobile.svg';

const CopyReferral = ({referralLink}) => {
  const isMobile = useMediaQuery({ query: '(max-width:768px)' });
   const [copyingIndicator, setCopyingIndicator] = useState(false);
   const copyToClipboard = async () => {
      if (navigator && navigator.clipboard) {
          await navigator.clipboard.writeText(referralLink);
          setCopyingIndicator((prev) => true);
      }
  };
  
  return (
    <div className={s.link}>
      <span className={s.link__title}>
          Your Referral Link
      </span>
      <div className={s.link__container}>
        <div className={s.link__text}>
          <span>
            {referralLink}
          </span>
        </div>
          <Tooltip
              placement="bottom"
              mouseLeaveDelay={isMobile ? 0.5 : 0.1}
              arrow={false}
              title={
                  copyingIndicator
                      ? 'Copied!'
                      : 'Copy to clipboard'
              }
          >
              <button
                  className={s.link__copy}
                  onClick={copyToClipboard}
              >
                  {isMobile ? <CopyMobile /> : <Copy />}
              </button>
          </Tooltip>
        </div>
    </div>
  )
}

export default CopyReferral