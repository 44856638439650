import React, { useEffect, useState } from "react";

import './ButtonScroll.scss'
import { useMediaQuery } from "react-responsive";

const ButtonScroll = ({ text, blockRef, mobileHeader, display, delay }) => {
  const isTablet = useMediaQuery({ query: '(max-width: 1200px' })

  const [isActive, setIsActive] = useState(false)
  const [isActiveStart, setIsActiveStart] = useState(false)
  
  useEffect(() => {
    let timeout;
    if (display >= 1) {
      if (delay) {
        timeout = setTimeout(() => {
          setIsActiveStart(true);
        }, delay);
      } else {
        setIsActiveStart(true);
      }
    } else {
      setIsActiveStart(false);
    }

    return () => {
      clearTimeout(timeout)
    }
  }, [display, delay])

  const scrollTo = () => {
    if (!blockRef.current) return;
    const scrollBlock = blockRef.current.closest('.white-content');
    const topEl = blockRef.current.getBoundingClientRect().top;
    let scrollVal = topEl - 50;

    if (isTablet) {
      scrollVal = topEl - 50 - mobileHeader;
    }

    scrollBlock.scrollBy({
      left: 0,
      top: scrollVal,
      behavior: "smooth"
    });
  };

  useEffect(() => {
    console.log('isActiveStart', isActiveStart);
    if (!blockRef.current || !isActiveStart) return;
    const scrollBlock = blockRef.current.closest('.white-content');
    const windowHeight = document.documentElement.clientHeight

    console.log('scroll');

    const changeActive = (e) => {
      const topEl = blockRef.current.getBoundingClientRect().top;
      const isVisible = windowHeight >= topEl + mobileHeader - 70
      
      if (isVisible) {
        setIsActive(false)
      } else if (display) {
        setIsActive(true)
      }

    }

    scrollBlock.addEventListener('scroll', changeActive)

    return () => {
       scrollBlock.removeEventListener('scroll', changeActive)
    }
  }, [blockRef, display, isActiveStart])
  
  return (
      <button type="button" className={`button-scroll ${isActive ? 'active' : ''}`} onClick={scrollTo}>
        {text}
      </button>
    )
}

export default ButtonScroll
