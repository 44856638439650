import React, { useEffect, useState } from "react";
import s from "./Experience.module.scss";
import { useMediaQuery } from "react-responsive";
import { ReactComponent as USA } from "../../../../assets/icons/marketplace/flags/USA.svg";
import { ReactComponent as Canada } from "../../../../assets/icons/marketplace/flags/Canada.svg";
import { ReactComponent as NZL } from "../../../../assets/icons/marketplace/flags/NewZealand.svg";
import { ReactComponent as UK } from "../../../../assets/icons/marketplace/flags/England.svg";
import { ReactComponent as AUS } from "../../../../assets/icons/marketplace/flags/Australia.svg";
import { ReactComponent as IRL } from "../../../../assets/icons/marketplace/flags/Ireland.svg";
const mapOfFlags = {
  USA: (
    <div className={s.card_country}>
      <USA style={{ marginRight: "4px" }} />
      USA
    </div>
  ),
  Canada: (
    <div className={s.card_country}>
      <Canada style={{ marginRight: "4px" }} />
      CAN
    </div>
  ),
  "New Zealand": (
    <div className={s.card_country}>
      <NZL style={{ marginRight: "4px" }} />
      NZL
    </div>
  ),
  UK: (
    <div className={s.card_country}>
      <UK style={{ marginRight: "4px" }} />
      UK
    </div>
  ),
  Australia: (
    <div className={s.card_country}>
      <AUS style={{ marginRight: "4px" }} />
      AUS
    </div>
  ),
  IRL: (
    <div className={s.card_country}>
      <IRL style={{ marginRight: "4px" }} />
      IRL
    </div>
  ),
};

const Experience = ({
  description,
  company,
  services,
  countries,
  students_mentored,
}) => {
  const isMobile = useMediaQuery({ query: "(max-width:767px)" });
  const [dispCountry, setDispCountry] = useState(null);

  useEffect(() => {
    setDispCountry(renderCountry(countries));
  }, []);

  const renderCountry = countries => {
    return JSON.parse(countries).map(country => (
      <div className={s.experience__info_country_type}>
        {mapOfFlags[country]}
      </div>
    ));
  };

  return (
    <section className={s.experience__section}>
      <div className={s.experience}>
        <h4 className={s.experience__title}>{company}</h4>
        <p className={s.experience__desc}>{description}</p>
      </div>
      <div className={s.experience__info}>
        {isMobile ? (
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: "17px",
            }}
          >
            <div className={s.experience__info_country}>
              <h3 className={s.experience__info_country__title}>Country:</h3>
              <div className={s.experience__info_country_types_mobile}>
                {countries && dispCountry}
              </div>
            </div>
            <div className={s.experience__info_student}>
              <h3 className={s.experience__info_student__title}>
                Students mentored:
              </h3>
              <div className={s.experience__info_student_types}>
                {isMobile ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="23"
                    height="13"
                    viewBox="0 0 23 13"
                    fill="none"
                  >
                    <path
                      d="M11.5664 7.24068C13.1133 7.24068 14.4798 7.61079 15.5902 8.09478C16.6151 8.55029 17.2604 9.57521 17.2604 10.6855V12.2229H5.87244V10.695C5.87244 9.57521 6.51776 8.55029 7.54267 8.10427C8.653 7.61079 10.0196 7.24068 11.5664 7.24068ZM3.97445 7.47793C5.01834 7.47793 5.87244 6.62383 5.87244 5.57994C5.87244 4.53604 5.01834 3.68195 3.97445 3.68195C2.93055 3.68195 2.07646 4.53604 2.07646 5.57994C2.07646 6.62383 2.93055 7.47793 3.97445 7.47793ZM5.04681 8.52182C4.69569 8.46488 4.34456 8.42692 3.97445 8.42692C3.03494 8.42692 2.14289 8.62621 1.33624 8.97734C0.633985 9.28102 0.178467 9.9643 0.178467 10.733V12.2229H4.44895V10.695C4.44895 9.90736 4.66722 9.16714 5.04681 8.52182ZM19.1584 7.47793C20.2023 7.47793 21.0564 6.62383 21.0564 5.57994C21.0564 4.53604 20.2023 3.68195 19.1584 3.68195C18.1145 3.68195 17.2604 4.53604 17.2604 5.57994C17.2604 6.62383 18.1145 7.47793 19.1584 7.47793ZM22.9544 10.733C22.9544 9.9643 22.4988 9.28102 21.7966 8.97734C20.9899 8.62621 20.0979 8.42692 19.1584 8.42692C18.7883 8.42692 18.4371 8.46488 18.086 8.52182C18.4656 9.16714 18.6839 9.90736 18.6839 10.695V12.2229H22.9544V10.733ZM11.5664 0.834961C13.1417 0.834961 14.4134 2.10661 14.4134 3.68195C14.4134 5.25728 13.1417 6.52893 11.5664 6.52893C9.99108 6.52893 8.71943 5.25728 8.71943 3.68195C8.71943 2.10661 9.99108 0.834961 11.5664 0.834961Z"
                      fill="#909090"
                    />
                  </svg>
                ) : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="31"
                    height="16"
                    viewBox="0 0 31 16"
                    fill="none"
                  >
                    <path
                      d="M15.5 8.71875C17.6054 8.71875 19.4654 9.2225 20.9767 9.88125C22.3717 10.5012 23.25 11.8962 23.25 13.4075V15.5H7.75V13.4204C7.75 11.8962 8.62833 10.5012 10.0233 9.89417C11.5346 9.2225 13.3946 8.71875 15.5 8.71875ZM5.16667 9.04167C6.5875 9.04167 7.75 7.87917 7.75 6.45833C7.75 5.0375 6.5875 3.875 5.16667 3.875C3.74583 3.875 2.58333 5.0375 2.58333 6.45833C2.58333 7.87917 3.74583 9.04167 5.16667 9.04167ZM6.62625 10.4625C6.14833 10.385 5.67042 10.3333 5.16667 10.3333C3.88792 10.3333 2.67375 10.6046 1.57583 11.0825C0.62 11.4958 0 12.4258 0 13.4721V15.5H5.8125V13.4204C5.8125 12.3483 6.10958 11.3408 6.62625 10.4625ZM25.8333 9.04167C27.2542 9.04167 28.4167 7.87917 28.4167 6.45833C28.4167 5.0375 27.2542 3.875 25.8333 3.875C24.4125 3.875 23.25 5.0375 23.25 6.45833C23.25 7.87917 24.4125 9.04167 25.8333 9.04167ZM31 13.4721C31 12.4258 30.38 11.4958 29.4242 11.0825C28.3263 10.6046 27.1121 10.3333 25.8333 10.3333C25.3296 10.3333 24.8517 10.385 24.3738 10.4625C24.8904 11.3408 25.1875 12.3483 25.1875 13.4204V15.5H31V13.4721ZM15.5 0C17.6442 0 19.375 1.73083 19.375 3.875C19.375 6.01917 17.6442 7.75 15.5 7.75C13.3558 7.75 11.625 6.01917 11.625 3.875C11.625 1.73083 13.3558 0 15.5 0Z"
                      fill="#909090"
                    />
                  </svg>
                )}
                <p className={s.experience__info_student_type}>
                  {students_mentored}
                </p>
              </div>
            </div>{" "}
          </div>
        ) : (
          <>
            <div className={s.experience__info_country}>
              <h3 className={s.experience__info_country__title}>Country:</h3>
              <div className={s.experience__info_country_types}>
                {countries && dispCountry}
              </div>
            </div>
            <div className={s.experience__info_student}>
              <h3 className={s.experience__info_student__title}>
                Students mentored:
              </h3>
              <div className={s.experience__info_student_types}>
                {isMobile ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="23"
                    height="13"
                    viewBox="0 0 23 13"
                    fill="none"
                  >
                    <path
                      d="M11.5664 7.24068C13.1133 7.24068 14.4798 7.61079 15.5902 8.09478C16.6151 8.55029 17.2604 9.57521 17.2604 10.6855V12.2229H5.87244V10.695C5.87244 9.57521 6.51776 8.55029 7.54267 8.10427C8.653 7.61079 10.0196 7.24068 11.5664 7.24068ZM3.97445 7.47793C5.01834 7.47793 5.87244 6.62383 5.87244 5.57994C5.87244 4.53604 5.01834 3.68195 3.97445 3.68195C2.93055 3.68195 2.07646 4.53604 2.07646 5.57994C2.07646 6.62383 2.93055 7.47793 3.97445 7.47793ZM5.04681 8.52182C4.69569 8.46488 4.34456 8.42692 3.97445 8.42692C3.03494 8.42692 2.14289 8.62621 1.33624 8.97734C0.633985 9.28102 0.178467 9.9643 0.178467 10.733V12.2229H4.44895V10.695C4.44895 9.90736 4.66722 9.16714 5.04681 8.52182ZM19.1584 7.47793C20.2023 7.47793 21.0564 6.62383 21.0564 5.57994C21.0564 4.53604 20.2023 3.68195 19.1584 3.68195C18.1145 3.68195 17.2604 4.53604 17.2604 5.57994C17.2604 6.62383 18.1145 7.47793 19.1584 7.47793ZM22.9544 10.733C22.9544 9.9643 22.4988 9.28102 21.7966 8.97734C20.9899 8.62621 20.0979 8.42692 19.1584 8.42692C18.7883 8.42692 18.4371 8.46488 18.086 8.52182C18.4656 9.16714 18.6839 9.90736 18.6839 10.695V12.2229H22.9544V10.733ZM11.5664 0.834961C13.1417 0.834961 14.4134 2.10661 14.4134 3.68195C14.4134 5.25728 13.1417 6.52893 11.5664 6.52893C9.99108 6.52893 8.71943 5.25728 8.71943 3.68195C8.71943 2.10661 9.99108 0.834961 11.5664 0.834961Z"
                      fill="#909090"
                    />
                  </svg>
                ) : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="31"
                    height="16"
                    viewBox="0 0 31 16"
                    fill="none"
                  >
                    <path
                      d="M15.5 8.71875C17.6054 8.71875 19.4654 9.2225 20.9767 9.88125C22.3717 10.5012 23.25 11.8962 23.25 13.4075V15.5H7.75V13.4204C7.75 11.8962 8.62833 10.5012 10.0233 9.89417C11.5346 9.2225 13.3946 8.71875 15.5 8.71875ZM5.16667 9.04167C6.5875 9.04167 7.75 7.87917 7.75 6.45833C7.75 5.0375 6.5875 3.875 5.16667 3.875C3.74583 3.875 2.58333 5.0375 2.58333 6.45833C2.58333 7.87917 3.74583 9.04167 5.16667 9.04167ZM6.62625 10.4625C6.14833 10.385 5.67042 10.3333 5.16667 10.3333C3.88792 10.3333 2.67375 10.6046 1.57583 11.0825C0.62 11.4958 0 12.4258 0 13.4721V15.5H5.8125V13.4204C5.8125 12.3483 6.10958 11.3408 6.62625 10.4625ZM25.8333 9.04167C27.2542 9.04167 28.4167 7.87917 28.4167 6.45833C28.4167 5.0375 27.2542 3.875 25.8333 3.875C24.4125 3.875 23.25 5.0375 23.25 6.45833C23.25 7.87917 24.4125 9.04167 25.8333 9.04167ZM31 13.4721C31 12.4258 30.38 11.4958 29.4242 11.0825C28.3263 10.6046 27.1121 10.3333 25.8333 10.3333C25.3296 10.3333 24.8517 10.385 24.3738 10.4625C24.8904 11.3408 25.1875 12.3483 25.1875 13.4204V15.5H31V13.4721ZM15.5 0C17.6442 0 19.375 1.73083 19.375 3.875C19.375 6.01917 17.6442 7.75 15.5 7.75C13.3558 7.75 11.625 6.01917 11.625 3.875C11.625 1.73083 13.3558 0 15.5 0Z"
                      fill="#909090"
                    />
                  </svg>
                )}
                <p className={s.experience__info_student_type}>
                  {students_mentored}
                </p>
              </div>
            </div>
          </>
        )}
      </div>
    </section>
  );
};

export default Experience;
