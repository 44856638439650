import React, { useEffect, useState } from 'react'
import './message.css'
const TypeWriter = ({ content = '', speed = 400 }) => {
	const [displayedContent, setDisplayedContent] = useState('')
	const [index, setIndex] = useState(0)
	useEffect(() => {
		/*Create a new setInterval and store its id*/
		const animKey = setInterval(() => {
			setIndex((index) => {
				/*This setState function will set the index
        to index+1 if there is more content otherwise
        it will destory this animation*/
				if (index >= content.length - 1) {
					clearInterval(animKey)
					return index
				}
				return index + 1
			})
		}, speed)
	}, [])
	useEffect(() => {
		setDisplayedContent((displayedContent) => displayedContent + content[index])
	}, [index])
	return <p className="type-writer title-gradient">{displayedContent}</p>
}
const sample_content = `I moved from band 4.5 to band 6 in a month. The feedbacks and comments
were top-notch!`
const UserMessage = () => <TypeWriter content={sample_content} speed={1} />
export default UserMessage
