import { getGroupRecordings } from 'api/ieltsApi'
import mixpanel from 'mixpanel-browser'

export const fetchAllRecordingsReading = async ({
	testId,
	attemptId,
	history,
	isMock,
}) => {
	if (isMock) {
		mixpanel.track('Clicked View Analysis Mock Reading', {})
		history.push('/admin/readingielts/mock/' + testId + '/result/' + attemptId)
	} else {
		mixpanel.track('Clicked View Analysis Round Reading', {})

		history.push('/admin/readingielts/round/' + testId + '/result/' + attemptId)
	}
}

export const fetchAllRecordingsSpeaking = async ({ testId, history }) => {
	if (testId !== -1) {
		var recordingsType = 'ielts'
		const response = await getGroupRecordings({ id: testId })

		let recordingNo = -1
		let created = 0
		const responseContent = await response.data
		responseContent.map((e, key) => {
			if (new Date(e.created).valueOf() >= created) {
				recordingNo = e.id
				created = new Date(e.created).valueOf()
			}
		})

		if (recordingNo === -1) {
		} else {
			mixpanel.track('Clicked See result Speaking', {})
			history.push(
				`/admin/${
					recordingsType === 'ielts' ? 'videoielts' : 'video'
				}/${recordingNo}`
			)
		}
	}
}

export const fetchAllRecordingsMockSpeaking = ({
	testId,
	attemptId,
	history,
}) => {
	mixpanel.track('Clicked See result Speaking Mock', {})
	history.push({
		pathname: '/admin/vMock/' + testId + '/' + attemptId + '/overview',
	})
}

export const fetchAllRecordingsListening = ({
	testId,
	attemptId,
	isMock,
	history,
}) => {
	if (isMock) {
		mixpanel.track('Clicked View Listening Mock Analysis', {})
		history.push(
			'/admin/listeningielts/mock/' + testId + '/result/' + attemptId
		)
	} else {
		mixpanel.track('Clicked View Listening Round Analysis', {})
		history.push(
			'/admin/listeningielts/round/' + testId + '/result/' + attemptId
		)
	}
}

export const fetchAllRecordingsWriting = ({ attemptId, history }) => {
	mixpanel.track('Clicked See Writing result', {})
	history.push({
		pathname: '/admin/writingielts/' + attemptId + '/overview',
	})
}
