import React, { useState, useEffect } from 'react'
import { Button } from 'reactstrap'
import { ReactComponent as PlayIcon } from '../assets/kandor-img/play-white.svg'
import VimeoModal from '../kandor-components/VimeoModal'
import MaterialAccordion from '../kandor-components/MaterialAccordion'
import './newChanges/LearnScreen.scss'
//import { dataVideo } from './helpers/constants'
import mixpanel from 'mixpanel-browser'

const LearnScreen = ({
	studyData,
	section,
	startPractice = () => {},
	studyMaterial = () => {},
}) => {
	const [learnList, setLearnList] = useState([])
	const [isVisitedVideo, setIsVisitedVideo] = useState(false)
	const [firstVideo, setFirstVideo] = useState('')
	const [isShowFirstModal, setIsShowFirstModal] = useState(false)
	const [activeAccordion, setActiveAccordion] = useState(0)

	const compareStudy = (a, b) => {
		const groupA = +a.numberGroup >= 0 ? +a.numberGroup : 0
		const groupB = +b.numberGroup >= 0 ? +b.numberGroup : 0

		let comparison = 0

		if (groupA > groupB) {
			comparison = 1
		} else if (groupA < groupB) {
			comparison = -1
		}
		return comparison
	}

	const filterStudyData = (arr) => {
		const filter = (arrayData, first) => {
			const studyArray = []
			if (!arrayData.length) return []

			const nameGroup = arrayData[0]?.titleGroup

			if (!nameGroup) return

			const newArr = arrayData.filter((el) => el.titleGroup == nameGroup)
			const resArr = arrayData.filter((el) => el.titleGroup != nameGroup)

			studyArray.push({
				titleGroup: newArr[0].titleGroup,
				numberGroup: newArr[0].numberGroup,
				data: newArr,
			})

			if (resArr.length) {
				studyArray.push(...filter(resArr, false))
			}

			if (first) {
				console.log('studyArray', studyArray)
			}

			return studyArray
		}

		const resultArr = filter(arr, true)?.sort(compareStudy)

		return resultArr
	}

	const compareVideo = (a, b) => {
		const numA = a?.numberGroup
		const numB = b?.numberGroup

		let comparison = 0
		if (numA > numB) {
			comparison = 1
		} else if (numA < numB) {
			comparison = -1
		}
		return comparison
	}

	const getActiveAccordion = (arr, firstWatched) => {
		let numActiveAccordion = arr.findIndex((group) => {
			let isWatched = false

			if (Array.isArray(group?.data)) {
				group.data.forEach((el) => {
					isWatched = isWatched || el.isWatched
				})

				return !isWatched
			}

			return false
		})

		if (numActiveAccordion == -1) {
			return 0
		} else if (numActiveAccordion == 0) {
			if (firstWatched) {
				return 1
			} else {
				return 0
			}
		} else {
			return numActiveAccordion
		}
	}

	useEffect(() => {
		if (!studyData.length) return
		const videoList = JSON.parse(JSON.stringify(studyData))?.filter(
			(el) => el.contentCategory == section && el.isVimeo
		)

		const list = filterStudyData(videoList)

		if (!list.length) return
		const filterList = list.sort(compareVideo)

		let isWatched = false

		videoList.forEach((video) => {
			isWatched = isWatched || video.isWatched
		})

		if (!isWatched && filterList?.length) {
			setFirstVideo(filterList[0]?.data[0])
		}

		setIsVisitedVideo(isWatched)
		setLearnList(filterList)
	}, [studyData])

	useEffect(() => {
		if (!learnList?.length) return
		setActiveAccordion(getActiveAccordion(learnList, isVisitedVideo))
	}, [learnList, isVisitedVideo])

	useEffect(() => {
		if (isVisitedVideo) {
			setFirstVideo('')
		}
	}, [isVisitedVideo])

	// const changeLike = ({ id, isLike }) => {
	// 	const newList = JSON.parse(JSON.stringify(learnList)).map((el) => {
	// 		if (el.contentId === id) {
	// 			el.isLiked = isLike
	// 		}

	// 		return el
	// 	})

	// 	setLearnList(newList)
	// }

	return (
		<>
			{learnList?.length ? (
				<div className="study-content">
					{firstVideo ? (
						<div className="study-content__head">
							<div className="study-content__title-wrap">
								<span className="study-content__title">
									Start your first topic!!!
								</span>
								<div className="study-content__info">
									<div className="study-content__info-icon">
										<PlayIcon />
									</div>
									<span className="study-content__info-text">
										{firstVideo.title}
									</span>
								</div>
							</div>
							<div className="study-content__btn-wrap">
								<Button
									className="study-content__btn"
									color="info"
									onClick={() => {
										setIsShowFirstModal(true)
										mixpanel.track('Open first video on Writing page', {})
									}}
								>
									Get Started
								</Button>
								{firstVideo.isVimeo ? (
									<VimeoModal
										stories={[
											{
												url: firstVideo.metadata.videoUrl,
												type: 'video',
												id: firstVideo.id,
											},
										]}
										show={isShowFirstModal}
										withFooter={true}
										handleClose={() => setIsShowFirstModal(false)}
										handlePractice={startPractice}
										handleMore={studyMaterial}
										handleEndVideo={() => setIsVisitedVideo(true)}
									/>
								) : (
									''
								)}
							</div>
						</div>
					) : (
						''
					)}

					<div className="study-content__body">
						{learnList.map((data, i) => (
							<div className="study-content__accordion" key={i}>
								<MaterialAccordion
									oneOpen={false}
									isActiveAcc={i == activeAccordion}
									openAccordion={() => {}}
									closeAccordion={() => {}}
									handleEndVideo={() => {
										setActiveAccordion(
											i + 1 <= learnList.length - 1 ? i + 1 : i
										)
									}}
									startPractice={() => startPractice()}
									moreVideo={() => studyMaterial()}
									data={data}
									isFirstWatched={isVisitedVideo}
									openVideo={() => {
										setIsVisitedVideo(true)
										mixpanel.track(`Open video on`, { section: section })
									}}
								/>
							</div>
						))}
					</div>
				</div>
			) : (
				<div className="study-content">
					<div className="study-content__body">
						{[{ titleGroup: '', data: [1] }].map((data, i) => (
							<div className="study-content__accordion" key={i}>
								<MaterialAccordion
									oneOpen={false}
									isActiveAcc={i == 0}
									openAccordion={() => {}}
									closeAccordion={() => {}}
									startPractice={() => startPractice()}
									moreVideo={() => studyMaterial()}
									data={data}
									openVideo={() => {
										mixpanel.track(`Open video on`, { section: section })
									}}
								/>
							</div>
						))}
					</div>
				</div>
			)}
		</>
	)
}

export default LearnScreen
