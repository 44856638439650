import { createSelector } from 'reselect';

const selectDocuments = state => state.documents.documents;

export const selectSubmittedDocuments = createSelector([selectDocuments], documents =>
  documents.filter(document => document.status === 'submitted')
);

export const selectDocumentsLeft = createSelector([selectDocuments], documents => {
  const totalRequiredDocuments = 11;

  const submittedDocuments = documents.filter(document => document.status === 'submitted');
  return totalRequiredDocuments - submittedDocuments.length;
});
