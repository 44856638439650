import React, { forwardRef, useEffect, useRef } from "react";
import { OverlayTrigger, Popover } from "react-bootstrap";
import DatePicker from "react-datepicker";
import { useMediaQuery } from "react-responsive";
import { ReactComponent as Calendar } from "../../../../../assets/icons/classes/calendar.svg";
//active
import {
  ListeningActive,
  ReadingActive,
  SpeakingActive,
  WritingActive,
} from "../../../../../assets/icons/classes/IconsForActive";
import {
  Listening,
  Reading,
  Speaking,
  Writing,
} from "../../../../../assets/icons/classes/IconsForSelect";
import { ReactComponent as FilterIcon } from "../../../../../assets/icons/marketplace/filter.svg";
import s from "./Filter.module.scss";
import mixpanel from "mixpanel-browser";

const buttons = [
  {
    title: "Today",
    icon: null,
    activeIcon: null,
  },

  {
    title: "This Week",
    icon: null,
    activeIcon: null,
  },

  {
    title: "This Month",
    icon: null,
    activeIcon: null,
  },
];

const classes = [
  { title: "All Classes", icon: null },
  { title: "Listening", icon: <Listening /> },
  { title: "Writing", icon: <Writing /> },
  { title: "Reading", icon: <Reading /> },
  { title: "Speaking", icon: <Speaking /> },
];

const styleForTypeButton = {
  Listening: {
    backgroundColor: "rgba(255, 209, 45, 0.20)",
    border: "1px solid rgba(226, 182, 25, 0.20)",
  },
  Writing: {
    backgroundColor: "rgba(207, 87, 132, 0.20)",
    border: "1px solid rgba(207, 87, 132, 0.20)",
  },
  Reading: {
    backgroundColor: "rgba(130, 54, 247, 0.20)",
    border: "1px solid rgba(130, 54, 247, 0.20)",
  },
  Speaking: {
    backgroundColor: "rgba(24, 195, 164, 0.20)",
    border: "1px solid rgba(24, 195, 164, 0.20)",
  },
};

const showButtonForType = type => {
  switch (type) {
    case "Listening":
      return (
        <div className={s.filter__type}>
          <ListeningActive />
        </div>
      );
    case "Writing":
      return (
        <div className={s.filter__type}>
          <WritingActive />
        </div>
      );
    case "Reading":
      return (
        <div className={s.filter__type}>
          <ReadingActive />
        </div>
      );
    case "Speaking":
      return (
        <div className={s.filter__type}>
          <SpeakingActive />
        </div>
      );
    default:
      return <FilterIcon />;
  }
};

const showButtonForTypePC = type => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        gap: "10px",
        width: "95px",
      }}
    >
      <p style={{ color: "14px", fontSize: "14px", margin: "0" }}> {type}</p>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="12"
        height="5"
        viewBox="0 0 12 5"
        fill="none"
      >
        <path
          d="M5.78642 4.38021L11.3826 0.850441C11.6835 0.603502 11.7035 0.385615 11.4428 0.196779C11.1018 -0.0501595 10.8009 -0.0646853 10.5402 0.153202L5.78642 3.16005L1.09284 0.153202C0.832085 -0.0646853 0.531215 -0.0501595 0.190228 0.196779C-0.0905847 0.400141 -0.0604976 0.618028 0.280489 0.850441L5.78642 4.38021Z"
          fill="black"
        />
      </svg>
    </div>
  );
};

const Filter = ({
  onFilterChange,
  onTypeFilterChange,
  filter,
  typeFilter,
  show,
  setShow,
}) => {
  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });

  const targetRef = useRef(null);
  const handleTypeFilterChange = selectedType => {
    onTypeFilterChange(selectedType);
    setShow(false);
  };

  useEffect(() => {
    const handleDocumentClick = e => {
      if (show && targetRef.current && !targetRef.current.contains(e.target)) {
        setShow(false);
      }
    };

    document.addEventListener("click", handleDocumentClick);

    return () => {
      document.removeEventListener("click", handleDocumentClick);
    };
  }, [show]);

  const getLocationFilter = () => {
    const popoverLeft = (
      <Popover>
        <div className={s.popover}>
          <ul
            onChange={handleTypeFilterChange}
            value={typeFilter}
            className={s.custom_select}
          >
            {classes.map((type, index) => (
              <li
                onClick={() => handleTypeFilterChange(type.title)}
                key={index}
              >
                {type?.icon}
                {type.title}
              </li>
            ))}
          </ul>
        </div>
      </Popover>
    );

    return (
      <OverlayTrigger
        trigger="click"
        rootClose
        className={s.popover_positioned_left}
        target={targetRef.current}
        onHide={() => setShow(!show)}
        placement="bottom-start"
        show={show}
        overlay={popoverLeft}
      >
        {isMobile ? (
          <button
            style={styleForTypeButton[typeFilter]}
            onClick={() => setShow(!show)}
            className={s.filter__button}
          >
            {showButtonForType(typeFilter)}
          </button>
        ) : (
          <div onClick={() => setShow(!show)}>
            {showButtonForTypePC(typeFilter)}
          </div>
        )}
      </OverlayTrigger>
    );
  };

  const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (
    <button
      className={`${s.all__button}  ${value ? s.active : ""}`}
      onClick={onClick}
      ref={ref}
    >
      <p style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        {value ? (
          value
        ) : (
          <>
            <Calendar /> Pick a date
          </>
        )}
      </p>
    </button>
  ));

  return (
    <div className={s.filter}>
      <div className={s.filter__container}>
        <div className={s.location__container}>{getLocationFilter()}</div>
        <div className={s.button__container}>
          {buttons.map(({ title, icon, activeIcon }) =>
            title === "Pick a date" ? (
              <DatePicker
                onChange={date => {
                  onFilterChange(date);
                }}
                customInput={<ExampleCustomInput title={title} />}
              />
            ) : (
              <button
                onClick={() => {
                  mixpanel.track(`Clicked on classes {title} on dashboard`);
                  onFilterChange(title);
                }}
                key={title}
                className={`${s.all__button} ${
                  title === filter ? s.active : ""
                }`}
              >
                <p
                  style={{
                    ...(title === filter && {
                      color: "#fff",
                      fontWeight: "700",
                      transition: "all 0.6s ease",
                    }),
                  }}
                >
                  {title === filter ? activeIcon : icon} {title}
                </p>
              </button>
            )
          )}
        </div>
      </div>
    </div>
  );
};

export default Filter;
