import React from 'react';
import { ReactComponent as ArrowIcon } from '../../../../../assets/icons/applyBanner/arrow-icon.svg';
import s from './RequiredBanner.module.scss';
const RequiredBanner = ({ handleCollapseToggle, isCollapsed, mockData, goToSop }) => {
  return (
    <div className={s.bannerDocument}>
      <div className={s.bannerDocument__wrap}>
        <div className={s.block}>
          <div className={s.wrap__collapse}>
            <div className={s.required__block_content}>
              <h2 className={s.title}>Document required for college Application</h2>
              <button className={s.collapse_button} onClick={handleCollapseToggle}>
                <ArrowIcon className={isCollapsed ? s.icon__collapsed : s.icon__collapse} />
              </button>
            </div>

            <ul className={`${s.docs__container} ${!isCollapsed ? s.docs__container_expanded : ''}`}>
              {mockData.map(el => (
                <li key={el.id}>{el.text}</li>
              ))}
            </ul>
          </div>
        </div>
        <div className={s.block}>
          <div className={s.wrap}>
            <h2 className={s.title}>Draft & Upload your Documents</h2>
            <p className={s.desc}>
              Prepare your documents with Advance AI tool Write SOP, Essay, LOR or Upload it if you already have it.
            </p>

            <div className={s.button__container}>
              <button onClick={() => goToSop()}>Try AI Writer</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RequiredBanner;
