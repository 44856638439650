import React from 'react'
import { BsTelephone } from 'react-icons/bs'
import { Button } from 'reactstrap'
import './newChanges/NeedHelp.scss'

const NeedHelp = ({ handleClick = () => {} }) => {
	return (
		<a
			className="need-help"
			onClick={handleClick}
			href="https://www.wa.link/ysdzfc"
		>
			<span className="need-help__icon">
				<BsTelephone />
			</span>
			<span className="help-info">
				<span
					className="title-gradient"
					style={{
						textTransform: 'capitalize',
						fonFamily: 'Open Sans',
						letterSpacing: '0.02em',
						fontStyle: 'normal',
						fontWeight: '600',
						fontSize: '16px',
						lineHeight: '24px',
					}}
				>
					Need Help?
				</span>
				<span className="btn-link help-btn arrow-right mt-auto" color="info">
					connect with your manager
				</span>
			</span>
		</a>
	)
}

export default NeedHelp
