export const quiz = {
  questions: [
    {
      question: "Which of the following scenarios excites you the most?",
      choices: [
        {
          text: "Developing a groundbreaking technology or business that disrupts an industry.",
          point: "Ambitious Innovator",
        },
        {
          text: "Working in a team to solve pressing social issues and improve people's lives.",
          point: "Empathetic Humanitarian",
        },
        {
          text: "Conducting research that uncovers new insights and expands our understanding of the world.",
          point: "Curious Explorer",
        },
      ],
    },
    {
      question: "When faced with a free weekend, you would most likely:",
      choices: [
        {
          text: "Plan and start a new project or entrepreneurial endeavor.",
          point: "Ambitious Innovator",
        },
        {
          text: "Organize or participate in a community service event.",
          point: "Empathetic Humanitarian",
        },
        {
          text: "Spend time exploring a new subject, reading, or experimenting.",
          point: "Curious Explorer",
        },
      ],
    },
    {
      question: "What achievement are you most proud of?",
      choices: [
        {
          text: "Leading a project or initiative that achieved significant results or recognition.",
          point: "Ambitious Innovator",
        },
        {
          text: "Making a positive difference in someone's life or your community.",
          point: "Empathetic Humanitarian",
        },
        {
          text: "Winning an academic competition or advancing knowledge in your field of interest.",
          point: "Curious Explorer",
        },
      ],
    },
    {
      question:
        "Imagine you have the opportunity to attend a conference. Which of the following themes would you choose?",
      choices: [
        {
          text: "The Future of Innovation: Trends and Opportunities.",
          point: "Ambitious Innovator",
        },
        {
          text: "Global Challenges: Strategies for Social Impact.",
          point: "Empathetic Humanitarian",
        },
        {
          text: "Frontiers of Knowledge: New Developments in Science and Research.",
          point: "Curious Explorer",
        },
      ],
    },
    {
      question: "Which quote resonates with you the most?",
      choices: [
        {
          text: "The best way to predict the future is to create it. - Peter Drucker",
          point: "Ambitious Innovator",
        },
        {
          text: "The best way to find yourself is to lose yourself in the service of others. - Mahatma Gandhi ",
          point: "Empathetic Humanitarian",
        },
        {
          text: "I have no special talents. I am only passionately curious. - Albert Einstein",
          point: "Curious Explorer",
        },
      ],
    },
  ],
};
