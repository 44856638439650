import React from 'react'
// import themapMd from '../../../images/themap-md.png'
// import themapWebpMd from '../../../images/themap-md.webp'
// import themapSm from '../../../images/themap-sm.png'
// import themapWebpSm from '../../../images/themap-sm.webp'
// import themap from '../../../images/themap.png'
// import themapWebp from '../../../images/themap.webp'
import './style.scss'

function index() {
	return (
		<section className="statement-section section">
			<div className="statement-wrap">
				{/* <div className="map-phot">
					<picture>
						<source
							type="image/webp"
							media="(min-width: 1024px)"
							srcset={themapWebp}
						/>
						<source media="(min-width: 1024px)" srcset={themap} />
						<source
							type="image/webp"
							media="(min-width: 450px)"
							srcset={themapWebpMd}
						/>
						<source media="(min-width: 450px)" srcset={themapMd} />
						<source type="image/webp" srcset={themapWebpSm} />
						<img src={themapSm} alt="map" />
					</picture>
				</div> */}
				<div className="statement-container landing-container">
					<div className="statement-inner">
						<div className="statement-img">
							<picture>
								<source
									type="image/webp"
									media="(min-width: 460px)"
									srcSet={require('assets/kandor-img/macBook-md.webp')}
								/>
								<source
									media="(min-width: 460px)"
									srcSet={require('assets/kandor-img/macBook-md.png')}
								/>
								<source
									type="image/webp"
									srcSet={require('assets/kandor-img/macBook-sm.webp')}
								/>

								<img
									src={require('assets/kandor-img/macBook-sm.png')}
									alt="map"
								/>
							</picture>
						</div>
						<div className="statement-info">
							<span
								className="statement-info__subtitle landing-subtitle landing-subtitle--small"
								id="stat-pacifico-text"
							>
								{' '}
								best-in-industry technology
							</span>
							<span className="statement-info__title  landing-title title-gradient">
								Kandor uses the most advanced AI technology
							</span>
							<p className="statement-info__text title-gradient">
								Our AI technology gives you personalized feedback that helps you
								improve your scores by 0.5 band within just 5 attempts
							</p>
						</div>
					</div>
				</div>
			</div>
		</section>
	)
}

export default index
