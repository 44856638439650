import React from 'react';
import s from './GuideModal.module.scss';
import mixpanel from 'mixpanel-browser';

const GuideModal = React.memo(({
  index,
  step,
  size,
  backProps,
  skipProps,
  primaryProps,
  isLastStep,
  tooltipProps,
}) => {
  const dots = Array.from({ length: size }, (_, i) => i + 1);

  const handleNext = (e) => {
    const text = `Clicked ${isLastStep ? 'close' : 'next step to ' + (index + 2)}  on Guide `
    console.log(text);
    mixpanel.track(text);
    primaryProps.onClick(e)
  }

  const handleBack = (e) => {
    const text = `Clicked back to ${index} on Guide `
    console.log(text);
    mixpanel.track(text);
    backProps.onClick(e)
  }

  const handleSkip = (e) => {
    console.log('Skip Guide', index + 1 );
    mixpanel.track('Skip Guide', {step: index + 1});
    skipProps.onClick(e)
  }

  return (
    <div className={`${s.guide} ${step.isFirstLoad ? s.first : ''}`} {...tooltipProps}>
      <div className={s.guide__modal}>
        <div className={s.guide__wrap}>
          <div className={s.guide__head}>
            {step.icon && (
              <div className={s.guide__icon}>{step.icon}</div>
            )}
            <button {...skipProps} type="button" className={s.guide__close} onClick={handleSkip}></button>
          </div>
          <div className={s.guide__content}>
            <span className={s.guide__title}>{step.content}</span>
            {step.text && <p className={s.guide__text}>{step.text}</p>}
          </div>
          <div className={s.guide__footer}>
            <div className={s.guide__dots}>
              {dots.map(el => (
                <span className={`${s.guide__dot} ${el == index + 1 ? s.active : ''}`} key={el}></span>
              ))}
            </div>
            <div className={s.guide__buttons}>
              {index > 0 && (
                <button {...backProps} className={`${s.guide__btn} ${s.guide__back}`} onClick={handleBack}>
                  Back
                </button>
              )}

              <button {...primaryProps} className={`${s.guide__btn} ${s.guide__next}`} onClick={handleNext}>
                {!isLastStep ? `Next` : 'Close'}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

export default GuideModal;
