import React, { useEffect, useState, useRef } from 'react';
import PreviousAttemptCard from './PreviousAttemptCard';
import CardList from './CardList';
import NeedHelp from './NeedHelp';
import { InputSelect, TipSelectText } from './InputSelect';
import { Row, Col, Label } from 'reactstrap';
import mixpanel from 'mixpanel-browser';
import ModalWindow from 'kandor-components/Modal/ModalWindow';
import { useMediaQuery } from 'react-responsive';
// import MobileBanner from "./Banner/MobileBanner";
import { getSelfDetails } from 'api/userApi';
import { useSelector } from 'react-redux';
import lazyRetry from 'utils/lazyRetry';
import ReferralWidget from './ReferralWidget/ReferralWidget';
import ButtonScroll from './components/ButtonScroll/ButtonScroll';

const MobileBanner = React.lazy(() => lazyRetry(() => import('./Banner/MobileBanner'), 'MobileBanner'));

const PracticeScreen = ({
  isPremium,
  isLoadingDetails,
  dataFree,
  dataPremium,
  section,
  practice_prompt,
  attemptsList,
  fetchAllRecordings,
  isMock,
  loadedContinue,
  iquestiontype,
  setiQuestiontype = () => {},
  withSelect = false,
  withContinue = true,
  continueAttempts = [],
  isPro,
}) => {
  const [name, setName] = useState('');
  const [show, setShow] = useState(false);
  const isMobile = useMediaQuery({ query: '(max-width:767px)' });
  const isTablet = useMediaQuery({ query: '(max-width:992px)' });

  useEffect(() => {
    if (section == 'Reading' || section == 'Listening') {
      if (isMock) {
        setName('Attempt');
      } else {
        setName('Practice');
      }
    } else if (section == 'Speaking') {
      setName('Recording');
    } else if (section == 'Writing') {
      setName('Task');
    }
  }, [section]);

  const change_question = e => {
    console.log(attemptsList);
    mixpanel.track(`Changed to round`, {
      round: e.target.value,
      section: section,
    });
    if (e.target.value !== iquestiontype) {
      setiQuestiontype(e.target.value);
    }
  };

  const renderBanner = () => {
    if (isPro === 'free') {
      return <MobileBanner show={show} setShow={setShow} />;
    }
  };

  const attemptRef = useRef(null);

  return (
    <>
      <ModalWindow show={show} setShow={setShow} />
      <Row>
        <Col xs="12" lg="8">
          {withSelect ? (
            <>
              <div className="lg-mobil">
                <TipSelectText />
              </div>

              <Row className="p-0">
                <Col xs="12" lg="5" xxl={6}>
                  <div className="mb-0 form-group">
                    <Label className="lg-desktop">
                      <TipSelectText />
                    </Label>

                    <InputSelect onChange={change_question} section={section} />
                  </div>
                </Col>

                <Col xs="0" sm="12" lg="7" xxl={6}>
                  <div className="lg-desktop h-100">
                    {' '}
                    <NeedHelp handleClick={() => mixpanel.track('Need help on Speaking', {})} />{' '}
                  </div>
                </Col>
              </Row>
            </>
          ) : (
            ''
          )}

          <CardList
            isPremium={isPremium}
            isLoadingDetails={isLoadingDetails}
            dataFree={dataFree}
            dataPremium={dataPremium}
            section={section}
            practice_prompt={practice_prompt}
            withContinue={withContinue}
            loadedContinue={loadedContinue}
            continueAttempts={continueAttempts}
            isMock={isMock}
          />
        </Col>

        <Col xs="12" lg="4">
          {!withSelect && !isMobile ? (
            <div className="mb-3">
              <NeedHelp handleClick={() => mixpanel.track(`Need help`, { section: section })} />
            </div>
          ) : (
            ''
          )}

          {/* New banner for referral */}
          {!isMobile && (
            <div className="mb-3 referral-wrap">
              <ReferralWidget />
            </div>
          )}
          {/* {!pro && (<MobileBanner show={show} setShow={setShow} />) } */}

          {isMobile && (
            <div className="mb-3">
              <NeedHelp handleClick={() => mixpanel.track(`Need help`, { section: section })} />
            </div>
          )}

          {isTablet && (
            <ButtonScroll
              text={'Previous Attempts'}
              blockRef={attemptRef}
              mobileHeader={160}
              display={attemptsList?.length}
              delay={8000}
            />
          )}

          <div
            ref={attemptRef}
            className={`mb-3 score_board ${attemptsList?.length ? 'previous-attempts' : ''} ${
              dataFree.length + dataPremium.length + continueAttempts.length < 12 ? 'previous-attempts--need' : ''
            }  `}>
            <h3 className="title-gradient">My previous attempts</h3>
            {attemptsList.map((data, i) => (
              <div key={data.id} className={i != attemptsList.length - 1 ? 'mb-4' : ''}>
                <PreviousAttemptCard
                  name={name}
                  data={data}
                  index={i}
                  practiceAgain={practice_prompt}
                  fetchAllRecordings={fetchAllRecordings}
                  isPremium={isPremium}
                  isMock={isMock}
                  section={section}
                />
              </div>
            ))}{' '}
          </div>

          {/* New banner for referral */}
          {isMobile && (
            <div className="mb-3 referral-wrap">
              <ReferralWidget />
            </div>
          )}
        </Col>
      </Row>
    </>
  );
};

export default PracticeScreen;
