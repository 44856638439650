import React from "react";
import s from "./ErrorFallbackScreen.module.scss";
import Lottie from "lottie-react";
import error from "./AnimationJson/Error.json";
import { useMediaQuery } from "react-responsive";
export const ErrorFallbackScreen = ({ resetErrorBoundary }) => {
  const isMobile = useMediaQuery({ query: "(max-width: 992px)" });

  const refreshPage = () => {
    resetErrorBoundary();
    window.location.reload();
  };

  return (
    <section className={s.error__section}>
      <div className={s.error_wrap}>
        <div className={s.error_content}>
          <h4 className={s.error_title}>Something went wrong :(</h4>
          <p className={s.error_desc}>Don’t worry our best minds are on it !</p>
        </div>
        <Lottie
          style={
            isMobile
              ? { width: "240px", margin: "auto" }
              : { margin: "auto", width: "334.5px" }
          }
          animationData={error}
        />
        <div className={s.button_block}>
          <button onClick={() => refreshPage()} className={s.refresh_button}>
            Try again{" "}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="17"
              viewBox="0 0 18 17"
              fill="none"
            >
              <path
                d="M13.255 4.64494C12.7369 4.12333 12.1207 3.70943 11.4419 3.4271C10.7631 3.14477 10.0352 2.99959 9.3 2.99994C6.206 2.99994 3.707 5.50594 3.707 8.59994C3.707 11.6939 6.206 14.1999 9.3 14.1999C11.911 14.1999 14.088 12.4149 14.711 9.99994H13.255C12.9667 10.8186 12.4313 11.5276 11.7229 12.0291C11.0145 12.5306 10.168 12.8 9.3 12.7999C6.983 12.7999 5.1 10.9169 5.1 8.59994C5.1 6.28294 6.983 4.39994 9.3 4.39994C10.462 4.39994 11.498 4.88294 12.254 5.64594L10 7.89994H14.9V2.99994L13.255 4.64494Z"
                fill="white"
              />
            </svg>
          </button>
        </div>
      </div>
    </section>
  );
};
