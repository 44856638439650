import React, { useMemo } from 'react';
import { ReactComponent as ArrowBack } from '../../../assets/icons/back.svg';
import s from './term.module.scss';
import { useLocation } from 'react-router-dom';
import { terms, termsForReferral } from './TermsMock.js';

const ADMIN_REFERRAL_PATH = '/admin/referral';
const ADMIN_PLAN_PATH = '/admin/plan-information';

const Terms = ({ showTerm, setShowTerm, setShow }) => {
  const location = useLocation();

  const renderTitle = useMemo(() => {
    return location.pathname === ADMIN_REFERRAL_PATH ||
      location.pathname === ADMIN_PLAN_PATH
      ? 'Referral Program Terms and Conditions'
      : 'General Terms';
  }, [location.pathname]);

  const renderTerms = useMemo(() => {
    if (
      location.pathname === ADMIN_REFERRAL_PATH ||
      location.pathname === ADMIN_PLAN_PATH
    ) {
      return (
        <>
          <h2 style={{ padding: '10px', margin: 0 }}>
            <span
              style={{
                textDecoration: 'underline',
                color: 'black',
              }}
            >
              {renderTitle}
            </span>
          </h2>
          {termsForReferral.map(({ title, content }, index) => (
            <>
              <React.Fragment>
                <h3 style={{ color: 'black' }}>{title}</h3>
                <ul>
                  {content.map((item, i) => (
                    <li style={{ color: 'black' }} key={i}>
                      {item}
                    </li>
                  ))}
                </ul>
              </React.Fragment>
            </>
          ))}
        </>
      );
    } else {
      return (
        <>
          <h2 style={{ padding: '10px', margin: 0 }}>
            <span
              style={{
                textDecoration: 'underline',
                color: 'black',
              }}
            >
              {renderTitle}
            </span>
          </h2>
          The following are the terms for uploading your IELTS Date receipt and
          availing the offer:
          <ul className={s.list__term}>
            {terms.map((term, idx) => (
              <li key={idx}>{term}</li>
            ))}
          </ul>
        </>
      );
    }
  }, [location.pathname]);

  const renderFunctionForPage = () => {
    if (
      location.pathname === ADMIN_REFERRAL_PATH ||
      location.pathname === ADMIN_PLAN_PATH
    ) {
      setShow(false);
    } else {
      setShowTerm(prevState => !prevState);
    }
  };

  return (
    <div style={{ overflowX: 'scroll' }}>
      {location.pathname !== ADMIN_REFERRAL_PATH &&
      location.pathname !== ADMIN_PLAN_PATH ? (
        <button
          className={s.term__button}
          onClick={() => renderFunctionForPage()}
        >
          <ArrowBack />{' '}
          <span style={{ borderBottom: '1px solid #fc8150' }}>Back</span>
        </button>
      ) : (
        <div className={s.term__button}></div>
      )}
      <div className={s.term__container}>
        <div
          style={{
            overflowX: 'scroll',
            height: '350px',
            padding: '10px',
            color: 'black',
          }}
        >
          {renderTerms}
        </div>
      </div>
    </div>
  );
};

export default Terms;
