import { ReactComponent as BUILD } from "assets/icons/marketplace/BUILD.svg";
import { ReactComponent as RATE } from "assets/icons/marketplace/RATE.svg";
import { ReactComponent as RATING } from "assets/icons/marketplace/RATING.svg";
import { ReactComponent as VISA } from "assets/icons/marketplace/VISA.svg";
import React from "react";
import s from "./StatBlock.module.scss";
import StatCard from "./StatCard/StatCard";

const mockStat = [
  {
    icon: <VISA />,
    bg: "rgba(238, 119, 52, 0.20)",
    value: "97%",
    name: "VISA Acceptance Rate",
  },
  {
    icon: <RATE />,
    bg: "rgba(87, 59, 118, 0.20)",

    value: "2x",
    name: "Improved Admission Rates",
  },
  {
    icon: <RATING />,
    bg: "rgba(71, 186, 82, 0.20)",

    value: "4.7",
    name: "Average Rating",
  },
  {
    icon: <BUILD />,
    bg: "rgba(244, 204, 63, 0.30)",
    value: "3289",
    name: "Admissions Secured",
  },
];

const StatBlock = () => {
  return (
    <div className={s.stat_container}>
      {mockStat.map(el => (
        <StatCard key={el.bg} {...el} />
      ))}
    </div>
  );
};

export default StatBlock;
