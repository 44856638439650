import React from "react";
import s from './SkeletonCounselorCard.module.scss'
import LoaderImg from "../../LoaderImg/LoaderImg";

const SkeletonCounselorCard = ({withDescription = false}) => {
  return (
    <div className={s.cardskeleton}>
      <div className={s.cardskeleton__wrap}>
        <div className={s.cardskeleton__head}>
          <div className={s.cardskeleton__headloader}>
            <LoaderImg/>
          </div>
        </div>
        <div className={s.cardskeleton__body}>
          <div className={s.cardskeleton__data}>
            <div className={s.cardskeleton__photo}>
              <LoaderImg/>
            </div>
            <div className={s.cardskeleton__info}>
              <div className={s.cardskeleton__name}>
                <LoaderImg/>
              </div>
              <div className={s.cardskeleton__text}>
                <LoaderImg/>
              </div>
              <div className={s.cardskeleton__text}>
                <LoaderImg/>
              </div>
            </div>
            <div className={s.cardskeleton__tag}>
              <LoaderImg/>
            </div>
          </div>
          {withDescription && (
            <div className={s.cardskeleton__descr}>
            <LoaderImg/>
            </div>)
          }
          <div className={s.cardskeleton__line}>
            <div className={s.cardskeleton__country}>
              <LoaderImg/>
            </div>
            <div className={s.cardskeleton__country}>
              <LoaderImg/>
            </div>
          </div>
          <div className={s.cardskeleton__footer}>
            <div className={s.cardskeleton__btn}>
              <LoaderImg/>
            </div>
            <div className={s.cardskeleton__btn}>
              <LoaderImg/>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SkeletonCounselorCard

