import React from "react";

const Edit = ({ size = 10, color }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 10 10"
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill={color}
        d="M1.85354 8.41008H2.50843L6.54306 4.35186L5.88816 3.69314L1.85354 7.75136V8.41008ZM8.54283 3.66961L6.55475 1.69343L7.20965 1.03471C7.38896 0.854343 7.60929 0.76416 7.87062 0.76416C8.13196 0.76416 8.35213 0.854343 8.53113 1.03471L9.18603 1.69343C9.36535 1.8738 9.4589 2.09149 9.4667 2.34651C9.47449 2.60153 9.38873 2.81907 9.20942 2.99912L8.54283 3.66961ZM1.38575 9.35112C1.25321 9.35112 1.14204 9.30595 1.05222 9.21561C0.96241 9.12527 0.917659 9.0136 0.91797 8.8806V7.55139C0.91797 7.48865 0.929665 7.4278 0.953054 7.36883C0.976443 7.30985 1.01153 7.257 1.05831 7.21026L5.87647 2.36392L7.86454 4.36362L3.04638 9.20996C2.9996 9.25702 2.94706 9.2923 2.88874 9.31583C2.83042 9.33936 2.76992 9.35112 2.70724 9.35112H1.38575ZM6.21561 4.0225L5.88816 3.69314L6.54306 4.35186L6.21561 4.0225Z"
      />
    </svg>
  );
};

export default Edit;
