import { ReactComponent as AUS } from "../assets/icons/marketplace/flags/Australia.svg";
import { ReactComponent as Canada } from "../assets/icons/marketplace/flags/Canada.svg";
import { ReactComponent as UK } from "../assets/icons/marketplace/flags/England.svg";
import { ReactComponent as IRL } from "../assets/icons/marketplace/flags/Ireland.svg";
import { ReactComponent as NZL } from "../assets/icons/marketplace/flags/NewZealand.svg";
import { ReactComponent as USA } from "../assets/icons/marketplace/flags/USA.svg";
import s from "../pages/Marketplace/Counsellor/Counsellor.module.scss";
const mapOfFlags = {
  USA: (
    <div className={s.counsellors__card_country}>
      <USA style={{ marginRight: "4px" }} />
      USA
    </div>
  ),
  Canada: (
    <div className={s.counsellors__card_country}>
      <Canada style={{ marginRight: "4px" }} />
      CAN
    </div>
  ),
  "New Zealand": (
    <div className={s.counsellors__card_country}>
      <NZL style={{ marginRight: "4px" }} />
      NZL
    </div>
  ),
  UK: (
    <div className={s.counsellors__card_country}>
      <UK style={{ marginRight: "4px" }} />
      UK
    </div>
  ),
  Australia: (
    <div className={s.counsellors__card_country}>
      <AUS style={{ marginRight: "4px" }} />
      AUS
    </div>
  ),
  Ireland: (
    <div className={s.counsellors__card_country}>
      <IRL style={{ marginRight: "4px" }} />
      IRL
    </div>
  ),
};

export const renderCountry = countries => {
  const ArrayOfCountries = JSON.parse(countries);

  let firstTwoCountries = ArrayOfCountries.slice(0, 2);
  let remainingCountriesLength =
    ArrayOfCountries.length - firstTwoCountries.length;

  let all = firstTwoCountries.map(country => mapOfFlags[country]);

  return { all, remainingCountriesLength };
};
