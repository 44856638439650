import React, { useState, useEffect } from 'react';
import WithoutReferral from './WithoutReferral/WithoutReferral';
import WithReferral from './WithReferral/WithReferral';
import { getAchievements } from 'api/loyaltyApi';
import { getReferralUrl } from 'api/loyaltyApi';
import LoaderImg from 'kandor-components/components/LoaderImg/LoaderImg';
import s from './ReferralWidget.module.scss'

const ReferralWidget = () => {
  const [referralLink, setReferralLink] = useState('');
  // mock
  const [referral, setReferral] = useState(null);
  const [isLoadedReferral, setIsLoadedReferral] = useState(false)

  useEffect(() => {
    async function fetchReferralLink() {
      const response = await getReferralUrl();
      const referralLink = await response.data;
      setReferralLink(referralLink);
    }
    fetchReferralLink();
  }, []);

  useEffect(() => {
    async function fetchAchievements() {
      const response = await getAchievements();
      const achievements = await response.data;

      if (achievements.length === 0){
        setIsLoadedReferral(true);
        return;
      }

      const referralAchievements = achievements.filter(ac => ac.achievement_type === 'referral');

      if (referralAchievements.length > 0) {
        const referrals = referralAchievements.map(referral => ({
          title: referral.achievement_name,
          actual: referral.current_value,
          target: referral.achievement_value,
          details: referral.achievement_details,
          id: referral.achievement_id,
          claimed: referral.claimed === 1,
        }));

        setReferral(referrals);
        setIsLoadedReferral(true)
      }
    }

    fetchAchievements();
  }, []);
  return (
    <>
      {
        isLoadedReferral ? (
          <>
            {referral && referral[0].actual > 0  ? (
              <WithReferral referral={referral && referral} referralLink={referralLink} />
            ) : (
              <WithoutReferral />
            )}
          </>
        ) : (
            <div className={s.skeleton}>
              <LoaderImg/>
            </div>
        )
      } 
    </>
  );
};

export default ReferralWidget;
