import React, { useState } from 'react';
import s from './BannersThroughKandor.module.scss';
import BannerContent from '../BannerContent/BannerContent';
import BannersSlider from './BannersSlider/BannersSlider';
import BannerDocument from './BannerDocument/BannerDocument';
import { useSelector } from 'react-redux';
import { PopupModal } from 'react-calendly';
import { selectSubmittedDocuments, selectDocumentsLeft } from 'selectors/selectSubmittedDocuments';
import BannerForApply from '../BannerInfoShortList/BannerForApply';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import DocumentExprert from '../../../assets/icons/applyBanner/exprert-doc.png';
import { useMediaQuery } from 'react-responsive';
import BannerForApplyPC from '../BannerInfoShortList/BannerForApplyPC/BannerForApplyPC';
import DocumentsLeftPhoto from '../../../assets/icons/applyBanner/pc-1-4-list.png';
import { ReactComponent as Arrow } from '../../../assets/icons/applyBanner/ArrowApply.svg';
import mixpanel from 'mixpanel-browser';

export const ExpertModal = ({
	isOpen,
	onClose,
}) => {
	const [calendlyLink, setCalendlyLink] = useState('https://calendly.com/kandor-ielts/shortlist-with-kandor');

	return (
		<PopupModal
			url={calendlyLink}
			onModalClose={onClose}
			open={isOpen}
			rootElement={document.getElementById('root')}
		/>
	)
};

const BannersThroughKandor = ({ currentSecondStep, setCurrentSecondStep, setCurrentStep }) => {
  const isMobile = useMediaQuery({ query: '(max-width:768px)' });
  const [isOpen, setIsOpen] = useState(false);

  const submittedDocuments = useSelector(selectSubmittedDocuments);
  const documentsLeft = useSelector(selectDocumentsLeft);
  const history = useHistory();
  const renderBannerContent = () => {
    if (currentSecondStep === 1) {
      return <BannerContent title="Apply Through Kandor" desc="Unlock exclusive Benefits!" />;
    }

    if (currentSecondStep === 2) {
      if (documentsLeft === 2 || documentsLeft === 3 || documentsLeft === 1) {
        return <BannerContent title="You're Almost There!" desc={null} />;
      }

      if (documentsLeft === 0) {
        return <BannerContent title="Ensure Your Application Stands Out!" desc={null} />;
      }

      if (submittedDocuments.length === 0) {
        return (
          <BannerContent
            title="Your Document Vault"
            desc={null}
            descSpan={
              <p className={s.desc}>
                <span>Peace of Mind: </span>Enjoy the confidence of knowing your documents are protected and always
                within reach
              </p>
            }
          />
        );
      }

      return (
        <BannerContent title="Start with Documents" desc="Prepare and Upload documents for your college application" />
      );
    }

    return null;
  };

  const renderActions = () => {
    if (currentSecondStep === 1) {
      return (
        <>
          <BannersSlider setCurrentSecondStep={setCurrentSecondStep} />
          {isMobile ? (
            <div className={s.action__button_wrap}>
              <button onClick={() => setCurrentSecondStep(2)} className={s.action__button}>
                Start Application Process <Arrow />
              </button>
            </div>
          ) : null}
        </>
      );
    }

    if (
      currentSecondStep === 2 &&
      !(documentsLeft === 2 || documentsLeft === 3 || documentsLeft === 1 || documentsLeft === 0)
    ) {
      return <BannerDocument setCurrentStep={setCurrentStep} />;
    }

    if (documentsLeft === 2 || documentsLeft === 3 || documentsLeft === 1) {
      return (
        <>
          {isMobile ? (
            <BannerForApply
              type={bannerConfig[0].type}
              title={bannerConfig[0].title}
              desc={bannerConfig[0].desc}
              button={
                <div className={s.button__container}>
                  <button className={bannerConfig[0].buttonClass} onClick={bannerConfig[0].onClick}>
                    {bannerConfig[0].buttonText}
                  </button>
                </div>
              }
            />
          ) : (
            <BannerForApplyPC
              title={bannerConfig[0].title}
              desc={bannerConfig[0].desc}
              img={bannerConfig[0].img}
              buttonText={bannerConfig[0].buttonText}
              onClick={bannerConfig[0].onClick}
            />
          )}
        </>
      );
    }

    if (documentsLeft === 0) {
      return (
        <>
          {isMobile ? (
            <BannerForApply
              type={bannerConfig[1].type}
              title={bannerConfig[1].title}
              img={bannerConfig[1].img}
              button={
                <div className={s.button__container}>
                  <button className={bannerConfig[1].buttonClass} onClick={bannerConfig[1].onClick}>
                    {bannerConfig[1].buttonText}
                  </button>
                </div>
              }
            />
          ) : (
            <BannerForApplyPC
              title={bannerConfig[1].title}
              img={bannerConfig[1].img}
              desc="Upload your remaining documents and review it with expert for free."
              buttonText={bannerConfig[1].buttonText}
              onClick={bannerConfig[1].onClick}
            />
          )}
        </>
      );
    }

    return null;
  };

  const bannerConfig = [
    {
      type: 'purple',
      title: `Only ${documentsLeft} Documents Left!`,
      desc: 'Upload your remaining documents and complete your application process. Just do it!',
      img: DocumentsLeftPhoto,
      buttonClass: s.orange__button,
      buttonText: 'Upload Document',
      onClick: () => {
        mixpanel.track("Clicked on upload document");
        history.push('/admin/upload-documents');
      },
    },
    {
      type: 'purple',
      title: `Get your docs reviewed by an Expert!`,
      img: DocumentExprert,
      buttonClass: s.orange__button,
      buttonText: 'Connect with Expert for Free',
      onClick: () => {
        setIsOpen(true);
        mixpanel.track("Clicked on connect with expert");
      },
    },
  ];

  const modalClose = () => {
    setIsOpen(false);
    setCurrentStep(3);
  };

  return (
    <div className={s.bannersThroughKandor}>
      <div className={s.bannersThroughKandor__wrap}>
        <div className={s.bannerContent__container}>{renderBannerContent()}</div>
        {renderActions()}
        <ExpertModal isOpen={isOpen} onClose={modalClose} />
      </div>
    </div>
  );
};

export default BannersThroughKandor;
