import React from "react";
import styles from "./ProgressStepM.module.scss";

const ProgressStep = ({ currentStep, handleStepChange }) => {
  return (
    <div className={styles.progressSteps}>
      <div className={styles.stepsContainer}>
        <div
          className={`${styles.step} ${
            currentStep >= 1 ? styles.active : styles.nonActive
          }`}
        >
          {currentStep > 1 ? (
            <div className={styles.active_icon}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="13"
                height="10"
                viewBox="0 0 13 10"
                fill="none"
              >
                <path
                  d="M0.994629 5.52096L4.47633 9.00266L11.4397 1.54187"
                  stroke="white"
                  strokeWidth="1.49216"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
          ) : (
            <div className={styles.icon}>1</div>
          )}
          Preferences
        </div>
        <div
          className={`${styles.step} ${
            currentStep >= 2 ? styles.active : styles.nonActive
          }`}
        >
          {currentStep > 2 ? (
            <div className={styles.active_icon}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="13"
                height="10"
                viewBox="0 0 13 10"
                fill="none"
              >
                <path
                  d="M0.994629 5.52096L4.47633 9.00266L11.4397 1.54187"
                  stroke="white"
                  strokeWidth="1.49216"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
          ) : (
            <div className={styles.icon}>2</div>
          )}
          Timeline
        </div>
        <div
          className={`${styles.step} ${
            currentStep >= 3 ? styles.active : styles.nonActive
          }`}
        >
          {currentStep > 3 ? (
            <div className={styles.active_icon}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="13"
                height="10"
                viewBox="0 0 13 10"
                fill="none"
              >
                <path
                  d="M0.994629 5.52096L4.47633 9.00266L11.4397 1.54187"
                  stroke="white"
                  strokeWidth="1.49216"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
          ) : (
            <div className={styles.icon}>3</div>
          )}
          Time Slot
        </div>
      </div>
    </div>
  );
};

export default ProgressStep;
