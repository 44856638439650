import React from 'react';
import Modal from 'react-bootstrap/Modal';
import { useMediaQuery } from 'react-responsive';
import { ReactComponent as ArrowRight } from '../../assets/img/ModalCache/arrow.svg';
import s from './ModalCache.module.scss';
const ModalCache = ({ showCache, setShowCache }) => {
  const isMobile = useMediaQuery({ query: '(max-width:767px)' });
  const handleRefresh = () => {
    if ('caches' in window) {
      caches.keys().then(names => {
        // Delete all the cache files
        names.forEach(name => {
          caches.delete(name);
        });
      });
    }
    window.location.reload();
    setShowCache(false);
  };
  return (
    <Modal
      dialogClassName={s.modalCache}
      contentClassName={s.custom_modalCache}
      show={showCache}
      centered
      onHide={() => setShowCache(false)}>
      <div className={s.modal__body}>
        <div className={s.modal__content}>
          <button onClick={() => setShowCache(false)} className={s.close__button}>
            {isMobile ? (
              <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                <path
                  d="M17.1894 0.402694C17.066 0.279089 16.9195 0.181026 16.7582 0.114117C16.5969 0.0472087 16.424 0.0127684 16.2494 0.0127684C16.0747 0.0127684 15.9018 0.0472087 15.7405 0.114117C15.5792 0.181026 15.4327 0.279089 15.3094 0.402694L8.78936 6.90936L2.26936 0.38936C2.14592 0.265918 1.99937 0.167998 1.83809 0.101191C1.6768 0.0343846 1.50394 1.30068e-09 1.32936 0C1.15479 -1.30068e-09 0.981922 0.0343846 0.820636 0.101191C0.659351 0.167998 0.512803 0.265918 0.38936 0.38936C0.265918 0.512803 0.167998 0.659351 0.101191 0.820636C0.0343846 0.981922 -1.30068e-09 1.15479 0 1.32936C1.30068e-09 1.50394 0.0343846 1.6768 0.101191 1.83809C0.167998 1.99937 0.265918 2.14592 0.38936 2.26936L6.90936 8.78936L0.38936 15.3094C0.265918 15.4328 0.167998 15.5794 0.101191 15.7406C0.0343846 15.9019 0 16.0748 0 16.2494C0 16.4239 0.0343846 16.5968 0.101191 16.7581C0.167998 16.9194 0.265918 17.0659 0.38936 17.1894C0.512803 17.3128 0.659351 17.4107 0.820636 17.4775C0.981922 17.5443 1.15479 17.5787 1.32936 17.5787C1.50394 17.5787 1.6768 17.5443 1.83809 17.4775C1.99937 17.4107 2.14592 17.3128 2.26936 17.1894L8.78936 10.6694L15.3094 17.1894C15.4328 17.3128 15.5794 17.4107 15.7406 17.4775C15.9019 17.5443 16.0748 17.5787 16.2494 17.5787C16.4239 17.5787 16.5968 17.5443 16.7581 17.4775C16.9194 17.4107 17.0659 17.3128 17.1894 17.1894C17.3128 17.0659 17.4107 16.9194 17.4775 16.7581C17.5443 16.5968 17.5787 16.4239 17.5787 16.2494C17.5787 16.0748 17.5443 15.9019 17.4775 15.7406C17.4107 15.5794 17.3128 15.4328 17.1894 15.3094L10.6694 8.78936L17.1894 2.26936C17.696 1.76269 17.696 0.909361 17.1894 0.402694Z"
                  fill="black"
                  fillOpacity="0.5"
                />
              </svg>
            ) : (
              <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                <path
                  d="M24.4001 7.61339C24.2767 7.48978 24.1302 7.39172 23.9689 7.32481C23.8076 7.2579 23.6347 7.22346 23.4601 7.22346C23.2854 7.22346 23.1125 7.2579 22.9512 7.32481C22.7899 7.39172 22.6434 7.48978 22.5201 7.61339L16.0001 14.1201L9.48005 7.60005C9.35661 7.47661 9.21006 7.37869 9.04878 7.31188C8.88749 7.24508 8.71463 7.21069 8.54005 7.21069C8.36548 7.21069 8.19261 7.24508 8.03133 7.31188C7.87004 7.37869 7.7235 7.47661 7.60005 7.60005C7.47661 7.7235 7.37869 7.87004 7.31188 8.03133C7.24508 8.19261 7.21069 8.36548 7.21069 8.54005C7.21069 8.71463 7.24508 8.88749 7.31188 9.04878C7.37869 9.21006 7.47661 9.35661 7.60005 9.48005L14.1201 16.0001L7.60005 22.5201C7.47661 22.6435 7.37869 22.79 7.31188 22.9513C7.24508 23.1126 7.21069 23.2855 7.21069 23.4601C7.21069 23.6346 7.24508 23.8075 7.31188 23.9688C7.37869 24.1301 7.47661 24.2766 7.60005 24.4001C7.7235 24.5235 7.87004 24.6214 8.03133 24.6882C8.19261 24.755 8.36548 24.7894 8.54005 24.7894C8.71463 24.7894 8.88749 24.755 9.04878 24.6882C9.21006 24.6214 9.35661 24.5235 9.48005 24.4001L16.0001 17.8801L22.5201 24.4001C22.6435 24.5235 22.79 24.6214 22.9513 24.6882C23.1126 24.755 23.2855 24.7894 23.4601 24.7894C23.6346 24.7894 23.8075 24.755 23.9688 24.6882C24.1301 24.6214 24.2766 24.5235 24.4001 24.4001C24.5235 24.2766 24.6214 24.1301 24.6882 23.9688C24.755 23.8075 24.7894 23.6346 24.7894 23.4601C24.7894 23.2855 24.755 23.1126 24.6882 22.9513C24.6214 22.79 24.5235 22.6435 24.4001 22.5201L17.8801 16.0001L24.4001 9.48005C24.9067 8.97339 24.9067 8.12005 24.4001 7.61339Z"
                  fill="black"
                  fillOpacity="0.5"
                />
              </svg>
            )}
          </button>
          <div className={s.modal__icons}>
            <img alt="" className={s.modal_photo} src={require('../../assets/img/ModalCache/cacheLogo.png')} />
            <ArrowRight className={s.arrow} />
            <img alt="" className={s.modal__photo_active} src={require('../../assets/img/ModalCache/activeLogo.png')} />
          </div>
          <h4 className={s.modal__title}>You’re using an old version!</h4>
          <p className={s.modal__sub_title}>
            To enjoy the latest features and improvements, please refresh your application.
          </p>
          <div className={s.modal__action}>
            <p className={s.modal__desc}>Refresh will only take 1-2 seconds</p>
            <button className={s.modal__refresh} onClick={() => handleRefresh()}>
              Refresh Now{' '}
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
                <path
                  d="M13.65 3.29995C12.9099 2.5548 12.0296 1.96351 11.0599 1.56018C10.0902 1.15685 9.05024 0.949457 8 0.949952C5.87827 0.949952 3.84344 1.79281 2.34315 3.2931C0.842855 4.79339 0 6.82822 0 8.94995C0 11.0717 0.842855 13.1065 2.34315 14.6068C3.84344 16.1071 5.87827 16.95 8 16.95C11.73 16.95 14.84 14.4 15.73 10.95H13.65C13.2381 12.1195 12.4733 13.1323 11.4613 13.8488C10.4493 14.5652 9.23994 14.95 8 14.95C6.4087 14.95 4.88258 14.3178 3.75736 13.1926C2.63214 12.0674 2 10.5413 2 8.94995C2 7.35865 2.63214 5.83253 3.75736 4.70731C4.88258 3.58209 6.4087 2.94995 8 2.94995C9.66 2.94995 11.14 3.63995 12.22 4.72995L9 7.94995H16V0.949952L13.65 3.29995Z"
                  fill="white"
                />
              </svg>
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ModalCache;
