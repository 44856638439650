import React from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import "./../Banner.scss";

const BannerMarket = () => {
  const history = useHistory();
  
  const routeToMarket = () => {
    history.push("/admin/counsellors");
  };

  return (
    <div onClick={() => routeToMarket()} className="intro-banner intro-banner--two">
      <div className="intro-banner__wrap">
        <div className="intro-banner__descr">
          <h5 className="intro-banner__title">
            STUDY ABROAD WITH KANDOR
          </h5>
          <p className="intro-banner__text intro-banner__text--white">
            Explore our study abroad suite and connect with our Counsellor
          </p>
          <button
            onClick={() => routeToMarket()}
            className="intro-banner__btn btn-hover"
          >
            Book Session
          </button>
        </div>
      </div>
    </div>
  )
}

export default BannerMarket