import React, { useRef, useEffect, useState, memo } from "react";
import { useSelector } from "react-redux";

import "./styles/Error.scss";
import mixpanel from "mixpanel-browser";
import s from "./styles/ErrorFallback.module.scss";
import { ErrorFallbackScreen } from "../kandor-components/ErrorFallbackScreen/ErrorFallbackScreen.jsx";
import { ErrorFallbackLoadingScreen } from "kandor-components/ErrorFallbackScreen/ErrorFallbackLoadingScreen";
import ErrorFallbackHeader from "kandor-components/ErrorFallbackScreen/Header/ErrorFallbackHeader";
const ErrorFallback = ({ error, resetErrorBoundary }) => {
  const isAuthenticated = useSelector(state => state.auth.isAuthenticated);

  const [isRefreshed, setIsRefreshed] = useState(false);
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [widthProgress, setWidthProgress] = useState(10);

  const wrapRef = useRef(null);

  const newWidth =
    window.innerWidth > 0 ? window.innerWidth : window.screen.width;
  const [width, setWidth] = useState(newWidth);

  useEffect(() => {
    mixpanel.track(`Error page`, { error: error.message, cause: error.cause });
    const isRefreshed = JSON.parse(localStorage.getItem("isRefreshed"));
    setIsRefreshed(isRefreshed);
    setLoading(!isRefreshed);
  }, [error.cause, error.message]);

  useEffect(() => {
    const changeWidth = () => {
      window.innerWidth > 0
        ? setWidth(window.innerWidth)
        : setWidth(window.screen.width);
    };
    window.addEventListener("resize", changeWidth);
    return () => window.removeEventListener("resize", changeWidth);
  }, []);

  useEffect(() => {
    const mobHeight = () => {
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty("--vh", `${vh}px`);
    };
    mobHeight();

    window.addEventListener("resize", mobHeight);
    return () => window.removeEventListener("resize", mobHeight);
  }, []);

  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    //wrapRef.scrollTop = 0
    document.body.classList.add("error-page");
  }, []);

  const handleRefresh = () => {
    localStorage.setItem("isRefreshed", true);
    window.location.reload();
    resetErrorBoundary();

    // Reset error boundary as needed
  };

  useEffect(() => {
    const updateInterval = 70; // Set the interval for progress updates (milliseconds)

    // Set up a timer to update the progress at regular intervals until loading is complete
    let progressTimer;
    let timeoutId;
    if (loading) {
      timeoutId = setTimeout(() => {
        setLoading(false);
      }, 7000);
      progressTimer = setInterval(() => {
        setProgress(prevProgress =>
          prevProgress < 100 ? prevProgress + 1 : prevProgress
        );

        setWidthProgress(prevProgress =>
          prevProgress < 100 ? prevProgress + 1 : prevProgress
        );

        if (progress === 100) {
          // Call handleRefresh only when progress is less than 100%
          handleRefresh();
        }
      }, updateInterval);
    }

    // Clean up the timeout and progress timer when the component unmounts or when loading is complete
    return () => {
      clearTimeout(timeoutId);
      clearInterval(progressTimer);
    };
  }, [loading, progress]);

  return (
    <>
      <div
        className="wrapper"
        ref={wrapRef}
        style={{ height: "calc(var(--vh, 1vh) * 100)" }}
      >
        <div className={`err-page ${isAuthenticated ? "err-page--small" : ""}`}>
          <ErrorFallbackHeader />
          {loading && !isRefreshed ? (
            <ErrorFallbackLoadingScreen
              progress={progress}
              widthProgress={widthProgress}
            />
          ) : (
            <ErrorFallbackScreen resetErrorBoundary={resetErrorBoundary} />
          )}
          <footer className={s.footer_error}></footer>
        </div>
      </div>
    </>
  );
};

export default memo(ErrorFallback);
