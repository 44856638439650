import React, { useRef, useState } from 'react';
import Slider from 'react-slick';
import BannerForApply from 'kandor-components/ApplyBanner/BannerInfoShortList/BannerForApply';
import './BannersSlider.scss';
import CustomDot from './CustomDot/CustomDot';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { useMediaQuery } from 'react-responsive';
import BannerForApplyPC from 'kandor-components/ApplyBanner/BannerInfoShortList/BannerForApplyPC/BannerForApplyPC';
import Slide1 from '../../../../assets/icons/applyBanner/1-slide.png';
import Slide2 from '../../../../assets/icons/applyBanner/2-slide.png';
import Slide3 from '../../../../assets/icons/applyBanner/3-slide.png';
import Slide4 from '../../../../assets/icons/applyBanner/4-slide.png';
import Slide6 from '../../../../assets/icons/applyBanner/6-slide.png';
import Avatar from '../../../../assets/icons/applyBanner/avatarPC.png';
const BannersSlider = ({ setCurrentSecondStep }) => {
  const isMobile = useMediaQuery({ query: '(max-width:768px)' });
  const sliderRef = useRef(null);
  const [currentSlide, setCurrentSlide] = useState(0);
  const history = useHistory();

  const CustomNextArrow = ({ onClick }) => {
    return (
      <button
        style={{
          display: currentSlide === totalSlides - 1 ? 'none' : 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
        className="slick-arrow slick-next"
        onClick={onClick}>
        Next
      </button>
    );
  };

  const CustomPrevArrow = ({ onClick }) => {
    return (
      <button
        className="slick-arrow slick-prev"
        onClick={onClick}
        style={{ display: currentSlide === 0 ? 'none' : 'flex', alignItems: 'center', justifyContent: 'center' }}>
        > Prev
      </button>
    );
  };

  const settings = {
    dots: isMobile ? true : false,
    infinite: isMobile ? true : false,
    speed: 500,
    autoplay: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: isMobile ? false : true,
    nextArrow: <CustomNextArrow />,
    prevArrow: <CustomPrevArrow />,
    adaptiveHeight: true,
    appendDots: dots => <ul>{dots}</ul>,
    customPaging: i => {
      const bannerType = bannerConfig[i].type;
      return (
        <CustomDot type={bannerType} onClick={() => sliderRef.current.slickGoTo(i)} isActive={currentSlide === i} />
      );
    },
    beforeChange: (oldIndex, newIndex) => {
      setCurrentSlide(newIndex);
    },
  };

  const bannerConfig = [
    {
      type: 'purple',
      title: 'Apply and Earn! ',
      desc: 'Earn up to $1000 just by applying through us. Scholarships worth $5000 and many more',
      startApplicationProcess: false,
    },

    {
      type: 'purple',
      title: 'Zero Application Fee! ',
      desc: 'Secure fee waivers on college applications by applying through Kandor',
      startApplicationProcess: false,
    },
    {
      type: 'orange',
      title: 'Premium Counselling!',
      desc: 'Get personalized feedback on your application documents from our experienced Advisors',
      startApplicationProcess: false,
    },
    {
      type: 'purple',
      title: 'Study Abroad AI Tools!',
      desc: 'Free Access to AI-powered IELTS prep and document prep (SOP, LOR & Essay) tools.',
      startApplicationProcess: false,
      goToAiTool: () => {
        history.push('/admin/aisop');
      },
      tryButton: true,
    },
    {
      type: 'orange',
      title: 'Document Vault!',
      desc: 'Enjoy the confidence of knowing your documents are protected and always within reach.',
      startApplicationProcess: false,
    },

    {
      type: 'orange',
      title: 'Why Choose Kandor?',
      info: ['10,000+ admissions', '$50k scholarships ', 'Built by IVY League, IIT Alumni '],
      startApplicationProcess: false,
    },
  ];

  const bannerConfigForPC = [
    {
      title: 'Zero Application Fee!',
      desc: 'Secure fee waivers on college applications by applying through Kandor',
      img: Slide1,
      buttonText: 'Start Application Process',
      padding: true,
      onClick: () => setCurrentSecondStep(2),
    },
    {
      title: 'Premium Counselling!',
      desc: 'Get personalized feedback on your application documents from our experienced Advisors',
      img: Slide2,
      buttonText: 'Start Application Process',
      padding: true,
      onClick: () => setCurrentSecondStep(2),
    },
    {
      title: 'Study Abroad AI Tools!',
      desc: 'Free Access to AI-powered IELTS prep and document prep (SOP, LOR & Essay) tools.',
      img: Slide3,
      buttonText: 'Start Application Process',
      padding: true,
      onClick: () => setCurrentSecondStep(2),
    },
    {
      title: 'Document Vault!',
      desc: 'Enjoy the confidence of knowing your documents are protected and always within reach.',
      img: Slide4,
      buttonText: 'Start Application Process',
      padding: true,
      onClick: () => setCurrentSecondStep(2),
    },
    //     {
    //       title: 'Why Choose Kandor?',
    //       desc: `With hundreds of successful admits and millions
    // in scholarships secured, we’re proud to be your
    // trusted partner on this journey.
    // Join the Kandor community and take the first
    // step towards a brighter future today.`,
    //       info: ['10,000+ admissions', '$50k scholarships ', 'Built by IVY League, IIT Alumni '],
    //       img: Slide6,
    //       buttonText: 'Start Application Process',
    //       onClick: () => setCurrentSecondStep(2),
    //     },
  ];
  const totalSlides = isMobile ? bannerConfig.length : bannerConfigForPC.length;
  return (
    <div className="banner__slider">
      {isMobile ? (
        <Slider ref={sliderRef} {...settings}>
          {bannerConfig.map(el => (
            <BannerForApply key={el.title} {...el} />
          ))}
        </Slider>
      ) : (
        <Slider ref={sliderRef} {...settings}>
          {bannerConfigForPC.map(el => (
            <BannerForApplyPC key={el.title} {...el} />
          ))}
        </Slider>
      )}
    </div>
  );
};

export default BannersSlider;
