import React, { useEffect } from 'react';
import s from './WishListCount.module.scss';
import { ReactComponent as Like } from '../../../assets/icons/applyBanner/like.svg';
import { ReactComponent as Arrow } from '../../../assets/icons/applyBanner/right-arrow 1.svg';

const WishListCount = ({ wishlist, isCollapse, setIsCollapse }) => {
  useEffect(() => {
    const storedIsCollapse = localStorage.getItem('isCollapse');
    if (storedIsCollapse !== null) {
      setIsCollapse(JSON.parse(storedIsCollapse));
    }
  }, [setIsCollapse]);

  const onCollapse = () => {
    const newCollapseState = !isCollapse;
    setIsCollapse(newCollapseState);
    localStorage.setItem('isCollapse', JSON.stringify(newCollapseState));
  };

  return (
    <>
      <div style={!isCollapse ? { marginBottom: '40px' } : null} className={s.wishlistCount}>
        <div className={s.wishlistCount__container}>
          <div className={s.wishlistCount__content}>
            <div className={s.wishlistCount__header}>
              <div className={s.wishlistCount__iconWrapper}>
                <div className={s.like}>
                  <Like />
                </div>
              </div>
              <h2 className={s.wishlistCount__title}>My Shortlist</h2>
            </div>
            <div className={s.wishlistCount__number}>{wishlist.length}</div>
            <button onClick={() => onCollapse()} className={s.wishlistCount__button}>
              <Arrow style={isCollapse ? { transform: 'rotate(180deg)' } : null} />
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default WishListCount;
