import { verifyAuth } from 'actions/index.js';
import ArticlesReducer from 'reducers/ArticlesReduce';
import auth from 'reducers/auth.js';
import counselorReducer from 'reducers/CounselorReducer';
import NewsReducer from 'reducers/NewsReducer';
import SopsReducer from 'reducers/SopsReducer';
import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import thunk from 'redux-thunk';
import WebinarsReducer from 'reducers/WebinarsReducer';
import ShortlistersReducer from 'reducers/ShortlistersReduce';
import OnBoardingReducer from 'reducers/OnBoardingReducer';
import MyIeltsTubReduce from 'reducers/MyIeltsTubReduce';
import AutoFullReducer from 'reducers/AutoFullReducer';
import DocumentsReducer from 'reducers/DocumentsReducer';

import WishlistReducer from 'reducers/WishlistReducer';
const isLocalApi = process.env.REACT_APP_API_URL === 'http://127.0.0.1:5000/';

const composeEnhancers = isLocalApi ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose : compose;

const rootReducer = combineReducers({
  auth: auth,
  counselors: counselorReducer,
  news: NewsReducer,
  articles: ArticlesReducer,
  sops: SopsReducer,
  shortlisters: ShortlistersReducer,
  webinars: WebinarsReducer,
  onBoarding: OnBoardingReducer,
  myIeltsTub: MyIeltsTubReduce,
  autofill: AutoFullReducer,
  documents: DocumentsReducer,
  wishlist: WishlistReducer,
});

const createStoreWithMiddleware = () => {
  const store = createStore(rootReducer, composeEnhancers(applyMiddleware(thunk)));
  store.dispatch(verifyAuth());

  return store;
};

export default createStoreWithMiddleware;
