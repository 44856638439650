import React from 'react';
import BannerForApply from './BannerForApply';
import s from '../ApplyBanner.module.scss';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { useMediaQuery } from 'react-responsive';
import BannerForApplyPC from './BannerForApplyPC/BannerForApplyPC';
import PcZeroList from '../../../assets/icons/applyBanner/pc-zero-list.png';
import Pc14List from '../../../assets/icons/applyBanner/pc-1-4-list.png';
import { ReactComponent as ArrowIcon } from '../../../assets/icons/applyBanner/ArrowApply.svg';
import mixpanel from 'mixpanel-browser';

const MainBanner = ({ wishlist, setCurrentStep, reviewOfExpert, setReviewOfExpert, isCollapse }) => {
  const history = useHistory();
  const isMobile = useMediaQuery({ query: '(max-width:768px)' });

  const bannerConfig = [
    {
      condition: wishlist.length === 5,
      type: 'purple',
      title: 'Awesome!',
      desc: `Your shortlist looks solid. it's time to prepare your college applications. Get started now!`,
      buttonClass: s.purple__button,
      buttonText: 'Start Application Process',
      onClick: () => {
        setCurrentStep(2);
        mixpanel.track('Clicked on start application process');
      },
      onStartApplicationProcess: () => {
        return;
      },
      startApplicationProcess: true,
    },
    {
      condition: wishlist.length > 0 && wishlist.length <= 4,
      type: 'purple',
      title: 'Expand Your Horizon!',
      desc: 'Reaching 5 colleges in your shortlist is a great milestone. Let’s explore some more options together',
      buttonClass: s.purple__button,
      buttonText: 'Add More Colleges',
      onClick: () => {
        mixpanel.track('Clicked on add more colleges');
        history.push('/admin/shortlister');
      },
      startApplicationProcess: false,
    },
    {
      condition: wishlist.length === 0,
      type: 'purple',
      title: 'Start Shortlisting Your Future!',
      desc: 'Creating a shortlist helps you stay organized and makes college comparison easy',
      buttonText: 'Add Your First College',
      buttonClass: s.orange__button,
      onClick: () => {
        mixpanel.track('Clicked on add your first college');
        history.push('/admin/shortlister');
      },
      startApplicationProcess: false,
    },
  ];

  const bannerConfigForPC = [
    {
      condition: wishlist.length === 5,
      title: 'Awesome!',
      img: Pc14List,
      desc: `Your shortlist looks solid. it's time to prepare your college applications. Get started now!`,
      buttonText: 'Start Application Process',
      onClick: () => {
        setCurrentStep(2);
        mixpanel.track('Clicked on start application process');
      },
      onStartApplicationProcess: () => history.push('/admin/shortlister'),
      startApplicationProcess: true,
    },
    {
      condition: wishlist.length > 0 && wishlist.length <= 4,
      title: 'Expand Your Horizon!',
      desc: 'Reaching 5 colleges in your shortlist is a great milestone. Let’s explore some more options together',
      buttonText: 'Add More Colleges',
      img: Pc14List,
      onClick: () => {
        mixpanel.track('Clicked on add more colleges');
        history.push('/admin/shortlister');
      },
    },
    {
      condition: wishlist.length === 0,
      title: 'Start Shortlisting Your Future!',
      desc: 'Creating a shortlist helps you stay organized and makes college comparison easy',
      img: PcZeroList,
      buttonText: 'Add Your First College',
      onClick: () => {
        mixpanel.track('Clicked on add your first college');
        history.push('/admin/shortlister');
      },
    },
  ];

  const bannerToShow = bannerConfig.find(config => config.condition);
  const bannerToShowForPC = bannerConfigForPC.find(config => config.condition);

  return (
    <div className={s.banner__container}>
      {wishlist.length > 0 && (
        <>
          <div className={`${s.final__container} ${isCollapse ? `${s.show}` : ''}`}>
            <div className={s.uniContainer}>
              {wishlist.map(el => (
                <div className={s.uni__block}>
                  <div className={s.uni__block_wrap}>
                    <div className={s.info}>
                      <div className={s.logo}>
                        <img src={el.logo_s3_link} />
                      </div>
                      <div>
                        <h2 className={s.name}>{el.title}</h2>
                        <p className={s.courses}>{el.courses?.course_name}</p>
                      </div>
                    </div>

                    <button
                      onClick={() => {
                        mixpanel.track('Clicked on quick view on apply flow');
                        history.push('/admin/wishlist');
                      }}
                      className={s.action__button}>
                      {isMobile ? 'View' : 'Quick View '}{' '}
                    </button>
                  </div>
                </div>
              ))}
            </div>

            <div className={s.footer}>
              <button
                onClick={() => {
                  mixpanel.track('Clicked on view all on apply flow');
                  history.push('/admin/wishlist');
                }}
                className={s.viewAll}>
                View All
              </button>
            </div>
          </div>

          {/* {isMobile ? (
            <BannerForApply
              type="purple"
              title="Ready with your Shortlist?"
              desc={`Your shortlist looks solid. it's time to prepare your college applications. Get started now!`}
              startApplicationProcess={''}
              onStartApplicationProcess={false}
              button={
                <div style={{ marginTop: '20px' }} className={s.button__container}>
                  <button onClick={() => setCurrentStep(2)} className={bannerToShow.buttonClass}>
                    Start Application Process
                  </button>
                </div>
              }
            />
          ) : (
            <BannerForApplyPC
              title="Awesome!"
              desc={`Your shortlist looks solid. it's time to prepare your college applications. Get started now!`}
              buttonText="Start Application Process"
              img={PcExpertReview}
              onClick={() => setCurrentStep(2)}
            />
          )} */}
        </>
      )}

      {bannerToShow &&
        reviewOfExpert === false &&
        (isMobile ? (
          <BannerForApply
            type={bannerToShow.type}
            title={bannerToShow.title}
            desc={bannerToShow.desc}
            startApplicationProcess={bannerToShow.startApplicationProcess}
            onStartApplicationProcess={bannerToShow.onStartApplicationProcess}
            button={
              <div
                style={bannerToShow.startApplicationProcess ? { marginTop: '20px' } : null}
                className={s.button__container}>
                <button
                  style={{ display: 'flex', alignItems: 'center', gap: '8px' }}
                  className={bannerToShow.buttonClass}
                  onClick={bannerToShow.onClick}>
                  {bannerToShow.buttonText} <ArrowIcon />
                </button>
              </div>
            }
          />
        ) : (
          <BannerForApplyPC
            title={bannerToShowForPC.title}
            desc={bannerToShowForPC.desc}
            buttonText={bannerToShowForPC.buttonText}
            img={bannerToShowForPC.img}
            onClick={bannerToShowForPC.onClick}
            baseline={bannerToShowForPC.baseline}
            onStartApplicationProcess={bannerToShowForPC.onStartApplicationProcess}
            startApplicationProcess={bannerToShowForPC.startApplicationProcess}
          />
        ))}

      <>
        {wishlist.length > 5 && (
          <div className={s.actionWrap}>
            <button onClick={() => setCurrentStep(2)} className={s.startApp}>
              Start Application Process <ArrowIcon />
            </button>
            <button onClick={() => history.push('/admin/shortlister')} className={s.AddColleges}>
              Add colleges
            </button>
          </div>
        )}
      </>
    </div>
  );
};

export default MainBanner;
