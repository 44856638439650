import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import s from "../../pages/styles/UploadForm.module.scss";

import { ReactComponent as Close } from "../../assets/icons/close.svg";
import "../../pages/styles/slick-theme.css";
import "../../pages/styles/slick.css";

import Slider from "./Slider/Slider";

import ModalForm from "./ModalForm/ModalForm";
import Terms from "./Terms/Terms";

const ModalWindow = ({ show, setShow }) => {
  const [uploadedFile, setUploadedFile] = useState(null);
  const [showTerm, setShowTerm] = useState(false);
  return (
    <Modal
      dialogClassName={s.myModalStyle}
      contentClassName={s.custom_modal}
      show={show}
      onHide={() => {
        setShow(false);
        setShowTerm(false);
      }}
    >
      <Modal.Header style={{ padding: 0 }} closeVariant="white">
        <button
          className={s.custom__close}
          onClick={() => {
            setShow(false);
            setUploadedFile(null);
          }}
        >
          <Close />
        </button>
      </Modal.Header>
      <div className={s.modal__container}>
        {showTerm ? (
          <Terms showTerm={showTerm} setShowTerm={setShowTerm} />
        ) : (
          <>
            <div className={s.modal__left}>
              <ModalForm
                show={show}
                setShow={setShow}
                showTerm={showTerm}
                setShowTerm={setShowTerm}
              />
            </div>
            <div className={s.line}></div>
            <div className={s.modal__right}>
              <Slider />
            </div>
          </>
        )}
      </div>
    </Modal>
  );
};

export default ModalWindow;
