import React, { useState } from 'react';
import s from './ApplyBanner.module.scss';
import HeaderApply from './HeaderApply/HeaderApply';
import StepIndicator from './StepIndicator/StepIndicator';
import WishListCount from './WishListCount/WishListCount';

import BannersInfoShortList from './BannerInfoShortList/BannerInfoShortList';
import { useSelector } from 'react-redux';
import BannersThroughKandor from './BannersThroughKandor/BannersThroughKandor';
import BannerFinalApply from './BannerFinalApply/BannerFinalApply';
const ApplyBanner = () => {
  const wishlist = useSelector(state => state.wishlist.wishlist);

  const [currentStep, setCurrentStep] = useState(1);

  const [currentSecondStep, setCurrentSecondStep] = useState(1);

  const [reviewOfExpert, setReviewOfExpert] = useState(false);

  const [isCollapse, setIsCollapse] = useState(false);

  return (
    <div className={s.applyBanner}>
      <HeaderApply />
      <div className={s.stepIndicator__container}>
        <StepIndicator currentStep={currentStep} />
      </div>
      <div style={wishlist.length > 0 ? { paddingBottom: '0' } : null} className={s.wishCount__container}>
        <WishListCount wishlist={wishlist} isCollapse={isCollapse} setIsCollapse={setIsCollapse} />
      </div>
      {currentStep === 1 && (
        <BannersInfoShortList
          wishlist={wishlist}
          setCurrentStep={setCurrentStep}
          reviewOfExpert={reviewOfExpert}
          setReviewOfExpert={setReviewOfExpert}
          isCollapse={isCollapse}
        />
      )}

      {currentStep === 2 && (
        <BannersThroughKandor
          currentSecondStep={currentSecondStep}
          setCurrentSecondStep={setCurrentSecondStep}
          setCurrentStep={setCurrentStep}
          currentStep={currentStep}
        />
      )}

      {currentStep === 3 && <BannerFinalApply />}
    </div>
  );
};

export default ApplyBanner;
