import mixpanel from "mixpanel-browser";
import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  Col,
  FormGroup,
  Input,
  Label,
  ListGroup,
  ListGroupItem,
  Row,
} from "reactstrap";
import { ReactComponent as ArrowDown } from "../assets/kandor-img/arrow-down.svg";

export const AnalysisAnswer = ({
  index,
  answersInfo,
  ExplainAnswers,
  reportQuestion,
  executeScroll,
  withLocale,
}) => {
  const intIndex = Number(index);

  const [toggleAnswer, setToggleAnswer] = useState("none");

  const toggleFunction = () => {
    if (toggleAnswer === "none") {
      mixpanel.track("Check Explanation on Reading Analytics Page", {});
      setToggleAnswer("");
    }
    if (toggleAnswer === "") {
      setToggleAnswer("none");
    }
  };

  return (
    <div className="answer-analysis">
      {answersInfo === undefined ||
      answersInfo[index - 1] === undefined ||
      answersInfo[index - 1].correct_answer === undefined ? (
        <div> </div>
      ) : (
        <>
          {answersInfo[index - 1].user_answer === undefined ? (
            <div className="answer-analysis__head">
              <p className="answer-analysis__text">
                <strong> {index}. </strong> Your Answer: ; Correct Answer:{" "}
                {answersInfo[index - 1].correct_answer}
              </p>
              <div className="answer-analysis__icon">
                <img src={require("assets/kandor-img/wrong.png")} />{" "}
              </div>
            </div>
          ) : (
            <div className="answer-analysis__head">
              <p className="answer-analysis__text">
                <strong> {index}. </strong> Your Answer:{" "}
                {answersInfo[index - 1].user_answer}; Correct Answer:{" "}
                {answersInfo[index - 1].correct_answer}
              </p>
              <div className="answer-analysis__icon">
                {answersInfo[index - 1].is_correct ? (
                  <img src={require("assets/kandor-img/right.png")} />
                ) : (
                  <img src={require("assets/kandor-img/wrong.png")} />
                )}
              </div>
            </div>
          )}
          <div className="answer-analysis__footer">
            {" "}
            {/* <Button
							className="answer-analysis__btn"
							color="info"
							size="sm"
							onClick={toggleFunction}
						>
							Explanation
						</Button> */}
            <Button
              className="answer-analysis__btn"
              color="info"
              size="sm"
              onClick={() => {
                mixpanel.track("Check Report on Reading Analytics Page", {});
                reportQuestion(intIndex);
              }}
            >
              Report
            </Button>
            {withLocale && (
              <Button
                className="answer-analysis__btn"
                color="info"
                size="sm"
                onClick={index => {
                  mixpanel.track("Check Locate on Reading Analytics Page", {});
                  executeScroll(intIndex);
                }}
              >
                Locate
              </Button>
            )}
          </div>

          <Card
            body
            inverse
            color="info"
            className="answer-explain"
            style={{
              display: toggleAnswer,
            }}
          >
            <a onClick={toggleFunction} className="answer-explain__close"></a>{" "}
            <CardBody className="answer-explain__text">
              {ExplainAnswers[index]}
            </CardBody>
          </Card>
        </>
      )}
    </div>
  );
};

export const ListeningAnalysisAnswer = ({
  index,
  answersInfo,
  ExplainAnswers,
  reportQuestion,
}) => {
  const intIndex = Number(index);

  const [toggleAnswer, setToggleAnswer] = useState("none");

  const toggleFunction = () => {
    if (toggleAnswer === "none") {
      setToggleAnswer("");
    }
    if (toggleAnswer === "") {
      setToggleAnswer("none");
    }
  };

  return (
    <div className="analysis-answer">
      {answersInfo === undefined ||
      answersInfo[index - 1] === undefined ||
      answersInfo[index - 1].correct_answer === undefined ? (
        <div> </div>
      ) : (
        <>
          {answersInfo[index - 1].user_answer === undefined ? (
            <div className="answer-analysis__head">
              {" "}
              <p className="answer-analysis__text">
                <strong> {index}. </strong> Your Answer: ; Correct Answer:{" "}
                {answersInfo[index - 1].correct_answer}
              </p>
              <div className="answer-analysis__icon">
                <img src={require("assets/kandor-img/wrong.png")} />{" "}
              </div>
            </div>
          ) : (
            <div className="answer-analysis__head">
              <p className="answer-analysis__text">
                <strong> {index}. </strong> Your Answer:{" "}
                {answersInfo[index - 1].user_answer}; Correct Answer:{" "}
                {answersInfo[index - 1].correct_answer}
              </p>{" "}
              <div className="answer-analysis__icon">
                {answersInfo[index - 1].is_correct ? (
                  <img src={require("assets/kandor-img/right.png")} />
                ) : (
                  <img src={require("assets/kandor-img/wrong.png")} />
                )}{" "}
              </div>
            </div>
          )}
          <div className="answer-analysis__footer">
            {" "}
            <Button
              color="info"
              className="answer-analysis__btn"
              size="sm"
              onClick={() => {
                mixpanel.track("Check Report on Listening Analytics Page", {});
                reportQuestion(intIndex);
              }}
            >
              <i
                className="tim-icons icon-alert-circle-exc"
                style={{ marginTop: "-0.25rem" }}
              />{" "}
              Report
            </Button>
          </div>
        </>
      )}
    </div>
  );
};

export const InputOptions = ({
  Items,
  submitAnswers,
  UserAnswers,
  Index,
  QIndex,
  isAnalytics,
}) => {
  return (
    <span className="passage-select">
      <Button
        className="btn-round btn-icon passage-select__icon"
        color="primary"
        disabled
        size="sm"
      >
        {Index}
      </Button>
      <Input
        type="select"
        name="select"
        id="inputState"
        className={`passage-select__select ${
          isAnalytics ? "passage-select__select--disabled" : ""
        }`}
        onChange={e => submitAnswers(Index, QIndex, e.target.value)}
        value={UserAnswers ? UserAnswers[Index] : ""}
      >
        {Items.map((name, index) => {
          return (
            <option key={index} value={name}>
              {" "}
              {name}{" "}
            </option>
          );
        })}
      </Input>
    </span>
  );
};

export const InputOptionsSingleLine = ({
  Items,
  UserAnswers,
  submitAnswers,
  Index,
  QIndex,
  InitialText,
  FinalText,
  isAnalytics,
  isBold,
}) => {
  return (
    <p className={`question-text ${isBold ? "question-text--bold" : ""}`}>
      {InitialText}
      <Button
        className="btn-round btn-icon question-text__icon"
        color="primary"
        disabled
        size="sm"
      >
        {Index}
      </Button>
      <Input
        type="select"
        name="select"
        id="inputState"
        className={`passage-select__select ${
          isAnalytics ? "passage-select__select--disabled" : ""
        }`}
        onChange={e => submitAnswers(Index, QIndex, e.target.value)}
        value={UserAnswers ? UserAnswers[Index] : ""}
      >
        {Items.map((name, index) => {
          return (
            <option key={index} value={name}>
              {" "}
              {name}{" "}
            </option>
          );
        })}
      </Input>{" "}
      {FinalText}
    </p>
  );
};

export const MultipleChoiceQuestions = ({
  Items,
  submitAnswers,
  UserAnswers,
  Index,
  QIndex,
  Text,
  isAnalytics,
}) => {
  const labelList = ["A", "B", "C", "D", "E", "F"];
  return (
    <div className="multi-chose">
      <div className="multi-chose__head">
        <Button
          className="btn-round btn-icon multi-chose__btn"
          color="primary"
          disabled
          size="sm"
        >
          {Index}
        </Button>
        <span className="multi-chose__title">{Text}</span>
      </div>
      <div className="multi-chose__content">
        {Items.map((item, i) => (
          <FormGroup
            check
            className="multi-chose__item form-check-radio"
            key={i}
          >
            <span className="multi-chose__subtitle">{labelList[i]}</span>
            <Label
              className={`form-check-label ${
                isAnalytics ? "form-check-label--disabled" : ""
              }`}
            >
              {UserAnswers === undefined ? (
                <Input
                  type="radio"
                  value={labelList[i]}
                  onChange={e => submitAnswers(Index, QIndex, e.target.value)}
                />
              ) : (
                <Input
                  type="radio"
                  value={labelList[i]}
                  checked={UserAnswers[Index] === labelList[i]}
                  onChange={e => submitAnswers(Index, QIndex, e.target.value)}
                />
              )}
              {item}
              <span className="form-check-sign"></span>
            </Label>
          </FormGroup>
        ))}
      </div>
    </div>
  );
};

export const CheckboxChoice = ({
  maxChose,
  Items,
  submitAnswers,
  UserAnswers,
  Index,
  QIndex,
  Text,
  isAnalytics,
}) => {
  const labelList = ["A", "B", "C", "D", "E", "F"];
  const [answerList, setAnswerList] = useState([]);

  const getCheck = value => {
    if (Array.isArray(UserAnswers[Index])) {
      const isInAnswers = UserAnswers[Index].indexOf(value);
      if (isInAnswers >= 0) return true;
    }
    return false;
  };

  const changeChoice = ({ target }) => {
    const newAnswers = [...answerList];
    const value = target.value;

    if (target.checked && newAnswers.length >= maxChose) return;

    if (target.checked) {
      newAnswers.push(value);
    } else {
      const indexAnswer = newAnswers.indexOf(value);

      if (indexAnswer >= 0) {
        newAnswers.splice(indexAnswer, 1);
      }
    }

    setAnswerList(newAnswers);
    submitAnswers(Index, QIndex, newAnswers);
  };

  return (
    <div className="multi-chose">
      <div className="multi-chose__head">
        <div className="multi-chose__btn-wrap">
          <Button
            className="btn-round btn-icon multi-chose__btn"
            color="primary"
            disabled
            size="sm"
          >
            {Index[0]}
          </Button>
          {Index[1] && Index[Index.length - 1] != Index[0] ? (
            <>
              <span>-</span>
              <Button
                className="btn-round btn-icon multi-chose__btn"
                color="primary"
                disabled
                size="sm"
              >
                {Index[Index.length - 1]}
              </Button>
            </>
          ) : (
            ""
          )}
        </div>
        <span className="multi-chose__title">{Text}</span>
      </div>
      <div className="multi-chose__content">
        {Items.map((item, i) => (
          <FormGroup
            check
            className="multi-chose__item form-check-radio"
            key={i}
          >
            <span className="multi-chose__subtitle">{labelList[i]}</span>
            <Label
              className={`form-check-label ${
                isAnalytics ? "form-check-label--disabled" : ""
              }`}
            >
              {UserAnswers === undefined ? (
                <Input
                  type="checkbox"
                  value={labelList[i]}
                  onChange={e => changeChoice(e)}
                />
              ) : (
                <Input
                  type="checkbox"
                  value={labelList[i]}
                  checked={getCheck(labelList[i])}
                  onChange={e => changeChoice(e)}
                />
              )}
              {item}
              <span className="form-check-sign"></span>
            </Label>
          </FormGroup>
        ))}
      </div>
    </div>
  );
};

export const DisableFillInBlanks = ({
  value,
  Index,
  InitialText,
  FinalText,
  isBold,
}) => {
  return (
    <div className={`question-text ${isBold ? "question-text--bold" : ""}`}>
      {" "}
      {InitialText}{" "}
      <div className="question-text__inner">
        <Button
          className="btn-round btn-icon question-text__icon"
          color="primary"
          disabled
          size="sm"
        >
          {Index ? Index : "0"}
        </Button>

        <Input
          style={{ background: "#e3e3e3", color: "black" }}
          className="question-text__input"
          type="text"
          spellCheck="false"
          value={value}
          readOnly
        />
      </div>
      {"  "}
      {FinalText}{" "}
    </div>
  );
};

export const FillInBlanks = ({
  submitAnswers,
  UserAnswers,
  Index,
  QIndex,
  InitialText,
  FinalText,
  isAnalytics,
  isBold,
}) => {
  return (
    <div className={`question-text ${isBold ? "question-text--bold" : ""}`}>
      {" "}
      {InitialText}{" "}
      <div className="question-text__inner">
        <Button
          className="btn-round btn-icon question-text__icon"
          color="primary"
          disabled
          size="sm"
        >
          {Index}
        </Button>
        {"  "}
        {isAnalytics ? (
          <Input
            className="question-text__input"
            type="text"
            spellCheck="false"
            value={UserAnswers ? UserAnswers[Index] : ""}
            readOnly
          />
        ) : (
          <Input
            style={{ border: "1px solid rgb(202, 209, 215)" }}
            className="question-text__input"
            type="text"
            spellCheck="false"
            value={UserAnswers ? UserAnswers[Index] : ""}
            onChange={e => submitAnswers(Index, QIndex, e.target.value)}
          />
        )}
      </div>
      {"  "}
      {FinalText}{" "}
    </div>
  );
};

export const InlineFillInBlanks = ({
  submitAnswers,
  UserAnswers,
  Index,
  QIndex,
  isAnalytics,
}) => {
  return (
    <div className="question-text question-text--input">
      <Button
        className="btn-round btn-icon question-text__icon"
        color="primary"
        disabled
        size="sm"
        style={{ display: "inline" }}
      >
        {Index}
      </Button>{" "}
      {isAnalytics ? (
        <Input
          className="question-text__input"
          type="text"
          spellCheck="false"
          readOnly
          value={UserAnswers ? UserAnswers[Index] : ""}
        />
      ) : (
        <Input
          style={{ border: "1px solid #cad1d7" }}
          className="question-text__input"
          type="text"
          spellCheck="false"
          value={UserAnswers ? UserAnswers[Index] : ""}
          onChange={e => submitAnswers(Index, QIndex, e.target.value)}
        />
      )}
    </div>
  );
};

export const InputOneLine = ({
  content,
  submitAnswers,
  UserAnswers,
  isAnalytics,
}) => {
  return (
    <div className="input-question-wrap">
      {content.map((data, i) => (
        <div className="input-question" key={i}>
          <div className="input-question__head">
            <Button
              className="btn-round btn-icon input-question__icon"
              color="primary"
              disabled
              size="sm"
              style={{ display: "inline" }}
            >
              {data.number}
            </Button>{" "}
            <p className="input-question__text">{data.initialText}</p>
          </div>

          {isAnalytics ? (
            <Input
              className="input-question__input"
              type="text"
              spellCheck="false"
              readOnly
              value={UserAnswers ? UserAnswers[data.number] : ""}
            />
          ) : (
            <Input
              className="input-question__input"
              type="text"
              spellCheck="false"
              value={UserAnswers ? UserAnswers[data.number] : ""}
              onChange={e =>
                submitAnswers(data.number, data.number, e.target.value)
              }
            />
          )}
        </div>
      ))}
    </div>
  );
};

export const DescriptionTable = ({ optionList, descriptionList, title }) => {
  return (
    <div className="question-table reading-table-90">
      <ListGroup flush className="question-table__list">
        {title && (title[0] || title[1]) && (
          <ListGroupItem className="question-table__item">
            <div className="question-table__content">
              <span className="question-table__option">{title[0]}</span>{" "}
              <span className="question-table__descr">{title[1]}</span>
            </div>
          </ListGroupItem>
        )}
        {optionList.map((data, i) => (
          <ListGroupItem className="question-table__item" key={i}>
            <div className="question-table__content">
              <span className="question-table__option">{data}</span>{" "}
              <span className="question-table__descr">
                {descriptionList[i]}
              </span>
            </div>
          </ListGroupItem>
        ))}
      </ListGroup>
    </div>
  );
};

export const SelectTable = ({
  optionList,
  textList,
  submitAnswers,
  UserAnswers,
  isAnalytics,
}) => {
  return (
    <div className="question-table-wrap scroll-wrap">
      <div className="question-table reading-table-75">
        <ListGroup flush className="question-table__list">
          {textList.map((data, i) => (
            <ListGroupItem className="question-table__item" key={i}>
              <Row>
                <Col xs="4" className="reading-col reading-col-small">
                  <InputOptions
                    Items={optionList}
                    submitAnswers={submitAnswers}
                    UserAnswers={UserAnswers}
                    Index={data.number}
                    QIndex={data.qIndex}
                    isAnalytics={isAnalytics}
                  />
                </Col>
                <Col xs="8" className="reading-col reading-col-small">
                  <span>{data.text}</span>
                </Col>
              </Row>{" "}
            </ListGroupItem>
          ))}
        </ListGroup>
      </div>
    </div>
  );
};

export const InputTable = ({
  optionList,
  submitAnswers,
  UserAnswers,
  isAnalytics,
}) => {
  const [tableWidth, setTableWidth] = useState(0);
  const [doubleCol, setDoubleCol] = useState([]);

  const getWidth = (row, index) => {
    if (row.length == 1) {
      return "100%";
    } else if (doubleCol.length) {
      const indexBigCol = doubleCol.findIndex(el => {
        return el.col == index;
      });

      if (indexBigCol != -1) {
        if (row.length == 2 || row.length == 3) {
          return "50%";
        } else {
          return doubleCol[indexBigCol].size * 140;
        }
      } else {
        if (row.length == 2) {
          return "50%";
        } else if (row.length == 3) {
          return "25%";
        } else {
          return "140px";
        }
      }
    } else if (row.length === 3 || (row.length === 2 && index === 0)) {
      return 100 / 3 + "%";
    } else if (row.length === 2 && index > 0) {
      return (100 / 3) * 2 + "%";
    } else {
      return 100 / row.length + "%";
    }
  };

  useEffect(() => {
    if (optionList.table.length) {
      const items = optionList.table[0].length;
      const bigCol = [];

      optionList.table.forEach((row, i) => {
        row.forEach((col, ind) => {
          if (Array.isArray(col))
            bigCol.push({
              size: col.length,
              col: ind,
            });
        });
      });

      let colSize = (items - bigCol.length) * 140;

      if (bigCol.length) {
        bigCol.forEach(el => {
          colSize += el.size * 140;
        });
      }

      setDoubleCol(bigCol);
      setTableWidth(colSize);
    }
  }, [optionList]);

  const getCol = col => {
    return (
      <>
        {Array.isArray(col) ? (
          <>
            {col.map((el, ind) => (
              <div
                key={ind}
                className={`question-table__col ${
                  ind === 0 ? "question-table__col--small" : ""
                } reading-col ${
                  Array.isArray(el) ? "d-flex flex-row p-0" : ""
                }`}
                style={{
                  width: 100 / col.length + "%",
                  minWidth: "30px",
                  height: "100%",
                }}
              >
                {getCol(el)}
              </div>
            ))}
          </>
        ) : (
          <>
            {col.textStart && (
              <span className="question-table__col-item">{col.textStart}</span>
            )}

            {!col.input ? (
              <span className="question-table__col-item">
                {col.initialText}
              </span>
            ) : (
              <div className="question-table__col-item">
                <FillInBlanks
                  submitAnswers={submitAnswers}
                  UserAnswers={UserAnswers}
                  Index={col.number}
                  QIndex={col.qIndex}
                  InitialText={col.initialText}
                  FinalText={col.finalText}
                  isAnalytics={isAnalytics}
                />
              </div>
            )}

            {col.textEnd && (
              <span className="question-table__col-item">{col.textEnd}</span>
            )}
          </>
        )}
      </>
    );
  };

  return (
    <div className="swipe-black">
      <div className="question-table-wrap scroll-wrap">
        <div
          className="question-table"
          style={{
            width: `calc(${
              optionList.table[0].length > 3 ? tableWidth + "px" : "100%"
            })`,
            minWidth: tableWidth + "px",
          }}
        >
          <ListGroup
            flush
            className="question-table__list question-table__list--table"
            style={{
              minWidth: `calc(${optionList.table[0].length * 140}px`,
              width: "100%",
            }}
          >
            {optionList?.header && optionList.header.length ? (
              <ListGroupItem
                className="question-table__item question-table__item--head"
                style={{
                  "--column": optionList.header.length,
                  width: "100%",
                }}
              >
                <div className="question-table__content m-0">
                  {optionList.header.map((item, index) => (
                    <div
                      key={index}
                      className={`question-table__col ${
                        index === 0 ? "question-table__col--small" : ""
                      } ${
                        optionList.header.length == 1
                          ? "justify-content-center"
                          : ""
                      } reading-col`}
                      style={{
                        width: getWidth(optionList.header, index),
                      }}
                    >
                      <span>{item}</span>
                    </div>
                  ))}
                </div>
              </ListGroupItem>
            ) : (
              ""
            )}
            {optionList.table.map((data, i) => (
              <ListGroupItem
                key={i}
                className="question-table__item"
                style={{
                  "--column": data.length,
                  width: "100%",
                }}
              >
                <div
                  className="question-table__content m-0"
                  style={{
                    width: "100%",
                  }}
                >
                  {data.map((item, index) => (
                    <div
                      key={index}
                      className={`question-table__col ${
                        index === 0 ? "question-table__col--small" : ""
                      } reading-col ${
                        Array.isArray(item) ? "d-flex flex-row p-0" : ""
                      }`}
                      style={{
                        width: getWidth(data, index),
                      }}
                    >
                      {getCol(item)}
                    </div>
                  ))}
                </div>
              </ListGroupItem>
            ))}
          </ListGroup>
        </div>
      </div>
    </div>
  );
};

export const SelectParagraph = ({
  optionList,
  contentList,
  submitAnswers,
  UserAnswers,
  isAnalytics,
}) => {
  return (
    <p className="select-text">
      {contentList.map((item, i) => {
        if (item.input) {
          return (
            <span className="select-text__select" key={i}>
              <InputOptions
                Items={optionList}
                submitAnswers={submitAnswers}
                UserAnswers={UserAnswers}
                Index={item.number}
                QIndex={item.qIndex}
                isAnalytics={isAnalytics}
              />
            </span>
          );
        } else {
          return <span key={i}>{item.text}</span>;
        }
      })}
      .
    </p>
  );
};

export const InputParagraph = ({
  contentList,
  submitAnswers,
  UserAnswers,
  isAnalytics,
}) => {
  const [title, setTitle] = useState("");
  const [content, setContent] = useState([]);

  useEffect(() => {
    if (contentList.length) {
      if (contentList[0].title) {
        setTitle(contentList[0].title);

        const newContent = contentList.filter(el => el.hasOwnProperty("input"));
        setContent(newContent);
      } else {
        setContent(contentList);
      }
    }
  }, [contentList]);

  return (
    <div className="select-text">
      {title && <span className="select-text__title">{title}</span>}
      <p style={{ color: "white" }} className="select-text__content">
        {content.map((item, i) => {
          if (item.input) {
            return (
              <span
                className={`select-text__input ${item.bold ? "bold" : ""}`}
                key={i}
              >
                <InlineFillInBlanks
                  submitAnswers={submitAnswers}
                  UserAnswers={UserAnswers}
                  Index={item.number}
                  QIndex={item.qIndex}
                  isAnalytics={isAnalytics}
                />{" "}
              </span>
            );
          } else {
            return (
              <span
                key={i}
                className={`select-text__text ${item.bold ? "bold" : ""}`}
              >
                {item.text}
              </span>
            );
          }
        })}
      </p>
    </div>
  );
};

export const InputTableParagraph = ({
  optionList,
  submitAnswers,
  UserAnswers,
  isAnalytics,
}) => {
  const [tableWidth, setTableWidth] = useState(0);
  const [doubleCol, setDoubleCol] = useState([]);

  const getWidth = (row, index) => {
    if (row.length == 1) {
      return "100%";
    } else if (doubleCol.length) {
      const indexBigCol = doubleCol.findIndex(el => {
        return el.col == index;
      });

      if (indexBigCol != -1) {
        if (row.length == 2 || row.length == 3) {
          return "50%";
        } else {
          return doubleCol[indexBigCol].size * 140;
        }
      } else {
        if (row.length == 2) {
          return "50%";
        } else if (row.length == 3) {
          return "25%";
        } else {
          return "140px";
        }
      }
    } else if (row.length === 3 || (row.length === 2 && index === 0)) {
      return 100 / 3 + "%";
    } else if (row.length === 2 && index > 0) {
      return (100 / 3) * 2 + "%";
    } else {
      return 100 / row.length + "%";
    }
  };

  useEffect(() => {
    if (optionList.table.length) {
      const items = optionList.table[0].length;
      const bigCol = [];

      optionList.table.forEach((row, i) => {
        row.forEach((col, ind) => {
          if (Array.isArray(col[0])) {
            const isIndexBigCol = bigCol.findIndex(el => {
              return el.col == ind;
            });

            let lengthCol = col.length;

            const addLength = item => {
              if (Array.isArray(item)) {
                lengthCol += item.length;

                item.forEach(el => {
                  addLength(el[0]);
                });
              }
            };

            addLength(col[0][0]);

            if (isIndexBigCol == -1) {
              bigCol.push({
                size: lengthCol,
                col: ind,
              });
            } else if (lengthCol > bigCol[isIndexBigCol].size) {
              bigCol[isIndexBigCol].size = lengthCol;
            }
          }
        });
      });

      let colSize = (items - bigCol.length) * 140;

      if (bigCol.length) {
        bigCol.forEach(el => {
          colSize += el.size * 140;
        });
      }

      setDoubleCol(bigCol);
      setTableWidth(colSize);
    }
  }, [optionList]);

  const getCol = col => {
    return (
      <>
        {Array.isArray(col[0]) ? (
          <>
            {col.map((el, ind) => (
              <div
                key={ind}
                className={`question-table__col ${
                  ind === 0 ? "question-table__col--small" : ""
                } reading-col ${
                  Array.isArray(el[0]) ? "d-flex flex-row p-0" : ""
                }`}
                style={{
                  width: 100 / col.length + "%",
                  minWidth: "30px",
                  height: "100%",
                }}
              >
                {getCol(el)}
              </div>
            ))}
          </>
        ) : (
          <InputParagraph
            contentList={col}
            submitAnswers={submitAnswers}
            UserAnswers={UserAnswers}
            isAnalytics={isAnalytics}
          />
        )}
      </>
    );
  };

  return (
    <div className="swipe-black">
      <div className="question-table-wrap scroll-wrap">
        <div
          className="question-table"
          style={{
            width: `calc(${
              optionList.table[0].length > 3 ? tableWidth + "px" : "100%"
            })`,
            minWidth: tableWidth + "px",
          }}
        >
          <ListGroup
            flush
            className="question-table__list question-table__list--table"
            style={{
              width: "100%",
              minWidth: `calc(${optionList.table[0].length * 140}px`,
            }}
          >
            {optionList?.header && optionList.header.length ? (
              <ListGroupItem
                className="question-table__item question-table__item--head"
                style={{
                  "--column": optionList.header.length,
                  width: "100%",
                }}
              >
                <div className="question-table__content m-0">
                  {optionList.header.map((item, index) => (
                    <div
                      key={index}
                      className={`question-table__col ${
                        index === 0 ? "question-table__col--small" : ""
                      } ${
                        optionList.header.length == 1
                          ? "justify-content-center"
                          : ""
                      } reading-col `}
                      style={{
                        width: getWidth(optionList.header, index),
                      }}
                    >
                      <span>{item}</span>
                    </div>
                  ))}
                </div>
              </ListGroupItem>
            ) : (
              ""
            )}
            {optionList.table.map((data, i) => (
              <ListGroupItem
                key={i}
                className="question-table__item"
                style={{
                  "--column": data.length,
                  width: "100%",
                }}
              >
                <div className="question-table__content m-0">
                  {data.map((item, index) => (
                    <div
                      key={index}
                      className={`question-table__col ${
                        index === 0 ? "question-table__col--small" : ""
                      } reading-col ${
                        Array.isArray(item[0]) ? "d-flex flex-row p-0" : ""
                      }`}
                      style={{
                        width: getWidth(data, index),
                      }}
                    >
                      {getCol(item)}
                    </div>
                  ))}
                </div>
              </ListGroupItem>
            ))}
          </ListGroup>
        </div>
      </div>
    </div>
  );
};

export const StepsTable = ({
  optionList,
  submitAnswers,
  UserAnswers,
  isAnalytics,
}) => {
  return (
    <div className="question-table question-table--steps reading-table-90">
      <ListGroup className="question-table__list">
        {optionList.map((item, i) => (
          <>
            <ListGroupItem className="question-table__item" key={i}>
              {item.input ? (
                <FillInBlanks
                  submitAnswers={submitAnswers}
                  UserAnswers={UserAnswers}
                  Index={item.number}
                  QIndex={item.qIndex}
                  InitialText={item.initialText}
                  FinalText={item.finalText}
                  isAnalytics={isAnalytics}
                />
              ) : (
                <span>{item.initialText}</span>
              )}
            </ListGroupItem>
            {i !== optionList.length - 1 ? (
              <ListGroupItem className="question-table__item" key={i + 100}>
                <Button className="question-table__next" color="info" size="sm">
                  <ArrowDown />
                </Button>
              </ListGroupItem>
            ) : (
              ""
            )}
          </>
        ))}
      </ListGroup>
    </div>
  );
};

export const StepsInputBox = ({
  title,
  contentList,
  submitAnswers,
  UserAnswers,
  isAnalytics,
}) => {
  return (
    <div className="steps-box">
      {title && <span className="steps-box__title">{title}</span>}
      <div className="steps-box__list">
        {contentList.map((data, i) => (
          <>
            <div className="steps-box__item" key={i}>
              {data.map((el, j) => (
                <div
                  className={`steps-box__row ${
                    el.center ? "steps-box__row--center" : ""
                  }`}
                  key={j}
                >
                  {el.textStart && <span className="bold">{el.textStart}</span>}

                  {el.input ? (
                    <FillInBlanks
                      submitAnswers={submitAnswers}
                      UserAnswers={UserAnswers}
                      Index={el.number}
                      QIndex={el.qIndex}
                      InitialText={el.initialText}
                      FinalText={el.finalText}
                      isAnalytics={isAnalytics}
                    />
                  ) : (
                    <span>{el.initialText}</span>
                  )}

                  {el.textEnd && <span>{el.textEnd}</span>}
                </div>
              ))}
            </div>

            {i < contentList.length - 1 && (
              <div className="steps-box__arrow" key={i + data.length}>
                <ArrowDown />
              </div>
            )}
          </>
        ))}
      </div>
    </div>
  );
};

export const InfoInputLine = ({
  contentList,
  submitAnswers,
  UserAnswers,
  isAnalytics,
}) => {
  return (
    <div className="questions-row-info">
      {contentList.header && (
        <span className="questions-row-info__title">{contentList.header}</span>
      )}

      {contentList.example && contentList.example.length && (
        <div className="questions-row-info__example">
          {contentList.example.map((data, i) => (
            <div className="questions-row-info__item" key={i}>
              {data.map((info, j) => (
                <div className="questions-row-info__col" key={j}>
                  {info.input ? (
                    <DisableFillInBlanks
                      value={info.value}
                      isBold={info.isBold}
                    />
                  ) : (
                    info.initialText && (
                      <span className={`${info.bold ? "bold" : ""}`}>
                        {info.initialText}
                      </span>
                    )
                  )}
                </div>
              ))}
            </div>
          ))}
        </div>
      )}

      <div className="questions-row-info__list">
        {contentList.textList.map((data, i) => (
          <div className="questions-row-info__item" key={i}>
            {data.map((info, j) => (
              <div className="questions-row-info__col" key={j}>
                {info.input ? (
                  <FillInBlanks
                    submitAnswers={submitAnswers}
                    UserAnswers={UserAnswers}
                    Index={info.number}
                    QIndex={info.number}
                    InitialText={info.initialText}
                    FinalText={info.finalText}
                    isAnalytics={isAnalytics}
                    isBold={info.bold}
                  />
                ) : (
                  info.initialText && (
                    <span className={`${info.bold ? "bold" : ""}`}>
                      {info.initialText}
                    </span>
                  )
                )}
              </div>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
};

export const GroupInputLine = ({
  contentList,
  submitAnswers,
  UserAnswers,
  isAnalytics,
}) => {
  return (
    <div className="group-input">
      {contentList.map((item, i) => (
        <div key={i} className="group-input__inner">
          {item.title && (
            <span className="group-input__title mb-3">{item.title}</span>
          )}
          <div className="group-input__item">
            {item.subtitle && (
              <span className="group-input__subtitle">{item.subtitle}</span>
            )}

            {item.data.map((data, j) => (
              <div className="group-input__content" key={j}>
                {data.input ? (
                  <FillInBlanks
                    submitAnswers={submitAnswers}
                    UserAnswers={UserAnswers}
                    Index={data.number}
                    QIndex={data.number}
                    InitialText={data.initialText}
                    FinalText={data.finalText}
                    isAnalytics={isAnalytics}
                  />
                ) : (
                  <p style={{ color: "white" }} className="group-input__text">
                    {data.initialText}
                  </p>
                )}
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

export const InputTableBlock = ({
  content,
  submitAnswers,
  UserAnswers,
  isAnalytics,
}) => {
  return (
    <div className="trans-table-block">
      {content.title && (
        <span className="trans-table-block__title">{content.title}</span>
      )}
      {content.subtitle && (
        <span className="trans-table-block__subtitle">{content.subtitle}</span>
      )}
      {content.example && (
        <div className="trans-table-block__example">
          {content.example.map((data, i) => (
            <div className="trans-table-block__row" key={i}>
              {data.map((item, j) => (
                <div className="trans-table-block__item" key={j}>
                  {item.input ? (
                    <DisableFillInBlanks
                      value={item.value}
                      isBold={item.isBold}
                    />
                  ) : (
                    <span
                      className={`trans-table-block__text ${
                        item.isBold ? "bold" : ""
                      }`}
                    >
                      {item.initialText}
                    </span>
                  )}
                </div>
              ))}
            </div>
          ))}
        </div>
      )}
      {/* 
			<InfoInputLine
				contentList={content}
				submitAnswers={submitAnswers}
				UserAnswers={UserAnswers}
				isAnalytics={isAnalytics}
			/> */}

      {content.textList.map((data, i) => (
        <div className="trans-table-block__row" key={i}>
          {data.map((item, j) => (
            <div className="trans-table-block__item" key={j}>
              {item.input ? (
                <FillInBlanks
                  submitAnswers={submitAnswers}
                  UserAnswers={UserAnswers}
                  Index={item.number}
                  QIndex={item.number}
                  InitialText={item.initialText}
                  FinalText={item.finalText}
                  isAnalytics={isAnalytics}
                  isBold={item.bold}
                />
              ) : (
                <span
                  className={`trans-table-block__text ${
                    item.bold ? "bold" : ""
                  }`}
                >
                  {item.initialText}
                </span>
              )}
            </div>
          ))}
        </div>
      ))}
    </div>
  );
};

export const SelectColumns = ({
  contentList,
  optionList,
  submitAnswers,
  UserAnswers,
  isAnalytics,
}) => {
  return (
    <div className="select-columns">
      {contentList.map((data, i) => (
        <Row key={i} className="select-columns__row">
          {data.map((info, j) => (
            <Col xs={6} key={j}>
              {info.input ? (
                <InputOptionsSingleLine
                  Items={optionList}
                  submitAnswers={submitAnswers}
                  UserAnswers={UserAnswers}
                  Index={info.number}
                  QIndex={info.number}
                  InitialText={info.initialText}
                  FinalText={info.finalText}
                  isAnalytics={isAnalytics}
                />
              ) : (
                <span
                  className={`select-columns__text ${info.bold ? "bold" : ""} `}
                >
                  {info.initialText}
                </span>
              )}
            </Col>
          ))}
        </Row>
      ))}
    </div>
  );
};

//import {AnalysisAnswer,InputOptions,InputOptionsSingleLine,MultipleChoiceQuestions,FillInBlanks} from 'Data/passage_components.js';
//<InputOptionsSingleLine Items= {["","True","False"]}  submitAnswers={submitAnswers} Index={"18"} QIndex={"19"} Text={"Research shows men make more purchasing decisions than women."}/>
//<InputOptions Items= {["","i","ii","iii","iv","v","vi","vii","viii","ix","x"]} submitAnswers={submitAnswers} Index={"5"} QIndex={"5"}/>
//<AnalysisAnswer index="12"  ActualAnswers={ActualAnswers} UserAnswers={UserAnswers} ExplainAnswers={ExplainAnswers} reportQuestion={reportQuestion}/>
