import axiosInstance from "./api";
import { refreshToken } from "../actions/auth";
import { getAuth } from "firebase/auth";

const setup = (store) => {
  const { dispatch, getState } = store;
  axiosInstance.interceptors.request.use(
    (config) => {
      const TokenId = getState().auth.TokenId;
      if (TokenId) {
        config.headers["Authorization"] = 'Bearer ' + TokenId;
      }
      return config;
    },
    (error) => {
      console.log(error)
      return Promise.reject(error);
    }
  );
  axiosInstance.interceptors.response.use(
    (res) => {
      return res;
    },
    async (err) => {
      console.log(err);
      const originalConfig = err.config;
      if (originalConfig.url !== "/login" && originalConfig.url !== "/logout" && err.response) {
        if ([400, 401, 403].find(el => el === err.response.status) && !originalConfig._retry) {
          originalConfig._retry = true;
          try {
            const accessToken = await getAuth().currentUser.getIdToken();
            console.log("Refresh token : " + accessToken);
            dispatch(refreshToken(accessToken));
            return axiosInstance(originalConfig);
          } catch (_error) {
            return Promise.reject(_error);
          }
        }
      }
      return Promise.reject(err);
    }
  );
};
export default setup;