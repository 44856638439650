export default function lazyRetry(componentImport, name) {
  //not using name for now
  return new Promise((resolve, reject) => {
    // check if the window has already been refreshed
    const hasRefreshed = JSON.parse(
      window.sessionStorage.getItem(`retry-${name}-refreshed`) || "false"
    );
    // try to import the component
    componentImport()
      .then(component => {
        window.sessionStorage.setItem(`retry-${name}-refreshed`, "false"); // success so reset the refresh
        resolve(component);
      })
      .catch(async error => {
        if (!hasRefreshed) {
          // Clear the browser cache if supported
          if (window.caches) {
            await caches.keys().then(names => {
              names.forEach(name => {
                caches.delete(name);
              });
            });
          }

          // Set the flag indicating the page has been force-refreshed
          window.sessionStorage.setItem(`retry-${name}-refreshed`, "true");
          return window.location.reload(); // Force a page refresh
        } else {
          // If already refreshed, reject the promise to let the application handle the error
          reject(error);
        }
      });
  });
}
