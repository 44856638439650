import React from 'react'

export const PassageContent = ({ content, isMock }) => {
	return (
		<div className="passage-content">
			<h3
				className={`passage-content__title ${
					!isMock ? 'passage-content__title--big' : ''
				}`}
			>
				{content.title}
			</h3>

			{!isMock
				? content.data.map((text, i) => (
						<p className="passage-content__text" key={i}>
							{text}
						</p>
				  ))
				: content.data.map((section, i) => (
						<div className="passage-content__section" key={i}>
							<span className="passage-content__subtitle">{section.title}</span>
							{section.data.map((text, i) => (
								<p className="passage-content__text" key={i}>
									{text}
								</p>
							))}
						</div>
				  ))}
		</div>
	)
}

const AnswerParagraph = ({ data, myRef }) => {
	return (
		<p className="passage-content__text passage-content__text--dark">
			{data.map((item, i) => {
				if (item.type === 'answer') {
					return (
						<span
							ref={myRef[+item.number]}
							className="passage-content__answer"
							key={i}
						>
							{' '}
							<span> Q{item.number} </span> {item.text}
						</span>
					)
				} else {
					return <span key={i}> {item.text} </span>
				}
			})}
		</p>
	)
}

export const ReadingAnswerContent = ({ content, myRef, isMock }) => {
	return (
		<div className="passage-content">
			{isMock && (
				<h3
					className={`passage-content__title ${
						!isMock ? 'passage-content__title--big' : ''
					}`}
				>
					{content.title}
				</h3>
			)}

			{!isMock ? (
				<div className="passage-content__section">
					{content.map((data, i) => (
						<AnswerParagraph data={data} myRef={myRef} key={i} />
					))}
				</div>
			) : (
				content.data.map((section, i) => (
					<div className="passage-content__section" key={i}>
						<span className="passage-content__subtitle">{section.title}</span>{' '}
						{section.data.map((data, i) => (
							<AnswerParagraph data={data} myRef={myRef} key={i} />
						))}
					</div>
				))
			)}
		</div>
	)
}
