import React, { useEffect, useRef, useState } from 'react';
import s from './LiveStage.module.scss';
import WebinarCard from '../WebinarCard/WebinarCard';
import { ReactComponent as Microphone } from '../../../assets/icons/webinar/microphone.svg';
import { ReactComponent as Whatsapp } from '../../../assets/icons/webinar/whatsapp.svg';
import { useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';

const LiveStage = () => {
  const webinars = useSelector(state => state.webinars.webinars);
  const liveRef = useRef(null);
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });
  const offsetTop = document.querySelector('#webinarlive')?.offsetTop;

  const scrollTo = () => {
    if (!liveRef.current) return;
    const scrollBlock = liveRef.current.closest('.white-content');
    const topEl = liveRef.current.getBoundingClientRect().top;
    let scrollVal = topEl - 50;

    if (isMobile) {
      scrollVal = topEl - 50 - 70;
    }

    scrollBlock.scrollBy({
      left: 0,
      top: scrollVal,
      behavior: 'instant',
      //behavior: "smooth"
    });
  };

  useEffect(() => {
    if (!liveRef.current) return;

    const id = window.location.hash.substr(1);
    const idWrap = liveRef.current.getAttribute('id');

    if (id && id === idWrap) {
      scrollTo();
    }
  }, [liveRef.current, offsetTop]);

  return (
    <>
      {webinars.length > 0 && (
        <div className={s.live} id={'webinarlive'} ref={liveRef}>
          <div className={s.live__head}>
            <div className={s.live__info}>
              <div className={s.info}>
                <div className={s.info__icon}>
                  <Microphone />
                </div>
                <span className={s.info__text}>Live Stage</span>
              </div>
            </div>
            <div className={s.live__share}>
              <div className={s.share}>
                <div className={s.share__icon}>
                  <Whatsapp />
                </div>
                <span className={s.share__text}>Share</span>
              </div>
            </div>
          </div>

          <div className={s.live__card}>
            {webinars.map(webinar => (
              <WebinarCard key={webinar.id} {...webinar} />
            ))}
          </div>
        </div>
      )}
    </>
  );
};

export default LiveStage;
