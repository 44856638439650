import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { BsFillMicFill } from "react-icons/bs";
import { GiNotebook, GiOpenBook } from "react-icons/gi";
import { MdOutlineHearing } from "react-icons/md";
import { Col, Container, Row, Button } from "reactstrap";
import "./newChanges/scoreBoard.css";
import { ReactComponent as GooglePlayIcon } from "../assets/kandor-img/google-play.svg";
import { isApp, isIOSApp } from "actions/referrer";
import LeaderInfo from "./LeaderInfo";
import { ReactComponent as SpeakingIcon } from "../assets/img/speaking-icon.svg";
import { ReactComponent as ReadingIcon } from "../assets/img/reading-icon.svg";
import { ReactComponent as ListeningIcon } from "../assets/img/listening-icon.svg";
import { ReactComponent as WritingIcon } from "../assets/img/writing-icon.svg";
import mixpanel from "mixpanel-browser";
import { useMediaQuery } from "react-responsive";

const ScoreBoard = ({
  speakingScore,
  listeningScore,
  readingScore,
  writingScore,
  goalScore,
  isLoadingDetails,
  review = null,
  isLoading = false,
  bandAdmin = false,
  withButton = false,
}) => {
  const [progressList, setProgressList] = useState([]);
  const history = useHistory();
  const isMobile = useMediaQuery({ query: "(max-width:767px)" });
  const comparScore = (a, b) => {
    const bandA = +a.score;
    const bandB = +b.score;

    let comparison = 0;
    if (+bandA < +bandB) {
      comparison = 1;
    } else if (+bandA > +bandB) {
      comparison = -1;
    }
    return comparison;
  };

  useEffect(() => {
    const newProgressList = [
      {
        name: "Speaking",
        score: speakingScore ? speakingScore : 0,
        bgColor: "#18C3A4",
        IconComponent: <SpeakingIcon width="13" height="13" />,
        classLoaderProgress: "loader-speaking",
        classIcon: "score-speaking-icon",
      },
      {
        name: "Listening",
        score: listeningScore ? listeningScore : 0,
        bgColor: "#8236F7",
        IconComponent: <ListeningIcon width="13" height="13" />,
        classLoaderProgress: "loader-listening",
        classIcon: "score-hearing-icon",
      },
      {
        name: "Reading",
        score: readingScore ? readingScore : 0,
        IconComponent: <ReadingIcon width="13" height="13" />,
        bgColor: "#CF5784",
        bgImage: "linear-gradient(286.49deg, #3D0F65 -8.45%, #140237 106%)",
        classLoaderProgress: "loader-reading",
        classIcon: "score-reading-icon",
      },
      {
        name: "Writing",
        score: writingScore ? writingScore : 0,
        bgColor: "#E3A504",
        IconComponent: <WritingIcon width="13" height="13" />,
        classLoaderProgress: "loader-writing",
        classIcon: "score-writing-icon",
      },
    ];

    setProgressList(newProgressList.sort(comparScore));
  }, [speakingScore, listeningScore, readingScore, writingScore]);

  // const totalScore = 5.7;
  const renderColorForBar = score => {
    if (score > 4) {
      return "#47BA52";
    }
    if (score === 4) {
      return "#F0C736";
    }
    if (score < 4) {
      return "#EF3616";
    }
  };
  return (
    <>
      <div className={`score_board ${bandAdmin ? "admin" : ""}  `}>
        {!bandAdmin ? (
          <div className="score_board_tittle">
            <div className="score_board_col ">
              <div className="band-wrapper">
                <h4 className="band-goal">My band</h4>
              </div>
            </div>

            <div className="score_board_col ">
              <div className="goal-wrapper wrap-bg">
                <p className="goal-band subtitle-gradient">
                  Your goal:{" "}
                  <span className="goal-band-h5 subtitle-gradient">
                    {goalScore}
                  </span>
                </p>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
        <div>
          {/* <Col xs={6} lg={12} className="p-lg-0 mb-md-3 pl-0 pr-3">
						{isLoading ? (
							<>
								{bandAdmin ? (
									<span className="band-goal d-block mb-2">My band score</span>
								) : (
									''
								)}
								<div className="loader-block loader-grey"></div>
							</>
						) : speakingScore &&
						  listeningScore &&
						  readingScore &&
						  writingScore ? (
							<Row className="m-0">
								<Col xs={12} lg={12} className="p-0">
									{bandAdmin ? (
										<span className="band-goal d-block mb-2">
											My band score
										</span>
									) : (
										''
									)}
									<div className="score_board_score_msg p-0 mb-2">
										<GaugeChart
											className="gauge-chart"
											id="gauge-chart1"
											nrOfLevels={1}
											colors={['#EE7734', ' rgba(110, 95, 123, 0.15)']}
											marginInPercent={0}
											hideText={true}
											arcsLength={[totalScore, 9.5 - totalScore]}
											arcWidth={0.15}
											cornerRadius={0}
											needleColor={'transparent'}
											needleBaseColor={'transparent'}
											style={{
												maxWidth: '180px',
												width: '70%',
											}}
										/>
										<span className="score-current title-gradient">
											{totalScore}
										</span>
									</div>
									{bandAdmin ? (
										<div className="goal-wrapper-admin">
											<p className="goal-band title-gradient">
												Your goal:{' '}
												<span className="goal-band-h5 title-gradient">
													{goalScore}
												</span>
											</p>
										</div>
									) : (
										<p className="score-message text-center">
											You definitely need to make an effort to achieve your
											goal!
										</p>
									)}
								</Col>
							</Row>
						) : (
							<Row className="p-3 mr-0 ml-0 score_board_msg_wrap">
								<Col xs={12} lg={12} align="center" className="p-0 pr-1">
									{' '}
									<div>
										<span
											className="subtitle-gradient"
											style={{
												color: '#EF3616',
												fontWeight: '600',
												fontSize: '16px',
											}}
										>
											TIP!
										</span>
										<p
											className="title-gradient"
											style={{ maxWidth: '210px', marginBottom: '0' }}
										>
											Finish your mock tests to see your score!
										</p>
									</div>
								</Col>
							</Row>
						)}
					</Col> */}

          <div className="score__container-block">
            <div className="score__container-progress">
              {progressList.map((data, i) => (
                <div className="progress-wrap" key={i}>
                  <div>
                    <div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                        className="score__container"
                      >
                        <p className="score-title">
                          {isMobile && (
                            <div
                              style={{
                                background: data.bgColor,
                                padding: "3px",
                                borderRadius: "4px",
                                display: "flex",
                                width: "20px",
                                height: "20px",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              {data.IconComponent}
                            </div>
                          )}
                          {data.name}
                        </p>

                        {isLoading ? (
                          <div className="loader-number loader-grey"></div>
                        ) : (
                          <>
                            <p className="score-number">{data.score}</p>
                          </>
                        )}
                      </div>
                    </div>
                    <div>
                      <div>
                        {isLoading ? (
                          <div
                            className={`loader-progress ${data.classLoaderProgress}`}
                          ></div>
                        ) : (
                          <div
                            className="score-progress"
                            style={{
                              width: "100%",
                              height: "4px",
                              background: "rgba(110, 95, 123, 0.15)",
                              borderRadius: "4px",
                            }}
                          >
                            <span
                              style={{
                                width: (data.score / 9) * 100 + "%",
                                backgroundColor: renderColorForBar(data.score),
                                borderRadius: "4px",
                                display: "block",
                                height: "100%",
                              }}
                            ></span>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <div className="score-leader">
              <LeaderInfo isAdmin={true} />
            </div>

            {/* <div className="xs-stats" style={{ height: "100%" }}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  height: "100%",
                }}
              >

                {progressList.map((data, i) => (
                  <Row
                    className="py-1 mr-0 ml-0"
                    style={{ flexWrap: "nowrap" }}
                    key={i}
                  >
                    <Col xs="auto" className="p-0 mr-2">
                      <p className="score-title">{data.name}</p>
                    </Col>
                    <Col xs="auto" align="right" className="p-0 ml-auto">
                      {isLoading ? (
                        <div className="loader-number loader-grey"></div>
                      ) : (
                        <p className="score-number">{data.score}</p>
                      )}
                    </Col>
                  </Row>
                ))}
              </div>
            </div> */}
          </div>
        </div>
      </div>

      {/* {!isApp() ? (
        <>
          <button
            className="show-story-all"
            type="button"
            onClick={() => {
              mixpanel.track("Opened study material Score Bar", {});
              history.push("/admin/study");
            }}
          >
            <span>Explore the study material {">"}</span>
          </button>
        </>
      ) : isLoadingDetails ? (
        ""
      ) : review >= 7 && !isIOSApp() ? (
        <Button
          color="info"
          className="review-btn"
          href="https://play.google.com/store/apps/details?id=com.trykandor.twa"
          target="_blank"
          onClick={() => {
            mixpanel.track("Leave review on Dashboard", {});
          }}
        >
          <span className="review-btn__icon">
            <GooglePlayIcon />
          </span>
          <span className="review-btn__text">Leave review</span>
        </Button>
      ) : withButton || bandAdmin ? (
        <>
          <button
            className="show-story-all"
            type="button"
            onClick={() => {
              mixpanel.track("Opened study material Score Bar", {});
              history.push("/admin/study");
            }}
          >
            <span>Explore the study material {">"}</span>
          </button>
        </>
      ) : (
        ""
      )} */}
    </>
  );
};

export default ScoreBoard;
