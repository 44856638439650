import React from "react";
import s from "./ClaimScreen.module.scss";
import { ReactComponent as Done } from "../../../../../assets/icons/price/DoneIcon.svg";
import { ReactComponent as FettiLeft } from "../../../../../assets/icons/price/fettiLeft.svg";
import { ReactComponent as FettiRight } from "../../../../../assets/icons/price/fettiRight.svg";
import { ReactComponent as FettiRightForMobile } from "../../../../../assets/icons/price/fettiRightForMobile.svg";
import { ReactComponent as FettiLeftForMobile } from "../../../../../assets/icons/price/fettiLeftForMobile.svg";

import { useMediaQuery } from "react-responsive";

const ClaimScreen = ({ title, id, onClaim, actual, target, internalClaimed }) => {
  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });

  return (
    <div className={s.claimScreen}>
      <div className={s.claimScreen_inner}>
        <div className={s.claimScreen_content}>
          <div className={s.claimScreen_wrap}>
            <div className={s.icon}>
              {isMobile ? <FettiLeftForMobile /> : <FettiLeft />}{" "}
            </div>
            <div className={s.claimScreen_wrap__content}>
              <Done className={s.done_icon} />
              <h5 className={s.claimScreen_title}>Congratulations!</h5>
            </div>
            <div className={s.icon}>
              {isMobile ? <FettiRightForMobile /> : <FettiRight />}{" "}
            </div>
          </div>
          <p className={s.claimScreen_desc}>{title}</p>
          {
            <button
              disabled={internalClaimed}
              onClick={() => onClaim(id)}
              className={s.claimScreen_button}
            >
              {actual >= target && target !== 0 && internalClaimed
                ? `Claimed`
                : ` Claim Now`}
            </button>
          }
        </div>
      </div>
    </div>
  );
};

export default ClaimScreen;
