import mixpanel from 'mixpanel-browser';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Card, CardImg, Col, Row } from 'reactstrap';
import { ReactComponent as ListeningImg } from '../assets/kandor-img/listening-small.svg';
import { ReactComponent as PremiumIcon } from '../assets/kandor-img/premium-icon.svg';
import { ReactComponent as ReadingImg } from '../assets/kandor-img/reading-small.svg';
import { ReactComponent as SpeakingMockImg } from '../assets/kandor-img/speaking-small.svg';
import { cardIconsListening, cardIconsReading, cardIconsWriting, tagsShotNameReading } from './helpers/constants';
import {
  practicePromptListening,
  practicePromptMockSpeaking,
  practicePromptReading,
  practicePromptSpeaking,
  practicePromptWriting,
} from './helpers/startPractice';
import './newChanges/PracticeCard.scss';
import UnlockBtn from './components/UnlockBtn/UnlockBtn';

const PracticeCard = ({
  data,
  isFree,
  index,
  listLength,
  section,
  practice_prompt = () => {},
  isEmpty = false,
  isMock = false,
  isContinue = false,
}) => {
  const history = useHistory();
  const [peoplePracticing, setpeoplePracticing] = useState([]);
  const [name, setName] = useState('');
  const [imgSrc, setImgSrc] = useState('');
  const [isImgLoaded, setIsImgLoaded] = useState(false);
  const [numImgIndex, setNumImgIndex] = useState(1);

  function getRandomInt(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  useEffect(() => {
    if (listLength) {
      const len = listLength;
      let temp = [];
      var i;
      for (i = 0; i < len; i++) {
        var j = getRandomInt(3000, 5000);
        temp.push(j);
      }
      setpeoplePracticing(temp);
    }
  }, [listLength]);

  useEffect(() => {
    if (section == 'Speaking') {
      if (data?.name) {
        setName(data.name);
      }

      if (data?.media) {
        setImgSrc(data.media);
      }
    } else if (section == 'Writing') {
      if (data?.keyword) {
        setName(data.keyword);
      }

      if (data?.category) {
        setImgSrc(cardIconsWriting[data.category]);
      }
    } else {
      if (data?.title) {
        setName(data.title);
      }

      if (section == 'Reading')
        if (cardIconsReading[data?.tags]) {
          setImgSrc(cardIconsReading[data.tags]);
        }

      if (section == 'Listening')
        if (cardIconsListening[data?.tags]) {
          setImgSrc(cardIconsListening[data.tags]);
        }
    }
  }, [data]);

  const handleLoadedImg = () => {
    setIsImgLoaded(true);
  };

  useEffect(() => {
    const newIndex = (index + 1) % 3;
    setNumImgIndex(newIndex + 1);
  }, [index]);

  return (
    <Card
      className={`test-card ${isEmpty ? 'test-card--empty' : ''} ${
        (section == 'Writing' && data?.in_progress) || isContinue ? 'in-progress' : ''
      }`}
      onClick={e => {
        let id = null;
        let attemptId = null;

        if (isContinue && (section == 'Listening' || section == 'Reading')) {
          id = data.testid;
          attemptId = data.id;
        } else {
          id = data.id;
        }

        if (section == 'Writing' && data?.in_progress) {
          attemptId = data.assesment_id;
        }
        if (isFree) {
          console.log('section', section);
          console.log('data', data);
          if (section == 'Reading') {
            practicePromptReading({
              id,
              attemptId,
              isMock,
              history,
            });
          } else if (section == 'Listening') {
            practicePromptListening({ id, attemptId, isMock, history });
          } else if (section == 'Speaking') {
            if (isMock) {
              practicePromptMockSpeaking({ id, history });
            } else {
              practicePromptSpeaking({ id, history });
            }
          } else if (section == 'Writing') {
            practicePromptWriting({ id, history, attemptId });
          }
        } else {
          mixpanel.track(`Refer & Upgrade for free!`, { section: section });
          history.push('/admin/referral');
        }
      }}>
      {(section == 'Writing' && data?.category) || (section == 'Reading' && tagsShotNameReading[data?.tags]) ? (
        <span className={`writing-category-badge ${section == 'Reading' ? 'big' : ''}`}>
          {section == 'Reading' ? tagsShotNameReading[data.tags] : data.category}
        </span>
      ) : (
        ''
      )}
      <div className="test-card__wrap">
        <Row
          className="text-center"
          style={{
            marginTop: '0rem',
            flex: 'auto',
          }}>
          {!isFree && !isEmpty ? (
            <div className="test-card__premium-icon">
              <PremiumIcon />
            </div>
          ) : (
            ''
          )}

          <Col
            xs="5"
            md="12"
            lg="12"
            sm="5"
            className={`card-image ${isEmpty ? 'card-image--empty' : ''} test-card__img`}>
            {isEmpty ? (
              <div className={`card-img-loader card-img-loader--${numImgIndex}`}></div>
            ) : isMock ? (
              section == 'Speaking' ? (
                <SpeakingMockImg />
              ) : section == 'Reading' ? (
                <ReadingImg />
              ) : section == 'Listening' ? (
                <ListeningImg />
              ) : (
                ''
              )
            ) : (
              <CardImg top src={imgSrc} onLoad={handleLoadedImg} alt="..."></CardImg>
            )}

            {!isImgLoaded && !isMock ? (
              <div className={`card-img-loader card-img-loader--${numImgIndex} test-card__loader`}></div>
            ) : (
              ''
            )}
          </Col>
          <Col xs="7" md="12" lg="12" sm="7" className="card-content">
            {isEmpty ? (
              <p className="card-title-loader">
                <span className="card-loader"></span>
              </p>
            ) : (
              <h6 className="question-text-center title-gradient">{name}</h6>
            )}

            <p className="question-text-center">
              Practiced this prompt:{' '}
              {isEmpty ? (
                <span className="card-text-loader">
                  <span className="card-loader"></span>
                </span>
              ) : (
                <strong className="title-gradient">{data.nusers ? data.nusers : peoplePracticing[index]} People</strong>
              )}
            </p>

            {isFree || isEmpty ? (
              isContinue ? (
                <Button
                  className="btn-link arrow-right test-card__btn test-card__btn--contin"
                  color="info"
                  onClick={e => {
                    e.preventDefault();
                  }}>
                  Continue Test
                </Button>
              ) : (
                <Button
                  className="btn-link arrow-right subtitle-gradient test-card__btn"
                  color="info"
                  onClick={e => {}}>
                  Start Practicing
                </Button>
              )
            ) : (
              <UnlockBtn isInline={true} />
            )}
          </Col>
        </Row>
      </div>
    </Card>
  );
};

export default PracticeCard;
