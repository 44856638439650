import api from 'services/api';

export async function getReferralUrl() {
  return await api.get('/loyalty/referral');
}

export async function getAchievements() {
  return await api.get('/loyalty/achievements');
}

export async function claimReward(achievement) {
  if (achievement == null || achievement === undefined) {
    throw new Error('missing required parameters');
  }
  return await api.put(`/loyalty/achievements/${encodeURI(achievement)}`, '{}');
}
