import React, { useState, useEffect, memo } from 'react';
import s from './TimerStart.module.scss';
import { ReactComponent as Time } from '../../../assets/icons/webinar/time.svg';
import moment from 'moment';

const TimerStart = ({ startTime, setTimeIsOver }) => {
  const [timeDifference, setTimeDifference] = useState('');
  // const currentTimeIST = moment().tz('Asia/Kolkata');

  useEffect(() => {
    const updateWebinarTimeDifference = () => {
      const startTimeF = moment.tz(
        startTime && startTime.replace('GMT', '+0530'),
        'ddd, DD MMM YYYY HH:mm:ss ZZ',
        'Asia/Kolkata'
      );

      const currentTimeIST = moment().tz('Asia/Kolkata');

      if (currentTimeIST.isBefore(startTimeF)) {
        const duration = moment.duration(startTimeF.diff(currentTimeIST));
        let timeDifferenceString = '';
        const days = duration.days();
        const hours = duration.hours();
        const minutes = duration.minutes();
        const seconds = duration.seconds();

        if (days + hours + minutes + seconds <= 0) {
          setTimeIsOver(true);
          return;
        }

        if (hours > 0 || days > 0) {
          timeDifferenceString += `${days}d `;
        }

        if (hours > 0) {
          timeDifferenceString += `${hours}h `;
        }

        if (minutes > 0 || hours > 0) {
          timeDifferenceString += `${minutes}m `;
        }

        timeDifferenceString += `${seconds}s`;

        setTimeDifference(timeDifferenceString);
      } else {
        setTimeIsOver(true);
      }
    };

    const interval = setInterval(updateWebinarTimeDifference, 1000);

    return () => clearInterval(interval);
  }, [timeDifference]);

  return (
    <div className={s.timer}>
      <span className={s.timer__text}>Starts in</span>
      <div className={s.timer__row}>
        <Time />
        <span className={s.timer__count}>{timeDifference}</span>
      </div>
    </div>
  );
};

export default memo(TimerStart);
