import React from 'react';
import { ReactComponent as Done } from '../../../assets/icons/marketplace/modal/Done.svg';
import styles from './ProgressStep.module.scss';

const ProgressStep = ({ currentStep, handleStepChange }) => {
  return (
    <div className={styles.progressSteps}>
      <div className={styles.stepsContainer}>
        <div className={`${styles.step} ${currentStep >= 1 ? styles.active : styles.nonActive}`}>
          {currentStep > 1 ? (
            <div className={styles.active_icon}>
              <Done />
            </div>
          ) : (
            <div className={styles.icon}>1</div>
          )}
          Preferences
        </div>
        <div className={`${styles.step} ${currentStep >= 2 ? styles.active : styles.nonActive}`}>
          {currentStep > 2 ? (
            <div className={styles.active_icon}>
              <Done />
            </div>
          ) : (
            <div className={styles.icon}>2</div>
          )}
          Timeline
        </div>
        <div className={`${styles.step} ${currentStep >= 3 ? styles.active : styles.nonActive}`}>
          {currentStep > 3 ? (
            <div className={styles.active_icon}>
              <Done />
            </div>
          ) : (
            <div className={styles.icon}>3</div>
          )}
          Time Slot
        </div>
      </div>
    </div>
  );
};

export default ProgressStep;
