import React from 'react';
import s from './StepIndicator.module.scss';
import { ReactComponent as Check } from '../../../assets/icons/applyBanner/check.svg';
const StepIndicator = ({ currentStep }) => {
  return (
    <>
      <div className={s.stepIndicator}>
        <div className={`${s.step} ${currentStep === 1 ? s.active : ''} ${currentStep > 1 ? s.done : ''}`}>
          <div className={s.circle}>{currentStep > 1 ? null : <div className={s.sub_circle}></div>}</div>

          <span className={s.title}>Create Shortlist</span>
        </div>
        <div style={{ width: '100%', height: '1px', background: '#D9D9D9' }}></div>
        <div className={`${s.step} ${currentStep === 2 ? s.active : ''} ${currentStep > 2 ? s.done : ''}`}>
          <div className={s.circle}>{currentStep === 2 ? <div className={s.sub_circle}></div> : null}</div>
          <span className={s.title}>{currentStep >= 2 ? 'Prepare Application' : 'Step 2'}</span>
        </div>
        <div style={{ width: '100%', height: '1px', background: '#D9D9D9' }}></div>

        <div className={`${s.step} ${currentStep === 3 ? s.active : ''} ${currentStep > 3 ? s.done : ''}`}>
          <div className={s.circle}>{currentStep === 3 ? <div className={s.sub_circle}></div> : null}</div>
          <span className={s.title}>{currentStep >= 3 ? 'Apply now' : 'Step 3'}</span>
        </div>
      </div>
    </>
  );
};

export default StepIndicator;
