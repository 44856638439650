import React from 'react'
import './styles/Loader.scss'

export default function Loader() {
	return (
		<div className="loader">
			<div className="loader__wrap">
				<div className="loader__inner">
					<div className="loader__logo">
						<img
							alt="..."
							src={require('assets/kandor-img/kandor-logo-loader.png')}
						/>
						<div className="loader__spinner">
							<img
								alt="..."
								src={require('assets/kandor-img/kandor-logo-spinner.png')}
							/>
						</div>
					</div>
					<div className="loader__text">
						<img
							alt="..."
							src={require('assets/kandor-img/kandor-logo-text.png')}
						/>
					</div>
				</div>
				<div className="loader-dots"></div>
			</div>
		</div>
	)
}
