import React, { useState } from "react";
import s from "./Generation.module.scss";
import Modal from "react-bootstrap/Modal";
import { useMediaQuery } from "react-responsive";
import ArrowRight from "svgIcon/ArrowRight";
import { ReactComponent as Close } from "../../../../assets/icons/close.svg";
import { ReactComponent as ArrowBack } from "../../../../assets/icons/back.svg";

const GenerationCard = el => {
  const [show, setShow] = useState(false);
  const isMobile = useMediaQuery({ query: "(max-width: 767px)" });

  return (
    <>
      <div onClick={() => setShow(true)} className={s.generation__card}>
        {isMobile ? (
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <h4 className={s.generation__card_title}>{el.title}</h4>
            <p className={s.read_more}>
              Read More <ArrowRight />
            </p>
          </div>
        ) : (
          <h4 className={s.generation__card_title}>{el.title}</h4>
        )}

        <p className={s.generation__card_desc}>{el.description}</p>
      </div>
      <Modal
        dialogClassName={s.myModalStyle}
        contentClassName={s.custom_modal}
        show={show}
        centered
        onHide={() => {
          setShow(false);
        }}
      >
        <Modal.Header style={{ padding: 0 }} closeVariant="white">
          <button
            className={s.custom__close}
            onClick={() => {
              setShow(false);
            }}
          >
            <Close />
          </button>
        </Modal.Header>
        <div style={{ display: "flex", justifyContent: "flex-start" }}>
          <button onClick={() => setShow(false)} className={s.term__button}>
            <ArrowBack style={{ marginRight: "5px" }} />
            <span style={{ borderBottom: "1px solid #fc8150" }}>Back</span>
          </button>
        </div>
        <div className={s.generation_modal_container}>
          <h3 className={s.generation_modal_title}>{el.title}</h3>
          <div
            className={s.generation_modal_desc}
            style={{ overflowX: "scroll", height: "322px", padding: "10px" }}
          >
            {el.body}
          </div>
        </div>
      </Modal>
    </>
  );
};

export default GenerationCard;
