import { setPersonality } from "actions/sopsAction";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { savePersonality } from "../../../../../api/sopApi";
import { ReactComponent as MobileTest } from "../../../../../assets/icons/chat/MobileP.svg";
import { ReactComponent as QuizTest } from "../../../../../assets/icons/quiz.svg";
import { ReactComponent as QuizIcon } from "../../../../../assets/icons/test/Quiz.svg";
import Ai from "../../../../../assets/img/Kando AI.png";
import { quiz } from "./questions";
import s from "./Quiz.module.scss";

const mapOfAnswer = {
  "Curious Explorer":
    "A curious explorer is an adventurous and inquisitive individual constantly seeking knowledge and new experiences to broaden their understanding of the world…. ",
  "Empathetic Humanitarian":
    "An empathetic humanitarian is a deeply compassionate individual dedicated to alleviating the suffering and promoting the well-being of others….",
  "Ambitious Innovator":
    " An ambitious innovator is an individual characterized by a unique and powerful combination of traits and behaviors that drive them to pursue groundbreaking ideas and achieve remarkable accomplishments…",
};

const mapForStyleResultBack = {
  "Curious Explorer": {
    background: "rgba(24, 195, 164, 0.10)",
    color: "#18C3A4",
  },
  "Empathetic Humanitarian": {
    background: "rgba(244, 204, 63, 0.10)",
    color: "#E3A504",
  },
  "Ambitious Innovator": {
    background: "rgba(71, 186, 82, 0.10)",
    color: "#47BA52",
  },
};

const mapForStyleResultColor = {
  "Curious Explorer": {
    color: "#18C3A4",
  },
  "Empathetic Humanitarian": {
    color: "#E3A504",
  },
  "Ambitious Innovator": {
    color: "#47BA52",
  },
};

const Quiz = ({ setShow }) => {
  const isMobile = useMediaQuery({ query: "(max-width:767px)" });
  const dispatch = useDispatch();
  const personality = useSelector(state => state.sops.personality);

  const [activeQuestion, setActiveQuestion] = useState(0);
  const [showResult, setShowResult] = useState(false);
  const [selectedAnswerIndex, setSelectedAnswerIndex] = useState(null);
  const [answer, setAnswer] = useState([]);
  const { questions } = quiz;
  const { question, choices } = questions[activeQuestion];

  // useEffect(() => {
  //   if (personality != null) {
  //   }
  // }, [personality]);

  const onClickNext = () => {
    setSelectedAnswerIndex(null);

    if (activeQuestion !== questions.length - 1) {
      setActiveQuestion(prev => prev + 1);
    } else {
      setActiveQuestion(0);
      setShowResult(true);
      dispatch(setPersonality(maxSameString(answer)));

      savePersonality({ personality: maxSameString(answer) });
    }
  };

  const onAnswerSelected = (answer, index) => {
    setAnswer(prev => [...prev, answer]);
  };

  function maxSameString(arr) {
    const stringCount = {};

    for (const element of arr) {
      stringCount[element] = (stringCount[element] || 0) + 1;
    }

    let maxCount = 0;
    let maxString = null;
    for (const string in stringCount) {
      if (stringCount[string] > maxCount) {
        maxCount = stringCount[string];
        maxString = string;
      }
    }

    return maxString;
  }

  return (
    <div className={s.quiz_container}>
      <div className={s.quiz__block}>
        <div className={s.quiz__content}>
          {!isMobile ? <QuizTest /> : <MobileTest />}
          <div
            style={!isMobile ? { marginLeft: "28px" } : { marginLeft: "10px" }}
          >
            <h2 className={s.quiz__title}>PERSONALITY TEST</h2>
            <p className={s.quiz_desc}>Get your SOP Personalised</p>
          </div>
        </div>
        <div>
          <div className={s.message__container}>
            <div className={s.ai_sop}>
              <img alt="" src={Ai} />
            </div>
            <div className={s.ai_sop_img}>
              {showResult && (
                <h3 className={s.think}>
                  We are done! Thanks for answering all my questions!
                </h3>
              )}
              {questions[0].question === question && !showResult && (
                <React.Fragment style={{ display: "flex" }}>
                  <h3 className={s.think}>
                    Yay! Are you ready to unravel your personality?
                  </h3>
                </React.Fragment>
              )}

              <h3 className={s.message}>
                {showResult
                  ? `Your personality type is ready! remember you can give this
                  quiz again if you feel this doesn't represent you the best`
                  : `${question}`}
              </h3>
            </div>
          </div>
        </div>
        {!showResult ? (
          <>
            <ul className={s.answer__container}>
              {choices.map((answer, index) => (
                <li
                  className={
                    selectedAnswerIndex === index
                      ? `${s.answer__block} ${s.answer__block_active}`
                      : `${s.answer__block}`
                  }
                  onClick={e => {
                    e.preventDefault();
                    onAnswerSelected(answer.point, index);
                    onClickNext();
                  }}
                  key={answer}
                >
                  {answer.text}
                </li>
              ))}
            </ul>
            <ul className={s.dots_block}>
              {questions.map((el, idx) => (
                <li
                  className={activeQuestion === idx ? `${s.dot_active}` : null}
                >
                  {/* {activeQuestion === idx &&
                    <span>{idx + 1}</span> / questions.length} */}
                </li>
              ))}
            </ul>
          </>
        ) : (
          <>
            <div
              style={mapForStyleResultBack[maxSameString(answer)]}
              className={s.test__result}
            >
              <h4
                style={mapForStyleResultColor[maxSameString(answer)]}
                className={s.result_title}
              >
                <QuizIcon style={{ marginRight: "22px" }} /> Your personality
                type is <span> {maxSameString(answer)}</span>
              </h4>
              <p className={s.result__text}>
                {mapOfAnswer[maxSameString(answer)]}
              </p>
            </div>
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <button
                onClick={() => setShow(false)}
                className={s.start_generating}
              >
                Start Generating
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Quiz;
