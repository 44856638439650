import api from "services/api";

export function getBlogs(objArgs) {
  if (typeof objArgs !== "object") {
    throw new Error("missing required parameters");
  }
  return api.get("/blog");
}

export function getBlogsCategory(objArgs) {
  if (typeof objArgs !== "object") {
    throw new Error("missing required parameters");
  }
  return api.get("/blog/categories");
}
