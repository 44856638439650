import * as Actions from '../actions/documentsAction';

const initialState = {
  documents: [],
  status: 'idle',
};

const DocumentsReducer = (state = initialState, action) => {
  switch (action.type) {
    case Actions.LOADING_DOCUMENTS_START: {
      return {
        ...state,
        status: 'idle',
      };
    }

    case Actions.LOADING_DOCUMENTS_SUCCESS: {
      return {
        ...state,
        documents: action.payload,
        status: 'success',
      };
    }
    case Actions.LOADING_DOCUMENTS_FAILED: {
      return {
        ...state,
        status: 'error',
      };
    }

    case Actions.CHANGE_DOCUMENT_STATUS:
      return {
        ...state,
        documents: state.documents.map(doc => {
          if (doc.value === action.payload.name) {
            return {
              ...doc,
              status: action.payload.status,
            };
          }
          return doc;
        }),
      };

    default:
      return state;
  }
};

export default DocumentsReducer;
