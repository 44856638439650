import BookModal from 'pages/Marketplace/BookModal/BookModal';
import Counsellor from 'pages/Marketplace/Counsellor/Counsellor';
import React, { useState, useRef, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import Slider from 'react-slick';
import { ReactComponent as Arrow } from '../../../assets/icons/arrow_left.svg';
import ProgressBar from '../ProgressBar/ProgressBar';
import './Counsellors.scss';
import mixpanel from 'mixpanel-browser';
import SkeletonCounselorCard from '../Skeletons/SkeletonCounselorCard/SkeletonCounselorCard';
import { useMediaQuery } from 'react-responsive';


const Counsellors = ({type = ''}) => {
  const counselors_admin = useSelector(state => state.counselors.counselors_admin);
  const history = useHistory();

  const [progress, setProgress] = React.useState(0);

  const [show, setShow] = useState(false);

  const [counselorBookId, setCounselorBookId] = useState(null);

  const [visibleCard, setVisibleCard] = useState(0);
  const containerRef = useRef(null);
  const isMobile = useMediaQuery({ query: '(max-width: 400px)' })
  
  const [data, setData] = useState({
    Place: '',
    Course: '',
    Target: '',
    BookDate: '',
    Date: '',
  });
  const toggleShow = id => {
    setCounselorBookId(id);
    setShow(s => !s);
  };

  const settings = {
    slidesToShow: visibleCard,
    slidesToScroll: 1,
    arrows: true,
    infinite: true,
    initialSlide: 0,
    centerMode: isMobile ? false : true,
    //autoplay: true,
    //swipeToSlide: true,
    //autoplaySpeed: 3000,
    
     afterChange: current => {
      const progressSlider = (100 / counselors_admin.length) * (current + visibleCard)

      if (progressSlider >= 100) {
        setProgress(100);
      } else {
        setProgress(progressSlider);
      }
    },
  };

  const settingsSkeleton = {
    slidesToShow: 1,
    variableWidth: true,
    slidesToScroll: 1,
    arrows: true,
    initialSlide: 0,
    centerMode: false,
    autoplay: true,
    swipeToSlide: true,
    autoplaySpeed: 3000,
  };

  const goToCounsellors = () => {
    mixpanel.track("Clicked on all counselors", {page: type});
    history.push("/admin/counsellors");
  };

  const [widthBlock, setWidthBlock] = useState(0);
  const [widthSlide, setWidthSlide] = useState(0)
  const [isLoadedWidthSlide, setIsLoadedWidthSlide] = useState(false)

  
  const getWidthBlock = () => {
    let width;
    if (containerRef.current) {
      width = containerRef.current.getBoundingClientRect().width;
    }

    return width;
  };

  const getVisibleCard = (widthParent) => {
    if (containerRef.current) {
      const widthCard = isMobile ? 316 : 340;
      const currentWidth = widthParent ? widthParent : containerRef.current.getBoundingClientRect().width
      
      const visible = Math.trunc(currentWidth / widthCard);

      if(!currentWidth) return

      if (currentWidth <= 370 && currentWidth > 0) {
        setVisibleCard(1)
        return
      }

      if (visible) {
        if (visible < counselors_admin.length && currentWidth > visible * widthCard + 140) {
          setVisibleCard(visible + 0.5);
        }  else if(visible >= counselors_admin.length && counselors_admin.length){
          setVisibleCard(counselors_admin.length);
        } else {
          setVisibleCard(visible);
        }
      }
    }
  };
  
  useEffect(() => {
    let interval;

    if (!widthBlock) {
      interval = setInterval(() => {
        const width = getWidthBlock();

        if (width) {
          setWidthBlock(width);
          getVisibleCard(width)
          clearInterval(interval);
        }
      }, 500);
    }

    return () => {
      clearInterval(interval);
    };
  }, [containerRef.current, counselors_admin]);
  
  const resizeObserver = new ResizeObserver((entries) => {
    for (const entry of entries) {
      const widthSlide = entry.contentBoxSize[0].inlineSize

      console.log('widthSlide', widthSlide);
      setWidthSlide(widthSlide)
    }

    console.log("Size changed");
  });

  useEffect(() => {
    let slide = null

    if (containerRef.current && counselors_admin?.length && visibleCard) {
      slide = containerRef.current.querySelector('.slick-slide')

      if (slide) {
        resizeObserver.observe(slide);
      }
    }

    return () => {
      if (slide) { 
        resizeObserver.unobserve(slide);
      }
    }
  }, [ containerRef.current, counselors_admin, visibleCard])

  useEffect(() => {
    let timeout

    if (widthSlide) {
      timeout = setTimeout(() => {
        setIsLoadedWidthSlide(true)
      }, 100)
    } else {
      setIsLoadedWidthSlide(false)
      clearTimeout(timeout)
    }

    return () => {
      clearTimeout(timeout)
    }
  }, [widthSlide])

  const onResize = () => {
    getVisibleCard(widthBlock)
  }

  useEffect(() => {
    window.addEventListener('resize', onResize)

    return () => {
       window.removeEventListener('resize', onResize)
    }
  } , [])

  useEffect(() => {
   const progressSlider = (100 / counselors_admin.length) * (visibleCard)
    if (progressSlider >= 100) {
      setProgress(100);
    } else {
      setProgress(progressSlider);
    }
  },[visibleCard, counselors_admin])

  return (
    <>
      <BookModal data={data} setData={setData} show={show} setShow={setShow} counselorBookId={counselorBookId} />
      <div className="counsellors">
        <div className="counsellors__container">
          <div className="counsellors_action">
            <h4 className="counsellors__title">Study Abroad Counselors</h4>
            <h4 onClick={() => goToCounsellors()} className="counsellors_seeAll">
              See all <Arrow style={{ marginLeft: '6px' }} />
            </h4>
          </div>

          <div>
            <div className="counsellors__block" ref={containerRef}>
              {
                counselors_admin?.length && visibleCard   ? (
                  <Slider className="counsellors__slider" {...settings}>
                    {
                      counselors_admin.map((counselor, idx) => (
                        <Counsellor
                          key={counselor.id}
                          {...counselor}
                          toggleShow={toggleShow}
                          isStretch={true}
                          isSlider={true}
                        />
                      ))}
                  </Slider>

                ) : (
                    
                   ''
                )
              }

              <div className={`counsellors__slider counsellors__slider--skeleton ${isLoadedWidthSlide ? 'disable' : ''}`}>
                {[1, 2, 3, 4].map(el => (
                  <div className="counsellors__slide" key={el} >
                    <SkeletonCounselorCard/>
                  </div>
                  ))}
              </div>


              <ProgressBar margin={18} progress={progress} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Counsellors;
