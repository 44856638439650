import React, { useState } from 'react'

import { CounselingModal } from 'kandor-components/CounselingButton'
import { Button } from 'reactstrap'
import { useHistory } from 'react-router-dom'
import './style.scss'
import mixpanel from 'mixpanel-browser'

const ImproveSection = ({ isBigFooter, withLogIn }) => {
	const history = useHistory()
	const [isOpenCounseling, setIsOpenCounseling] = useState(false)

	const takeMockTest = (e) => {
		e.preventDefault()
		mixpanel.track(`Take Mock on Counseling page`, {})
		history.push('/signup')
	}

	const bookCounselor = (e) => {
		e.preventDefault()
		// mixpanel.track(`Counsellor on Counseling page`, {})
		setIsOpenCounseling(true)
	}

	return (
		<section
			className={`counseling-hero ${isBigFooter ? 'counseling-hero--big' : ''}`}
		>
			<div className="counseling-hero__bg">
				<picture className="counseling-hero__bg-img">
					<source
						type="image/webp"
						media="(min-width: 1024px)"
						srcSet={require('assets/kandor-img/counseling-hero-bg-lg.webp')}
					/>
					<source
						type="image/jpeg"
						media="(min-width: 1024px)"
						srcSet={require('assets/kandor-img/counseling-hero-bg-lg.jpg')}
					/>
					<source
						type="image/webp"
						media="(min-width: 768px)"
						srcSet={require('assets/kandor-img/counseling-hero-bg-md.webp')}
					/>
					<source
						type="image/jpeg"
						media="(min-width: 768px)"
						srcSet={require('assets/kandor-img/counseling-hero-bg-md.jpg')}
					/>
					<source
						type="image/webp"
						media="(min-width: 320px)"
						srcset={require('assets/kandor-img/counseling-hero-bg-sm.webp')}
					/>

					<img
						src={require('assets/kandor-img/counseling-hero-bg-sm.jpg')}
						alt="image"
					/>
				</picture>
			</div>
			<div className="counseling-hero__container landing-container">
				<div className="counseling-hero__wrap">
					<div className="counseling-hero__head">
						<span className="counseling-hero__subtitle landing-subtitle">
							introducing Kandor Aspire
						</span>
						<h1 className="counseling-hero__title">
							A study abroad program that <span>changes your life</span>
						</h1>
					</div>

					<div className="counseling-hero__buttons">
						<Button
							className="landing-btn"
							type="button"
							color="info"
							onClick={bookCounselor}
						>
							Book counseling session
						</Button>

						{withLogIn && (
							<Button
								className="landing-btn secondary"
								type="button"
								onClick={takeMockTest}
							>
								Sign Up for Free
							</Button>
						)}
					</div>

					<CounselingModal
						isOpen={isOpenCounseling}
						onClose={() => setIsOpenCounseling(false)}
					/>

					<div className="counseling-hero__descr">
						<span className="counseling-hero__descr-text">
							Guided by Mentors from the <span>Ivy League Schools</span>
						</span>
						<div className="counseling-hero__univ-list">
							<div className="counseling-hero__univ">
								<div className="counseling-hero__univ-icon">
									<picture>
										<source
											type="image/png"
											media="(min-width: 768px)"
											srcSet={require('assets/kandor-img/cornell-university.png')}
										/>
										<img
											src={require('assets/kandor-img/cornell-university-dark.png')}
											alt="image"
										/>
									</picture>
								</div>
								<span className="counseling-hero__univ-title">
									Cornell University
								</span>
							</div>
							<div className="counseling-hero__univ">
								<div className="counseling-hero__univ-icon">
									<img
										src={require('assets/kandor-img/university-california.png')}
										alt="image"
									/>
								</div>
								<span className="counseling-hero__univ-title">
									University of California
								</span>
							</div>
							<div className="counseling-hero__univ">
								<div className="counseling-hero__univ-icon">
									<img
										src={require('assets/kandor-img/IITG_White.png')}
										alt="image"
									/>
								</div>
								<span className="counseling-hero__univ-title">
									IIT Guwahati
								</span>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	)
}

export default ImproveSection
