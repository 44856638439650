import { getWishList, removeCourseFromWishlist, wishlistCourse } from '../api/shortlisterApi';

export const LOADING_WISHLIST_START = 'LOADING_WISHLIST_START';
export const LOADING_WISHLIST_SUCCESS = 'LOADING_WISHLIST_SUCCESS';
export const LOADING_WISHLIST_FAILED = 'LOADING_WISHLIST_FAILED';
export const ADD_TO_WISHLIST = 'ADD_TO_WISHLIST';
export const REMOVE_FROM_WISHLIST = 'REMOVE_FROM_WISHLIST';

export const loadingWishlistStartAction = () => {
  return {
    type: LOADING_WISHLIST_START,
  };
};

export const loadingWishlistSuccessAction = Wishlist => {
  return {
    type: LOADING_WISHLIST_SUCCESS,
    payload: Wishlist,
  };
};

export const addToWishlist = newCourse => {
  return {
    type: ADD_TO_WISHLIST,
    payload: newCourse,
  };
};

export const removeFromWishlist = course_id => {
  return {
    type: REMOVE_FROM_WISHLIST,
    payload: course_id,
  };
};

export const loadingWishlistFailedAction = e => {
  return {
    type: LOADING_WISHLIST_FAILED,
    payload: e,
  };
};

export const getAllWishlist = () => async dispatch => {
  try {
    const res = await getWishList();

    const combinedData = combineArrays(res.data);

    dispatch(loadingWishlistSuccessAction(combinedData));
  } catch (error) {}
};

export const addToCourseToList =
  (id, type, title, country, rank_qs, location, logo_s3_link, courses) => async dispatch => {
    const course_id = id;
    try {
      const res = await wishlistCourse(course_id, type, title, country, rank_qs, location, logo_s3_link, courses);
      dispatch(addToWishlist({ course_id: id, type, title, country, rank_qs, location, logo_s3_link, courses }));
    } catch (error) {}
  };

export const removeCourseFromList = (id) => async dispatch => {
  const course_id = id;
  try {
    const res = await removeCourseFromWishlist({course_id: course_id});
    dispatch(removeFromWishlist(course_id));
  } catch (error) {}
}

const combineArrays = ({ dream, safe, target }) => {
  return [...dream, ...safe, ...target];
};
