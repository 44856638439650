import api from 'services/api'
export function getWritingComments(objArgs) {
	if (typeof objArgs !== 'object' || !objArgs.hasOwnProperty('id')) {
		throw new Error('missing required parameters')
	}
	return api.get('writing/' + objArgs.id + '/comment')
}

export function addCommentWriting(objArgs) {
	if (
		typeof objArgs !== 'object' ||
		!objArgs.hasOwnProperty('id') ||
		!objArgs.hasOwnProperty('comment')
	) {
		throw new Error('missing required parameters')
	}
	return api.post(
		'writing/' + objArgs.id + '/comment',
		JSON.stringify({ comment: objArgs.comment })
	)
}

export function startAssesment(objArgs) {
	if (typeof objArgs !== 'object' || !objArgs.hasOwnProperty('questionId')) {
		throw new Error('missing required parameters')
	}
	return api.put(
		'writing/start',
		JSON.stringify({ questionId: objArgs.questionId })
	)
}

export function stopAssesment(objArgs) {
	if (
		typeof objArgs !== 'object' ||
		!objArgs.hasOwnProperty('assesment_id') ||
		!objArgs.hasOwnProperty('text')
	) {
		throw new Error('missing required parameters')
	}
	return api.put(
		'writing/stop',
		JSON.stringify({ assesment_id: objArgs.assesment_id, text: objArgs.text })
	)
}

export function getWritingQuestion(objArgs) {
	if (typeof objArgs !== 'object' || !objArgs.hasOwnProperty('questionId')) {
		throw new Error('missing required parameters')
	}
	return api.get('writing/questions/' + objArgs.questionId)
}

export function getWritingAnalyticsHistory(objArgs) {
	if (typeof objArgs !== 'object' || !objArgs.hasOwnProperty('assesmentId')) {
		throw new Error('missing required parameters')
	}
	return api.get('writing/' + objArgs.assesmentId + '/analytics/history')
}

export function getWritingAnalytics(objArgs) {
	if (typeof objArgs !== 'object' || !objArgs.hasOwnProperty('assesmentId')) {
		throw new Error('missing required parameters')
	}
	return api.get('writing/' + objArgs.assesmentId + '/analytics')
}

export function setWritingProgress(objArgs) {
	return api.put('/writing/progress', JSON.stringify(objArgs))
}

export function getWritingProgress(objArgs) {
	if (typeof objArgs !== 'object' || !objArgs.hasOwnProperty('assesment_id')) {
		throw new Error('missing required parameters')
	}

	return api.get('writing/' + objArgs.assesment_id + '/progress')
}

export function getWritingOcr(objArgs) {
	if (typeof objArgs !== 'object' || !objArgs.hasOwnProperty('assesment_id') || !objArgs.hasOwnProperty('ocr_id')) {
		throw new Error('missing required parameters')
	}

	return api.get('/writing/ocr/' + objArgs.assesment_id + '/' + objArgs.ocr_id)
}
