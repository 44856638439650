import { trackClass } from "api/classesApi";
import mixpanel from "mixpanel-browser";
import moment from "moment-timezone";
import React from "react";
import { useMediaQuery } from "react-responsive";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { ReactComponent as Listening } from "../../../../assets/icons/classes/IconsForType/Listening.svg";
import { ReactComponent as Reading } from "../../../../assets/icons/classes/IconsForType/Reading.svg";
import { ReactComponent as Speaking } from "../../../../assets/icons/classes/IconsForType/Spealing.svg";
import { ReactComponent as Writing } from "../../../../assets/icons/classes/IconsForType/Writing.svg";
import { ReactComponent as People } from "../../../../assets/icons/classes/people.svg";
import { ReactComponent as ProForLive } from "../../../../assets/icons/classes/ProForLive.svg";
import s from "./Live.module.scss";
import clevertap from "clevertap-web-sdk";

const mapOfType = {
  Speaking: (
    <div
      style={{
        background: "rgba(37, 164, 131, 0.20)",
        border: "1px solid rgba(37, 164, 131, 0.20)",
        color: "#25A483",
      }}
      className={s.type}
    >
      <Speaking />
      Speaking
    </div>
  ),
  Listening: (
    <div
      style={{
        background: "rgba(255, 209, 45, 0.20)",
        border: "1px solid rgba(226, 182, 25, 0.20)",
        color: "#E3A504",
      }}
      className={s.type}
    >
      <Listening />
      Listening
    </div>
  ),
  Reading: (
    <div
      style={{
        background: "rgba(130, 54, 247, 0.20)",
        border: "1px solid rgba(130, 54, 247, 0.20)",
        color: "#8236F7",
      }}
      className={s.type}
    >
      <Reading />
      Reading
    </div>
  ),
  Writing: (
    <div
      style={{
        background: "rgba(207, 87, 132, 0.20)",
        border: "1px solid rgba(207, 87, 132, 0.30)",
        color: "#CF5784",
      }}
      className={s.type}
    >
      <Writing />
      Writing
    </div>
  ),
};

const Live = ({
  limit,
  setFreeLimit,
  premium,
  id,
  type,
  topic,
  teacher,
  image,
  startTime,
  meetingJoinURL,
}) => {
  const history = useHistory();
  const isMobile = useMediaQuery({ query: "(max-width:500px)" });

  const renderTeacher = teacher => {
    if (!teacher) {
      return "";
    } else {
      return JSON.parse(teacher).name;
    }
  };

  const parseAndFormatTime = startTime => {
    const startTimeF = moment.tz(
      startTime.replace("GMT", "+0530"),
      "ddd, DD MMM YYYY HH:mm:ss ZZ",
      "Asia/Kolkata"
    );
    return startTimeF.clone().add(1, "hours").format("hh:mm A");
  };

  const navigateToAnotherRoute = () => {
    if (limit === 0 && !premium) {
      clevertap.event.push("Navigated to pricing from LIVE card")
      mixpanel.track("Navigated to pricing from LIVE card");
      history.push("/admin/plan-information");
    } else {
      trackClass(id);
      clevertap.event.push("Clicked on join now on LIVE card")
      mixpanel.track("Clicked on join now on LIVE card");
      const state = {
        id: id,
      };
      history.push("/admin/zoom", state);
    }
  };

  return (
    <>
      <div className={s.live}>
        <div className={s.live__block}>
          <div className={s.gradient}></div>

          <img
            style={{ width: "100%", height: "100%", objectFit: "cover" }}
            src={image}
          />
          <div className={s.live_badge}>
            <span className={s.blinking_text}>LIVE</span>
            <div className={s.dot}></div>
          </div>
        </div>
        <div className={s.live__content}>
          <h3 className={s.live__title}>
            {type}: {topic}
          </h3>
          <div className={s.live__person}>
            {!isMobile && (
              <div className={s.person__details}>
                <h4 className={s.person__name}>{renderTeacher(teacher)}</h4>
                <div className={s.dot}></div>
                <h5 className={s.live__type}>
                  Top rated Exceptional educators
                </h5>
              </div>
            )}
          </div>
          {isMobile ? (
            <>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <div>
                  <h4 className={s.person__name}>{renderTeacher(teacher)}</h4>
                  <h5 className={s.live__type}>
                    Top rated Exceptional educators
                  </h5>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <p className={s.live_end_mobile}>
                    Ends at {parseAndFormatTime(startTime)}
                  </p>

                  <button
                    onClick={() => navigateToAnotherRoute()}
                    className={s.live__button}
                  >
                    {!premium && limit === 0 ? (
                      <>
                        <ProForLive /> Upgrade Now!
                      </>
                    ) : (
                      <>Join Now</>
                    )}
                  </button>
                </div>
              </div>
            </>
          ) : (
            <>
              <h5 className={s.students}>
                <People />
                1000+ Students Mentored
              </h5>
              <p className={s.live_end}>
                Ends at {parseAndFormatTime(startTime)}
              </p>
              <div className={s.live__actions}>
                <button
                  onClick={() => navigateToAnotherRoute()}
                  className={s.live__button}
                >
                  {!premium && limit === 0 ? (
                    <>
                      {" "}
                      <ProForLive /> Upgrade Now!
                    </>
                  ) : (
                    <>Join Now</>
                  )}
                </button>

                <div>
                  <div className={s.type}>{mapOfType[type]}</div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default Live;
