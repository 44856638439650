import api from 'services/api';

export async function getAutoFillUniversity() {
  return await api.get('/shortlister/india/school');
}

export async function getAutoFillShortlisterUniversity() {
  return await api.get('/shortlister/universities');
}

export async function getAutoFillShortlisterCourses() {
  return await api.get('/shortlister/courses');
}
