import MarketplaceFormFinal from "pages/Marketplace/MarketplaceForm/MarketplaceFormFinal";
import MarketPlaceFormPreferences from "pages/Marketplace/MarketplaceForm/MarketPlaceFormPreferences";
import MarketplaceFormTimeLine from "pages/Marketplace/MarketplaceForm/MarketplaceFormTimeline";
import MarketplaceFormTimeSlot from "pages/Marketplace/MarketplaceForm/MarketplaceFormTimeSlot";
import ProgressStep from "pages/Marketplace/ProgressStep/ProgressStep";
import React, { useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import s from "../../Marketplace.module.scss";
const ModalPC = ({
  show,
  handleClose,
  currentStep,
  setData,
  data,
  formErrors,
  setCurrentStep,
  handleStepChange,
  handleFormSubmit,
}) => {
  const showForm = step => {
    switch (step) {
      case 1:
        return (
          <MarketPlaceFormPreferences
            setData={setData}
            data={data}
            errors={formErrors}
          />
        );
      case 2:
        return (
          <MarketplaceFormTimeLine
            setData={setData}
            data={data}
            errors={formErrors}
          />
        );
      case 3:
        return (
          <MarketplaceFormTimeSlot
            setData={setData}
            data={data}
            errors={formErrors}
          />
        );
      default:
        return <MarketplaceFormFinal />;
    }
  };

  const showButton = step => {
    if (step === 1 || step === 2) {
      return "Next";
    } else if (step === 3) {
      return "Confirm Booking";
    } else {
      return "Continue Exploring";
    }
  };

  useEffect(() => {
    const listener = event => {
      if (event.code === "Enter" || event.code === "NumpadEnter") {
        console.log("Enter key was pressed. Run your function.");
        event.preventDefault();
        handleFormSubmit();

        console.log(data);
      }
    };
    document.addEventListener("keydown", listener);
    return () => {
      document.removeEventListener("keydown", listener);
    };
  }, [data, handleFormSubmit]);

  return (
    <Modal
      dialogClassName={s.myModalStyle}
      contentClassName={s.custom_modal}
      centered
      show={show}
      onHide={handleClose}
    >
      <div className={s.header__modal}>
        <div className={s.header__modal_container}>
          <div className={s.modal__logo}>
            <img
              style={{ marginRight: "15px" }}
              src={require("../../../../assets/icons/marketplace/modal/logo.png")}
            />
            <p style={{ margin: 0 }}>Kandor Counselors</p>
          </div>
          {currentStep <= 3 && (
            <ProgressStep
              currentStep={currentStep}
              handleStepChange={handleStepChange}
            />
          )}

          <div
            onClick={() => handleClose()}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: "inherit",
              height: "31px",
              width: "31px",
              marginRight: "18px",
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="13"
              height="13"
              viewBox="0 0 13 13"
              fill="none"
            >
              <path
                d="M7.65811 6.27557L12.959 11.5785L12.2676 12.2692L6.959 6.97392L1.65041 12.2692L0.958984 11.5785L6.2599 6.27557L0.958984 0.972665L1.65041 0.281982L6.959 5.57721L12.2676 0.281982L12.959 0.972665L7.65811 6.27557Z"
                fill="#1E1E1E"
              />
            </svg>
          </div>
        </div>

        <div className={s.modal__progress}></div>
      </div>
      <div className={s.modal__container}>
        {currentStep <= 3 && (
          <div className={s.modal__left}>
            <img
              style={{ marginLeft: "30px" }}
              alt=""
              src={require("../../../../assets/icons/marketplace/modal/plane.png")}
            />
            <h4 className={s.modal__left_title}>
              Expression of Interest for Study Abroad Assistance with Kandor
            </h4>
            <p className={s.modal__left_desc}>
              Kickstart your study abroad journey <br></br> with guidance on universities, <br></br> courses, and loans from our top experts in India.
            </p>
          </div>
        )}
        <div
          style={
            currentStep > 3
              ? {
                  paddingTop: "33px",
                  paddingRight: "40px",
                  paddingLeft: "40px",
                }
              : {
                  // paddingTop: "60px",
                  paddingBottom: "90px",
                  paddingLeft: "66px",
                  paddingRight: "55px",
                }
          }
          className={s.modal__right}
        >
          {showForm(currentStep)}
          <div className={s.modal__next}>
            {currentStep > 1 && currentStep < 4 && (
              <button
                onClick={() => {
                  setCurrentStep(prev => prev - 1);
                }}
                className={s.previous__btn}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="7"
                  height="10"
                  viewBox="0 0 7 10"
                  fill="none"
                >
                  <path
                    d="M5.5 9.00012L1.5 5.00005L5.5 0.999969"
                    stroke="#573B76"
                    strokeWidth="1.2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                Previous
              </button>
            )}
            <button className={s.next__btn} onClick={handleFormSubmit}>
              {showButton(currentStep)}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="6"
                height="10"
                viewBox="0 0 6 10"
                fill="none"
              >
                <path
                  d="M1 0.999906L5 4.99998L1 9.00006"
                  stroke="white"
                  strokeWidth="1.2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ModalPC;
