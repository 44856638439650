import s from './SopBanner.module.scss';
import { ReactComponent as Arrow } from '../../assets/icons/sopBanner/Arrow 3.svg';
import Hat from '../../assets/icons/sopBanner/hat.png';
import Passport from '../../assets/icons/sopBanner/visa.png';
import Letter from '../../assets/icons/sopBanner/letter.png';
import { ReactComponent as Star } from '../../assets/icons/sopBanner/bi_stars.svg';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
const mockData = [
  {
    id: 1,
    text: 'College SOP',
    img: Hat,
  },
  {
    id: 2,
    text: 'Visa SOP',
    img: Passport,
  },
  {
    id: 3,
    text: 'LOR',
    img: Letter,
  },
];

export const SopBanner = () => {
  const history = useHistory();
  const goToSop = () => {
    history.push('/admin/aisop');
  };
  return (
    <div onClick={() => goToSop()} className={s.sopBanner}>
      <div className={s.sop__star}>
        <Star />
      </div>
      <div className={s.sopBanner__wrap}>
        <div className={s.sopBanner__text}>
          {/* <div className={s.sopBanner__icon}>
            <EditIcon />
          </div> */}
          <div className={s.sopBanner__info}>
            <h2 className={s.sopBanner__info_title}>ADVANCED AI</h2>
            <p className={s.sopBanner__info_desc}>SOP Writer</p>
          </div>
        </div>
        <div className={s.sopBanner__block}>
          {mockData.map(el => (
            <div key={el.key} className={s.sopBanner__item}>
              <div className={s.sopBanner__item_icon}>
                <img src={el.img} alt={el.text} />
              </div>
              <span className={s.sopBanner__item_text}>{el.text}</span>
            </div>
          ))}
        </div>
        <div className={s.sopBanner__button_container}>
          <button className={s.sopBanner__button}>
            Try Now <Arrow />
          </button>
        </div>
      </div>
    </div>
  );
};
