import s from "./Timer.module.scss";
function Timer({ timeDifference }) {
  // Set the start time in GMT format

  return (
    <div>
      <p className={s.timer__title}>Starts in</p>
      <p className={s.time}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="13"
          height="15"
          viewBox="0 0 13 15"
          fill="none"
        >
          <g clip-path="url(#clip0_542_2790)">
            <circle cx="10.5" cy="11.8706" r="2.5" fill="#47BA52" />
            <path
              d="M11.25 7.54559C11.2852 6.4926 11.0028 5.45338 10.4394 4.56307C9.87606 3.67275 9.05787 2.97253 8.09123 2.55345C7.12459 2.13436 6.05422 2.01581 5.0193 2.21321C3.98438 2.41061 3.03279 2.91482 2.28827 3.66028C1.54375 4.40574 1.04075 5.35797 0.844661 6.39314C0.648575 7.4283 0.768479 8.49852 1.18878 9.46463C1.60909 10.4307 2.31035 11.248 3.20138 11.8103C4.0924 12.3725 5.13198 12.6536 6.18492 12.6171"
              stroke="black"
              strokeWidth="1.16667"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M6 4.45398V7.37065L7.16667 8.53731"
              stroke="black"
              strokeWidth="1.16667"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </g>
          <defs>
            <clipPath id="clip0_542_2790">
              <rect
                width="13"
                height="14"
                fill="white"
                transform="translate(0 0.370605)"
              />
            </clipPath>
          </defs>
        </svg>
        {timeDifference && timeDifference}
      </p>
    </div>
  );
}
export default Timer;
