import { getReadingTestAttemptState, updateReadingTestAttempt, getReadingTestAttemptAnalysis } from 'api/ieltsApi';

import 'assets/css/select-dropdown.css';
//import "assets/scss/black-dashboard-pro-react.scss";
import ColorNavbar from 'kandor-components/ColorNavbar.js';
import Sidebar from 'kandor-components/Sidebar.js';
import mixpanel from 'mixpanel-browser';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import {
  Alert,
  Button,
  CardBody,
  CardHeader,
  Col,
  Collapse,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Spinner,
} from 'reactstrap';
import { PassageContent } from 'ReadingPassages/PassageContent.js';
import PassagesQuestions from 'Data/PassageQuestions';
import routes from 'routers/routes.js';
import { logoutUser } from '../actions';
import { ReactComponent as DesktopIcon } from '../assets/kandor-img/desktop-icon.svg';
import './styles/Tests.scss';
import moment from 'moment';
// import {
// 	dataRound1,
// 	dataQuestion1,
// 	dataQuestion2,
// 	dataMock1,
// 	dataMock2,
// 	questionMock1,
// 	questionMock2,
// } from 'ReadingPassages/PassagesJson.js'
import { ReactComponent as ArrowRight } from '../assets/kandor-img/arrow-right.svg';
import { getReadingTaskContent } from 'api/ieltsApi';
import { useMediaQuery } from 'react-responsive';

var style = {
  backgroundColor: '#F8F8F8',
  borderTop: '1px solid #E7E7E7',
  textAlign: 'center',
  padding: '10px',
  position: 'fixed',
  left: '0',
  bottom: '0',
  height: 'auto',
  width: '100%',
};

var phantom = {
  display: 'block',
  padding: '0px',
  height: '10px',
  width: '100%',
};

function Footer({ children }) {
  return (
    <div>
      <div style={phantom} />
      <div style={style}>{children}</div>
    </div>
  );
}

const TestDev = props => {
  const params = useParams();
  const MockTestId = '8cb320c3-d803-4c48-80c4-023609c9d6da';
  const attemptId = '42f4cd4d-a847-4b95-91e7-50c9e30a2ac8';
  const isMock = false;
  const vhWindow = props.vhWindow;
  const resize = props.resize;

  const isMobile = useMediaQuery({ query: '(max-width:767px)' });
  const isDesktop = useMediaQuery({ query: '(max-width:1199px)' });
  const wrapRef = useRef('');

  const [tier, setTier] = useState('free');

  const [currentPassage, setCurrentPassage] = useState(1);

  const [isComplete, setIsComplete] = useState(false);
  const [title, setTitle] = useState('');

  const [seconds, setSeconds] = useState('-');
  const [minutes, setMinutes] = useState(0);
  const [correctTime, setCorrectTime] = useState(0);
  const [isActive, setIsActive] = useState(false);
  const history = useHistory();
  const [endTest, setEndTest] = useState(false);

  const isAuthenticated = useSelector(state => state.auth.isAuthenticated);
  const displayName = useSelector(state => state.auth.user.displayName || state.auth.user.email);

  const TokenId = useSelector(state => state.auth.TokenId);
  const [isFetching, setisFetching] = useState(false);
  const [isFetchingState, setIsFetchingState] = useState(false);
  const [alert, setAlertToggle] = useState(false);
  const alertToggle = () => setAlertToggle(!alert);

  const [pauseExam, setPauseToggle] = useState(false);
  const PauseToggle = () => setPauseToggle(!pauseExam);

  const [isPause, setIsPause] = useState(false);
  const IsPauseToggle = () => setIsPause(!isPause);

  const [isPauseTime, setIsPauseTime] = useState(false);

  const [endExam, setEndExam] = useState(false);
  const EndToggle = () => setEndExam(!endExam);

  const [review, setReview] = useState(false);
  const ReviewToggle = () => setReview(!review);

  const [textExam, setTextExam] = useState(true);
  const heightToggle = () => setTextExam(!textExam);

  const [testContent, setTestContent] = useState(isMock ? [] : {});
  const [testQuestions, setTestQuestions] = useState(isMock ? [] : {});
  const [numberQuestions, setNumberQuestions] = useState(isMock ? 40 : 0);

  const [isDeviceAlert, setIsDeviceAlert] = useState(true);

  const deviceToggle = () => {
    setIsDeviceAlert(!isDeviceAlert);
  };

  const reviewQuestions = Array.apply(null, { length: numberQuestions }).map(Number.call, Number);

  useEffect(() => {
    if (!MockTestId) return;
    if (isMock) {
      switch (MockTestId) {
        case '1':
          //setTestContent(dataMock1)
          //setTestQuestions(questionMock1)
          break;
        case '2':
          //setTestContent(dataMock2)
          //setTestQuestions(questionMock2)
          break;

        default:
          break;
      }
    } else {
      getReadingTaskContent({ id: MockTestId })
        .then(res => res.data)
        .then(data => {
          setTestContent(data.sections);
          console.log(data.sections);
          let number = 0;
          data.sections[currentPassage - 1].questions.forEach(data => {
            if (data.answers.length) number += data.answers.length;
          });

          setNumberQuestions(number);
          setisFetching(true);
          setIsActive(true);
        });
    }
  }, [MockTestId]);

  function toggle() {
    setIsActive(!isActive);
  }

  function viewAnalysis() {
    mixpanel.track('Clicked View Analysis on Reading Test', {});
    if (isMock) {
      history.push('/admin/readingielts/mock/' + MockTestId + '/result/' + attemptId);
    } else {
      history.push('/admin/readingielts/round/' + MockTestId + '/result/' + attemptId);
    }
  }

  const [endTime, setEndTime] = useState(10 * 60 * 1000);

  useEffect(() => {
    var initialTime = new Date().getTime();
    const endInterval = initialTime + endTime;
    let interval = null;
    if (isActive && (seconds > 0 || seconds == '-') && isFetchingState) {
      interval = setInterval(() => {
        var currentTime = new Date().getTime();
        var delay = currentTime - initialTime + correctTime;
        var endDelay = endInterval - currentTime;
        setMinutes(Math.floor((delay % (1000 * 60 * 60)) / (1000 * 60)));
        setSeconds(Math.floor(endDelay / 1000));
        setEndTime(endDelay);
        setCorrectTime(delay);
      }, 1000);
    } else if ((!isActive && seconds) || seconds <= 0) {
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [isActive, isFetchingState]);

  const [timeLeft, setTimeLeft] = useState(null);

  useEffect(() => {
    if (timeLeft === 0) {
      setTimeLeft(null);
    }

    // exit early when we reach 0
    if (!timeLeft) return;

    // save intervalId to clear the interval when the
    // component re-renders
    const intervalId = setInterval(() => {
      setTimeLeft(timeLeft - 1);
    }, 1000);

    // clear interval on re-render to avoid memory leaks
    return () => clearInterval(intervalId);
    // add timeLeft as a dependency to re-rerun the effect
    // when we update it
  }, [timeLeft]);

  const dispatch = useDispatch();

  const goToQuestion = x => {
    if (x <= 13) {
      setCurrentPassage(1);
    }
    if ((x > 13) & (x <= 27)) {
      setCurrentPassage(2);
    }
    if (x > 27) {
      setCurrentPassage(3);
    }
    ReviewToggle();
  };

  const handleLogout = () => {
    dispatch(logoutUser());
  };

  const [answers, setAnswers] = useState({});

  useEffect(() => {
    // dispatch(verifyAuth);
    // /* forceRefresh true */
    // var s = new Date();
    // var start = s.getTime();
    // getAuth().currentUser.getIdToken().then(function(idToken) {
    // 	var e = new Date();
    // 	var end = e.getTime();
    // 	console.log(end-start);

    getReadingTestAttemptState({
      id: MockTestId,
      attemptId: attemptId,
    }).then(json => {
      if (json.data.answers) {
        var answers = {};
        json.data.answers.forEach(element => {
          answers[`${element.question_number}`] = element.answer;
        });
        setAnswers(answers);
      }

      if (json.data.minutes_passed) {
        setCorrectTime(+json.data.minutes_passed * 1000);
        setEndTime(endTime - +json.data.minutes_passed * 1000);
      }

      setIsFetchingState(true);
    });
  }, [TokenId]);

  const saveAttemptState = async () => {
    // dispatch(verifyAuth);
    var state = { answers: [], minutes_passed: parseInt(correctTime / 1000) };
    for (const [key, value] of Object.entries(answers)) {
      state.answers.push({ question_number: key, answer: value });
    }
    try {
      // var s = new Date();
      // var start = s.getTime();
      // getAuth().currentUser.getIdToken().then(function(idToken) {
      // 	var e = new Date();
      // 	var end = e.getTime();
      // 	console.log(end-start);

      if (isMock) {
        console.log('state', state);
        updateReadingTestAttempt({
          id: MockTestId,
          attemptId: attemptId,
          state: state,
        });
      } else {
        console.log('state', state);
        updateReadingTestAttempt({
          id: MockTestId,
          attemptId: attemptId,
          state: state,
        });
      }
      // }).catch(function(error) {
      // 	console.log(error)
      // });
    } catch (ex) {
      console.error(ex);
    }
  };

  useEffect(() => {
    if (seconds <= 1) {
      const closeTest = async () => {
        toggle();
        await saveAttemptState();

        getReadingTestAttemptAnalysis({
          id: MockTestId,
          attemptId: attemptId,
        }).then(res => {
          setAlertToggle(true);
          setEndTest(true);
          setSeconds(0);
        });
      };

      closeTest();
    }
  }, [seconds]);

  const [isSaved, setIsSaved] = useState(false);

  useEffect(() => {
    const sec = correctTime / 1000;
    if (isActive) {
      if (sec % 60 > 0 && sec % 60 < 10) {
        if (!isSaved) {
          saveAttemptState();
          setIsSaved(true);
        }
      } else {
        setIsSaved(false);
      }
    }
  }, [isActive, MockTestId, attemptId, correctTime, answers]);

  useEffect(() => {
    if (navigator.platform.indexOf('Win') > -1) {
      document.documentElement.classList.remove('perfect-scrollbar-off');
    }

    return () => {
      if (navigator.platform.indexOf('Win') > -1) {
        document.documentElement.classList.add('perfect-scrollbar-off');
      }
    };
  }, []);

  useEffect(() => {
    //document.body.style.overflow = 'hidden'
    //document.body.classList.add('no-scroll')

    //document.documentElement.style.overflow = 'hidden'
    // firefox, chrome
    document.body.scroll = 'no'; // ie only
    document.body.classList.toggle('white-content');
    return () => {
      document.body.classList.toggle('white-content');
      document.body.style.overflow = 'unset';
      document.documentElement.style.overflow = 'auto'; // firefox, chrome
      document.body.scroll = 'yes'; // ie only
    };
  }, []);

  const [openedCollapses, setopenedCollapses] = useState(false);

  function collapsesToggle(collapse) {
    setopenedCollapses(!openedCollapses);
  }

  const [sidebarOpened, setSidebarOpened] = useState(false);

  const toggleSidebar = () => {
    setSidebarOpened(!sidebarOpened);
    document.documentElement.classList.toggle('nav-open');
  };

  const closeSidebar = ({ e, layout, path }) => {
    e.preventDefault();

    history.push(`${layout}${path}`);
    setSidebarOpened(false);
    document.documentElement.classList.remove('nav-open');
  };

  function formatDuration(duration) {
    return moment.utc(moment.duration(duration, 'seconds').asMilliseconds()).format('mm:ss', { trim: false });
  }

  const handleEndExam = () => {
    const localAttemptsReading = +localStorage.getItem('attempts_reading');
    const attemptsReading = localAttemptsReading ? localAttemptsReading : 0;
    localStorage.setItem('attempts_reading', attemptsReading + 1);
    localStorage.setItem('reading_after_test', true);
  };

  const contentRef = useRef(null);
  const questionsRef = useRef(null);

  const [selection, setSelection] = useState(null);
  const [range, setRange] = useState(null);

  const selectRef = useRef();

  const addSpan = (range, selection) => {
    var selectionContents = range.extractContents();
    var span = document.createElement('span');
    span.appendChild(selectionContents);
    span.style.backgroundColor = 'yellow';
    span.style.color = 'green';
    span.style.cursor = 'pointer';
    span.addEventListener('click', onHighlight);
    range.insertNode(span);
    setRange(null);
    selection.removeAllRanges();
    setSelection(null);
  };

  const onMouseDown = e => {
    if (window.getSelection() == '') {
      return false;
    }

    const selection = window.getSelection();

    const range = window.getSelection().getRangeAt(0);
    setSelection(selection);
    setRange(range);

    if (!range?.commonAncestorContainer?.parentElement?.closest('.reading-card-body')) return;

    addSpan(range, selection);
  };

  const onHighlight = e => {
    e.stopPropagation();
    const span = e.target.closest('span');
    span.insertAdjacentText('beforebegin', span.textContent);
    span.remove();
  };

  useEffect(() => {
    if (contentRef.current) {
      contentRef.current.scrollTo(0, 0);
    }

    if (questionsRef.current) {
      questionsRef.current.scrollTo(0, 0);
    }
  }, [currentPassage]);

  return (
    <div>
      {isDesktop ? (
        <>
          <ColorNavbar
            isAuthenticated={isAuthenticated}
            displayName={displayName}
            plan={tier}
            isComplete={isComplete}
            sidebarOpened={sidebarOpened}
            toggleSidebar={toggleSidebar}
            LoggingOut={() => {
              handleLogout();
            }}
          />
          <Sidebar
            routes={routes}
            isComplete={isComplete}
            activeColor={'orange'}
            closeSidebar={closeSidebar}
            location={document.location}
            plan={tier}
          />
        </>
      ) : (
        <ColorNavbar
          isAuthenticated={isAuthenticated}
          displayName={displayName}
          plan={tier}
          isComplete={isComplete}
          LoggingOut={() => {
            handleLogout();
          }}
        />
      )}

      <Modal isOpen={pauseExam} toggle={PauseToggle}>
        <div className="modal-header">
          <button
            type="button"
            className="close modal-close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={PauseToggle}></button>
          <h4 className="modal-title">Are you sure you want to pause the exam?</h4>
        </div>
        <ModalBody>
          <p>All your answers have been saved. If you come back, you can resume it from here.</p>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={PauseToggle}>
            Resume the exam
          </Button>
          <Button
            color="danger"
            onClick={() => {
              PauseToggle();
              setIsActive(false);
              IsPauseToggle();
              setIsPauseTime(true);
            }}>
            Pause the exam
          </Button>
        </ModalFooter>
      </Modal>

      <Modal isOpen={isPause} toggle={IsPauseToggle}>
        <div className="modal-header">
          <button
            type="button"
            className="close modal-close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={IsPauseToggle}>
            <i className="tim-icons icon-simple-remove"></i>
          </button>
          <h4 className="modal-title">The test is paused</h4>
        </div>
        {/* <ModalBody>
					<p>
						All your answers have been saved. If you come back, you can resume
						it from here.
					</p>
				</ModalBody> */}
        <ModalFooter>
          <Button
            color="primary"
            className="mx-auto"
            onClick={() => {
              setIsActive(true);
              IsPauseToggle();
            }}>
            Click to resume the test
          </Button>
        </ModalFooter>
      </Modal>

      <Modal isOpen={endExam} toggle={EndToggle}>
        <div className="modal-header">
          <button
            type="button"
            className="close modal-close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={EndToggle}></button>
          <h4 className="modal-title">Are you sure you want to end the exam?</h4>
        </div>
        <ModalBody>
          <p></p>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={EndToggle}>
            Resume the exam
          </Button>
          <Button
            color="danger"
            onClick={() => {
              handleEndExam();
              viewAnalysis();
              setIsPauseTime(false);
            }}>
            End the exam
          </Button>
        </ModalFooter>
      </Modal>

      <Modal size="lg" isOpen={review} toggle={ReviewToggle} className="modal-review">
        <ModalHeader className="mb-0">
          <button
            type="button"
            className="close modal-close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={ReviewToggle}></button>
          <h4 className="mb-0 text-center" style={{ color: 'black' }}>
            <strong> Review Your Answers </strong>{' '}
          </h4>
        </ModalHeader>

        <ModalBody>
          <div className="modal-review__title-wrap">
            <div className="modal-review__title">
              <Button className="btn-round btn-icon m-0" color="danger" size="sm"></Button>
              <span>- Unattempted</span>
            </div>
            <div className="modal-review__title">
              <Button className="btn-round btn-icon m-0" color="success" size="sm"></Button>
              <span>- Attempted</span>
            </div>
          </div>
          <Row>
            {reviewQuestions.map((rQ, key) => {
              return (
                <Col xs="3" key={key} className="mt-2">
                  {answers[rQ + 1] !== undefined ? (
                    <p className="m-0">
                      <Button
                        className="btn-round btn-icon m-0"
                        onClick={() => goToQuestion(rQ + 1)}
                        color="success"
                        size="sm">
                        {rQ + 1}
                      </Button>
                      <strong> {answers[rQ + 1]} </strong>
                    </p>
                  ) : (
                    <p className="m-0">
                      <Button
                        className="btn-round btn-icon m-0"
                        color="danger"
                        onClick={() => goToQuestion(rQ + 1)}
                        size="sm">
                        {rQ + 1}
                      </Button>
                      <strong> {answers[rQ + 1]} </strong>
                    </p>
                  )}
                </Col>
              );
            })}
          </Row>
        </ModalBody>
        <ModalFooter className="text-right" style={{ display: 'none' }}>
          <Button color="primary" onClick={() => ReviewToggle()}>
            Done
          </Button>
        </ModalFooter>
      </Modal>

      <div className="wrapper reading-wrap" ref={wrapRef}>
        <div className="team-2 height-full width-full" style={{ overflow: 'hidden' }}>
          {isFetching === false && isFetchingState === false ? (
            <div
              style={{
                display: 'flex',
                marginLeft: '45rem',
                marginTop: '20rem',
              }}>
              <Spinner color="info" size="lg" />
            </div>
          ) : (
            <div className="text-left parent height-full position-relative">
              <Row className="height-full">
                <Col xs="12" md="6" className={`reading-col-text ${textExam ? '' : 'hide'}`}>
                  <div className="questions-wrap questions-wrap--reading card mb-md-0" ref={contentRef}>
                    {isMock ? (
                      <CardHeader className="mb-3">
                        <span className="text-info test-content-title">Reading Passage {currentPassage}</span>
                        <p className="text-white m-0">
                          You should spend about 20 minutes on Questions in this section, which are based on this
                          passage.{' '}
                        </p>
                      </CardHeader>
                    ) : (
                      <CardHeader className="mb-3">
                        <span className="text-info test-content-title">
                          vhWindow {vhWindow}: {resize}
                        </span>
                      </CardHeader>
                    )}

                    <CardBody className=" reading-card-body text-black" onPointerUp={onMouseDown}>
                      {testContent.length ? (
                        <PassageContent content={testContent[currentPassage - 1]} isMock={isMock} />
                      ) : (
                        ''
                      )}
                    </CardBody>
                  </div>
                </Col>

                {isMobile ? (
                  <Col xs="12" className="pt-2" style={{ maxHeight: '50%' }}>
                    <div className="card questions-wrap questions-wrap--reading m-0" ref={questionsRef}>
                      <div aria-multiselectable={true} className="card-collapse" id="accordion" role="tablist">
                        <CardHeader role="tab" className="reading-accordion-head">
                          {' '}
                          <a
                            aria-expanded={openedCollapses}
                            href="#pablo"
                            data-parent="#accordion"
                            data-toggle="collapse"
                            onClick={e => {
                              e.preventDefault();
                              collapsesToggle();
                              heightToggle();
                            }}>
                            <span>{isMock ? testContent[currentPassage - 1]?.title : `Questions`}</span>
                            <span className={`reading-accordion-arrow ${openedCollapses ? 'active' : ''}`}>
                              <svg
                                width="24"
                                height="23"
                                viewBox="0 0 24 23"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path
                                  d="M12 14.375L12.6227 15.0248L12 15.6216L11.3773 15.0248L12 14.375ZM18.6227 9.27479L12.6227 15.0248L11.3773 13.7252L17.3773 7.97521L18.6227 9.27479ZM11.3773 15.0248L5.37729 9.27479L6.62271 7.97521L12.6227 13.7252L11.3773 15.0248Z"
                                  fill="white"
                                />
                              </svg>
                            </span>
                          </a>{' '}
                        </CardHeader>
                        <Collapse role="tabpanel" isOpen={openedCollapses}>
                          <CardBody className={'reading-card-body'}>
                            {testContent[currentPassage - 1]?.questions
                              ? testContent[currentPassage - 1].questions.map((data, i) => (
                                  <PassagesQuestions
                                    key={i}
                                    isAnalytics={false}
                                    isMock={isMock}
                                    questionList={data}
                                    test={MockTestId}
                                    passage={currentPassage}
                                    setAnswers={setAnswers}
                                    UserAnswers={answers}
                                  />
                                ))
                              : ''}
                          </CardBody>
                        </Collapse>
                      </div>
                    </div>
                  </Col>
                ) : (
                  <Col xs="6" className="height-full">
                    <div className="questions-wrap questions-wrap--reading card mb-0" ref={questionsRef}>
                      <CardBody className={'text-white reading-card-body'}>
                        <span className="text-info test-content-title">
                          {isMock ? testQuestions[currentPassage - 1]?.title : `Questions`}
                        </span>
                        {testContent[currentPassage - 1]?.questions
                          ? testContent[currentPassage - 1].questions.map((data, i) => (
                              <PassagesQuestions
                                key={i}
                                isAnalytics={false}
                                isMock={isMock}
                                questionList={data}
                                test={MockTestId}
                                passage={currentPassage}
                                setAnswers={setAnswers}
                                UserAnswers={answers}
                              />
                            ))
                          : ''}
                      </CardBody>
                    </div>
                  </Col>
                )}
              </Row>

              <Footer>
                {isMobile ? (
                  <div className="reading-test-footer">
                    {isMock ? (
                      <div style={{ width: '100%' }}>
                        {currentPassage === 1 ? (
                          <Row>
                            <Col xs="3" className="text-left"></Col>

                            <Col xs="6" className="text-center">
                              <div className="tests-timer">
                                <p className="m-0 tests-timer__content">
                                  {!isActive ? (
                                    <>
                                      {seconds == '-'
                                        ? '-'
                                        : seconds <= 2
                                        ? formatDuration(0)
                                        : formatDuration(seconds)}
                                    </>
                                  ) : (
                                    formatDuration(seconds)
                                  )}
                                </p>
                              </div>
                            </Col>

                            <Col xs="3" className="text-left">
                              <Button
                                color="info"
                                onClick={() => {
                                  setCurrentPassage(currentPassage + 1);
                                  if (openedCollapses) {
                                    collapsesToggle();
                                    heightToggle();
                                  }
                                }}
                                className="reading-steps-btn">
                                <ArrowRight />
                              </Button>
                            </Col>
                          </Row>
                        ) : currentPassage === 3 ? (
                          <Row>
                            <Col xs="3" className="text-left">
                              <Button
                                color="info"
                                onClick={() => {
                                  setCurrentPassage(currentPassage - 1);
                                  if (openedCollapses) {
                                    collapsesToggle();
                                    heightToggle();
                                  }
                                }}
                                className="reading-steps-btn">
                                <ArrowRight style={{ transform: 'rotate(180deg)' }} />
                              </Button>
                            </Col>

                            <Col xs="6" className="text-center">
                              <div className="tests-timer">
                                <p className="m-0 tests-timer__content">
                                  {!isActive ? (
                                    <>
                                      {seconds == '-'
                                        ? '-'
                                        : seconds <= 2
                                        ? formatDuration(0)
                                        : formatDuration(seconds)}
                                    </>
                                  ) : (
                                    formatDuration(seconds)
                                  )}
                                </p>
                              </div>
                            </Col>

                            <Col xs="3" className="text-right">
                              <Button
                                color="danger"
                                className="mb-0 mt-0 reading-steps-btn p-1"
                                onClick={async () => {
                                  await saveAttemptState();
                                  EndToggle();
                                }}>
                                Done
                              </Button>
                            </Col>
                          </Row>
                        ) : (
                          <Row>
                            <Col xs="3" className="text-left">
                              <Button
                                color="info"
                                onClick={() => {
                                  setCurrentPassage(currentPassage - 1);
                                  if (openedCollapses) {
                                    collapsesToggle();
                                    heightToggle();
                                  }
                                }}
                                className="reading-steps-btn">
                                <ArrowRight style={{ transform: 'rotate(180deg)' }} />
                              </Button>
                            </Col>

                            <Col xs="6" className="text-center">
                              <div className="tests-timer">
                                <p className="m-0 tests-timer__content">
                                  {!isActive ? (
                                    <>
                                      {seconds == '-'
                                        ? '-'
                                        : seconds <= 2
                                        ? formatDuration(0)
                                        : formatDuration(seconds)}
                                    </>
                                  ) : (
                                    formatDuration(seconds)
                                  )}
                                </p>
                              </div>
                            </Col>

                            <Col xs="3" className="text-right">
                              <Button
                                color="info"
                                onClick={() => {
                                  setCurrentPassage(currentPassage + 1);
                                  if (openedCollapses) {
                                    collapsesToggle();
                                    heightToggle();
                                  }
                                }}
                                className="reading-steps-btn">
                                <ArrowRight />
                              </Button>
                            </Col>
                          </Row>
                        )}
                      </div>
                    ) : (
                      <div style={{ width: '100%' }}>
                        <Row>
                          <Col xs="3" className="text-left"></Col>

                          <Col xs="6" className="text-center">
                            <div className="tests-timer">
                              <p className="m-0 tests-timer__content">
                                {!isActive ? (
                                  <>
                                    {seconds == '-' ? '-' : seconds <= 2 ? formatDuration(0) : formatDuration(seconds)}
                                  </>
                                ) : (
                                  formatDuration(seconds)
                                )}
                              </p>
                            </div>
                          </Col>

                          <Col xs="3" className="text-right">
                            <Button
                              color="danger"
                              className="mb-0 mt-0 reading-steps-btn p-1"
                              onClick={async () => {
                                await saveAttemptState();
                                EndToggle();
                              }}>
                              Done
                            </Button>
                          </Col>
                        </Row>
                      </div>
                    )}
                  </div>
                ) : (
                  <div className="reading-test-footer">
                    <Row>
                      <Col xs="5" xl={4} className="text-left">
                        <Button
                          className="my-0"
                          color="info"
                          onClick={() => {
                            mixpanel.track('Clicked Review on Reading Test', {});
                            ReviewToggle();
                          }}>
                          Review
                        </Button>

                        {endTest ? (
                          ''
                        ) : isActive === true ? (
                          <Button
                            color="danger"
                            onClick={() => {
                              mixpanel.track('Clicked Pause on Reading Test', {});
                              PauseToggle();
                            }}
                            className="mobile_absent my-0">
                            Pause
                          </Button>
                        ) : (
                          <Button
                            color="success"
                            onClick={() => {
                              mixpanel.track('Clicked Resume on Reading Test', {});
                              setIsActive(true);
                              setIsPauseTime(false);
                            }}
                            className="mobile_absent my-0">
                            Resume
                          </Button>
                        )}
                      </Col>

                      <Col xs="2" xl={4} style={{ display: 'flex', justifyContent: 'center' }}>
                        <div className="tests-timer">
                          {/* {isPauseTime ? (
														<div className="tests-timer__pause">
															<span></span>
															<span></span>
														</div>
													) : (
														''
													)} */}

                          <p className="m-0 tests-timer__content">
                            {!isActive ? (
                              <>{seconds == '-' ? '-' : seconds <= 2 ? formatDuration(0) : formatDuration(seconds)}</>
                            ) : (
                              formatDuration(seconds)
                            )}
                          </p>
                        </div>
                      </Col>

                      <Col xs="5" xl={4} className="text-right">
                        {isMock ? (
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'flex-end',
                            }}>
                            {currentPassage === 1 ? (
                              <>
                                <Button
                                  color="info"
                                  className="reading-steps-btn"
                                  onClick={() => setCurrentPassage(currentPassage + 1)}>
                                  <ArrowRight />
                                </Button>
                                <Button
                                  color="danger"
                                  className="my-0"
                                  onClick={async () => {
                                    await saveAttemptState();
                                    EndToggle();
                                  }}>
                                  Done
                                </Button>
                              </>
                            ) : currentPassage === 3 ? (
                              <>
                                {' '}
                                <Button
                                  color="info"
                                  className="reading-steps-btn"
                                  onClick={() => setCurrentPassage(currentPassage - 1)}>
                                  <ArrowRight style={{ transform: 'rotate(180deg)' }} />
                                </Button>
                                <Button
                                  color="danger"
                                  className="my-0"
                                  onClick={async () => {
                                    await saveAttemptState();
                                    EndToggle();
                                  }}>
                                  Done
                                </Button>
                              </>
                            ) : (
                              <>
                                {' '}
                                <Button
                                  color="info"
                                  className="reading-steps-btn"
                                  onClick={() => setCurrentPassage(currentPassage - 1)}>
                                  <ArrowRight style={{ transform: 'rotate(180deg)' }} />
                                </Button>
                                <Button
                                  color="info"
                                  className="reading-steps-btn"
                                  onClick={() => setCurrentPassage(currentPassage + 1)}>
                                  <ArrowRight />
                                </Button>
                                <Button
                                  color="danger"
                                  className="my-0"
                                  onClick={async () => {
                                    await saveAttemptState();
                                    EndToggle();
                                  }}>
                                  Done
                                </Button>{' '}
                              </>
                            )}
                          </div>
                        ) : (
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'flex-end',
                            }}>
                            <Button
                              color="danger"
                              className="my-0"
                              onClick={async () => {
                                await saveAttemptState();
                                EndToggle();
                              }}>
                              Done
                            </Button>
                          </div>
                        )}
                      </Col>
                    </Row>{' '}
                  </div>
                )}
              </Footer>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default TestDev;

//   <i className="tim-icons icon-video-66" /> {"   "}
