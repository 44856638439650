import React from "react";
import styles from "./MarketplaceForm.module.scss";

const RadioButtonGroup = ({ name, options, selectedValue, onChange }) => {
  return (
    <div className={styles.radioGroup}>
      {options.map(option => (
        <label key={option.value} className={styles.radioLabel}>
          <input
            type="radio"
            name={name}
            value={option.value}
            checked={selectedValue === option.value}
            onChange={onChange}
          />
          {option.label}
        </label>
      ))}
    </div>
  );
};

const MarketplaceFormTimeSlot = ({ data, setData, errors }) => {
  const timeOptions = [
    {
      value: "Morning (7:00AM - 12:00AM)",
      label: "Morning (7:00AM - 12:00AM)",
    },
    {
      value: "Afternoon (12:00PM - 4:00PM)",
      label: "Afternoon (12:00PM - 4:00PM)",
    },
    { value: "Evening (4:00PM - 8:00PM)", label: "Evening (4:00PM - 8:00PM)" },
  ];

  const handleOptionDateChange = event => {
    const value = event.target.value;
    setData(prev => ({ ...prev, Date: value }));
  };

  return (
    <form
      style={{ height: "100%", justifyContent: "center" }}
      className={styles.radioButtonForm}
    >
      <div className={styles.formGroup}>
        {errors.Date && !data.Date && (
          <span className={styles.error}> * This is a compulsory field</span>
        )}
        <label className={styles.label}>
          What time should our counsellor reach out to you? *
        </label>
        <RadioButtonGroup
          name="Date"
          options={timeOptions}
          selectedValue={data.Date}
          onChange={handleOptionDateChange}
        />
      </div>
    </form>
  );
};

export default MarketplaceFormTimeSlot;
