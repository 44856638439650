import { getLimitClasses } from 'api/classesApi';
import moment from 'moment-timezone';
import React, { memo, useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { ReactComponent as AllSet } from '../../../assets/icons/classes/allSet.svg';
import { ReactComponent as Gift } from '../../../assets/icons/classes/gift.svg';
import { ReactComponent as Notice } from '../../../assets/icons/classes/notice.svg';
import { ReactComponent as Pro } from '../../../assets/icons/classes/premium.svg';
import Filter from '../Classes/Upcoming/Filter/Filter';
import s from './Classes.module.scss';
import Upcoming from './Upcoming/Upcoming';

const mapOfType = {
  0: 'Speaking',
  1: 'Writing',
  2: 'Reading',
  3: 'Listening',
};
const Classes = ({ upcomingClasses, tabIndex = null }) => {
  const history = useHistory();
  const [classes, setClasses] = useState([]);
  const [refreshClasses, setRefreshClasses] = useState(false);
  const [show, setShow] = useState(false);
  const [isNotify, setIsNotify] = useState(false);
  const [filter, setFilter] = useState('Today');
  const [typeFilter, setTypeFilter] = useState(tabIndex === null ? 'All Classes' : mapOfType[tabIndex]); // Added typeFilter state
  const [freeLimit, setFreeLimit] = useState(0);
  const [premium, setPremium] = useState(false);

  const todayClasses = upcomingClasses.some(cls => {
    const now = moment().tz('Asia/Kolkata');
    const classStartTime = moment.tz(
      cls.startTime.replace('GMT', '+0530'),
      'ddd, DD MMM YYYY HH:mm:ss ZZ',
      'Asia/Kolkata'
    );
    return now.isSame(classStartTime, 'day');
  });

  const thisWeekClasses = upcomingClasses.some(cls => {
    const now = moment().tz('Asia/Kolkata');
    const startOfWeek = now.clone().startOf('isoWeek');
    const endOfWeek = now.clone().endOf('isoWeek');
    const classStartTime = moment.tz(
      cls.startTime.replace('GMT', '+0530'),
      'ddd, DD MMM YYYY HH:mm:ss ZZ',
      'Asia/Kolkata'
    );
    return classStartTime.isBetween(startOfWeek, endOfWeek) && classStartTime.isAfter(now);
    // return classStartTime.isSame(tomorrow, "day");
  });
  useEffect(() => {
    if (todayClasses) {
      setFilter('Today');
    } else if (thisWeekClasses) {
      setFilter('This Week');
    } else {
      setFilter('This Month');
    }
  }, [upcomingClasses]);

  useEffect(() => {
    getLimitClasses()
      .then(res => res.data)
      .then(res => {
        if (res.limit === 'Premium') {
          setPremium(true);
        } else {
          setFreeLimit(res.limit);
        }
      })
      .catch(e => {
        console.log(e);
      });
  }, []);

  useEffect(() => {
    if (refreshClasses) {
      setRefreshClasses(!refreshClasses);
      const now = moment().tz('Asia/Kolkata');
      const active = [];
      const upcoming = [];
      classes.forEach(cls => {
        // Convert the received time to IST
        const startTime = moment.tz(
          cls.startTime.replace('GMT', '+0530'),
          'ddd, DD MMM YYYY HH:mm:ss ZZ',
          'Asia/Kolkata'
        );
        const endTime = startTime.clone().add(1, 'hours');
        const bufferTime = startTime.clone().subtract(20, 'minutes');

        if (now.isBetween(bufferTime, endTime)) {
          active.push(cls);
        } else {
          upcoming.push(cls);
        }
      });

      // setUpcomingClasses(upcoming);
    }
  }, [refreshClasses]);

  const filterUpcomingClasses = useCallback(
    (filter, typeFilter) => {
      const now = moment().tz('Asia/Kolkata');
      const startOfWeek = now.clone().startOf('isoWeek');
      const endOfWeek = now.clone().endOf('isoWeek');
      const startOfNextWeek = now.clone().startOf('isoWeek').add(1, 'week');
      const startOfWeekend = startOfWeek.clone().add(5, 'days');
      const endOfWeekend = startOfWeek.clone().add(2, 'days').endOf('day');
      const startOfMonth = now.clone().startOf('month');
      const endOfMonth = now.clone().endOf('month');

      return upcomingClasses.filter(cls => {
        const classStartTime = moment.tz(
          cls.startTime.replace('GMT', '+0530'),
          'ddd, DD MMM YYYY HH:mm:ss ZZ',
          'Asia/Kolkata'
        );

        const isTypeMatch = typeFilter === 'All Classes' || cls.type === typeFilter;

        switch (filter) {
          case 'Today':
            return isTypeMatch && now.isSame(classStartTime, 'day');
          case 'Tomorrow':
            return isTypeMatch && now.clone().add(1, 'day').isSame(classStartTime, 'day');
          case 'This Week':
            return isTypeMatch && classStartTime.isBetween(startOfWeek, endOfWeek) && classStartTime.isAfter(now);
          case 'This Weekend':
            return (
              isTypeMatch &&
              (classStartTime.isBetween(startOfWeekend, endOfWeekend) ||
                classStartTime.isBetween(startOfWeekend, startOfNextWeek))
            );
          case 'This Month':
            return isTypeMatch && classStartTime.isBetween(startOfMonth, endOfMonth) && classStartTime.isAfter(now);
          default:
            return isTypeMatch;
        }
      });
    },
    [upcomingClasses]
  );

  const handleTypeFilterChange = useCallback(selectedType => {
    setTypeFilter(selectedType);
  }, []);

  const routeToPlanPage = useCallback(() => {
    freeLimit === 0 && history.push('/admin/plan-information');
  }, [freeLimit, history]);

  const onNotify = () => {
    setIsNotify(true);
  };

  return (
    <section className={s.classes__container} >
      <div id="classes-guide">
        <div className={`${s.class_block}`}>
          <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
            <h4 className={s.classes__title}>Kandor Classes</h4>
            <div style={{ margin: 0 }} className="corner-new">
              new
            </div>
          </div>
          {!premium && (
            <div onClick={routeToPlanPage} className={s.classes__gift}>
              {freeLimit === 0 ? (
                <>
                  <Pro /> Upgrade Now!{' '}
                  <svg xmlns="http://www.w3.org/2000/svg" width="12" height="13" viewBox="0 0 12 13" fill="none">
                    <path
                      d="M7.00074 2.2522H10.5007V5.7522M10.5007 8.12071V10.5022C10.5007 10.7011 10.4217 10.8919 10.2811 11.0325C10.1404 11.1732 9.94966 11.2522 9.75075 11.2522H2.25073C2.05182 11.2522 1.86106 11.1732 1.7204 11.0325C1.57975 10.8919 1.50073 10.7011 1.50073 10.5022V3.0022C1.50073 2.80329 1.57975 2.61252 1.7204 2.47187C1.86106 2.33122 2.05182 2.2522 2.25073 2.2522H4.50074M6.45074 6.30221L10.2757 2.4772"
                      stroke="#EE7734"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </>
              ) : (
                <>
                  <Gift /> Free Classes Left: {freeLimit}
                </>
              )}
            </div>
          )}
        </div>

        <Filter
          onFilterChange={setFilter}
          onTypeFilterChange={handleTypeFilterChange}
          filter={filter}
          typeFilter={typeFilter}
          show={show}
          setShow={setShow}
        />
      </div>

      {filterUpcomingClasses(filter, typeFilter).length === 0 && tabIndex !== null ? (
        <div className={s.message__container}>
          <h3 className={s.message__title}>Check back later</h3>
          <button onClick={() => onNotify()} className={s.notice__button}>
            {isNotify ? (
              <>
                All Set! <AllSet />
              </>
            ) : (
              <>
                Notify me <Notice />
              </>
            )}
          </button>
        </div>
      ) : (
        <Upcoming classes={filterUpcomingClasses(filter, typeFilter)} filter={filter} show={show} />
      )}
    </section>
  );
};

export default memo(Classes);
