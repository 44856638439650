import React, { useEffect, useState } from 'react'
import { Input } from 'reactstrap'
import './newChanges/InputSelect.scss'

const speakingOptionList = [
	{
		text: 'Round 2',
		value: '2',
	},
	{
		text: 'Round 1',
		value: '1',
	},
	{
		text: 'Round 3',
		value: '3',
	},
]

const writingOptionList = [
	{
		text: 'Academics Task 01',
		value: '1',
	},
	{
		text: 'General Task 01',
		value: 'general'
	},
	{
		text: 'Essay Writing Task 02',
		value: '2',
	}
]

const InputSelect = ({ section, onChange, id = '', optionList = [] }) => {
	const [currentOptionList, setCurrentOptionList] = useState([])

	useEffect(() => {
		if (optionList && optionList?.length && Array.isArray(optionList)) {
			setCurrentOptionList(optionList)
		} else {
			switch (section) {
				case 'Speaking':
					setCurrentOptionList(speakingOptionList)
					break
				case 'Writing':
					setCurrentOptionList(writingOptionList)
					break

				default:
					setCurrentOptionList([])
					break
			}
		}
	}, [section, optionList])

	return (
		<Input type="select" id={id} onChange={onChange}>
			{currentOptionList.map((data, i) => (
				<option value={data.value} key={i} style={{ padding: '12px 16px' }}>
					{data.text}
				</option>
			))}
		</Input>
	)
}

const TipSelectText = () => {
	return (
		<p className="tip-label">
			{' '}
			<span className="subtitle-gradient">TIP! </span>
			Select a <b>Prompt</b> to practice
		</p>
	)
}

export { InputSelect, TipSelectText }
