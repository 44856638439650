import api from 'services/api';

export async function getAllDocuments() {
  return await api.get('/document');
}

export async function postDocuments(objArgs, name) {
  console.log(objArgs);
  return await api.put(`/document/${name}`, objArgs);
}
