import React, { useEffect, useState } from 'react'
import { Row, Col, Button } from 'reactstrap'
import Listening from '../../../images/enucation.png'
import Writing from '../../../images/grammer.png'
import improvePic from '../../../images/improvePic.png'
import improvePicWebp from '../../../images/improvePic.webp'
import Arrow from '../../../images/Line 2.png'
import userprofile from '../../../images/userprofile.png'
import userprofileWebp from '../../../images/userprofile.webp'
import fluency from '../../../images/Vector.png'
import Vocabulary from '../../../images/vocabulary.png'
import './style.css'
import './bandScoreCardNew.css'
import UserMessage from './UseMessage'
import { useHistory } from 'react-router-dom'
import BannerBenefits from '../BannerBenefits/BannerBenefits'

function ImproveSection() {
	const newWidth =
		window.innerWidth > 0 ? window.innerWidth : window.screen.width
	const [width, setWidth] = useState(newWidth)

	const isMobile = width <= 767

	useEffect(() => {
		const changeWidth = () => {
			window.innerWidth > 0
				? setWidth(window.innerWidth)
				: setWidth(window.screen.width)
		}
		window.addEventListener('resize', changeWidth)
		return () => window.removeEventListener('resize', changeWidth)
	}, [])

	const history = useHistory()

	return (
		<div className={`banner-container`}>
			<div className="landing-container landing-container--small d-flex flex-column ">
				<Row className="banner-content">
					<Col xs={12} md={6} className="first-column">
						<div className="banner-content__info">
							<div className="animated-wrap">
								<div className="animatated">
									<p
										id="learning-partner"
										className="landing-subtitle banner-subtitle"
									>
										{' '}
										india’s #1 ielts prep platform
									</p>
								</div>
							</div>

							<h1 className="banner-title">
								The fastest way to <span>improve your IELTS score</span>
							</h1>

							<p className="banner-para">
								Get detailed Band Score & Feedback for all sections in{' '}
								<span>just 15 seconds!</span>
							</p>

							<div className="banner-list">
								<span className="banner-list__item">Practice</span>
								<span className="banner-list__item">Identify</span>
								<span className="banner-list__item">Train</span>
								<span className="banner-list__item">Succeed</span>
							</div>

							{!isMobile ? (
								<Button
									className={`banner-button`}
									color="info"
									onClick={(e) => {
										e.preventDefault()
										history.push('/signup')
									}}
								>
									Get Started for Free
								</Button>
							) : (
								''
							)}
						</div>
					</Col>

					<Col xs={12} md={6} className="banner-info">
						<div className="band-score-section">
							<div className="improve-image">
								<picture>
									<source type="image/webp" srcSet={improvePicWebp} />
									<img src={improvePic} className="w-100" alt="improve pic" />
								</picture>
							</div>
						</div>
						<BandCard />
						<UserCard />
						<ProgressCard />
					</Col>
				</Row>
				<BannerBenefits />
			</div>
		</div>
	)
}

const UserCard = () => {
	return (
		<div className="user-card">
			<div className="user-wrap">
				<picture>
					<source type="image/webp" srcSet={userprofileWebp} />
					<img src={userprofile} alt="pro" />
				</picture>

				<UserMessage />

				<h2 className="title-gradient" id="name">
					Okeke Arinze
				</h2>
				<h4 className="title-gradient">NIGERIA</h4>
			</div>
		</div>
	)
}

const BandCard = () => {
	return (
		<div className="band-card">
			<p className="title-gradient">
				Increase Your <br />
				<strong>Band Score</strong>
			</p>
			<div
				style={{
					display: 'flex',
					alignItems: 'flex-start',
					position: 'relative',
				}}
			>
				<h2 className="title-gradient">7.5</h2>
				<img src={Arrow} />
			</div>
		</div>
	)
}

const ProgressCard = () => {
	return (
		<div className="bandScoreCardNew">
			<div className="instan-evaluation">
				<span>Instant evaluation</span>
			</div>
			<div className="balsa">
				<span style={{ fontWeight: '500', color: '#3D0F65' }}>Speaking</span>
				<div className="progress-wrap">
					<div className="balsa-progress">
						<span
							style={{
								width: '60%',
								backgroundColor: '#18D1AE',
							}}
						></span>
					</div>
					{/* <svg
						width="14"
						height="14"
						viewBox="0 0 14 14"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					></svg> */}
					<div className="balsa-img">
						<img src={fluency} className="img" />
					</div>
				</div>
			</div>

			<div className="balsa">
				<span style={{ fontWeight: '500', color: '#3D0F65' }}>Reading</span>
				<div className="progress-wrap">
					<div className="balsa-progress">
						<span
							style={{
								width: '40%',
								backgroundColor: '#CE5783',
							}}
						></span>
					</div>
					{/* <svg
						width="14"
						height="14"
						viewBox="0 0 14 14"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					></svg> */}

					<div className="balsa-img">
						<img src={Vocabulary} className="img" />
					</div>
				</div>
			</div>

			<div className="balsa">
				<span style={{ fontWeight: '500', color: '#3D0F65' }}>Writing</span>

				<div className="progress-wrap">
					<div className="balsa-progress">
						<span
							style={{
								width: '90%',
								backgroundColor: '#8236F9',
							}}
						></span>
					</div>
					{/* 	<svg
						width="14"
						height="14"
						viewBox="0 0 14 14"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<defs>
							<clipPath id="clip0">
								<rect width="14" height="13.9957" fill="white" />
							</clipPath>
						</defs>
					</svg> */}
					<div className="balsa-img">
						<img src={Writing} className="img" />
					</div>
				</div>
			</div>

			<div className="balsa">
				<span style={{ fontWeight: '500', color: '#3D0F65' }}>Listening</span>
				<div className="progress-wrap">
					<div className="balsa-progress">
						<span
							style={{
								width: '80%',
								backgroundImage:
									'linear-gradient(273.5deg, #EE7734 -12.6%, #EF3616 107.81%)',
							}}
						></span>
					</div>
					{/* 	<svg
						width="14"
						height="14"
						viewBox="0 0 14 14"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<defs>
							<clipPath id="clip0">
								<rect width="14" height="13.9957" fill="white" />
							</clipPath>
						</defs>
					</svg> */}
					<div className="balsa-img">
						<img src={Listening} className="img" />
					</div>
				</div>
			</div>
		</div>
	)
}

export default ImproveSection
