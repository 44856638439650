export const SET_ONBOARDING_STATE = 'SET_ONBOARDING_STATE';
export const UPDATE_STEP_INDEX = 'UPDATE_STEP_INDEX';
export const UPDATE_STEPS_GUIDE = 'UPDATE_STEPS_GUIDE';
export const UPDATE_RUN = 'UPDATE_RUN';
export const ADDED_STEPS = 'ADDED_STEPS';

export const addedSteps = (steps) => ({
  type: ADDED_STEPS,
  payload: steps,
});

export const setOnboardingState = (stepIndex, runGuide) => ({
  type: SET_ONBOARDING_STATE,
  payload: { stepIndex, runGuide },
});

export const updateStepIndex = index => ({
  type: UPDATE_STEP_INDEX,
  payload: index,
});

export const updateStepsGuide = stepsGuide => ({
  type: UPDATE_STEPS_GUIDE,
  payload: stepsGuide,
});
export const updateRunGuide = runGuide => ({
  type: UPDATE_RUN,
  payload: runGuide,
});
