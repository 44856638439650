import api from "services/api";

export function getCounsellors() {
  return api.get("/counselor");
}

export function getCounsellorsById(id) {
  return api.get(`/counselor/${id}`);
}

export function getPublicCounsellorsById(id) {
  return api.get(`/public/counselor/${id}`);
}

export function saveCounsellorById(id) {
  return api.put(`/counselor/${id}`);
}

export function makeBooking(id, objArgs) {
  if (typeof objArgs !== "object") {
    throw new Error("missing required parameters");
  }
  return api.post(`/counselor/book/${id}`, JSON.stringify(objArgs));
}

export function getPublicCounselors() {
  return api.get("/public/counselors");
}
