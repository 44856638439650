import { getAllDocuments } from 'api/documentsApi';

export const LOADING_DOCUMENTS_START = 'LOADING_DOCUMENTS_START';
export const LOADING_DOCUMENTS_SUCCESS = 'LOADING_DOCUMENTS_SUCCESS';
export const LOADING_DOCUMENTS_FAILED = 'LOADING_DOCUMENTS_FAILED';

export const CHANGE_DOCUMENT_STATUS = 'CHANGE_DOCUMENT_STATUS';

export const changeDocumentStatusAction = (name, status) => {
  return {
    type: CHANGE_DOCUMENT_STATUS,
    payload: {
      name,
      status,
    },
  };
};

export const loadingDocumentsStartAction = () => {
  return {
    type: LOADING_DOCUMENTS_START,
  };
};

export const loadingDocumentsSuccessAction = documents => {
  return {
    type: LOADING_DOCUMENTS_SUCCESS,
    payload: documents,
  };
};

export const loadingDocumentsFailedAction = e => {
  return {
    type: LOADING_DOCUMENTS_FAILED,
    payload: e,
  };
};

const listOfDocuments = [
  {
    id: 1,
    name: 'Grade X Transcript',
    value: 'gradex',
  },
  {
    id: 2,
    name: 'Grade XII Transcript',
    value: 'gredexii',
  },
  {
    id: 3,
    name: 'Provisional/Final Degree Certificate',
    value: 'degreecert',
  },
  {
    id: 4,
    name: 'Degree Transcript',
    value: 'degree',
  },
  {
    id: 5,
    name: 'Passport',
    value: 'passport',
  },
  {
    id: 6,
    name: 'Resume/CV',
    value: 'resume',
  },
  {
    id: 7,
    name: 'Letter of Recommendations',
    value: 'letterrec',
  },
  {
    id: 8,
    name: 'Statement of Purpose (SOP)',
    value: 'sop',
  },
  {
    id: 9,
    name: 'English Language Certificate Transcript (IELTS, TOEFL, DuoLingo etc)',
    value: 'langcert',
  },
  {
    id: 10,
    name: 'Financial Statement',
    value: 'finstat',
  },
  {
    id: 11,
    name: 'External Credential Evaluation',
    value: 'extcredeval',
  },
];

export const getAllDocs = () => async dispatch => {
  try {
    dispatch(loadingDocumentsStartAction());

    const res = await getAllDocuments();
    dispatch(loadingDocumentsSuccessAction(mergeDocuments(res.data)));
  } catch (error) {
    console.error('Error fetching Documents:', error);

    dispatch(loadingDocumentsFailedAction(error));
  }
};

const mergeDocuments = fetchedDocuments => {
  const mergedDocuments = listOfDocuments.map(listDoc => {
    const fetchedDoc = fetchedDocuments.find(fetchedDoc => fetchedDoc.doc_type === listDoc.value);
    if (fetchedDoc) {
      return {
        ...listDoc,
        status: fetchedDoc.status,
        doc_type: fetchedDoc.doc_type,
      };
    } else {
      return listDoc;
    }
  });

  return mergedDocuments;
};
