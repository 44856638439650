import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { Alert, Button, Col } from 'reactstrap'

const LimitRecordingsAlert = ({ recordingsLeft, visible, setVisible }) => {
	const history = useHistory()
	const [currentVisible, setCurrentVisible] = useState(visible)

	const onDismiss = () => {
		setVisible(false)
		setCurrentVisible(false)
	}

	useEffect(() => {
		if (visible != currentVisible) {
			setCurrentVisible(visible)
		}
	}, [visible])

	return (
		<>
			{recordingsLeft > 60 ? (
				<Alert
					className="d-md-flex justify-content-center align-items-center"
					style={{ backgroundColor: '#F06641' }}
					isOpen={visible}
				>
					<div className="d-flex align-items-center">
						<svg
							className="mr-2"
							width="16"
							height="16"
							viewBox="0 0 16 16"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								d="M16 8C16 12.4193 12.418 16 8 16C3.58203 16 0 12.4193 0 8C0 3.58332 3.58203 0 8 0C12.418 0 16 3.58332 16 8ZM8 9.6129C7.18048 9.6129 6.51613 10.2773 6.51613 11.0968C6.51613 11.9163 7.18048 12.5806 8 12.5806C8.81952 12.5806 9.48387 11.9163 9.48387 11.0968C9.48387 10.2773 8.81952 9.6129 8 9.6129ZM6.59119 4.27916L6.83048 8.66626C6.84168 8.87155 7.01142 9.03226 7.217 9.03226H8.783C8.98858 9.03226 9.15832 8.87155 9.16952 8.66626L9.40881 4.27916C9.4209 4.05742 9.24436 3.87097 9.02229 3.87097H6.97768C6.75561 3.87097 6.5791 4.05742 6.59119 4.27916Z"
								fill="white"
							/>
						</svg>
						<p className="text-white mb-0">
							You have {Math.floor(recordingsLeft / 60)} recording minutes left
							in your plan.
						</p>
					</div>
					<div className="d-flex align-items-center ml-auto upgrade-buttons">
						<Button
							className="mr-2 mr-md-0 ml-md-2"
							color="white"
							style={{
								height: '2rem',
								color: '#F06641',
								lineHeight: '0.5rem',
							}}
							onClick={(e) => {
								e.preventDefault()
								history.push('/admin/plan-information')
							}}
						>
							Upgrade Plan
						</Button>
						<button type="button" onClick={onDismiss} className="text-white">
							Dismiss
						</button>
					</div>
				</Alert>
			) : (
				<Alert className="text-center p-5 mb-0 alert-plan" isOpen={visible}>
					<p className="mb-2 alert-plan__title">
						You have used all free recordings.
					</p>
					<p className="mb-2 alert-plan__subtitle">
						If you want to keep using Kandor to prepare for your tests, you’ll
						have to upgrade.
					</p>

					<Col
						xs="11"
						sm="11"
						md="6"
						lg="5"
						xl="4"
						className="text-center mx-auto my-3 my-md-4"
					>
						<Button
							className="w-100"
							color="info"
							style={{
								height: '3.625rem',
								fontSize: '1.125rem',
								lineHeight: '2.3rem',
							}}
							onClick={(e) => {
								e.preventDefault()
								history.push('/admin/plan-information')
							}}
						>
							Upgrade Plan
						</Button>
					</Col>
					<button type="button" onClick={onDismiss} className="alert-plan__btn">
						I don’t want to upgrade
					</button>
				</Alert>
			)}
		</>
	)
}

export default LimitRecordingsAlert
