import axios from "axios";
const instance = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}`,
  withCredentials: true,
  
  headers: {
    "Content-Type": "application/json",
    "Referrer-Policy": "strict-origin-when-cross-origin",
  },
});
export default instance;