import { getArticles, getArticlesCategories } from 'actions/articlesAction';
import { getAllNews } from 'actions/newsAction';
import { getAllSops, getPersonalData } from 'actions/sopsAction';
import { getLimitClasses, getSchedule } from 'api/classesApi';
import { getStudyCards } from 'api/ieltsApi';
import { getVersion } from 'api/maintenanceApi';
import { getselfActivitySummary, getSelfDetails, updateIeltsUserProfile, updateSelfSettings } from 'api/userApi';
import 'assets/scss/newcss/style.scss';
import Live from 'kandor-components/components/Classes/Live/Live';
import Next from 'kandor-components/components/Classes/Next/Next';
import ModalCache from 'kandor-components/ModalCache/ModalCache';
import ReferralWidget from 'kandor-components/ReferralWidget/ReferralWidget';
import ScoreBoard from 'kandor-components/ScoreBoard';
import mixpanel from 'mixpanel-browser';
import moment from 'moment';
import React, { useEffect, useMemo, useRef, useState } from 'react';
//tabs
import Nav from 'react-bootstrap/Nav';
import Tab from 'react-bootstrap/Tab';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import MetaTags from 'react-meta-tags';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { useHistory } from 'react-router-dom';
// react plugin used to create charts
// reactstrap components
import { Button, Col, Input, Modal } from 'reactstrap';
import { fetchState } from '../actions';
import { ReactComponent as DateTest } from '../assets/icons/date.svg';
import { ReactComponent as WhatsAppIcon } from '../assets/kandor-img/whatsapp.svg';
import '../kandor-components/newChanges/Dashboard-Video.scss';
import '../pages/redesign/redesign.scss';
//svg
import Edit from '../svgIcon/Edit';
import lazyRetry from './../utils/lazyRetry';
import './styles/myIelts.css';
import './styles/StartIelts.scss';
import './styles/WritingQuestions.css';
import LiveStage from 'kandor-components/Webinar/LiveStage/LiveStage';
import Banner from 'kandor-components/components/Banner/Banner';
import Counsellors from 'kandor-components/components/Counsellors/Counsellors';
import Joyride, { ACTIONS, EVENTS, STATUS, LIFECYCLE } from 'react-joyride';
import GuideModal from 'kandor-components/components/GuideModal/GuideModal';
import { addedSteps } from 'actions/onBoardingAction';
import { setOnboardingState } from 'actions/onBoardingAction';
import { updateStepIndex } from 'actions/onBoardingAction';
import { updateTubIndex } from 'actions/myIeltsTubActions';
import { ReactComponent as PracticeIcon } from '../assets/icons/onboarding/pen.svg';
import { ReactComponent as ClassesIcon } from '../assets/icons/onboarding/laptop.svg';
import { ReactComponent as SopIcon } from '../assets/icons/onboarding/note.svg';
import { ReactComponent as StudyIcon } from '../assets/icons/onboarding/man.svg';
import GuideSpinner from 'kandor-components/components/GuideModal/GuideSpinner/GuideSpinner';
import ShortlisterBanner from 'kandor-components/ShortlisterBanner/ShortlisterBanner';
import { SopBanner } from 'kandor-components/SopBanner/SopBanner';
import ApplyBanner from 'kandor-components/ApplyBanner/ApplyBanner';
import { InfoStudyAbroadBanner } from 'kandor-components/InfoStudyAbroadBanner/InfoStudyAbroadBanner';
import WebinarPopup from 'kandor-components/WebinarBanner/WebinarBanner';
import { filteredWebinarsForToday } from 'selectors/filteredWebinarsSelection';
import MobileShortlisterBanner from 'kandor-components/ShortlisterBanner/MobileShortlisterBanner';

// lazy Load
const ModalWindow = React.lazy(() => lazyRetry(() => import('kandor-components/Modal/ModalWindow'), 'ModalWindow'));

const Profile = React.lazy(() =>
  lazyRetry(() => import('./../kandor-components/components/Profile/Profile'), 'Profile')
);

const Learn = React.lazy(() => lazyRetry(() => import('./../kandor-components/components/Learn/Learn'), 'Learn'));

const Practice = React.lazy(() =>
  lazyRetry(() => import('./../kandor-components/components/Practice/Practice'), 'Practice')
);

const Articles = React.lazy(() =>
  lazyRetry(() => import('./../kandor-components/components/Articles/Articles'), 'Articles')
);

const Classes = React.lazy(
  () => lazyRetry(() => import('./../kandor-components/components/Classes/Classes.jsx')),
  'Classes'
);

const News = React.lazy(() => lazyRetry(() => import('./../kandor-components/components/News/News'), 'News'));

const MyIelts = ({ openChatHandler, sendChatMessageHandler, toggleSidebar }) => {
  const Mobile = useMediaQuery({ query: '(max-width: 768px)' });
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });
  const isDesktop = useMediaQuery({ query: '(min-width: 1200px)' });
  const history = useHistory();
  const webinarsByToday = useSelector(filteredWebinarsForToday);
  const news = useSelector(state => state.news.news);
  const articles = useSelector(state => state.articles.articles);
  const sops = useSelector(state => state.sops.sops);
  const personality = useSelector(state => state.sops.personality);
  const wishlist = useSelector(state => state.wishlist.wishlist);

  const [showModalWithDate, setShowModalWithDate] = useState(false);

  // banner
  const tubIndex = useSelector(state => state.myIeltsTub.tubIndex);

  const [show, setShow] = useState(false);
  const [active, setActive] = useState(tubIndex ? tubIndex : 'second');
  const TokenId = useSelector(state => state.auth.TokenId);
  const dispatch = useDispatch();
  const [speakingScore, setSpeakingScore] = useState(0);
  const [listeningScore, setListeningScore] = useState(0);
  const [readingScore, setReadingScore] = useState(0);
  const [writingScore, setWritingScore] = useState(0);
  const [isLoadingScore, setIsLoadingScore] = useState(true);
  const [isNewUser, setIsNewUser] = useState(null);
  const [isNewUserTest, setIsNewUserTest] = useState(null);

  const [plannedDate, setPlannedDate] = useState(false);
  const [intervalDate, setIntervalDate] = useState(false);
  const [currentDate, setCurrentDate] = useState(false);
  const [aspirationalIeltsScore, setaspirationalIeltsScore] = useState('');

  const [dreamCountry, setDreamCountry] = useState('');
  const [review, setReview] = useState(null);
  const [isLoadingDetails, setIsLoadingDetails] = useState(true);

  const [isPremium, setIsPremium] = useState(false);
  const [userName, setUserName] = useState(null);
  const [newName, setNewName] = useState('');

  const [studyData, setStudyData] = useState([]);
  const [dashboardVideo, setDashboardVideo] = useState();
  const [dashboardList, setDashboardList] = useState([]);

  const [isTimeDashboardVideo, setIsTimeDashboardVideo] = useState(false);

  const [isEditName, setIsEditName] = useState(false);

  const [isQuestionModal, setIsQuestionModal] = useState(false);
  const [city, setCity] = useState('');
  const [source, setSource] = useState('');

  // state for showing cache modal
  const [showCache, setShowCache] = useState(false);
  const hasTracked = useRef(false);

  useEffect(() => {
    const location = history?.location;
    if (!location) return;
    if (location.pathname.includes('newuser')) {
      setIsNewUser(true);
      if (!hasTracked.current) {
        mixpanel.track('Redirecting new user');
        hasTracked.current = true;
      }
    } else {
      setIsNewUser(false);
      hasTracked.current = false;
    }
    if (location.pathname.includes('newuser_test')) {
      /* Not required here - this is already being done on admin  page */
      // clevertap.notifications.push({
      //   titleText: 'Would you like to receive Push Notifications?',
      //   bodyText: 'We promise to only send you relevant content and help you with your study abroad journey!',
      //   okButtonText: 'Sign me up!',
      //   rejectButtonText: 'No thanks',
      //   okButtonColor: '#f28046',
      //   askAgainTimeInSeconds: 1,
      // });
      setIsNewUserTest(true);
    } else {
      setIsNewUserTest(false);
    }
  }, [history]);

  function isBlank(str) {
    return !str || /^\s*$/.test(str);
  }

  const roundingScore = score => {
    let round = Math.floor(score);
    if (score - round < 0.3) {
      return round;
    } else if (score - round < 0.8) {
      return round + 0.5;
    } else return round + 1;
  };

  //redux

  useEffect(() => {
    dispatch(fetchState());
  }, []);

  const generateInsertId = () => {
    const timestamp = new Date().toISOString(); // Current timestamp
    const uniqueIdentifier = Math.random().toString(36).substr(2, 9); // Unique identifier

    // Combine timestamp and unique identifier to create the insert_id
    const insertId = `${timestamp}-${uniqueIdentifier}`;

    return insertId;
  };

  const isFirstRender = useRef(true);

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      const insertId = generateInsertId();
      if (isMobile) {
        mixpanel.track('On mobile dashboard page', { insert_id: insertId, page: isNewUser ? 'newuser' : 'dashboard' });
      } else {
        mixpanel.track('On desktop dashboard page', { insert_id: insertId, page: isNewUser ? 'newuser' : 'dashboard' });
      }
      return;
    }
  }, []);

  useEffect(() => {
    if (news.length === 0) {
      dispatch(getAllNews());
    }
  }, [dispatch, news.length]);

  useEffect(() => {
    if (articles.length === 0) {
      dispatch(getArticles());
      dispatch(getArticlesCategories());
    }
  }, [articles.length, dispatch]);

  useEffect(() => {
    if (sops.length === 0) {
      dispatch(getAllSops());
    }
  }, [dispatch, sops.length]);

  useEffect(() => {
    if (!personality) {
      dispatch(getPersonalData());
    }
  }, [dispatch, personality]);

  //redux end
  useEffect(() => {
    getVersion()
      .then(res => res.data)
      .then(res => {
        if (res.data !== process.env.REACT_APP_VERSION) {
          mixpanel.track('Detected version change', {
            newversion: res.data,
            page: isNewUser ? 'newuser' : 'dashboard',
          });
          setShowCache(true);
        }
      });
  }, []);

  useEffect(() => {
    if (!isBlank(TokenId))
      getselfActivitySummary({})
        .then(res => res.data)
        .then(json => {
          setSpeakingScore(roundingScore(json.find(x => x.name === 'speaking').score));
          setListeningScore(roundingScore(json.find(x => x.name === 'listening').score));
          setReadingScore(roundingScore(json.find(x => x.name === 'reading').score));
          setWritingScore(roundingScore(json.find(x => x.name === 'writing').score));

          setIsLoadingScore(false);
        })
        .catch(() => {});
  }, [TokenId]);

  useEffect(() => {
    if (TokenId !== null && TokenId != '')
      getSelfDetails({})
        .then(res => res.data)
        .then(qdata => {
          setIsPremium(qdata.tier != 'free');
          setPlannedDate(qdata.profile.PlannedDate);
          if (qdata.profile.PlannedDate) {
            const startDate = new Date();
            const endDate = new Date(qdata.profile.PlannedDate);

            setDateCalendar(moment(endDate).format('MMM D YYYY'));

            const timeDiff = endDate.getTime() - startDate.getTime();

            const daysDifference = Math.floor(timeDiff / (1000 * 60 * 60 * 24));
            setDayCount(daysDifference);
          }
          setIntervalDate(qdata.profile.IntervalIelts);
          setDreamCountry(qdata.profile.DreamCountry);
          setUserName(qdata.userName);
          if (qdata.profile.aspirational_ielts_speaking_band) {
            setaspirationalIeltsScore(qdata.profile.aspirational_ielts_speaking_band);
          } else {
            setaspirationalIeltsScore('7.5');
          }
          setReview(qdata.review);
          setIsLoadingDetails(false);
        })
        .catch(res => console.log(res));
  }, [TokenId]);

  useEffect(() => {
    if (plannedDate) {
      const dateNow = new Date().getTime();
      const currentTime = new Date(plannedDate).getTime();
      const difference = currentTime - dateNow;
      const days = Math.floor(difference / (1000 * 60 * 60 * 24));
      setCurrentDate(days);
    }
  }, [plannedDate]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setIsTimeDashboardVideo(true);
      // console.log("time");
    }, 5000);

    return () => clearTimeout(timeout);
  }, []);

  useEffect(() => {
    getStudyCards({})
      .then(res => res.data)
      .then(data => {
        const newData = [...data];
        newData.forEach(el => {
          el.metadata = JSON.parse(el.metadata);
        });
        setStudyData(newData);
      });
  }, []);

  const compare = (a, b) => {
    const numA = a.metadata.videoNo;
    const numB = b.metadata.videoNo;

    let comparison = 0;
    if (numA > numB) {
      comparison = 1;
    } else if (numA < numB) {
      comparison = -1;
    }
    return comparison;
  };

  useEffect(() => {
    //to change
    const firstVideo = JSON.parse(JSON.stringify(studyData)).filter(el => el.contentCategory == 'Dashboard');

    const sortDashboard = firstVideo.sort(compare);

    setDashboardList(sortDashboard);
    setDashboardVideo(sortDashboard[0]);
  }, [studyData]);

  useEffect(() => {
    if (isTimeDashboardVideo && dashboardVideo && !dashboardVideo?.isVisited) {
      setIsTimeDashboardVideo(false);
    }
  }, [isTimeDashboardVideo, dashboardVideo]);

  useEffect(() => {
    if (!currentDate && !intervalDate) return;
    if (
      (currentDate && currentDate <= 30) ||
      (intervalDate && (intervalDate == 'In less than 30 days' || intervalDate == 'Yes, I have!'))
    ) {
      setDashboardVideo(dashboardList[0]);
    } else {
      setDashboardVideo(dashboardList[1]);
    }
  }, [currentDate, intervalDate, dashboardList]);

  const goToIelts = () => {
    mixpanel.track('Clicked on explore all benefits on dashboard', { page: isNewUser ? 'newuser' : 'dashboard' });
    history.push('/admin/practice');
  };

  const [whatsAppLink, setWhatsAppLink] = useState('');

  useEffect(() => {
    if (dreamCountry) {
      switch (dreamCountry) {
        case 'USA':
          setWhatsAppLink('https://chat.whatsapp.com/DbWCESDTWEtHeKifNzQaUg');
          break;
        case 'UK':
          setWhatsAppLink('https://chat.whatsapp.com/JTZtt1L4tqDENVWIg6QimT');
          break;
        case 'Canada':
          setWhatsAppLink('https://chat.whatsapp.com/Iu1adcc8eIbKCUAWlTpZXt');
          break;
        case 'Other':
          setWhatsAppLink('https://chat.whatsapp.com/G1a7q3XCvWeLT7Y2fwBWAv');
          break;
        default:
          setWhatsAppLink('https://chat.whatsapp.com/G1a7q3XCvWeLT7Y2fwBWAv');
          break;
      }
    }
  }, [dreamCountry]);

  useEffect(() => {
    if (isPremium) {
      const sessionId = sessionStorage.getItem('tabIDPremium');
      const numSession = localStorage.getItem('numSessionPremium');
      if (sessionId) return;
      const currentSession = numSession ? +numSession + 1 : 1;
      const newId = Math.random() * 100000;
      sessionStorage.setItem('tabIDPremium', newId);
      localStorage.setItem('tabIDPremium', newId);
      localStorage.setItem('numSessionPremium', currentSession);

      const showQuestionsPremium = sessionStorage.getItem('showed_question_premium');
      const isAnswerQuestionPremium = localStorage.getItem('answer_question_premium');

      if (
        (currentSession == 2 || currentSession == 4 || currentSession == 6) &&
        !showQuestionsPremium &&
        !isAnswerQuestionPremium
      ) {
        sessionStorage.setItem('showed_question_premium', true);
        setIsQuestionModal(true);
      }
    }
  }, [isPremium]);

  const closeQuestionsModal = () => {
    setIsQuestionModal(!isQuestionModal);
  };

  const submitQuestionsAnswer = () => {
    updateIeltsUserProfile({
      city: city,
      source: source,
    }).then(res => {
      localStorage.setItem('answer_question_premium', true);
      setIsQuestionModal(false);
    });
  };

  const changeCity = ({ target }) => {
    setCity(target.value);
  };

  const changeSource = ({ target }) => {
    if (target.checked) {
      setSource(target.value);
    }
  };
  const [dateCalendar, setDateCalendar] = useState(null);

  const [timeNow, setTimeNow] = useState(null);

  const [dayCount, setDayCount] = useState(0);

  const handleChangeDateCalendar = time => {
    setTimeNow(time);
    setDateCalendar(moment(time).format('MMM D YYYY'));

    const startDate = new Date();
    const endDate = new Date(time);

    const timeDiff = endDate.getTime() - startDate.getTime();

    const daysDifference = Math.floor(timeDiff / (1000 * 60 * 60 * 24));
    setDayCount(daysDifference);
    updateIeltsUserProfile({
      PlannedDate: moment(time).format('MMM D YYYY'),
    })
      .then(res => {
        setShowModalWithDate(false);
      })
      .catch(res => {});
  };

  const changeName = async () => {
    mixpanel.track(`Changed name on Profile Page`, { page: isNewUser ? 'newuser' : 'dashboard' });
    if (newName.trim().length) {
      await updateSelfSettings({ name: newName }).then(res => {
        setUserName(newName);
        setIsEditName(false);
      });
    } else {
      setIsEditName(false);
    }
  };

  const handleChangeName = ({ target }) => {
    setNewName(target.value);
  };

  const [activeClasses, setActiveClasses] = useState([]);
  const [nextClass, setNextClass] = useState([]);
  const [upcomingClasses, setUpcomingClasses] = useState([]);
  const [nextClassTime, setNextClassTime] = useState('');
  const [refreshClasses, setRefreshClasses] = useState(false);
  // Added typeFilter state
  const [freeLimit, setFreeLimit] = useState(0);
  const [premium, setPremium] = useState(false);
  const [timeDifference, setTimeDifference] = useState('');

  //Webinar

  useEffect(() => {
    getLimitClasses()
      .then(res => res.data)
      .then(res => {
        if (res.limit === 'Premium') {
          setPremium(true);
        } else {
          setFreeLimit(res.limit);
        }
      })
      .catch(e => {
        console.log(e);
      });
    // Simulate API call to fetch classes
    getSchedule()
      .then(res => res.data)
      .then(res => {
        filterClasses(res);
      })
      .catch(error => {});

    // Refresh active classes every 20 minutes
    const interval = setInterval(() => {
      setRefreshClasses(!refreshClasses);
    }, 20 * 60 * 1000);

    // Cleanup interval on component unmount
    return () => clearInterval(interval);
  }, []);

  function filterClasses(resClasses) {
    const now = moment().tz('Asia/Kolkata');
    const active = [];
    const next = [];
    const upcoming = [];
    let foundNextClass = false; // Flag to track if we've found the next class

    resClasses.forEach(cls => {
      // Convert the received time to IST
      const startTime = moment.tz(
        cls.startTime.replace('GMT', '+0530'),
        'ddd, DD MMM YYYY HH:mm:ss ZZ',
        'Asia/Kolkata'
      );
      const endTime = startTime.clone().add(1, 'hours');
      const bufferTime = startTime.clone().subtract(1, 'seconds');

      if (now.isBetween(bufferTime, endTime)) {
        active.push(cls);
      } else {
        // Check if the class starts within the next 4 hours only if it's not active
        const fourHoursFromNow = now.clone().add(4, 'hours');
        if (startTime.isBefore(fourHoursFromNow)) {
          // Add the first class that meets the condition to the 'next' array
          if (!foundNextClass) {
            next.push(cls);
            setNextClassTime(next[0].startTime);
            foundNextClass = true; // Set the flag to true
          } else {
            upcoming.push(cls); // Add the rest to the 'upcoming' array
          }
        } else {
          upcoming.push(cls);
        }
      }
    });

    setActiveClasses(active);
    setNextClass(next);
    setUpcomingClasses(upcoming);
  }
  function updateTimeDifference() {
    // Convert the start time to IST timezone

    const startTimeF = moment.tz(
      nextClassTime && nextClassTime.replace('GMT', '+0530'),
      'ddd, DD MMM YYYY HH:mm:ss ZZ',
      'Asia/Kolkata'
    );

    // Get the current time in IST timezone

    const currentTimeIST = moment().tz('Asia/Kolkata');

    const duration = moment.duration(currentTimeIST.diff(startTimeF));

    const hours = Math.abs(duration.hours());
    const minutes = Math.abs(duration.minutes());
    const seconds = Math.abs(duration.seconds());

    let timeDifferenceString = '';

    if (hours > 0) {
      timeDifferenceString += `${hours}h `;
    }

    if (minutes > 0 || hours > 0) {
      timeDifferenceString += `${minutes}m `;
    }

    timeDifferenceString += `${seconds}s`;

    setTimeDifference(timeDifferenceString);
    if (timeDifferenceString === '0s') {
      getSchedule()
        .then(res => res.data)
        .then(res => {
          filterClasses(res);
        })
        .catch(error => {});
    }
  }

  useEffect(() => {
    if (nextClassTime) {
      const interval = setInterval(updateTimeDifference, 1000);

      return () => clearInterval(interval);
    }
  }, [nextClassTime]);

  const activeClassesMemo = useMemo(() => activeClasses, [activeClasses]);
  const activeClassesLength = activeClassesMemo.length;

  //onboarding

  const onboarding = useSelector(state => state.onBoarding);
  const practiceRef = useRef(null);
  const [isFirstLoad, setIsFirstLoad] = useState(true);

  const [widthPractice, setWidthPractice] = useState(0);
  function debounce(func, wait) {
    let timeout;
    return function executedFunction(...args) {
      clearTimeout(timeout);
      timeout = setTimeout(() => {
        func(...args);
      }, wait);
    };
  }

  function smoothScrollToElement(selector, callback) {
    const element = document.querySelector(selector);
    if (element) {
      const scrollBlock = element.closest('.white-content');
      const topEl = element.getBoundingClientRect().top;
      let scrollVal = topEl - 60;

      if (isMobile) {
        scrollVal = topEl - 50 - 70;
      }

      // Bugfix from Mixpanel - null is not an object (evaluating 'e.scrollBy')
      if (scrollBlock) {
        scrollBlock.scrollBy({
          left: 0,
          top: scrollVal,
          behavior: 'smooth',
        });
      }

      // Estimate scrolling completion time (adjust based on typical behavior)
      setTimeout(callback, 700); // 1000ms = 1s for example
    }
  }

  const [isHaveClickOverlay, setIsHaveClickOverlay] = useState(false);

  const handleJoyrideCallback = ({ action, index, status, type, lifecycle, step }) => {
    const shouldCloseOnboarding = [STATUS.FINISHED, STATUS.SKIPPED].includes(status) || ACTIONS.CLOSE.includes(action);
    const spotlight = document.querySelector('.react-joyride__spotlight');
    const body = document.querySelector('.__floater__body');
    const spinner = document.querySelector('[class^="GuideSpinner"]');

    const joyrideOverlay = document.querySelector('.react-joyride__overlay');

    if (joyrideOverlay && !isHaveClickOverlay) {
      setIsHaveClickOverlay(true);
      joyrideOverlay.addEventListener('click', e => {
        if (e.target.closest('.react-joyride__spotlight')) {
          e.stopPropagation();
          mixpanel.track('Clicked on highlighted area on Guide');
        }
      });
    }

    if (ACTIONS.CLOSE.includes(action)) {
      mixpanel.track('Closed Guide from overlay');
    }

    // Function to hide the spotlight
    const hideSpotlight = () => {
      if (spotlight) {
        spotlight.style.opacity = '0';
        spotlight.style.transition = 'opacity 0.2s ease-in';
        console.log('Hiding now');
      }
    };
    const hideSpinner = () => {
      if (spinner) {
        spinner.classList.remove('active');
        console.log('hideSpinner');
      }
    };

    const showSpinner = () => {
      if (spinner) {
        spinner.classList.add('active');
        console.log('showSpinner');
      }
    };

    const hideElements = () => {
      hideSpotlight();
    };

    // Hide the spotlight immediately on step change to mitigate flicker
    if (['next', 'prev'].includes(action) || ACTIONS.UPDATE.includes(action) || type === EVENTS.TOOLTIP) {
      hideElements();

      if (index === 2 || index === 3) {
        showSpinner();
      }
    }

    if (index == 0 && lifecycle === LIFECYCLE.COMPLETE) {
      // console.log(document.querySelector("#classes-guide"))
      // document.querySelector("#classes-guide").scrollIntoView({
      //   behavior: "smooth",
      // });
    }

    if (shouldCloseOnboarding) {
      dispatch(setOnboardingState(0, false));
      hideSpinner();
      return;
    }

    if (type === EVENTS.STEP_AFTER) {
      if (
        !isMobile &&
        !isDesktop &&
        ((action === 'next' && (onboarding.stepIndex === 3 || onboarding.stepIndex === 1)) ||
          (action === 'prev' && onboarding.stepIndex === 2))
      ) {
        toggleSidebar();

        if (onboarding.stepIndex === 3) {
          dispatch(setOnboardingState(0, false));
        } else {
          setTimeout(() => dispatch(updateStepIndex(index + (action === 'prev' ? -1 : 1))), 700);
        }
      } else {
        setTimeout(() => dispatch(updateStepIndex(index + (action === 'prev' ? -1 : 1))), 300);
      }
    }

    if (isFirstLoad) {
      setIsFirstLoad(false);
    }

    // Function to show the spotlight
    const showSpotlight = () => {
      if (spotlight) {
        console.log('Showing now');
        spotlight.style.opacity = '1';
        spotlight.style.transition = 'opacity 0.2s ease-in';
      }
    };

    const showElements = () => {
      showSpotlight();
      hideSpinner();
    };

    // Adjusted debounce function specifically for showing the spotlight
    const debouncedShowSpotlight = debounce(showElements, 100); // Debounce time adjusted for your scenario

    if (lifecycle === LIFECYCLE.READY) {
      // Instead of immediate scrollIntoView, use the smoothScrollToElement function
      smoothScrollToElement(step.target, () => {
        // Callback after scrolling completes
        if (index == 1) {
          console.log('Scrolling completed, waiting to show spotlight.');
          debouncedShowSpotlight();
        } else {
          console.log('No need for delay, showing spotlight.');
          showElements();
        }
      });
    }

    if (body) {
      if (onboarding.stepIndex === 0) {
        body.setAttribute('style', '--delay: 0.4s');
        //body.style['--delay'] = 0.4;
      } else if (onboarding.stepIndex === 1) {
        body.setAttribute('style', '--delay: 0.2s');
        //body.style['--delay'] = 0.3;
      } else if (onboarding.stepIndex === 2 || onboarding.stepIndex === 3) {
        body.setAttribute('style', '--delay: 0.7s');
        //body.style['--delay'] = 0.7;
      }
    }

    console.log('Spotlight:', spotlight);
    console.log('Action:', action);
    console.log('ACTIONS:', ACTIONS);
    console.log('EVENTS:', EVENTS);
    console.log('type', type);
    console.log('status', status);
    console.log('index', index);
    console.log('step', step);
    console.log('lifecyle', lifecycle);
  };

  const getWidthPractice = () => {
    let width;
    if (practiceRef.current) {
      width = practiceRef.current.getBoundingClientRect().width;
    }

    return width;
  };
  //
  useEffect(() => {
    let timeout;
    if (practiceRef.current && widthPractice && (isNewUserTest || isNewUser)) {
      //  dispatch(updateRunGuide(true));
      timeout = setTimeout(() => {
        dispatch(setOnboardingState(0, true));
        mixpanel.track('Show Guide');
      }, 4000);
    }

    return () => {
      clearTimeout(timeout);
    };
  }, [dispatch, widthPractice, isNewUserTest, isNewUser]);

  useEffect(() => {
    let interval;
    dispatch(
      addedSteps([
        {
          target: '.practice_container',
          content: 'Curated modules that helps you secure 7+ Band in IELTS ',
          disableBeacon: true,
          placement: 'top',
          text: 'Ielts test has 4 different sections that you’re judged on and we have mock tests with AI based instant feedback to help you practise and improve at your fingertips',
          icon: <PracticeIcon />,
          isFirstLoad,
          delay: 0.4,
        },
        {
          placement: 'top',
          target: '#classes-guide',
          // disableBeacon: true,
          content: 'Attend free Classes by Industry Experts!',
          text: 'Live interactive classes where you can clear your personal doubts and learn with peers',
          icon: <ClassesIcon />,
          delay: 0.2,
        },
        {
          target: isMobile ? '.footer-admin__item.aisop-menu' : '.nav__link.SOP',
          content: 'Craft Standout College SOP and Visa essay in just 2 minutes',
          text: 'When you’re ready to sail the ship, generate a SOP or VISA essay with fun.',
          placement: 'top',
          icon: <SopIcon />,
          delay: 0.6,
        },
        {
          target: isMobile ? '.footer-admin__item.counsellors-menu' : '.nav__link.Abroad',
          content: 'Connect with Expert Counsellors at Kandor',
          text: 'Expert guidance every Step of the way: Clearing doubts, Finding the Right Path, and making your study abroad experience effortless',
          placement: 'top',
          icon: <StudyIcon />,
          delay: 0.5,
        },
      ])
    );
    if (!widthPractice) {
      interval = setInterval(() => {
        const width = getWidthPractice();

        if (width) {
          setWidthPractice(width);
          clearInterval(interval);
        }
      }, 500);
    }

    return () => {
      clearInterval(interval);
    };
  }, []);

  const handleShow = () => {
    setShowModalWithDate(true);
    mixpanel.track('Clicked on change IELTS date on dashboard');
  };

  const goToShorlitr = () => {
    history.push('/admin/shortlister');
  };

  const [showPopup, setShowPopup] = useState(false);

  useEffect(() => {
    const checkTimeAndStartTimer = () => {
      const now = new Date();
      const hours = now.getHours();
      console.log('hours', hours);

      const startHour = 20; // 8 PM
      const endHour = 22; // 10 PM

      const popupClosed = sessionStorage.getItem('webinarPopupClosed') === 'true';

      if (hours >= startHour && hours < endHour && !popupClosed) {
        const timerId = setTimeout(() => {
          setShowPopup(true);
        }, 5000);

        return () => clearTimeout(timerId);
      }
    };

    checkTimeAndStartTimer();
  }, []);

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  return (
    <>
      <ModalWindow show={show} setShow={setShow} />
      <MetaTags>
        <title>
          The best-in-industry IELTS Prep Tool in the world, powered by Artificial Intelligence, and trusted by
          teachers.
        </title>
      </MetaTags>

      <GuideSpinner />

      <Joyride
        steps={onboarding.stepsGuide}
        stepIndex={onboarding.stepIndex}
        continuous
        run={onboarding.runGuide}
        showSkipButton={true}
        callback={handleJoyrideCallback}
        scrollToSteps={true}
        disableScrolling={true}
        tooltipComponent={GuideModal}
        scrollOffset={isMobile ? 70 : 0}
        spotlightPadding={6}
        styles={{
          options: {
            zIndex: 10000,
          },
          overlay: {
            height: '100%',
          },
          spotlight: {
            borderRadius: '10px',
            border: '1px solid #a7450e ',
          },
        }}
      />

      <div className="content-myielts content content--footer">
        <Button className="whatsapp-btn" color="info" href={whatsAppLink}>
          {!isMobile ? <span>Join</span> : ''}
          <span className="whatsapp-btn__icon">
            <WhatsAppIcon />
          </span>
        </Button>
        {!!webinarsByToday.length && showPopup && <WebinarPopup handleClosePopup={handleClosePopup} />}
        <ModalCache showCache={showCache} setShowCache={setShowCache} />

        <Modal
          className="calendar-info-modal"
          isOpen={showModalWithDate}
          style={{ borderRadius: '12px' }}
          toggle={() => {
            setShowModalWithDate(false);
          }}>
          <DatePicker selected={timeNow} onChange={handleChangeDateCalendar} minDate={new Date()} withPortal inline />
        </Modal>

        <>
          <Modal isOpen={isQuestionModal} toggle={closeQuestionsModal} className="question-modal">
            <div className="question-modal__wrap">
              <button
                type="button"
                className="modal-close"
                onClick={() => setIsQuestionModal(!isQuestionModal)}></button>
              <span className="question-modal__title">Help us with a little more info to serve you better.</span>
              <div className="question-modal__inner">
                <span className="question-modal__label">1. Which city do you live in?</span>
                <Input placeholder="City" type="text" onChange={changeCity} className="form-control" />
              </div>
              <div className="question-modal__inner">
                <span className="question-modal__label">2. How did you hear about us?</span>

                <div>
                  <Col xs="12" className="mb-3">
                    <label className="radio-btn" for="hear-us-1">
                      <input
                        className="radio-btn__input"
                        type="radio"
                        id="hear-us-1"
                        name="hear-us"
                        value="Google Ads"
                        onChange={changeSource}
                      />
                      <span className="radio-btn__content">
                        <span className="radio-btn__check">
                          <span></span>
                        </span>
                        <span className="radio-btn__text">Google Ads</span>
                      </span>
                    </label>
                  </Col>
                  <Col xs="12" className="mb-3">
                    <label className="radio-btn" for="hear-us-2">
                      <input
                        className="radio-btn__input"
                        type="radio"
                        id="hear-us-2"
                        name="hear-us"
                        value="Instagram Ads"
                        onChange={changeSource}
                      />
                      <span className="radio-btn__content">
                        <span className="radio-btn__check">
                          <span></span>
                        </span>
                        <span className="radio-btn__text">Instagram Ads</span>
                      </span>
                    </label>
                  </Col>
                  <Col xs="12" className="mb-3">
                    <label className="radio-btn" for="hear-us-3">
                      <input
                        className="radio-btn__input"
                        type="radio"
                        id="hear-us-3"
                        name="hear-us"
                        value="From a friend/peer"
                        onChange={changeSource}
                      />
                      <span className="radio-btn__content">
                        <span className="radio-btn__check">
                          <span></span>
                        </span>
                        <span className="radio-btn__text">From a friend/peer</span>
                      </span>
                    </label>
                  </Col>
                  <Col xs="12">
                    <label className="radio-btn" for="hear-us-4">
                      <input
                        className="radio-btn__input"
                        type="radio"
                        id="hear-us-4"
                        name="hear-us"
                        value="Through own research"
                        onChange={changeSource}
                      />
                      <span className="radio-btn__content">
                        <span className="radio-btn__check">
                          <span></span>
                        </span>
                        <span className="radio-btn__text">Through own research</span>
                      </span>
                    </label>
                  </Col>
                </div>
              </div>
              <div className="question-modal__footer">
                <div className="question-modal__col">
                  <Button className="question-modal__btn " color="info" onClick={submitQuestionsAnswer}>
                    Submit
                  </Button>
                </div>
              </div>
            </div>
          </Modal>

          <div className="left__side">
            <div className="left__side-container">
              {Mobile && (
                <Tab.Container defaultActiveKey="second">
                  <div className="mobile_tab-container">
                    <Nav
                      onSelect={selectedKey => {
                        setActive(selectedKey);
                        dispatch(updateTubIndex(selectedKey));
                      }}
                      style={{
                        borderRadius: '20px',
                        background: '#FFF',
                        boxShadow: ' 0px 6px 15px 0px rgba(0, 0, 0, 0.08)',
                      }}>
                      <Nav.Item>
                        <Nav.Link
                          className="tab__link"
                          eventKey="first"
                          onClick={() =>
                            mixpanel.track('Click on IELTS tab on mobile dashboard', {
                              page: isNewUser ? 'newuser' : 'dashboard',
                            })
                          }>
                          <img style={{ marginRight: '6px' }} alt="IELTS" src={require('../assets/img/IELTS.png')} />
                          IELTS
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link
                          className="tab__link"
                          eventKey="second"
                          onClick={() =>
                            mixpanel.track('Click on Study Abroad tab on mobile dashboard', {
                              page: isNewUser ? 'newuser' : 'dashboard',
                            })
                          }>
                          <img
                            style={{ marginRight: '6px' }}
                            alt="study abroad"
                            src={require('../assets/img/studyAbroad.png')}
                          />
                          Study Abroad
                        </Nav.Link>
                      </Nav.Item>
                    </Nav>
                  </div>

                  <Tab.Content animation="true">
                    <Tab.Pane eventKey="first">
                      <div style={{ padding: '16px' }}>
                        <div className="myielts_tab mb-4">
                          <div className="hello home">
                            {!isEditName ? (
                              <h3 className="hello__user d-flex align-items-center">Hi!</h3>
                            ) : (
                              <div style={{ display: 'flex' }}>
                                <Input type="text" placeholder={userName} onInput={handleChangeName} />
                                <Button className="profile__btn" onClick={changeName}>
                                  Done
                                </Button>
                              </div>
                            )}
                            <p className="hello__desc">
                              Your path to study abroad success with AI-powered prep & counseling
                            </p>
                          </div>

                          <ScoreBoard
                            style={{ margin: '15px' }}
                            speakingScore={speakingScore}
                            writingScore={writingScore}
                            listeningScore={listeningScore}
                            readingScore={readingScore}
                            withButton={true}
                            isLoading={isLoadingScore}
                            goalScore={aspirationalIeltsScore}
                            review={review}
                            isLoadingDetails={isLoadingDetails}
                          />

                          <button onClick={() => goToIelts()} className="explore">
                            Explore our AI IELTS practice tool
                          </button>
                        </div>

                        <div className="mb-4">
                          <div className="practice-guide" ref={practiceRef}>
                            <Practice />
                          </div>
                        </div>

                        <div className="testDate">
                          <div className="testDate_card">
                            <div className="testDate_card-content">
                              <h5 className="testDate_card-title">
                                Days to go for IELTS
                                {dateCalendar !== null ? (
                                  <div
                                    onClick={handleShow}
                                    className="edit_button"
                                    style={{
                                      background: '#573B76',
                                      cursor: 'pointer',
                                    }}>
                                    <Edit color="white" />
                                  </div>
                                ) : (
                                  <></>
                                )}
                              </h5>

                              {dateCalendar === null ? (
                                <button onClick={handleShow} className="testDate_card-button">
                                  <DateTest style={{ marginRight: '8px' }} />
                                  Add IELTS date
                                </button>
                              ) : (
                                <div
                                  style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                  }}>
                                  <h3
                                    className="testDate_card__num"
                                    style={{
                                      marginBottom: 0,
                                      marginRight: '10px',
                                    }}>
                                    {dayCount}
                                  </h3>
                                  <h3 className="testDate_card-ago">
                                    <DateTest style={{ marginRight: '4px' }} /> {dateCalendar}
                                  </h3>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>

                        <>
                          {activeClassesLength > 0 && (
                            <>
                              <h4 className="live__title">LIVE Now</h4>
                              {activeClassesMemo.map(el => (
                                <Live
                                  key={el.id}
                                  limit={freeLimit}
                                  setFreeLimit={setFreeLimit}
                                  premium={premium}
                                  {...el}
                                />
                              ))}
                            </>
                          )}
                        </>
                        <>
                          {nextClass.length > 0 && (
                            <>
                              <h4 className="live__title">Next Class</h4>

                              {nextClass.map(el => (
                                <Next key={el.id} {...el} timeDifference={timeDifference} />
                              ))}
                            </>
                          )}
                        </>

                        <Banner openChatHandler={openChatHandler} sendChatMessageHandler={sendChatMessageHandler} />

                        <div className="classes-guide">
                          <Classes upcomingClasses={upcomingClasses} />
                        </div>
                        <Learn />
                        <div style={{ marginTop: '35px' }}>
                          <ReferralWidget />
                        </div>
                      </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey="second">
                      <div
                        style={{
                          paddingTop: '16px',
                          paddingLeft: '16px',
                          paddingRight: '16px',
                        }}>
                        {wishlist.length === 0 ? <MobileShortlisterBanner /> : <ApplyBanner />}

                        {/*  */}
                      </div>

                      <div style={{ paddingLeft: '16px', paddingRight: '16px', paddingTop: '30px' }}>
                        {/* <h2 style={{ marginBottom: '16px' }} className="corner__title">
                          Explore study abroad
                        </h2> */}

                        <InfoStudyAbroadBanner />
                      </div>
                      <div
                        style={{
                          paddingLeft: '16px',
                          paddingRight: '16px',
                        }}>
                        <LiveStage />
                      </div>

                      <div style={{ paddingLeft: '16px', paddingRight: '16px', paddingTop: '16px' }}>
                        {/* <h2 style={{ marginBottom: '16px' }} className="corner__title">
                          SOP Writer{' '}
                        </h2> */}
                        <SopBanner />
                      </div>
                      <div
                        style={{
                          paddingLeft: '16px',
                        }}>
                        <Counsellors type={'Dashboard'} />
                        <News />
                      </div>
                      <div
                        style={{
                          paddingLeft: '16px',
                          paddingRight: '16px',
                          paddingBottom: '70px',
                        }}>
                        <h4 className="corner__title">
                          Kandor Corner{' '}
                          <div
                            className="corner-new"
                            style={{
                              borderRadius: ' 3px',
                              background: '#EE7734',
                            }}>
                            new
                          </div>
                        </h4>

                        <Articles />
                      </div>
                    </Tab.Pane>
                  </Tab.Content>
                </Tab.Container>
              )}

              {!Mobile && (
                <>
                  <div style={{ padding: '20px' }}>
                    <div className="hello home">
                      {!isEditName ? (
                        <h3 className="hello__user d-flex align-items-center">
                          Hi!
                          {/* {userName === 'PhoneOnboarding' && (
                            <div
                              onClick={() => setIsEditName(true)}
                              className="edit_button"
                              style={{
                                background: '#D7D2DE',
                                cursor: 'pointer',
                              }}>
                              <Edit color="#573B76" />
                            </div>
                          )} */}
                        </h3>
                      ) : (
                        <div
                          style={{
                            display: 'flex',
                          }}>
                          <Input type="text" placeholder={userName} onInput={handleChangeName} />
                          <Button className="profile__btn" onClick={changeName}>
                            Done
                          </Button>
                        </div>
                      )}
                      <p className="hello__desc">Your path to study abroad success with AI-powered prep & counseling</p>
                    </div>

                    {wishlist.length === 0 ? (
                      <div style={{ margin: '34px 0' }}>
                        <ShortlisterBanner />
                      </div>
                    ) : (
                      <div className="shortlister-banner__container">
                        <ApplyBanner />
                      </div>
                    )}

                    <div className="practice-guide" ref={practiceRef}>
                      <Practice />
                    </div>
                  </div>
                  <Banner openChatHandler={openChatHandler} sendChatMessageHandler={sendChatMessageHandler} />

                  <div style={{ padding: '25px 20px 0' }}>
                    <LiveStage />
                    <>
                      {activeClassesLength > 0 && (
                        <>
                          <h4 className="live__title">LIVE Now</h4>
                          {activeClassesMemo.map(el => (
                            <Live key={el.id} limit={freeLimit} setFreeLimit={setFreeLimit} premium={premium} {...el} />
                          ))}
                        </>
                      )}
                    </>

                    <>
                      {nextClass.length > 0 && (
                        <>
                          <h4 className="live__title">Next Class</h4>
                          {nextClass.map(el => (
                            <Next key={el.id} timeDifference={timeDifference} {...el} />
                          ))}
                        </>
                      )}
                    </>
                    <Counsellors type={'Dashboard'} />
                    <div className="classes-guide">
                      <Classes upcomingClasses={upcomingClasses} />
                    </div>
                    <Learn />

                    <h4 className="corner__title">
                      Kandor Corner{' '}
                      <div
                        className="corner-new"
                        style={{
                          borderRadius: ' 3px',
                          background: '#EE7734',
                        }}>
                        new
                      </div>
                    </h4>
                    <Articles />
                  </div>
                </>
              )}
            </div>
          </div>

          {!Mobile && (
            <div className="right__side">
              <div className="right__side-container">
                <Profile />

                <div>
                  <ScoreBoard
                    style={{ margin: '15px' }}
                    speakingScore={speakingScore}
                    writingScore={writingScore}
                    listeningScore={listeningScore}
                    readingScore={readingScore}
                    withButton={true}
                    isLoading={isLoadingScore}
                    goalScore={aspirationalIeltsScore}
                    review={review}
                    isLoadingDetails={isLoadingDetails}
                  />
                </div>

                <div className="testDate">
                  <div className="testDate_card">
                    <div className="testDate_card-content">
                      <h5 className="testDate_card-title">
                        Days to go for IELTS
                        {dateCalendar !== null ? (
                          <div
                            onClick={handleShow}
                            className="edit_button"
                            style={{
                              background: '#573B76',
                              cursor: 'pointer',
                            }}>
                            <Edit color="white" />
                          </div>
                        ) : (
                          <></>
                        )}
                      </h5>

                      {dateCalendar === null ? (
                        <button onClick={handleShow} className="testDate_card-button">
                          <DateTest
                            style={{
                              marginRight: '8px',
                            }}
                          />
                          Add IELTS date
                        </button>
                      ) : (
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                          }}>
                          <h3
                            className="testDate_card__num"
                            style={{
                              marginBottom: 0,
                              marginRight: '10px',
                            }}>
                            {dayCount}
                          </h3>
                          <h3 className="testDate_card-ago">
                            <DateTest
                              style={{
                                marginRight: '4px',
                              }}
                            />{' '}
                            {dateCalendar}
                          </h3>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                {!Mobile && <hr />}
                {!Mobile && <News />}
              </div>
            </div>
          )}
        </>
      </div>
    </>
  );
};

export default MyIelts;
