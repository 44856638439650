import { Drawer } from 'antd';
import { getCounsellorsById, saveCounsellorById } from 'api/counsellorApi';
import React, { useEffect, useState } from 'react';
import Spinner from 'react-bootstrap/Spinner';
import { useMediaQuery } from 'react-responsive';
import { ReactComponent as Book } from '../../../assets/icons/marketplace/profile/mobile/book.svg';
import { ReactComponent as SaveMobile } from '../../../assets/icons/marketplace/profile/mobile/save.svg';
import { ReactComponent as NotSave } from '../../../assets/icons/marketplace/profile/notsave.svg';
import { ReactComponent as Save } from '../../../assets/icons/marketplace/profile/save.svg';
import s from './Drawer.module.scss';
import Experience from './Experience/Experience';
import Profile from './Profile/Profile';
import Stories from './Stories/Stories';

const DrawerProfile = ({ open, onClose, id, showBooking }) => {
  const isMobile = useMediaQuery({ query: '(max-width:1200px)' });
  const [counselor, setCounselor] = useState([]);
  const [save, setSave] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);

  const saveCounselor = () => {
    saveCounsellorById(id);
    setSave(prevSave => !prevSave);
  };

  useEffect(() => {
    setIsLoaded(false);
    if (open) {
      getCounsellorsById(id)
        .then(res => res.data)
        .then(res => {
          setCounselor(res);
          setSave(!!res.isSaved);

          setIsLoaded(true);
        });
    }
  }, [id, open]);

  return (
    <>
      <Drawer
        zIndex={1201}
        headerStyle={{ border: 'none' }}
        placement={isMobile ? 'bottom' : 'right'}
        onClose={onClose}
        bodyStyle={{ padding: 0 }}
        open={open}
        contentWrapperStyle={isMobile ? { border: 'none' } : { border: 'none', right: '16px' }}
        closeIcon={false}
        height={isMobile ? '100%' : 'calc(100vh - 32px)'}
        width={isMobile ? '100%' : '71%'}
        style={
          isMobile
            ? { marginTop: '120px', borderRadius: '12px', zIndex: '1201' }
            : {
                borderRadius: '12px',
                marginTop: '16px',
                zIndex: '1201',
                height: 'calc(100vh - 32px)',
              }
        }>
        <header className={s.header__drawer}>
          {isMobile ? (
            <button className={s.closeButton} onClick={onClose}>
              <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 19 19" fill="none">
                <path
                  d="M5.21428 13.7858L13.7857 5.21436M13.7857 13.7858L5.21428 5.21436"
                  stroke="black"
                  strokeWidth="1.2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              Close
            </button>
          ) : (
            <button className={s.closeButton} onClick={onClose}>
              <svg xmlns="http://www.w3.org/2000/svg" width="10" height="18" viewBox="0 0 10 18" fill="none">
                <path
                  d="M1 0.999839L9 8.99992L1 17"
                  stroke="#7D7D7D"
                  strokeWidth="1.33334"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </button>
          )}

          <div className={s.header__actions}>
            <button className={s.header__button} disabled>
              Share
              <div style={{ marginLeft: '10px' }}>
                {isMobile ? (
                  <svg xmlns="http://www.w3.org/2000/svg" width="13" height="14" viewBox="0 0 13 14" fill="none">
                    <path
                      d="M9.38457 9.09234C8.99482 9.09234 8.64611 9.24618 8.37944 9.48721L4.72303 7.35901C4.74867 7.24106 4.76918 7.12311 4.76918 7.00003C4.76918 6.87695 4.74867 6.75901 4.72303 6.64106L8.33841 4.53336C8.61534 4.78977 8.97944 4.94875 9.38457 4.94875C10.2359 4.94875 10.923 4.26157 10.923 3.41029C10.923 2.55901 10.2359 1.87183 9.38457 1.87183C8.53329 1.87183 7.84611 2.55901 7.84611 3.41029C7.84611 3.53336 7.86662 3.65131 7.89226 3.76926L4.27688 5.87695C3.99995 5.62054 3.63585 5.46157 3.23072 5.46157C2.37944 5.46157 1.69226 6.14875 1.69226 7.00003C1.69226 7.85131 2.37944 8.53849 3.23072 8.53849C3.63585 8.53849 3.99995 8.37952 4.27688 8.12311L7.92816 10.2564C7.90252 10.3641 7.88713 10.477 7.88713 10.5898C7.88713 11.4154 8.55893 12.0872 9.38457 12.0872C10.2102 12.0872 10.882 11.4154 10.882 10.5898C10.882 9.76413 10.2102 9.09234 9.38457 9.09234Z"
                      fill="#303030"
                    />
                  </svg>
                ) : (
                  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                    <path
                      d="M15 13.4C14.3667 13.4 13.8 13.65 13.3667 14.0417L7.425 10.5834C7.46667 10.3917 7.5 10.2 7.5 10C7.5 9.80002 7.46667 9.60835 7.425 9.41669L13.3 5.99169C13.75 6.40835 14.3417 6.66669 15 6.66669C16.3833 6.66669 17.5 5.55002 17.5 4.16669C17.5 2.78335 16.3833 1.66669 15 1.66669C13.6167 1.66669 12.5 2.78335 12.5 4.16669C12.5 4.36669 12.5333 4.55835 12.575 4.75002L6.7 8.17502C6.25 7.75835 5.65833 7.50002 5 7.50002C3.61667 7.50002 2.5 8.61669 2.5 10C2.5 11.3834 3.61667 12.5 5 12.5C5.65833 12.5 6.25 12.2417 6.7 11.825L12.6333 15.2917C12.5917 15.4667 12.5667 15.65 12.5667 15.8334C12.5667 17.175 13.6583 18.2667 15 18.2667C16.3417 18.2667 17.4333 17.175 17.4333 15.8334C17.4333 14.4917 16.3417 13.4 15 13.4Z"
                      fill="#303030"
                    />
                  </svg>
                )}
              </div>
            </button>
            {!isMobile && (
              <button className={s.header__button} onClick={saveCounselor}>
                {save ? `Saved` : `Save`}

                {save ? <Save style={{ marginLeft: '15px' }} /> : <NotSave style={{ marginLeft: '15px' }} />}
              </button>
            )}
          </div>
        </header>
        {isLoaded ? (
          <main
            style={
              isMobile
                ? {
                    paddingTop: '0',
                    paddingLeft: '20px',
                    paddingRight: '20px',
                    paddingBottom: '210px',
                  }
                : {
                    paddingTop: '0',
                    paddingLeft: '32px',
                    paddingRight: '32px',
                    paddingBottom: '120px',
                  }
            }>
            <Profile
              photo={counselor.photo}
              name={counselor.name}
              location={counselor.location}
              experience_years={counselor.experience}
              is_booked={counselor.isBooked}
              showBooking={showBooking}
              rating={counselor.rating}
            />
            <Experience
              description={counselor.description}
              company={counselor.company}
              services={counselor.services}
              countries={counselor.countries}
              students_mentored={counselor.studentsMentored}
            />
            {/* <RatingComponent
              averageRating={averageRating}
              totalRatings={totalRatings}
              ratingsDistribution={ratingsDistribution}
            /> */}
            {/* <Recommended /> */}
            {/* <University /> */}
            {/* <Services /> */}
            <Stories testimonials={counselor.testimonials} />
          </main>
        ) : (
          <div
            style={{
              height: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}>
            <Spinner style={{ color: '#ee7734' }} animation="border" role="primary">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div>
        )}
        {isMobile && isLoaded && (
          <div className={s.mobile__actions}>
            <button onClick={saveCounselor} className={save ? s.active__save : s.save}>
              {save ? `Saved` : `Save`}
              {save ? (
                <svg xmlns="http://www.w3.org/2000/svg" width="11" height="14" viewBox="0 0 11 14" fill="none">
                  <path
                    d="M9.13196 12.8927L9.13183 12.8926L5.84882 10.401L5.50005 10.1363L5.15128 10.401L1.86827 12.8926L1.86805 12.8927C1.7729 12.965 1.67382 12.9651 1.58473 12.9164C1.49206 12.8658 1.4103 12.7584 1.4103 12.596V2.66137C1.4103 2.31034 1.54187 1.9802 1.76605 1.742C1.98912 1.50499 2.28393 1.37892 2.58338 1.37892H8.41672C8.71617 1.37892 9.01098 1.50499 9.23405 1.742C9.45823 1.9802 9.5898 2.31034 9.5898 2.66137V12.5954C9.5898 12.7575 9.50807 12.8651 9.4151 12.9159C9.32558 12.9648 9.22653 12.9645 9.13196 12.8927Z"
                    fill="white"
                    stroke="white"
                    strokeWidth="1.15385"
                  />
                </svg>
              ) : (
                <SaveMobile style={{ marginLeft: '9px' }} />
              )}
            </button>
            <button className={s.book} onClick={showBooking} disabled={counselor.isBooked}>
              {counselor.isBooked === 1 ? <Book style={{ marginRight: '6px' }} /> : null}
              {counselor.isBooked ? `Session Booked` : `Book Session`}
            </button>
          </div>
        )}
      </Drawer>
    </>
  );
};

export default DrawerProfile;
