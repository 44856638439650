import {
	ListeningAnalysisAnswer,
	AnalysisAnswer,
	FillInBlanks,
	InputOptions,
	DescriptionTable,
	SelectTable,
	InputTable,
	MultipleChoiceQuestions,
	InputOptionsSingleLine,
	SelectParagraph,
	StepsTable,
	InputParagraph,
	InfoInputLine,
	GroupInputLine,
	InputTableBlock,
	StepsInputBox,
	CheckboxChoice,
	SelectColumns,
	InputOneLine,
	InputTableParagraph,
} from 'Data/passage_components_new.js'
import React, { useState, useEffect } from 'react'
import {
	Button,
	Card,
	Col,
	Form,
	FormGroup,
	Input,
	Label,
	ListGroup,
	ListGroupItem,
	Modal,
	ModalBody,
	ModalFooter,
	ModalHeader,
	Row,
} from 'reactstrap'

const agreeDescription = [
	'If the statement agrees with the information',
	'If the statement contradicts with the information',
	'If there is no information on this',
]

const PassagesQuestions = ({
	isMock,
	answersInfo,
	isAnalytics,
	questionList,
	setAnswers,
	UserAnswers,
	ActualAnswers,
	ExplainAnswers,
	submitReport,
	executeScroll,
	withLocate = true,
	section = '',
}) => {
	const { info, questions } = questionList
	const [startIndex, setStartIndex] = useState('')
	const [stopIndex, setStopIndex] = useState('')
	const [optionDescription, setOptionDescription] = useState([])
	const [optionList, setOptionList] = useState([])
	const [titleDescription, setTitleDescription] = useState(null)
	const [numberList, setNumberList] = useState([])

	useEffect(() => {
		const getNumbers = (array) => {
			const numbers = []
			let currentIndex = 0

			do {
				if (Array.isArray(array[currentIndex])) {
					const addNumber = (array) => {
						array.forEach((el) => {
							if (Array.isArray(el)) {
								addNumber(el)
							} else if (el.number) {
								numbers.push(+el.number)
							}
						})
					}

					addNumber(array[currentIndex])
				} else if (
					array[currentIndex].data &&
					Array.isArray(array[currentIndex].data)
				) {
					array[currentIndex].data.forEach((el) => {
						if (el.number) {
							numbers.push(+el.number)
						}
					})
				} else if (array[currentIndex]?.number) {
					if (Array.isArray(array[currentIndex].number)) {
						array[currentIndex].number.forEach((num) => numbers.push(+num))
					} else {
						numbers.push(+array[currentIndex].number)
					}
				}

				currentIndex++
			} while (currentIndex < array.length)

			return numbers
		}

		if (questions?.data) {
			let arrayData = []

			if (questions.data?.textList) {
				arrayData = questions.data.textList
			} else if (questions.data?.table) {
				arrayData = questions.data.table
			} else {
				arrayData = questions.data
			}

			const numbersArray = getNumbers(arrayData)
			console.log('numbers', numbersArray)
			setNumberList(numbersArray)

			if (numbersArray.length) {
				setStartIndex(Math.min(...numbersArray))
				setStopIndex(Math.max(...numbersArray))
			}
		}

		if (
			questions.type === 'agree-select-line' ||
			questions.type === 'select-line'
		) {
			setOptionDescription(agreeDescription)
			setOptionList(questions.options)
		} else if (Array.isArray(questions.options)) {
			setOptionList(questions.options)
		} else {
			const descriptions = []
			const options = []

			for (const key in questions.options) {
				if (Object.hasOwnProperty.call(questions.options, key)) {
					const element = questions.options[key]
					if (key === 'headerTable') {
						setTitleDescription(element)
					} else {
						descriptions.push(element)
						options.push(key)
					}
				}
			}

			setOptionList(options)
			setOptionDescription(descriptions)
		}
	}, [questions])

	const [report, setReportToggle] = useState(false)
	const [currentQuestion, setCurrentQuestion] = useState('')
	const [notes, setNotes] = useState('')
	const reportToggle = () => setReportToggle(!report)
	const reportQuestion = (question) => {
		reportToggle()
		setCurrentQuestion(question)
	}

	const changeNotes = (e) => {
		setNotes(e.target.value)
	}

	const submit_report = (question) => {
		submitReport(currentQuestion, notes)
	}

	const submitAnswers = (o, a, b) => {
		console.log(o)
		console.log(b)
		setAnswers((prevAnswers) => ({ ...prevAnswers, [o]: b }))
	}

	return (
		<div
			className={`passage-question ${
				isAnalytics ? 'passage-question--dark' : ''
			}`}
		>
			<Modal
				size="lg"
				isOpen={report}
				toggle={reportToggle}
				style={{ 'margin-top': '-2rem' }}
			>
				<div className="modal-header">
					<button
						type="button"
						className="close modal-close"
						data-dismiss="modal"
						aria-label="Close"
						onClick={reportToggle}
					></button>
					<h4 className="modal-title">
						<strong> If there is a mistake, please let us know </strong>
					</h4>
				</div>

				<ModalBody>
					<h4 className="text-left" style={{ color: 'black' }}>
						{' '}
						Please describe the mistake as detailed as possible along with your
						expected correction, leave your email so that we can contact you
						when needed. Thank you !
					</h4>

					<h4 className="text-left" style={{ color: 'black' }}>
						{' '}
						Please describe exactly what is wrong *:
					</h4>

					<Form>
						<FormGroup>
							<Label for="exampleText"></Label>
							<Input
								type="textarea"
								name="text"
								id="exampleText"
								value={notes}
								style={{
									height: '10rem',
									'background-color': 'white',
									'margin-top': '-1.8rem',
									color: 'black',
								}}
								onChange={changeNotes}
							/>
						</FormGroup>
					</Form>
				</ModalBody>
				<ModalFooter>
					<Button color="info" onClick={submit_report} size="sm">
						Submit
					</Button>{' '}
					<Button className="btn-link" onClick={reportToggle} color="danger">
						Cancel
					</Button>
				</ModalFooter>
			</Modal>

			{startIndex && stopIndex ? (
				<h2 className="passage-question__title">
					Questions {startIndex}
					{stopIndex != startIndex ? `-${stopIndex}` : ''}
				</h2>
			) : (
				''
			)}
			<div className="passage-question__head">
				{info?.length ? (
					info.map((data, i) => (
						<p className="passage-question__text" key={i}>
							{data.map((text, i) => (
								<span className={`${text.bold ? 'bold' : ''}`} key={i}>
									{text.text}
								</span>
							))}
						</p>
					))
				) : (
					<p className="passage-question__text">
						Do the statements below agree with the information given in Reading
						Passage? In{' '}
						<strong>
							{' '}
							boxes {startIndex}-{stopIndex}{' '}
						</strong>{' '}
						on your answer sheet, write
					</p>
				)}
			</div>
			<div className="passage-question__body">
				{questions.type === 'picture-select-line' ||
				questions.type === 'picture-description-select-line' ||
				questions.type === 'picture-radio-text' ? (
					<div className="picture-question">
						{questions.pictureInfo.title && (
							<span className="picture-question__title">
								{questions.pictureInfo.title}
							</span>
						)}
						<div className="picture-question__img">
							<img src={questions.pictureInfo.src} alt="..." />
						</div>
					</div>
				) : (
					<></>
				)}

				{questions.type === 'description-select-table' ||
				questions.type === 'description-select-line' ||
				questions.type === 'description-select-paragraph' ||
				questions.type === 'description-select-block-paragraph' ||
				questions.type === 'agree-select-line' ||
				questions.type === 'picture-description-select-line' ||
				questions.type === 'description-select-columns' ? (
					<div className="passage-question__content">
						<DescriptionTable
							title={titleDescription}
							optionList={optionList}
							descriptionList={optionDescription}
						/>
					</div>
				) : (
					''
				)}

				<div className="passage-question__content">
					{questions.type === 'description-select-table' ? (
						<SelectTable
							optionList={['', ...optionList]}
							textList={questions.data}
							submitAnswers={submitAnswers}
							UserAnswers={UserAnswers}
							isAnalytics={isAnalytics}
						/>
					) : questions.type === 'table-input' ? (
						<InputTable
							submitAnswers={submitAnswers}
							UserAnswers={UserAnswers}
							optionList={questions.data}
							isAnalytics={isAnalytics}
						/>
					) : questions.type === 'table-input-paragraph' ? (
						<InputTableParagraph
							optionList={questions.data}
							submitAnswers={submitAnswers}
							UserAnswers={UserAnswers}
							isAnalytics={isAnalytics}
						/>
					) : questions.type === 'input-line' ? (
						questions.data.map((item, i) => (
							<div className="passage-question__inner" key={i}>
								{item.title && (
									<div className="passage-question__inner-head">
										<span className="passage-question__subtitle">
											{item.title}
										</span>

										{item.subtitle && <span>{item.subtitle}</span>}
									</div>
								)}
								{item.input ? (
									<FillInBlanks
										submitAnswers={submitAnswers}
										UserAnswers={UserAnswers}
										Index={item.number}
										QIndex={item.qIndex}
										InitialText={item.initialText}
										FinalText={item.finalText}
										isAnalytics={isAnalytics}
									/>
								) : (
									<p
										className={`passage-question__text ${
											item.bold ? 'bold' : ''
										}`}
									>
										{item.initialText}
									</p>
								)}
							</div>
						))
					) : questions.type === 'input-line-big' ? (
						<div className="passage-question__inner">
							<InputOneLine
								content={questions.data}
								submitAnswers={submitAnswers}
								UserAnswers={UserAnswers}
								isAnalytics={isAnalytics}
							/>
						</div>
					) : questions.type === 'select-line' ||
					  questions.type === 'description-select-line' ||
					  questions.type === 'agree-select-line' ||
					  questions.type === 'picture-select-line' ||
					  questions.type === 'picture-description-select-line' ? (
						questions.data.map((item, i) => (
							<div className="passage-question__inner" key={i}>
								{item.input ? (
									<InputOptionsSingleLine
										Items={['', ...optionList]}
										submitAnswers={submitAnswers}
										UserAnswers={UserAnswers}
										Index={item.number}
										QIndex={item.number}
										InitialText={item.initialText}
										FinalText={item.finalText}
										isAnalytics={isAnalytics}
									/>
								) : (
									<p
										className={`passage-question__text ${
											item.bold ? 'bold' : ''
										}`}
									>
										{item.initialText}
									</p>
								)}
							</div>
						))
					) : questions.type === 'radio-text' ||
					  questions.type === 'picture-radio-text' ? (
						questions.data.map((item, i) => (
							<div className="passage-question__inner" key={i}>
								<MultipleChoiceQuestions
									Items={item.options}
									submitAnswers={submitAnswers}
									UserAnswers={UserAnswers}
									Index={item.number}
									QIndex={item.number}
									Text={item.text}
									isAnalytics={isAnalytics}
								/>
							</div>
						))
					) : questions.type === 'checkbox-text' ? (
						questions.data.map((item, i) => (
							<div className="passage-question__inner" key={i}>
								<CheckboxChoice
									maxChose={item.maxChose}
									Items={item.options}
									submitAnswers={submitAnswers}
									UserAnswers={UserAnswers}
									Index={item.number}
									QIndex={item.number}
									Text={item.text}
									isAnalytics={isAnalytics}
								/>
							</div>
						))
					) : questions.type === 'description-select-paragraph' ? (
						questions.data.map((item, i) => (
							<div className="passage-question__inner" key={i}>
								{item.length === 1 && !item[0].input ? (
									<p
										className={`passage-question__text ${
											item[0].bold ? 'bold' : ''
										}`}
									>
										{item[0].text}
									</p>
								) : (
									<SelectParagraph
										optionList={['', ...optionList]}
										contentList={item}
										submitAnswers={submitAnswers}
										UserAnswers={UserAnswers}
										isAnalytics={isAnalytics}
									/>
								)}
							</div>
						))
					) : questions.type === 'table-steps' ? (
						<StepsTable
							optionList={questions.data}
							submitAnswers={submitAnswers}
							UserAnswers={UserAnswers}
							isAnalytics={isAnalytics}
						/>
					) : questions.type === 'input-paragraph' ? (
						questions.data.map((item, i) => (
							<div className="passage-question__inner" key={i}>
								<InputParagraph
									contentList={item}
									submitAnswers={submitAnswers}
									UserAnswers={UserAnswers}
									isAnalytics={isAnalytics}
								/>
							</div>
						))
					) : questions.type === 'description-select-block-paragraph' ? (
						<div className="passage-card">
							<p className="passage-card__title">{questions.title}</p>

							<div className="passage-card__item">
								{questions.data.map((item, i) => {
									if (item.length === 1 && !item[0].input) {
										return <p key={i}>{item[0].text}</p>
									} else {
										return (
											<div key={i}>
												<SelectParagraph
													optionList={['', ...optionList]}
													contentList={item}
													submitAnswers={submitAnswers}
													UserAnswers={UserAnswers}
													isAnalytics={isAnalytics}
												/>
											</div>
										)
									}
								})}
							</div>
						</div>
					) : questions.type === 'input-block-paragraph' ? (
						<div className="passage-card">
							{questions.title && (
								<p className="passage-card__title">{questions.title}</p>
							)}

							<div className="passage-card__item">
								{questions.data.map((item, i) => {
									return (
										<div key={i}>
											<InputParagraph
												contentList={item}
												submitAnswers={submitAnswers}
												UserAnswers={UserAnswers}
												isAnalytics={isAnalytics}
											/>
										</div>
									)
								})}
							</div>
						</div>
					) : questions.type === 'info-input-line' ? (
						<InfoInputLine
							contentList={questions.data}
							submitAnswers={submitAnswers}
							UserAnswers={UserAnswers}
							isAnalytics={isAnalytics}
						/>
					) : questions.type === 'group-input-line' ? (
						<GroupInputLine
							contentList={questions.data}
							submitAnswers={submitAnswers}
							UserAnswers={UserAnswers}
							isAnalytics={isAnalytics}
						/>
					) : questions.type === 'input-table-block' ? (
						<InputTableBlock
							content={questions.data}
							submitAnswers={submitAnswers}
							UserAnswers={UserAnswers}
							isAnalytics={isAnalytics}
						/>
					) : questions.type === 'steps-input-box' ? (
						<StepsInputBox
							title={questions.data.header}
							contentList={questions.data.table}
							submitAnswers={submitAnswers}
							UserAnswers={UserAnswers}
							isAnalytics={isAnalytics}
						/>
					) : questions.type === 'description-select-columns' ? (
						<SelectColumns
							optionList={['', ...optionList]}
							contentList={questions.data}
							submitAnswers={submitAnswers}
							UserAnswers={UserAnswers}
							isAnalytics={isAnalytics}
						/>
					) : (
						''
					)}
				</div>

				{isAnalytics ? (
					<div className="passage-question__content">
						{numberList.map((number, i) => (
							<div className="passage-question__inner" key={i}>
								{section == 'Reading' ? (
									<AnalysisAnswer
										index={number}
										answersInfo={answersInfo}
										ExplainAnswers={ExplainAnswers}
										reportQuestion={reportQuestion}
										executeScroll={executeScroll}
										withLocale={withLocate}
									/>
								) : section == 'Listening' ? (
									<ListeningAnalysisAnswer
										index={number}
										answersInfo={answersInfo}
										reportQuestion={reportQuestion}
										ExplainAnswers={ExplainAnswers}
									/>
								) : (
									''
								)}
							</div>
						))}
					</div>
				) : (
					''
				)}
			</div>
		</div>
	)
}

export default PassagesQuestions
