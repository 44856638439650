import React, { useEffect, useState } from 'react';
import './navbar.css';
import { Button } from 'reactstrap';
import { isApp, isIOS, isIOSApp, isMac } from 'actions/referrer';
import { CounselingModal } from 'kandor-components/CounselingButton';
import ModalWindow from 'kandor-components/Modal/ModalWindow';
import mixpanel from 'mixpanel-browser';
import { useHistory } from 'react-router-dom';
import { ReactComponent as AppleStoreIcon } from '../../assets/kandor-img/apple_logo.svg';
import { ReactComponent as GooglePlayIcon } from '../../assets/kandor-img/google-play.svg';
function NavBar() {
  const [open, setOpen] = useState(false);
  const [showAlert, setShowAlert] = useState(true);
  const [show, setShow] = useState(false);
  const [fixed, setFixed] = useState(false);
  const [isOpenCounseling, setIsOpenCounseling] = useState(false);
  const history = useHistory();
  const clszFix = fixed ? 'fixed' : '';
  const currentPath = window.location.pathname;

  const newWidth = window.innerWidth > 0 ? window.innerWidth : window.screen.width;
  const [width, setWidth] = useState(newWidth);

  const isDesktop = width >= 991;
  useEffect(() => {
    const changeWidth = () => {
      window.innerWidth > 0 ? setWidth(window.innerWidth) : setWidth(window.screen.width);
    };
    window.addEventListener('resize', changeWidth);
    return () => window.removeEventListener('resize', changeWidth);
  }, []);

  useEffect(() => {
    const onScroll = e => {
      const documentHeight = document.documentElement.clientHeight;

      const top = window.pageYOffset;

      if (top >= documentHeight) {
        setFixed(true);
      } else {
        setFixed(false);
      }
    };
    window.addEventListener('scroll', onScroll);
    return () => window.removeEventListener('scroll', onScroll);
  }, []);

  const bookCounselor = () => {
    setIsOpenCounseling(true);
  };

  const toggleNavbar = e => {
    const width = window.innerWidth > 0 ? window.innerWidth : window.screen.width;
    if (open) {
      setOpen(false);
      if (window.location.pathname.includes('admin')) {
        document.querySelector('body').style.overflow = 'visible';
        document.querySelector('html').style.height = '';
        document.querySelector('body').style.height = '';
      } else {
        document.querySelector('body').style.overflow = '';
        document.querySelector('html').style.height = '100%';
        document.querySelector('body').style.height = '100%';
      }
      document.documentElement.classList.remove('nav-open');
    } else {
      setOpen(true);
      if (width < 991) {
        document.querySelector('body').style.overflow = 'hidden';
        document.documentElement.classList.add('nav-open');
      }
    }
  };

  const closeNavbar = () => {
    setOpen(false);
    if (window.location.pathname.includes('admin')) {
      document.querySelector('body').style.overflow = 'visible';
    } else {
      document.querySelector('body').style.overflow = '';
      document.querySelector('html').style.height = '100%';
      document.querySelector('body').style.height = '100%';
    }
    document.documentElement.classList.remove('nav-open');
  };

  const generateInsertId = () => {
    const timestamp = new Date().toISOString(); // Current timestamp
    const uniqueIdentifier = Math.random().toString(36).substr(2, 9); // Unique identifier

    // Combine timestamp and unique identifier to create the insert_id
    const insertId = `${timestamp}-${uniqueIdentifier}`;

    return insertId;
  };

  const activeRoute = routeName => {
    return history?.location.pathname.indexOf(routeName) > -1 ? true : false;
  };

  return (
    <>
      <ModalWindow show={show} setShow={setShow} />
      <CounselingModal isOpen={isOpenCounseling} onClose={() => setIsOpenCounseling(false)} />
      <div className={`navbar-wrap ${clszFix}`}>
        <div className="nav-bar-fix">
          <nav className="navbar-container">
            <div>
              <a
                onClick={e => {
                  mixpanel.track(`Go to Default Page`, {});

                  e.preventDefault();
                  closeNavbar();
                  history.push(isApp() || isIOSApp() ? '/register-app' : '/default');
                }}
                className="nav-logo"
                href="#">
                <img width="140" height="43" src={require('assets/kandor-img/kandor logo_black font.png')} alt="logo" />
              </a>
            </div>
            {!isApp() && !isIOSApp() && (
              <>
                <div className={`menu-item active`}>
                  <button
                    aria-label="burger-trigger"
                    className={`burger-trigger ${open ? 'active' : ''}`}
                    type="button"
                    onClick={toggleNavbar}>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                  </button>
                </div>

                <div className={`navbar-inner ${open ? 'active' : ''}`}>
                  <div className="navbar-center">
                    <a
                      href="#"
                      className={`ielts-button title-gradient ${activeRoute('counseling') ? 'active' : ''}`}
                      onClick={e => {
                        e.preventDefault();
                        closeNavbar();
                        mixpanel.track(`Go to Counseling Page`, {});
                        history.push('/counseling');
                      }}>
                      Study Abroad
                    </a>

                    <a
                      href="#"
                      className={`ielts-button title-gradient ${activeRoute('ielts') ? 'active' : ''}`}
                      onClick={e => {
                        e.preventDefault();
                        mixpanel.track(`Go to Ielts Page`, {});
                        closeNavbar();
                        history.push('/ielts');
                        document.getElementById('ai-speaking-tool').scrollIntoView({ behavior: 'smooth' });
                      }}>
                      IELTS
                    </a>
                    <a
                      href="https://blog.trykandor.com"
                      target="_blank"
                      className="ielts-button title-gradient"
                      onClick={() =>
                        mixpanel.track(`Go to Blog`, {
                          insert_id: generateInsertId(),
                        })
                      }>
                      Blog
                    </a>
                  </div>
                  <div className={`navbar-right-side`}>
                    {!isDesktop ? (
                      <></>
                    ) : // <Button
                    //   className={`navbar-btn`}
                    //   onClick={e => {
                    //     mixpanel.track(`Counsellor from navbar`, {});
                    //     closeNavbar();
                    //     bookCounselor();
                    //   }}
                    //   color="info"
                    // >
                    //   {" "}
                    //   Book counseling session{" "}
                    // </Button>
                    !isMac() ? (
                      <Button
                        href="https://play.google.com/store/apps/details?id=com.trykandor.twa"
                        onClick={e =>
                          mixpanel.track('Download app from button on desktop', { insert_id: generateInsertId() })
                        }
                        className="p-2 navbar-btn navbar-btn--white"
                        target="_blank">
                        <span className="navbar-btn__icon">
                          <GooglePlayIcon />
                        </span>
                        Download App
                      </Button>
                    ) : (
                      <Button
                        href="https://apps.apple.com/in/app/kandor-ielts/id6446410798"
                        onClick={e =>
                          mixpanel.track('Download ios app from button on desktop', { insert_id: generateInsertId() })
                        }
                        target="_blank"
                        className="p-2 navbar-btn navbar-btn--white">
                        <span className="navbar-btn__icon">
                          <AppleStoreIcon />
                        </span>
                        Download App
                      </Button>
                    )}

                    <Button
                      className={`navbar-btn`}
                      onClick={e => {
                        e.preventDefault();
                        closeNavbar();
                        mixpanel.track(`Signup from navbar`, {});
                        history.push('/signup');
                      }}
                      color="info">
                      {' '}
                      Sign Up for Free{' '}
                    </Button>
                  </div>
                </div>
              </>
            )}
          </nav>
        </div>
      </div>
      {!isApp() && !isIOSApp() && (
        <div className={`mobile-footer-btn active `}>
          {!isIOS() && !isMac() ? (
            <Button
              href="https://play.google.com/store/apps/details?id=com.trykandor.twa"
              onClick={e =>
                mixpanel.track('Download app from button on Mobile footer', { insert_id: generateInsertId() })
              }
              className={`navbar-btn navbar-btn--white`}
              color="info"
              style={{
                background: 'white',
                color: '#1f0644',
                boxShadow: '0px 8.85363px 20.1219px rgba(32, 32, 32, 0.2)',
              }}
              target="_blank">
              <span className="navbar-btn__icon">
                <GooglePlayIcon />
              </span>
              Download App
            </Button>
          ) : (
            <Button
              href="https://apps.apple.com/in/app/kandor-ielts/id6446410798"
              onClick={e =>
                mixpanel.track('Download ios app from button on Mobile footer', { insert_id: generateInsertId() })
              }
              target="_blank"
              className={`navbar-btn navbar-btn--white`}
              style={{
                background: 'white',
                color: '#1f0644',
                boxShadow: '0px 8.85363px 20.1219px rgba(32, 32, 32, 0.2)',
              }}
              color="info">
              <span className="navbar-btn__icon">
                <AppleStoreIcon />
              </span>
              Download App{' '}
            </Button>
          )}
          <Button
            onClick={e => {
              e.preventDefault();
              closeNavbar();
              mixpanel.track(`Signup from mobile footer`, {});
              history.push('/signup');
            }}
            className={`navbar-btn`}
            color="info">
            Sign up for Free
          </Button>
        </div>
      )}
    </>
  );
}

export default NavBar;
