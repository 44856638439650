import React from "react";
import { AiOutlineReload } from "react-icons/ai";
import { ReactComponent as Upload } from "../../../../assets/icons/upload.svg";
import Spinner from "react-bootstrap/Spinner";

import Swal from "sweetalert2";
import { postTicket, getTicketInfo } from "./../../../../api/bannerApi";

import s from "./form.module.scss";
const Form = ({ show, setShow, showTerm, setShowTerm }) => {
  const inputRef = React.useRef(null);
  const [terms, setTerms] = React.useState(false);
  const [dragActive, setDragActive] = React.useState(false);

  const [disableInput, setDisableInput] = React.useState(false);

  const [getPro, setGetPro] = React.useState(false);
  const [uploadedFile, setUploadedFile] = React.useState(null);
  const [status, setStatus] = React.useState({
    error: false,
    loading: false,
    success: false,
  });

  const submitTicker = async () => {
    if (terms === false) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Agree to the General Terms of the offer",
      });
    } else {
      setStatus(prev => ({ ...prev, loading: true }));
      setGetPro(false);
      try {
        setDisableInput(true);
        const formData = new FormData();
        formData.append("file", uploadedFile);
        const response = await postTicket(formData);

        if (response.data.message === "Successful") {
          localStorage.setItem("ocr_id", response.data.ocr_id);
          setStatus(prev => ({ ...prev, loading: true }));
          setGetPro(true);
        }
        setUploadedFile(null);
      } catch (error) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
        });
        setDisableInput(false);
        setUploadedFile(null);
        setStatus(prev => ({ ...prev, loading: false, error: true }));
        setShow(false);
      }
    }
  };

  const handleDrag = function (e) {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };

  const handleDrop = function (e) {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      setUploadedFile(e.dataTransfer.files[0]);
    }
  };

  const handleUpload = () => {
    inputRef.current?.click();
  };

  const handleDisplayFileDetails = e => {
    e.preventDefault();
    if (e.target.files && e.target.files[0]) {
      setUploadedFile(e.target.files[0]);
    }
  };

  React.useEffect(() => {
    let interval;
    let startTime = 0;
    if (getPro) {
      interval = setInterval(async () => {
        const currentTime = new Date().getTime();
        if (!startTime) {
          startTime = currentTime / 1000;
        } else {
          const def = currentTime / 1000 - startTime;
          if (def > 60) {
            clearInterval(interval);
            setShow(false);
            Swal.fire({
              position: "center",
              icon: "warning",
              title: " Sorry! We couldn't verify your receipt.",
              buttonsStyling: false,
              customClass: {
                confirmButton: `${s.sweet_alert_button}`,
                title: `${s.title__toast}`,
              },
              confirmButtonText: "Try again",
            }).then(result => {
              if (result.isConfirmed) {
                setShow(true);
              }
            });
          }
        }
        try {
          const res = await getTicketInfo({
            ocr_id: localStorage.getItem("ocr_id"),
          });
          if (res.status === 200) {
            setDisableInput(false);
            setGetPro(false);
            clearInterval(interval);
            setStatus(prev => ({ ...prev, loading: false }));
            setShow(false);
            Swal.fire({
              position: "center",
              icon: "success",
              title: "Congratulations! 🎉",
              text: `We Welcome\nYou to the Kandor Pro Family!\n Enjoy Your FREE Membership!`,
              buttonsStyling: false,
              customClass: {
                confirmButton: `${s.sweet_alert_button}`,
              },
              confirmButtonText: "Explore ",
            }).then(result => {
              if (result.isConfirmed) {
                setShow(false);
                window.location.reload();
              }
            });
          }
        } catch (error) {
          if (error.response.status === 401) {
            setDisableInput(false);
            setGetPro(false);
            clearInterval(interval);
            setStatus(prev => ({ ...prev, loading: false }));
            setShow(false);
            Swal.fire({
              position: "center",
              icon: "warning",
              title: " Sorry! We couldn't verify your receipt.",
              buttonsStyling: false,
              customClass: {
                confirmButton: `${s.sweet_alert_button}`,
                title: `${s.title__toast}`,
              },
              confirmButtonText: "Try again",
            }).then(result => {
              if (result.isConfirmed) {
                setShow(true);
              }
            });
          }
        }
      }, 1000);
    }

    return () => {
      clearInterval(interval);
    };
  }, [getPro]);

  function cutStringWithDots(str, maxLength) {
    if (str.length > maxLength) {
      return str.substring(0, maxLength - 3) + "...";
    }
    return str;
  }

  return (
    <form
      onDragEnter={handleDrag}
      className={s.form_container}
      encType="multipart/form-data"
    >
      <div className={s.upload__files_container}>
        <input
          onChange={handleDisplayFileDetails}
          ref={inputRef}
          type="file"
          disabled={disableInput}
          className={s.default__file_input}
        />
        <div
          onDragEnter={handleDrag}
          onDragLeave={handleDrag}
          onDragOver={handleDrag}
          onDrop={handleDrop}
          onClick={() => handleUpload()}
          className={s.drag__file_area}
        >
          <div className={s.upload__container}>
            {status.loading ? (
              <Spinner
                style={{ width: "20px", height: "20px" }}
                animation="border"
                role="status"
              >
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            ) : (
              <Upload size="30" />
            )}
            <div>
              {uploadedFile ? (
                <>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <p className={s.area__title_file}>
                      {uploadedFile && cutStringWithDots(uploadedFile.name, 15)}
                    </p>
                  </div>
                </>
              ) : (
                <p className={s.area__title}>
                  {status.loading && <b>Processing your image...</b>}
                  {status.loading === true ? (
                    <></>
                  ) : (
                    <>
                      <b> click to upload </b>or drag and drop
                    </>
                  )}
                </p>
              )}
            </div>

            {uploadedFile && (
              <AiOutlineReload
                onClick={() => handleDisplayFileDetails()}
                size="25px"
              />
            )}
          </div>
        </div>
        <div
          style={{
            display: "flex",
            margin: "10px 0",
            color: "#000000",
          }}
        >
          <label
            style={{
              margin: 0,
            }}
          >
            <input
              value={terms}
              onChange={() => setTerms(!terms)}
              style={{ marginRight: "10px" }}
              type="checkbox"
            />
          </label>
          <p style={{ margin: 0, fontSize: "14px" }}>
            I agree to the
            <span
              style={{
                textDecoration: "underline",
                cursor: "pointer",
                marginLeft: "2px",
                marginRight: "2px",
              }}
              onClick={() => {
                setShowTerm(!showTerm);
              }}
            >
              General Terms
            </span>
            of the offer
          </p>
        </div>
        <button
          style={terms === false ? { opacity: "0.5" } : {}}
          onClick={() => submitTicker()}
          type="button"
          disabled={!uploadedFile}
          className={s.upload_button}
        >
          Upload!
        </button>
      </div>
    </form>
  );
};

export default Form;
