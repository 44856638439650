import { makeBooking, getCounsellorsById } from 'api/counsellorApi';
import { useCallback, useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import ModalMobile from './ModalMobile/ModalMobile';
import ModalPC from './ModalPC/ModalPC';
import { useDispatch } from 'react-redux';
import { changeBookingStatus } from 'actions/counselorAction';
import clevertap from 'clevertap-web-sdk';

const BookModal = ({ data, setData, show, setShow, counselorBookId }) => {
  const isMobile = useMediaQuery({ query: '(max-width: 767px)' });
  const dispatch = useDispatch();

  const [currentStep, setCurrentStep] = useState(1);
  const [currentStepMobile, setCurrentStepMobile] = useState(0);
  const [formErrors, setFormErrors] = useState({});
  const [formErrorsMobile, setFormErrorsMobile] = useState({});
  const handleStepChange = step => {
    setCurrentStep(step);
  };

  const handleClose = () => {
    setShow(false);
    setCurrentStep(1);
    setCurrentStepMobile(1);
    setData({
      Place: '',
      Course: '',
      Target: '',
      BookDate: '',
      Date: '',
    });
  };

  const validateStepMobile = useCallback(
    step => {
      const errors = {};

      if (step === 1) {
        if (!data.Place) {
          errors.Place = 'First Name is required';
        }
        if (!data.Course) {
          errors.Course = 'Last Name is required';
        }
      } else if (step === 2) {
        if (!data.Target) {
          errors.Target = 'Place is required';
        }
        if (!data.BookDate) {
          errors.BookDate = 'Course is required';
        }
      } else if (step === 3) {
        if (!data.Date) {
          errors.Date = 'Date is required';
        }
      }

      setFormErrorsMobile(errors);
      return Object.keys(errors).length === 0;
    },
    [data]
  );

  const validateStep = useCallback(
    step => {
      const errors = {};

      if (step === 1) {
        if (!data.Place) {
          errors.Place = 'First Name is required';
        }
        if (!data.Course) {
          errors.Course = 'Last Name is required';
        }
      } else if (step === 2) {
        if (!data.Target) {
          errors.Target = 'Place is required';
        }
        if (!data.BookDate) {
          errors.BookDate = 'Course is required';
        }
      } else if (step === 3) {
        if (!data.Date) {
          errors.Date = 'Date is required';
        }
      }

      setFormErrors(errors);
      return Object.keys(errors).length === 0;
    },
    [data]
  );

  const handleFormSubmit = useCallback(
    event => {
      const isValid = validateStep(currentStep);

      if (isValid) {
        setCurrentStep(prev => prev + 1);
      }
    },
    [currentStep, validateStep]
  );

  const handleFormSubmitForMobile = useCallback(
    event => {
      event.preventDefault();

      const isValid = validateStepMobile(currentStepMobile);

      if (isValid) {
        setCurrentStepMobile(prev => prev + 1);
      }
    },
    [validateStepMobile, currentStepMobile]
  );

  useEffect(() => {
    const createBooking = async () => {
      try {
        const res = await makeBooking(counselorBookId, { data: data });
        if (res.status === 200) {
          dispatch(changeBookingStatus(counselorBookId));
          const res_couns = await getCounsellorsById(counselorBookId);
          const counselor = res_couns.data;
          clevertap.event.push('Counselor Booked', {
            counselor_name: counselor.name,
            counselor_id: counselor.id,
            location: counselor.location,
            experience: counselor.experience,
            rating: counselor.rating,
            countries: JSON.parse(counselor.countries).join(", "),
            date: new Date()
          });
        }
      } catch (error) {
        console.log(error);
      }
    };

    if (currentStep === 4 || currentStepMobile === 4) {
      createBooking();
    }
    if (currentStep >= 5 || currentStepMobile >= 5) {
      handleClose();
    }
  }, [currentStep, currentStepMobile]);

  return (
    <>
      {isMobile ? (
        <ModalMobile
          show={show}
          handleClose={handleClose}
          currentStepMobile={currentStepMobile}
          setData={setData}
          data={data}
          formErrorsMobile={formErrorsMobile}
          setCurrentStepMobile={setCurrentStepMobile}
          handleFormSubmitForMobile={handleFormSubmitForMobile}
          handleStepChange={handleStepChange}
        />
      ) : (
        <ModalPC
          show={show}
          handleClose={handleClose}
          currentStep={currentStep}
          setData={setData}
          data={data}
          formErrors={formErrors}
          setCurrentStep={setCurrentStep}
          handleStepChange={handleStepChange}
          handleFormSubmit={handleFormSubmit}
        />
      )}
    </>
  );
};

export default BookModal;
