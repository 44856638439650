import React from "react";

import s from './ButtonMore.module.scss'

const ButtonMore = (params) => {
  const onClick = params.onClick
  const clas = params.clas

  return (
    <button className={`${clas}, ${s.btn}`} onClick={onClick} type="button">
      {params.children? params.children : (<span className={s.btn__text}>Show more</span>)}
    </button>
  )
}

export default ButtonMore