import * as Actions from '../actions/articlesAction';

const initialState = {
  articles: [],
  categories: ['All'],
  category: 'All',
  status: 'idle' | 'success' | 'error',
};

const ArticlesReducer = (state = initialState, action) => {
  switch (action.type) {
    case Actions.LOADING_ARTICLES_START: {
      return {
        ...state,
        status: 'idle',
      };
    }

    case Actions.LOADING_ARTICLES_SUCCESS: {
      return {
        ...state,
        articles: action.payload,
        status: 'success',
      };
    }
    case Actions.LOADING_ARTICLES_FAILED: {
      return {
        ...state,
        status: 'error',
      };
    }

    case Actions.SET_CATEGORIES_ARTICLES:
      return {
        ...state,
        categories: [state.category, ...action.payload],
      };

    case Actions.FILTER_BY_CATEGORY_ARTICLES:
      return {
        ...state,
        category: action.payload,
      };

    default:
      return state;
  }
};

export default ArticlesReducer;
