import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Alert } from 'reactstrap'
import { ReactComponent as InfoIcon } from '../assets/kandor-img/info-icon.svg'
import mixpanel from 'mixpanel-browser'

const LimitAlert = ({
	isPremium,
	practiceAttemptsLeft,
	isLoadingDetails,
	section,
}) => {
	const history = useHistory()
	const [isPAttemptsModal, setIsPAttemptsModal] = useState(true)

	return (
		<>
			{practiceAttemptsLeft == '-' || isPremium || isLoadingDetails ? (
				''
			) : practiceAttemptsLeft == 1 ? (
				<Alert
					className="limit-alert align-items-center justify-content-start"
					color="warning"
					isOpen={isPAttemptsModal}
				>
					<div className="limit-alert__icon">
						<InfoIcon />
					</div>
					<span className="limit-alert__text">
						Only 1 attempt left.{' '}
						<a
							href="#"
							onClick={(e) => {
								e.preventDefault()
								mixpanel.track(`Upgrade plan limit alert on`, { section: section })
								history.push('/admin/plan-information')
							}}
						>
							Upgrade Now
						</a>{' '}
						to keep all Kandor Pro benefits.
					</span>
				</Alert>
			) : practiceAttemptsLeft == 0 ? (
				<Alert
					className="limit-alert align-items-center justify-content-start"
					color="warning"
					isOpen={isPAttemptsModal}
				>
					<div className="limit-alert__icon">
						<InfoIcon />
					</div>
					<span className="limit-alert__text">
						Your Free attempts are over.{' '}
						<a
							href="#"
							onClick={(e) => {
								e.preventDefault()
								mixpanel.track(`Upgrade plan limit alert on`, { section: section })
								history.push('/admin/plan-information')
							}}
						>
							Upgrade Now
						</a>{' '}
						to enjoy all the benefits of Kandor Pro.
					</span>
				</Alert>
			) : (
				''
			)}
		</>
	)
}

export default LimitAlert
