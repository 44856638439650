import { getPublicCounselors } from 'api/counsellorApi';
import { getCounsellors } from 'api/counsellorApi';

export const LOADING_COUNSELOR_START = 'LOADING_COUNSELOR_START';
export const LOADING_COUNSELOR_SUCCESS = 'LOADING_COUNSELOR_SUCCESS';
export const LOADING_COUNSELOR_FAILED = 'LOADING_COUNSELOR_FAILED';

export const LOADING_COUNSELOR_ADMIN_SUCCESS = 'LOADING_COUNSELOR_ADMIN_SUCCESS';
export const CHANGE_BOOKING_STATUS = 'CHANGE_BOOKING_STATUS';

export const loadingCounselorsAdminSuccessAction = counselors => {
  return {
    type: LOADING_COUNSELOR_ADMIN_SUCCESS,
    payload: counselors,
  };
};

export const loadingCounselorsStartAction = () => {
  return {
    type: LOADING_COUNSELOR_START,
  };
};

export const loadingCounselorsSuccessAction = counselors => {
  return {
    type: LOADING_COUNSELOR_SUCCESS,
    payload: counselors,
  };
};

export const loadingCounselorsFailedAction = e => {
  return {
    type: LOADING_COUNSELOR_FAILED,
    payload: e,
  };
};

export const changeBookingStatus = id => {
  return {
    type: CHANGE_BOOKING_STATUS,
    payload: id,
  };
};

export const getLandingCounselors = () => async dispatch => {
  try {
    dispatch(loadingCounselorsStartAction());

    const res = await getPublicCounselors();

    dispatch(loadingCounselorsSuccessAction(res.data));
  } catch (error) {
    console.error('Error fetching counselors:', error);

    dispatch(loadingCounselorsFailedAction(error));
  }
};

export const getAdminCounselors = () => async dispatch => {
  const res = await getCounsellors();
  dispatch(loadingCounselorsAdminSuccessAction(res.data));
};
