import { React, useEffect, useState } from "react";
import { OverlayTrigger, Popover } from "react-bootstrap";
import { useMediaQuery } from "react-responsive";
import { ReactComponent as Filter } from "../../../assets/icons/marketplace/filter.svg";
import { ReactComponent as NotSave } from "../../../assets/icons/marketplace/filter/notsave.svg";
import { ReactComponent as Save } from "../../../assets/icons/marketplace/filter/save.svg";
import s from "./MarketplaceFilter.module.scss";

const MarketplaceFilter = ({
  cities,
  countries,
  visCounselors,
  setVisCounselors,
  counsellors,
  setIsLoadedCounselors
}) => {
  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });
  const [selectedLocation, setSelectedLocation] = useState("All Cities");
  const [selectedCountry, setSelectedCountry] = useState("All Countries");
  const [saved, setSaved] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");

  const handleSearch = e => {
    setSearchTerm(e.target.value);
  };
  useEffect(() => {
    setIsLoadedCounselors(false)
    const filteredCounselors = counsellors.filter(counselor => {
      if (
        selectedLocation !== "All Cities" &&
        counselor.location !== selectedLocation
      ) {
        return false;
      }

      if (
        selectedCountry !== "All Countries" &&
        !counselor.countries?.includes(selectedCountry)
      ) {
        return false;
      }

      if (saved && counselor.isSaved !== 1) {
        return false;
      }

      if (searchTerm != null && searchTerm !== "") {
        const lowerTerm = searchTerm.toLowerCase();
        const counselorString = JSON.stringify(counselor).toLowerCase();
        if (!counselorString.includes(lowerTerm)) {
          return false;
        }
      }

      return true;
    });

    setVisCounselors(filteredCounselors);
    setIsLoadedCounselors(true)
  }, [
    selectedLocation,
    selectedCountry,
    counsellors,
    saved,
    searchTerm,
    setVisCounselors,
  ]);

  const popoverLeft = (
    <Popover id="popover-positioned-left" title="Popover left">
      <div className={s.popover}>
        <div className={s.popover__selects}>
          <div>
            <select
              onChange={e => setSelectedLocation(e.target.value)}
              value={selectedLocation}
              className={s.custom_select}
            >
              {cities.map((location, index) => (
                <option key={index} value={location}>
                  {location}
                </option>
              ))}
            </select>
          </div>
          <div>
            <select
              onChange={e => setSelectedCountry(e.target.value)}
              value={selectedCountry}
              className={s.custom_select}
            >
              {countries.map((country, index) => (
                <option key={index} value={country}>
                  {country}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className={s.popover__buttons}>
          <h4>Filters</h4>
          <div className={s.button__container}>
            <button className={s.all__button}>All Specialists</button>
            <button className={s.free__button}>Free</button>
          </div>
        </div>
      </div>
    </Popover>
  );
  return (
    <div className={s.filter}>
      <div className={s.filter__container}>
        {/*  Location */}

        {!isMobile && (
          <div className={s.location__container}>
            <select
              onChange={e => setSelectedLocation(e.target.value)}
              value={selectedLocation}
              className={s.custom_select}
            >
              {cities.map((location, index) => (
                <option key={index} value={location}>
                  {location}
                </option>
              ))}
            </select>
          </div>
        )}
        {!isMobile && (
          <div className={s.location__container}>
            <select
              onChange={e => setSelectedCountry(e.target.value)}
              value={selectedCountry}
              className={s.custom_select}
            >
              {countries.map((country, index) => (
                <option key={index} value={country}>
                  {country}
                </option>
              ))}
            </select>
          </div>
        )}

        {/*  button */}
        <div className={s.button__container}>
          {!isMobile && (
            <>
              <button className={s.all__button}>All Specialists</button>
              <button className={s.free__button}>Free</button>
            </>
          )}
          {isMobile && (
            <OverlayTrigger
              trigger="click"
              rootClose
              placement="bottom"
              overlay={popoverLeft}
            >
              <button className={s.filter__button}>
                <Filter />
              </button>
            </OverlayTrigger>
          )}
        </div>
        {/*  search */}
        <div className={s.search__container}>
          <button
            className={saved ? s.save__button : s.notsave__button}
            onClick={() => setSaved(!saved)}
          >
            Saved
            {saved ? (
              <Save style={{ marginLeft: "8px" }} />
            ) : (
              <NotSave style={{ marginLeft: "8px" }} />
            )}
          </button>
          <div className={s.search__block}>
            <input
              className={s.search}
              placeholder="Search..."
              value={searchTerm}
              onChange={handleSearch}
            />

            <svg
              className={s.icon}
              xmlns="http://www.w3.org/2000/svg"
              width="13"
              height="13"
              viewBox="0 0 13 13"
              fill="none"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M9.84974 8.87779C10.7073 7.77182 11.1114 6.38057 10.9799 4.98709C10.8485 3.5936 10.1913 2.30256 9.14209 1.37661C8.09289 0.450652 6.73048 -0.0406552 5.33203 0.00263592C3.93358 0.0459271 2.60415 0.620564 1.61418 1.60964C0.623353 2.59941 0.0470656 3.92989 0.00275767 5.32996C-0.0415503 6.73003 0.449455 8.09432 1.37572 9.1448C2.30198 10.1953 3.59378 10.8529 4.98788 10.9837C6.38198 11.1144 7.77345 10.7084 8.87874 9.84848L8.90831 9.87944L11.8254 12.7984C11.8893 12.8623 11.9652 12.913 12.0486 12.9476C12.1321 12.9822 12.2216 13 12.3119 13C12.4023 13 12.4918 12.9822 12.5753 12.9476C12.6587 12.913 12.7346 12.8623 12.7985 12.7984C12.8624 12.7345 12.913 12.6586 12.9476 12.5751C12.9822 12.4916 13 12.4021 13 12.3117C13 12.2213 12.9822 12.1318 12.9476 12.0483C12.913 11.9647 12.8624 11.8889 12.7985 11.8249L9.88068 8.90668C9.87063 8.89677 9.86032 8.88713 9.84974 8.87779ZM8.42213 2.58309C8.81039 2.96523 9.11918 3.42049 9.33068 3.92261C9.54219 4.42473 9.65222 4.96378 9.65444 5.50866C9.65666 6.05354 9.55101 6.59346 9.3436 7.09729C9.13619 7.60112 8.83112 8.05888 8.44599 8.44417C8.06085 8.82946 7.60327 9.13466 7.09964 9.34215C6.59601 9.54964 6.0563 9.65532 5.51164 9.65311C4.96698 9.65089 4.42815 9.54081 3.92623 9.32922C3.4243 9.11763 2.96922 8.80872 2.58724 8.42031C1.82386 7.64409 1.39801 6.59755 1.40245 5.50866C1.40688 4.41976 1.84123 3.37673 2.6109 2.60676C3.38056 1.83679 4.42318 1.40226 5.51164 1.39783C6.6001 1.39339 7.64622 1.81941 8.42213 2.58309Z"
                fill="#7D7D7D"
              />
            </svg>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MarketplaceFilter;
