import React, { useState, useEffect } from 'react';
import s from './BannerDocument.module.scss';
import { useSelector } from 'react-redux';

import { selectSubmittedDocuments, selectDocumentsLeft } from '../../../../selectors/selectSubmittedDocuments.js';
import EmptyPhoto from '../../../../assets/icons/applyBanner/empty.png';

import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import EmptyBanner from './EmptyBanner/EmptyBanner';
import RequiredBanner from './RequiredBanner/RequiredBanner';
import { useMediaQuery } from 'react-responsive';
import BannerForApplyPC from 'kandor-components/ApplyBanner/BannerInfoShortList/BannerForApplyPC/BannerForApplyPC';

const mockData = [
  {
    id: 1,
    text: '12th, UP, PG Transcript ',
  },
  {
    id: 2,
    text: 'SOP, LOR and Essay',
  },
  {
    id: 3,
    text: 'IELTS, GRE marksheet',
  },
  {
    id: 4,
    text: 'Other',
  },
];

const BannerDocument = ({ setCurrentStep }) => {
  const history = useHistory();
  const submittedDocuments = useSelector(selectSubmittedDocuments);
  const documentsLeft = useSelector(selectDocumentsLeft);
  const isMobile = useMediaQuery({ query: '(max-width:768px)' });

  const [isCollapsed, setIsCollapsed] = useState(false);

  const handleCollapseToggle = () => {
    setIsCollapsed(prevState => !prevState);
  };

  const goToDocumentPage = () => {
    history.push('/admin/upload-documents');
  };

  const goToSop = () => {
    history.push('/admin/aisop');
  };

  useEffect(() => {
    if (submittedDocuments.length === 12) {
      setCurrentStep(3);
    }
  }, [submittedDocuments]);

  return (
    <>
      {submittedDocuments.length === 0 ? (
        isMobile ? (
          <EmptyBanner goToDocumentPage={goToDocumentPage} />
        ) : (
          <BannerForApplyPC
            title="Its seems Lonely here!"
            desc="You have not uploaded any documents yet.
Start your dream college journey"
            img={EmptyPhoto}
            buttonText="Start Uploading Document"
            onClick={goToDocumentPage}
          />
        )
      ) : (
        <>
          <RequiredBanner
            handleCollapseToggle={handleCollapseToggle}
            isCollapsed={isCollapsed}
            mockData={mockData}
            goToSop={goToSop}
          />

          <div className={s.upload__container}>
            <button
              onClick={() => {
                history.push('/admin/upload-documents');
              }}
              className={s.upload__button}>
              Upload Document
            </button>
          </div>
        </>
      )}
    </>
  );
};

export default BannerDocument;
