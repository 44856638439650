import api from 'services/api'

export function getQuestion(objArgs) {
	if (typeof objArgs !== 'object' || !objArgs.hasOwnProperty('id')) {
		throw new Error('missing required parameters')
	}
	return api.get('ielts/questions/' + objArgs.id)
}

export function getRound(objArgs) {
	if (typeof objArgs !== 'object' || !objArgs.hasOwnProperty('id')) {
		throw new Error('missing required parameters')
	}
	return api.get('ielts/rounds/' + objArgs.id)
}

export function getRounds(objArgs) {
	if (typeof objArgs !== 'object') {
		throw new Error('missing required parameters')
	}
	return api.get(
		`ielts/rounds${Object.keys(objArgs).length > 0 ? '?' : ''}${Object.keys(
			objArgs
		)
			.map((el) => `${el}=${objArgs[el]}`)
			.join('&')}`
	)
}

export function getSelfAssesmentsWriting() {
	return api.get(`writing/questions`)
}

export function getQuestionsWriting(objArgs) {
	if (typeof objArgs !== 'object') {
		throw new Error('missing required parameters')
	}
	return api.get(
		`writing/question/all${
			Object.keys(objArgs).length > 0 ? '?' : ''
		}${Object.keys(objArgs)
			.map((el) => `${el}=${objArgs[el]}`)
			.join('&')}`
	)
}

export function getGroup(objArgs) {
	if (typeof objArgs !== 'object' || !objArgs.hasOwnProperty('id')) {
		throw new Error('missing required parameters')
	}
	return api.get('ielts/groups/' + objArgs.id)
}

export function saveGroup(objArgs) {
	if (typeof objArgs !== 'object' || !objArgs.hasOwnProperty('id')) {
		throw new Error('missing required parameters')
	}
	return api.put('ielts/groups/' + objArgs.id, JSON.stringify({ saved: true }))
}

export function getChallenge(objArgs) {
	if (typeof objArgs !== 'object') {
		throw new Error('missing required parameters')
	}
	return api.get('ielts/challenge')
}

export function getGroupRecordings(objArgs) {
	if (typeof objArgs !== 'object' || !objArgs.hasOwnProperty('id')) {
		throw new Error('missing required parameters')
	}
	return api.get('ielts/groups/' + objArgs.id + '/recordings')
}

export function getQuestionPublic(objArgs) {
	if (typeof objArgs !== 'object' || !objArgs.hasOwnProperty('id')) {
		throw new Error('missing required parameters')
	}
	return api.get('public/ielts/questions/' + objArgs.id)
}

export function getGroupPublic(objArgs) {
	if (typeof objArgs !== 'object' || !objArgs.hasOwnProperty('id')) {
		throw new Error('missing required parameters')
	}
	return api.get('public/ielts/groups/' + objArgs.id)
}

export function getRoundsPublic(objArgs) {
	if (typeof objArgs !== 'object') {
		throw new Error('missing required parameters')
	}
	return api.get(
		`public/ielts/rounds${
			Object.keys(objArgs).length > 0 ? '?' : ''
		}${Object.keys(objArgs)
			.map((el) => `${el}=${objArgs[el]}`)
			.join('&')}`
	)
}

export function getReadingTests(objArgs) {
	if (typeof objArgs !== 'object') {
		throw new Error('missing required parameters')
	}
	return api.get(`ielts/reading`)
}

export function getReadingPractice(objArgs) {
	if (typeof objArgs !== 'object') {
		throw new Error('missing required parameters')
	}
	return api.get(`ielts/reading/practice/topics`)
}

/*[
    {
        "id": "f3e4391a-790a-4c57-9c55-6cf6a3afe3b8",
        "testid": "1",
        "created": "2022-01-18T14:28:29",
        "updated": "2022-02-15T17:54:20",
        "status": "COMPLETE", //can be COMPLETE, IN_PROGRESS
        "bandScore": null,
        "type": "Mock",
        "title": "Mock Test 01"
    }
 ]*/
export function getLastReadingAttempts(objArgs) {
	if (typeof objArgs !== 'object') {
		throw new Error('missing required parameters')
	}
	return api.get(
		`ielts/reading/practice/attempts${
			Object.keys(objArgs).length > 0 ? '?' : ''
		}${Object.keys(objArgs)
			.map((el) => `${el}=${objArgs[el]}`)
			.join('&')}`
	)
}

export function getReadingTaskContent(objArgs) {
	if (typeof objArgs !== 'object' || !objArgs.hasOwnProperty('id')) {
		throw new Error('missing required parameters')
	}
	return api.get(`ielts/reading/practice/topics/${objArgs.id}/content`)
}

export function getReadingTaskAnalyticsContent(objArgs) {
	if (typeof objArgs !== 'object' || !objArgs.hasOwnProperty('id')) {
		throw new Error('missing required parameters')
	}
	return api.get(
		`ielts/reading/practice/topics/${objArgs.id}/analytics/content`
	)
}

export function getContentCategoryProgress() {
	return api.get(
		`ielts/content/categories`
	)
}

export function createReadingTestAttempt(objArgs) {
	if (typeof objArgs !== 'object' || !objArgs.hasOwnProperty('id')) {
		throw new Error('missing required parameters')
	}
	return api.post(`ielts/reading/practice/topics/${objArgs.id}/attempts`)
}

export function getReadingTestAttemptState(objArgs) {
	if (
		typeof objArgs !== 'object' ||
		!objArgs.hasOwnProperty('id') ||
		!objArgs.hasOwnProperty('attemptId')
	) {
		throw new Error('missing required parameters')
	}
	return api.get(
		`ielts/reading/${objArgs.id}/attempts/${objArgs.attemptId}/state`
	)
}

export function updateReadingTestAttempt(objArgs) {
	if (
		typeof objArgs !== 'object' ||
		!objArgs.hasOwnProperty('id') ||
		!objArgs.hasOwnProperty('attemptId') ||
		!objArgs.hasOwnProperty('state')
	) {
		throw new Error('missing required parameters')
	}
	return api.put(
		`ielts/reading/${objArgs.id}/attempts/${objArgs.attemptId}/state`,
		JSON.stringify(objArgs.state)
	)
}

export function getReadingTestAttemptAnalysis(objArgs) {
	if (
		typeof objArgs !== 'object' ||
		!objArgs.hasOwnProperty('id') ||
		!objArgs.hasOwnProperty('attemptId')
	) {
		throw new Error('missing required parameters')
	}
	return api.get(
		`ielts/reading/practice/topics/${objArgs.id}/attempts/${objArgs.attemptId}/analytics`
	)
}

export function getListeningTests(objArgs) {
	if (typeof objArgs !== 'object') {
		throw new Error('missing required parameters')
	}
	return api.get(`ielts/listening`)
}

export function getListeningTest(objArgs) {
	if (typeof objArgs !== 'object' || !objArgs.hasOwnProperty('id')) {
		throw new Error('missing required parameters')
	}
	return api.get(`ielts/listening/${objArgs.id}`)
}

export function getListeningPractice(objArgs) {
	if (typeof objArgs !== 'object') {
		throw new Error('missing required parameters')
	}
	return api.get(`ielts/listening/practice/topics`)
}

export function getLastListeningAttempts(objArgs) {
	if (typeof objArgs !== 'object') {
		throw new Error('missing required parameters')
	}
	return api.get(
		`ielts/listening/practice/attempts${
			Object.keys(objArgs).length > 0 ? '?' : ''
		}${Object.keys(objArgs)
			.map((el) => `${el}=${objArgs[el]}`)
			.join('&')}`
	)
}

export function getListeningTestsAttempts(objArgs) {
	if (typeof objArgs !== 'object' || !objArgs.hasOwnProperty('id')) {
		throw new Error('missing required parameters')
	}
	return api.get(`ielts/listening/${objArgs.id}/attempts`)
}

export function getLastListeningTestAttempt(objArgs) {
	if (typeof objArgs !== 'object' || !objArgs.hasOwnProperty('id')) {
		throw new Error('missing required parameters')
	}
	return api.get(`ielts/listening/${objArgs.id}/attempts?latest`)
}

export function createListeningTestAttempt(objArgs) {
	if (typeof objArgs !== 'object' || !objArgs.hasOwnProperty('id')) {
		throw new Error('missing required parameters')
	}
	return api.post(`ielts/listening/practice/topics/${objArgs.id}/attempts`)
}

export function getListeningTaskContent(objArgs) {
	if (typeof objArgs !== 'object' || !objArgs.hasOwnProperty('id')) {
		throw new Error('missing required parameters')
	}
	return api.get(`ielts/listening/practice/topics/${objArgs.id}/content`)
}

export function getListeningTaskAnalyticsContent(objArgs) {
	if (typeof objArgs !== 'object' || !objArgs.hasOwnProperty('id')) {
		throw new Error('missing required parameters')
	}
	return api.get(
		`ielts/listening/practice/topics/${objArgs.id}/analytics/content`
	)
}

export function getListeningTestAttemptState(objArgs) {
	if (
		typeof objArgs !== 'object' ||
		!objArgs.hasOwnProperty('id') ||
		!objArgs.hasOwnProperty('attemptId')
	) {
		throw new Error('missing required parameters')
	}
	return api.get(
		`ielts/listening/${objArgs.id}/attempts/${objArgs.attemptId}/state`
	)
}

export function updateListeningTestAttempt(objArgs) {
	if (
		typeof objArgs !== 'object' ||
		!objArgs.hasOwnProperty('id') ||
		!objArgs.hasOwnProperty('attemptId') ||
		!objArgs.hasOwnProperty('state')
	) {
		throw new Error('missing required parameters')
	}
	return api.put(
		`ielts/listening/${objArgs.id}/attempts/${objArgs.attemptId}/state`,
		JSON.stringify(objArgs.state)
	)
}

export function getListeningTestAttemptAnalysis(objArgs) {
	if (
		typeof objArgs !== 'object' ||
		!objArgs.hasOwnProperty('id') ||
		!objArgs.hasOwnProperty('attemptId')
	) {
		throw new Error('missing required parameters')
	}
	return api.get(
		`ielts/listening/practice/topics/${objArgs.id}/attempts/${objArgs.attemptId}/analytics`
	)
}

export function getSpeakingMockTests(objArgs) {
	if (typeof objArgs !== 'object') {
		throw new Error('missing required parameters')
	}
	return api.get(`ielts/speaking/mocks`)
}

export function getSpeakingMockTest(objArgs) {
	if (typeof objArgs !== 'object' || !objArgs.hasOwnProperty('id')) {
		throw new Error('missing required parameters')
	}
	return api.get(`ielts/speaking/mocks/${objArgs.id}`)
}

export function startSpeakingMockTest(objArgs) {
	if (typeof objArgs !== 'object' || !objArgs.hasOwnProperty('id')) {
		throw new Error('missing required parameters')
	}
	return api.post(`ielts/speaking/mocks/${objArgs.id}/attempts`, {})
}

export function updateSpeakingMockTestAttempt(objArgs) {
	if (
		typeof objArgs !== 'object' ||
		!objArgs.hasOwnProperty('id') ||
		!objArgs.hasOwnProperty('attemptId')
	) {
		throw new Error('missing required parameters')
	}
	return api.put(
		`ielts/speaking/mocks/${objArgs.id}/attempts/${objArgs.attemptId}`,
		objArgs
	)
}

export function getSpeakingMockTestAttemptAnalytics(objArgs) {
	if (
		typeof objArgs !== 'object' ||
		!objArgs.hasOwnProperty('id') ||
		!objArgs.hasOwnProperty('attemptId')
	) {
		throw new Error('missing required parameters')
	}
	return api.get(
		`ielts/speaking/mocks/${objArgs.id}/attempts/${objArgs.attemptId}/analytics`
	)
}

export function getSpeakingMockTestsAttempts(objArgs) {
	if (typeof objArgs !== 'object') {
		throw new Error('missing required parameters')
	}
	return api.get(`ielts/speaking/mocks/attempts`)
}

export function getSpeakingMockTestAttempts(objArgs) {
	if (typeof objArgs !== 'object' || !objArgs.hasOwnProperty('id')) {
		throw new Error('missing required parameters')
	}
	return api.get(`ielts/speaking/mocks/${objArgs.id}/attempts`)
}

export function getStudyCards(objArgs) {
	if (typeof objArgs !== 'object') {
		throw new Error('missing required parameters')
	}
	return api.get(`ielts/content`)
}

export function uploadImage(objArgs) {
	if (typeof objArgs !== 'object' || !objArgs.hasOwnProperty('assesment_id')) {
		throw new Error('missing required parameters')
	}
	return api.post(`/file/` + objArgs.assesment_id, objArgs.file)
}
