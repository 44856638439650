import React from "react";
import { useMediaQuery } from "react-responsive";
import s from "./MarketplaceForm.module.scss";
const MarketplaceFormFinal = () => {
  const isMobile = useMediaQuery({ query: "(max-width:767px)" });

  return (
    <div>
      <div className={s.marketplaceFormFinal}>
        <div className={s.marketplaceFormFinal_left}>
          <div className={s.marketplaceFormFinal_left__done}>
            {isMobile ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="32"
                height="32"
                viewBox="0 0 32 32"
                fill="none"
              >
                <path
                  d="M5.5 17.5L12.5 24.5L26.4999 9.5"
                  stroke="white"
                  strokeWidth="2.99999"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            ) : (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="34"
                height="25"
                viewBox="0 0 34 25"
                fill="none"
              >
                <path
                  d="M2.68164 13.0456L12.2271 22.5912L31.318 2.1365"
                  stroke="white"
                  strokeWidth="4.09092"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            )}
          </div>
          <h3 className={s.marketplaceFormFinal_left__title}>
            Your booking has been confirmed!
          </h3>
        </div>
        <div className={s.marketplaceFormFinal_right}>
          <div className={s.marketplaceFormFinal_right__card}>
            <div className={s.marketplaceFormFinal_right__phone}>
              {isMobile ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="30"
                  height="31"
                  viewBox="0 0 30 31"
                  fill="none"
                >
                  <path
                    d="M23.6495 20.3006C22.2579 20.1696 20.9429 19.8211 19.7348 19.2917C19.3566 19.1326 18.914 19.1808 18.573 19.4631L15.893 21.6818C12.8866 19.7737 10.5604 16.9639 9.24716 13.6541L11.9271 11.4354C12.2682 11.1531 12.3982 10.7273 12.3124 10.3261C12.0108 9.00382 11.923 7.64181 12.0521 6.29177C12.0799 5.9965 11.9893 5.70227 11.8001 5.47382C11.611 5.24537 11.3389 5.10141 11.0436 5.07361L7.14701 4.70665C6.85174 4.67884 6.55752 4.76947 6.32907 4.9586C6.10062 5.14772 5.95665 5.41985 5.92885 5.71512C5.45614 10.7347 6.99682 15.7365 10.2119 19.6201C13.4271 23.5038 18.0533 25.9512 23.0729 26.4239C23.3682 26.4517 23.6624 26.361 23.8908 26.1719C24.1193 25.9828 24.2632 25.7107 24.2911 25.4154L24.658 21.5188C24.6858 21.2235 24.5952 20.9293 24.4061 20.7008C24.2169 20.4724 23.9448 20.3284 23.6495 20.3006Z"
                    fill="#573B76"
                  />
                </svg>
              ) : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="40"
                  height="40"
                  viewBox="0 0 40 40"
                  fill="none"
                >
                  <path
                    d="M31.4549 26.352C29.5882 26.1762 27.8242 25.7087 26.2037 24.9987C25.6964 24.7852 25.1028 24.8498 24.6452 25.2286L21.0504 28.2046C17.0176 25.6452 13.8973 21.8761 12.1357 17.4364L15.7306 14.4603C16.1881 14.0815 16.3624 13.5104 16.2474 12.9722C15.8429 11.1985 15.725 9.37156 15.8982 7.56063C15.9355 7.16455 15.8139 6.76989 15.5603 6.46345C15.3066 6.15701 14.9415 5.9639 14.5455 5.9266L9.31859 5.43437C8.92252 5.39707 8.52785 5.51864 8.22141 5.77233C7.91497 6.02602 7.72186 6.39105 7.68456 6.78712C7.05047 13.5204 9.11712 20.2297 13.4299 25.4392C17.7426 30.6487 23.9482 33.9315 30.6814 34.5656C31.0775 34.6029 31.4721 34.4814 31.7786 34.2277C32.085 33.974 32.2781 33.6089 32.3154 33.2129L32.8077 27.986C32.845 27.5899 32.7234 27.1953 32.4697 26.8888C32.216 26.5824 31.851 26.3893 31.4549 26.352Z"
                    fill="#573B76"
                  />
                </svg>
              )}
            </div>
            <div className={s.marketplaceFormFinal_right__text}>
              Our counselors will soon be reaching out to you over a telephonic
              call.
            </div>
          </div>
        </div>
      </div>
      {isMobile ? (
        <div>
          <div style={{ marginTop: "28px" }} className={s.mobile__card}>
            <div className={s.icon}></div>
            <div>
              <div className={s.container_titleImage}>
                <img
                  style={{ width: "64px" }}
                  className={s.img_calendar}
                  src={require("../../../assets/icons/marketplace/modal/centerCard.png")}
                />
                <h4 className={s.title__mobile}>
                  Make yourself <br></br> Available.
                </h4>
              </div>

              <p className={s.desc__mobile}>
                Our counselors are leading experts. Let's make the most of their
                guidance by using their time thoughtfully and respecting their
                commitment to your success.
              </p>
            </div>
          </div>
          <div style={{ marginTop: "15px" }} className={s.mobile__card}>
            <div className={s.icon}></div>
            <div>
              <div className={s.container_titleImage}>
                <img
                  style={{ width: "64px" }}
                  className={s.img_shoes}
                  src={require("../../../assets/icons/marketplace/modal/centerCardTwo.png")}
                />
                <h4 className={s.title__mobile}>
                  Explore your <br></br> Options!
                </h4>
              </div>
              <p className={s.desc__mobile}>
                Like your favorite sneakers, there is no one size fits all when
                it comes to counselors, booking more than one counselor gives
                you a better shot at discovering your perfect match.
              </p>
            </div>
          </div>
        </div>
      ) : (
        <div
          style={{ marginTop: "28px" }}
          className={s.marketplaceFormFinal_center}
        >
          <div className={s.marketplaceFormFinal_center__card}>
            <div className={s.icon}>
              <img
                className={s.img_calendar}
                src={require("../../../assets/icons/marketplace/modal/centerCard.png")}
              />
            </div>
            <div className={s.marketplaceFormFinal_center__card__content}>
              <h4>Make yourself Available.</h4>
              <p>
                Our counselors are leading experts. Let's make the most of their
                guidance by using their time thoughtfully and respecting their
                commitment to your success.
              </p>
            </div>
          </div>
          <div
            style={{ marginTop: "20px" }}
            className={s.marketplaceFormFinal_center__card}
          >
            <div className={s.icon}>
              <img
                className={s.img_shoes}
                src={require("../../../assets/icons/marketplace/modal/centerCardTwo.png")}
              />
            </div>
            <div className={s.marketplaceFormFinal_center__card__content}>
              <h4>Explore your options!</h4>
              <p>
                Like your favorite sneakers, there is no one size fits all when
                it comes to counselors, Booking more than one counselor gives
                you a better shot at discovering your perfect match.
              </p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default MarketplaceFormFinal;
