import React from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import "./../Banner.scss";

const BannerReferral = () => {
  const history = useHistory();
  
  const routeToReferral = () => {
    history.push("/admin/referral");
  };

  return (
     <div onClick={() => routeToReferral()} className="intro-banner intro-banner--one">
      <div className="intro-banner__wrap">
        <div className="intro-banner__descr">
          <h5 className="intro-banner__title">
            REFER & GET KANDOR PRO WORTH Rs 4999/- <span>FOR FREE</span>
          </h5>
          <p className="intro-banner__text">
            Learning is more fun when done together, invite your friends today!
          </p>
          <button
            onClick={() => routeToReferral()}
            className="intro-banner__btn btn-hover"
          >
            Send Referrals!
          </button>
        </div>
      </div>
    </div>
  )
}

export default BannerReferral