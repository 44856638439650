import { registerClass } from "api/classesApi";
import moment from "moment-timezone";
import React, { useState } from "react";
import { useMediaQuery } from "react-responsive";
import { ReactComponent as AllSet } from "../../../../assets/icons/classes/allSet.svg";
import { ReactComponent as Listening } from "../../../../assets/icons/classes/IconsForType/Listening.svg";
import { ReactComponent as Reading } from "../../../../assets/icons/classes/IconsForType/Reading.svg";
import { ReactComponent as Speaking } from "../../../../assets/icons/classes/IconsForType/Spealing.svg";
import { ReactComponent as Writing } from "../../../../assets/icons/classes/IconsForType/Writing.svg";
import { ReactComponent as Notice } from "../../../../assets/icons/classes/notice.svg";
import { ReactComponent as People } from "../../../../assets/icons/classes/people.svg";
import s from "./Next.module.scss";
import Timer from "./Timer/Timer";

const mapOfType = {
  Speaking: (
    <div
      style={{
        background: "rgba(37, 164, 131, 0.20)",
        border: "1px solid rgba(37, 164, 131, 0.20)",
        color: "#25A483",
      }}
      className={s.type}
    >
      <Speaking />
      Speaking
    </div>
  ),
  Listening: (
    <div
      style={{
        background: "rgba(255, 209, 45, 0.20)",
        border: "1px solid rgba(226, 182, 25, 0.20)",
        color: "#E3A504",
      }}
      className={s.type}
    >
      <Listening />
      Listening
    </div>
  ),
  Reading: (
    <div
      style={{
        background: "rgba(130, 54, 247, 0.20)",
        border: "1px solid rgba(130, 54, 247, 0.20)",
        color: "#8236F7",
      }}
      className={s.type}
    >
      <Reading />
      Reading
    </div>
  ),
  Writing: (
    <div
      style={{
        background: "rgba(207, 87, 132, 0.20)",
        border: "1px solid rgba(207, 87, 132, 0.30)",
        color: "#CF5784",
      }}
      className={s.type}
    >
      <Writing />
      Writing
    </div>
  ),
};

const Next = ({
  timeDifference,
  id,
  type,
  topic,
  teacher,
  image,
  startTime,
  isNotify,
}) => {
  const isMobile = useMediaQuery({ query: "(max-width:500px)" });

  const [notified, setNotified] = useState(isNotify);

  // const [notified, setNotified] = useState(isNotify);

  const renderTeacher = teacher => {
    if (!teacher) {
      return "";
    } else {
      return JSON.parse(teacher).name;
    }
  };

  const parseAndFormatTimeRange = startTime => {
    const startTimeF = moment.tz(
      startTime.replace("GMT", "+0530"),
      "ddd, DD MMM YYYY HH:mm:ss ZZ",
      "Asia/Kolkata"
    );

    // Calculate the end time by adding 1 hour to the start time
    const endTimeF = startTimeF.clone().add(1, "hours");

    // Format both start and end times in "hh:mm A" format
    const formattedStartTime = startTimeF.format("hh:mm A");
    const formattedEndTime = endTimeF.format("hh:mm A");

    // Combine start and end times into a string
    return `${formattedStartTime} - ${formattedEndTime}`;
  };

  const register = () => {
    registerClass(id).then(res => {
      setNotified(true);
    });
  };

  return (
    <>
      <div className={s.live}>
        {isMobile && (
          <div className={s.mobile_timer}>
            <p> Starts in</p>
            <div className={s.time}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="13"
                height="14"
                viewBox="0 0 13 14"
                fill="none"
              >
                <circle cx="10.5" cy="11.5" r="2.5" fill="#47BA52" />
                <path
                  d="M11.25 7.17498C11.2852 6.12199 11.0028 5.08278 10.4394 4.19246C9.87606 3.30214 9.05787 2.60192 8.09123 2.18284C7.12459 1.76376 6.05422 1.64521 5.0193 1.84261C3.98438 2.04 3.03279 2.54421 2.28827 3.28968C1.54375 4.03514 1.04075 4.98736 0.844661 6.02253C0.648575 7.0577 0.768479 8.12792 1.18878 9.09403C1.60909 10.0601 2.31035 10.8774 3.20138 11.4397C4.0924 12.0019 5.13198 12.283 6.18492 12.2465"
                  stroke="black"
                  stroke-width="1.16667"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M6 4.08331V6.99998L7.16667 8.16665"
                  stroke="black"
                  stroke-width="1.16667"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              {timeDifference && timeDifference}
            </div>
          </div>
        )}

        <div className={s.live__block}>
          <div className={s.gradient}></div>

          <img
            style={{ width: "100%", height: "100%", objectFit: "cover" }}
            src={image}
          />
        </div>
        <div className={s.live__content}>
          <h3 className={s.live__title}>
            {type}: {topic}
          </h3>
          <div className={s.live__person}>
            {!isMobile && (
              <div className={s.person__details}>
                <h4 className={s.person__name}>{renderTeacher(teacher)}</h4>
                <div className={s.dot}></div>
                <h5 className={s.live__type}>
                  Top rated Exceptional educators
                </h5>
              </div>
            )}
          </div>
          {isMobile ? (
            <>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <div>
                  <h4 className={s.person__name}>{renderTeacher(teacher)}</h4>
                  <h5 className={s.live__type}>
                    Top rated Exceptional educators
                  </h5>
                </div>
                <div>
                  <button
                    disabled={notified}
                    onClick={() => register()}
                    className={s.live__button}
                  >
                    {" "}
                    {notified ? `All Set!` : `Notify me`}
                    {notified ? <AllSet /> : <Notice />}
                  </button>
                </div>
              </div>
            </>
          ) : (
            <>
              <h5 className={s.students}>
                <People />
                1000+ Students Mentored
              </h5>
              <p className={s.live_end}>{parseAndFormatTimeRange(startTime)}</p>
              <div className={s.live__actions}>
                <button
                  disabled={notified}
                  onClick={() => register()}
                  className={s.live__button}
                >
                  {" "}
                  {notified ? `All Set!` : `Notify me`}
                  {notified ? <AllSet /> : <Notice />}
                </button>
                <div>{mapOfType[type]}</div>

                <Timer timeDifference={timeDifference} />
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default Next;
