import React, { useState, memo } from 'react';
import { claimReward } from 'api/loyaltyApi';
import ClaimScreen from 'pages/ReferralPage/Reward/Pass/ClaimScreen/ClaimScreen';
import ProgressBar from 'pages/ReferralPage/Reward/Pass/ProgressBar/ProgressBar';
import s from '../WithReferral.module.scss';
const PassWithReferral = ({ title, id, actual, target, claimed }) => {
  const [internalClaimed, setInternalClaimed] = useState(claimed);

  async function claim(id) {
    const response = await claimReward(id);
    if (response.status === 200) {
      setInternalClaimed(true);
    }
  }

  return (
    <div>
      {actual >= target && target !== 0 ? (
        <ClaimScreen
          title={title}
          onClaim={claim}
          id={id}
          actual={actual}
          target={target}
          internalClaimed={internalClaimed}
        />
      ) : (
        <div className={s.pass__card}>
          <div className={s.pass__card_content}>
            <div className={s.pass__card__block}>
              <div className={s.pass__card_count}>{target}</div>
              <h4 className={s.pass__card__text}>{title}</h4>
            </div>
          </div>
          <ProgressBar percentage={target > 0 ? (actual / target > 1 ? 100 : ~~((actual / target) * 100)) : 0} />
        </div>
      )}
    </div>
  );
};

export default memo(PassWithReferral);
