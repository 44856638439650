import { UPDATE_TUB_INDEX } from 'actions/myIeltsTubActions';

const initialState = {
  tubIndex: 'first',
};

const MyIeltsTubReduce = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_TUB_INDEX:
      return {
        ...state,
        tubIndex: action.index,
      };

    default:
      return state;
  }
};

export default MyIeltsTubReduce;
