import React from "react";
import { ReactComponent as AppleStoreIcon } from "../../../assets/kandor-img/apple_logo.svg";
import { ReactComponent as GooglePlayWhiteIcon } from "../../../assets/kandor-img/google-play-white.svg";

const DownloadCard = ({ handleClick, url, type }) => {
  return (
    <a href={url} onClick={handleClick} target="_blank">
      <div className="download_app">
        <h4 className="download_app-title">
          Now get Kandor on your phone as well 🎉
        </h4>
        <div style={{ display: "flex", justifyContent: "center" }}>
          {type === "ios" ? (
            <button className="download_app-button">
              {/* <span className="sidebar-googleplay__icon">
											<AppleStoreIcon />
              </span> */}
              Download App
            </button>
          ) : (
            <button className="download_app-button">
              {/* <span className="sidebar-googleplay__icon">
											<GooglePlayWhiteIcon />
              </span> */}
              Download App
            </button>
          )}
        </div>
      </div>
    </a>
  );
};

export default DownloadCard;
