import React, { useState, useEffect, useRef } from 'react'

import { updateContentTracker } from '../api/userApi.js'

import { ReactComponent as VideoIcon } from '../assets/img/video-icon.svg'
import { ReactComponent as PlayIcon } from '../assets/kandor-img/play-white.svg'
import { ReactComponent as ArrowIcon } from '../assets/kandor-img/arrow-down.svg'
import { ReactComponent as CheckIcon } from '../assets/kandor-img/check.svg'

import './newChanges/MaterialAccordion.scss'
import YoutubeModal from 'kandor-components/YoutubeModal'
import VimeoModal from 'kandor-components/VimeoModal'

const MaterialAccordion = ({
	oneOpen,
	isActiveAcc,
	openAccordion,
	closeAccordion,
	startPractice,
	studyMaterial,
	openVideo,
	handleEndVideo = () => {},
	isFirstWatched = false,
	data: { titleGroup, numberGroup, data },
}) => {
	const [isActive, setIsActive] = useState(isActiveAcc)
	const [height, setHeight] = useState(0)
	const [isWatchedGroup, setIsWatchedGroup] = useState(false)
	const [isWatchedList, setIsWatchedList] = useState([])

	const contentRef = useRef(null)

	useEffect(() => {
		if (data?.length) {
			let isWatched = true

			data.forEach((el) => {
				isWatched = isWatched && el.isWatched
			})

			setIsWatchedGroup(isWatched || (numberGroup == 1 && isFirstWatched))
		}
	}, [data, isFirstWatched])

	useEffect(() => {
		if (isActiveAcc != isActive) {
			setIsActive(isActiveAcc)
		}

		if (isActiveAcc && !height) {
			if (contentRef.current) {
				const heightContent = contentRef.current.offsetHeight + 126

				if (heightContent) {
					setHeight(heightContent)
				}
			}
		} else if (!isActiveAcc && height) {
			setHeight(0)
		}
	}, [isActiveAcc, contentRef])

	const handleClick = () => {
		if (isActive) {
			setIsActive(false)
			setHeight(0)
			if (oneOpen) closeAccordion()
		} else {
			if (contentRef.current) {
				const heightContent = contentRef.current.offsetHeight + 26

				if (heightContent) {
					setHeight(heightContent)
				}
			}
			setIsActive(true)
			if (oneOpen) openAccordion()
		}
	}

	const endVideo = (i) => {
		setIsWatchedGroup(true)
		handleEndVideo()
		setIsActive(false)
		setHeight(0)
		if (oneOpen) closeAccordion()
		const newIsWatched = [...isWatchedList]
		newIsWatched.push(i)
		console.log('newIsWatched', newIsWatched)
		setIsWatchedList(newIsWatched)
	}

	const getIsWatched = (i) => {
		let result = false

		if (Array.isArray(isWatchedList))
			isWatchedList.forEach((el) => {
				if (el == i) {
					result = true
				}
			})

		return result
	}

	return (
		<div className="material-accord">
			<div className="material-accord__head" onClick={handleClick}>
				<span
					className={`material-accord__title ${
						data[0]?.title ? '' : 'loading'
					}`}
				>
					{data[0]?.title}
				</span>
				{isWatchedGroup ? (
					<div className="material-done">
						<CheckIcon />
					</div>
				) : (
					''
				)}
				<span className={`material-accord__arrow ${isActive ? 'active' : ''}`}>
					<ArrowIcon />
				</span>
			</div>
			<div
				className="material-accord__content"
				style={{ maxHeight: height + 'px' }}
			>
				<div className="material-accord__inner" ref={contentRef}>
					{data.map((item, i) => (
						<div className="material-accord__item" key={i}>
							<MaterialAccordionCard
								id={item.contentId}
								title={item.title}
								type={item?.metadata?.type}
								imgUrl={item?.metadata?.imgUrl}
								videoUrl={item?.metadata?.videoUrl}
								duration={item?.metadata?.duration}
								isWatched={
									item?.isWatched ||
									(i === 0 && isFirstWatched && numberGroup == 1) ||
									getIsWatched(i)
								}
								isVimeo={item?.isVimeo}
								startPractice={startPractice}
								moreVideo={studyMaterial}
								currentTip={item?.contentCategory}
								titleGroup={titleGroup}
								handleClick={(e) => {
									e.preventDefault()
									openVideo()
								}}
								handleEndVideo={() => endVideo(i)}
							/>
						</div>
					))}
				</div>
			</div>
		</div>
	)
}

export default MaterialAccordion

const MaterialAccordionCard = ({
	id,
	title,
	type,
	imgUrl,
	isWatched,
	handleClick,
	currentTip,
	startPractice,
	moreVideo,
	isVimeo,
	duration,
	titleGroup,
	handleEndVideo,
	videoUrl = '',
}) => {
	const [showVideo, setShowVideo] = useState(false)
	const [storiesData, setStoriesData] = useState([])
	const [srcImg, setSrcImg] = useState('')
	const [srcWebp, setSrcWebp] = useState('')

	useEffect(() => {
		if (!imgUrl) return

		const getImg = async () => {
			let resource = ''
			let resourceWebp = ''
			let nameImg = imgUrl.replace('.png', '')
			const nameWebp = nameImg.split(' ')
			const newArr = nameWebp.filter((name) => name != ' ')
			const imgNameUrl = newArr.join('')

			resource = await import(`../assets/thumbnails/${imgNameUrl}.png`)
			resourceWebp = await import(`../assets/thumbnails/${imgNameUrl}.webp`)

			setSrcWebp(resourceWebp?.default)
			setSrcImg(resource?.default)
		}

		getImg()
	}, [imgUrl])

	useEffect(() => {
		if (videoUrl) {
			setStoriesData([
				{
					url: videoUrl,
					type: 'video',
					id,
				},
			])
		}
	}, [videoUrl])

	const [isImgLoaded, setIsImgLoaded] = useState(false)

	const handleLoadedImg = () => {
		setIsImgLoaded(true)
	}

	return (
		<div
			className={`study-card`}
			style={{ cursor: videoUrl ? 'pointer' : 'auto' }}
			onClick={(e) => {
				if (videoUrl) {
					handleClick(e)
					setShowVideo(true)
				}
			}}
		>
			<div className="study-card__img">
				<picture>
					<source type="image/webp" srcSet={srcWebp} />
					<img
						className={`${isImgLoaded ? 'active' : ''}`}
						src={srcImg}
						alt="img"
						onLoad={handleLoadedImg}
					/>
				</picture>

				{!isImgLoaded ? <div className="study-card__loader"></div> : ''}
				<div className="study-card__icon">
					<PlayIcon />{' '}
				</div>
			</div>
			<div className="study-card__content">
				<span className="study-card__title">{titleGroup}</span>
				<span className="study-card__type">
					{type} <span></span> {duration} mins
				</span>
			</div>
			{isWatched ? (
				<div className="material-done">
					<CheckIcon />
				</div>
			) : (
				''
			)}

			<VimeoModal
				stories={storiesData}
				show={showVideo}
				withFooter={true}
				withModal={false}
				currentTip={currentTip}
				handleClose={() => setShowVideo(false)}
				handlePractice={startPractice}
				handleMore={moreVideo}
				handleEndVideo={handleEndVideo}
			/>
		</div>
	)
}
