import React from 'react';
import WebinarBanner from '../../assets/img/webinar__banner/banner.png';
import PCWebinarBanner from '../../assets/img/webinar__banner/pc.png';

import './WebinarPopup.css'; // Optional, for styling
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { useMediaQuery } from 'react-responsive';

const WebinarPopup = ({ handleClosePopup }) => {
  const history = useHistory();
  const isMobile = useMediaQuery({ query: '(max-width:767px)' });
  const onGoToWebinar = () => {
    history.push({
      pathname: '/admin/join_webinar',
      state: { redirectToZoom: true },
    });
    sessionStorage.setItem('webinarPopupClosed', 'true');
  };

  const handleClose = () => {
    handleClosePopup();
  };

  return (
    <div className="popup-overlay">
      <div onClick={() => onGoToWebinar()} className="popup-content">
        <img src={isMobile ? WebinarBanner : PCWebinarBanner} alt="Webinar Banner" />
      </div>
    </div>
  );
};

export default WebinarPopup;
