import s from '../SopBanner/SopBanner.module.scss';
import { ReactComponent as Arrow } from '../../assets/icons/sopBanner/Arrow 3.svg';
import Photo1 from '../../assets/icons/sopBanner/fisrt-photo.png';
import Photo2 from '../../assets/icons/sopBanner/second-photo.png';
import Photo3 from '../../assets/icons/sopBanner/third-photo.png';
import { ReactComponent as Star } from '../../assets/icons/sopBanner/bi_stars.svg';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
const mockData = [
  {
    id: 1,
    text: 'Colleges for you',
    img: Photo1,
    route: '/admin/shortlister',
  },
  {
    id: 2,
    text: 'Scholarships for you',
    img: Photo2,
  },
  {
    id: 3,
    text: 'Your Counselor',
    img: Photo3,
    route: '/admin/counsellors',
  }, 
];

export const InfoStudyAbroadBanner = () => {
  const history = useHistory();
  const goToShortlist = () => {
    history.push('/admin/shortlister');
  };

  const goToPage = route => {
    history.push(route);
  };
  return (
    <div className={s.sopBanner}>
      <div className={s.sop__star}>
        <Star />
      </div>
      <div className={s.sopBanner__wrap}>
        <div className={s.sopBanner__text}>
          {/* <div className={s.sopBanner__icon}>
            <EditIcon />
          </div> */}
          <div className={s.sopBanner__info}>
            <h2 className={s.sopBanner__info_title}>ADVANCED AI</h2>
            <p className={s.sopBanner__info_desc}>College Finder</p>
          </div>
        </div>
        <div className={s.sopBanner__block}>
          {mockData.map(el => (
            <div key={el.key} onClick={() => goToPage(el.route)} className={s.sopBanner__item}>
              <div className={s.sopBanner__item_icon}>
                <img src={el.img} alt={el.text} />
              </div>
              <span className={s.sopBanner__item_text}>{el.text}</span>
            </div>
          ))}
        </div>
        <div className={s.sopBanner__button_container}>
          <button onClick={() => goToShortlist()} className={s.sopBanner__button}>
            Try Now <Arrow />
          </button>
        </div>
      </div>
    </div>
  );
};
