import React, { useCallback } from "react";
import styles from "./MarketplaceForm.module.scss";

const MarketplaceFormTimeLine = React.memo(({ setData, data, errors }) => {
  const handleFieldChange = useCallback(
    (fieldName, value) => {
      setData(prev => ({ ...prev, [fieldName]: value }));
    },
    [setData]
  );

  const targetRanges = [
    "May-Sept '24",
    "Jan '25",
    "May-Sept '25",
    "Not Yet Decided",
  ];

  const answerRanges = ["Yes", "No"];

  return (
    <>
      <form className={styles.radioButtonForm}>
        <div className={styles.formGroup}>
          {
            <span
              style={errors.Target && !data.Target ? {} : { opacity: 0 }}
              className={styles.error}
            >
              * This is a compulsory field
            </span>
          }
          <label className={styles.label}>
            Which intake are you targeting? *
          </label>
          <div className={styles.radioGroup}>
            {targetRanges.map(ageRange => (
              <label key={ageRange} className={styles.radioLabel}>
                <input
                  type="radio"
                  value={ageRange}
                  checked={data.Target === ageRange}
                  onChange={() => handleFieldChange("Target", ageRange)}
                />
                {ageRange}
              </label>
            ))}
          </div>
        </div>
      </form>
      <form style={{ marginTop: "15px" }} className={styles.radioButtonForm}>
        <div className={styles.formGroup}>
          {
            <span
              style={errors.BookDate && !data.BookDate ? {} : { opacity: 0 }}
              className={styles.error}
            >
              * This is a compulsory field
            </span>
          }
          <label className={styles.label}>
            Have you booked your IELTS Date?*
          </label>
          <div className={styles.radioGroup}>
            {answerRanges.map(ageRange => (
              <label key={ageRange} className={styles.radioLabel}>
                <input
                  type="radio"
                  value={ageRange}
                  checked={data.BookDate === ageRange}
                  onChange={() => handleFieldChange("BookDate", ageRange)}
                />
                {ageRange}
              </label>
            ))}
          </div>
        </div>
      </form>
    </>
  );
});

export default MarketplaceFormTimeLine;
