import React from 'react';
import s from './MobileShortlisterBanner.module.scss';
import { ReactComponent as Wallet } from '../../assets/icons/shortlisterBanner/wallet.svg';
import { ReactComponent as Star } from '../../assets/icons/shortlisterBanner/bi_stars.svg';
import { ReactComponent as Clock } from '../../assets/icons/shortlisterBanner/ph_clock-duotone.svg';
import { ReactComponent as ArrowIcon } from '../../assets/icons/shortlisterBanner/arrow.svg';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

const MobileShortlisterBanner = ({ type, goToLogin }) => {
  const history = useHistory();

  const goToShortlister = () => {
    if (type === 'shortlist') {
      history.push('/signup');
    } else {
      history.push('/admin/shortlister');
    }
  };

  return (
    <div className={s['mobile-banner']}>
      <div className={s['mobile-banner__wrap']}>
        <div className={s['mobile-banner__content']}>
          <div className={s['mobile-banner__block']}>
            <div className={s['mobile-banner__icon']}>
              <Wallet />
            </div>
            <div>
              <h2 className={s['mobile-banner__title']}>ADVANCED AI</h2>
              <p className={s['mobile-banner__subtitle']}>AI COLLEGE SHORTLISTER</p>
            </div>
          </div>

          <div className={s['mobile-banner__star']}>
            <Star />
          </div>
        </div>

        <div className={s['mobile-banner__buttons']}>
          <p className={s['mobile-banner__time']}>
            <Clock />2 Minutes
          </p>

          <button onClick={() => goToShortlister()} className={s['mobile-banner__start']}>
            START NOW <ArrowIcon />
          </button>
        </div>
      </div>
    </div>
  );
};

export default MobileShortlisterBanner;
