import React from "react";
import { ReactComponent as Chat } from "../../../../assets/icons/banner/chat-icon.svg";
import { ReactComponent as Star } from "../../../../assets/icons/banner/star.svg";
import mixpanel from 'mixpanel-browser';
import { useMediaQuery } from 'react-responsive'
import "./../Banner.scss";

const BannerChat = ({openChatWithMess, openChatHandler}) => {
  const isMobile = useMediaQuery({ query: '(max-width:768px)' })
  return (
    <div className="intro-banner intro-banner--three" onClick={() => {
      if (isMobile) {
        openChatHandler()
      }
    }}>
      <div className="intro-banner__wrap">
        <div className="intro-banner__descr">
          <span className="intro-banner__tag">NEW</span>
          <h5 className="intro-banner__title">
              INTRODUCING KODA AI
              <Star/>
          </h5>
          <p className="intro-banner__text intro-banner__text--white">
            Talk to Koda, for ALL study abroad related queries
          </p>
          <button type='button' className="intro-banner__btn btn-hover" onClick={() => {
            if (!isMobile) {
              openChatHandler()
            }
          }}>
            Start Chatting
          </button>
        </div>
        <div className="intro-banner__info">
          <button type="button" className="intro-banner__inform" onClick={() => {
            openChatWithMess('Cost of living in US')
            mixpanel.track('Clicked on Koda from Banner Cost', {});
          }}>
            <Chat/>
            Cost of living in US</button>
          <button type="button" className="intro-banner__inform" onClick={() => {
            openChatWithMess('Top Programs in Canada')
            mixpanel.track('Clicked on Koda from Banner Programs', {});
          }}>
            <Chat/>
            Top Programs in Canada</button>
          <button type='button' className="intro-banner__btn btn-hover" onClick={() => { 
            openChatHandler()
            mixpanel.track('Clicked on Koda from Banner', {});
          } } >
            Start Chatting
          </button>
        </div>
      </div>
    </div>
  )
}

export default BannerChat