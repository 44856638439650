import React from 'react'
import PracticeCard from 'kandor-components/PracticeCard'
import { Row, Col } from 'reactstrap'

const CardList = ({
	isPremium,
	isLoadingDetails,
	dataFree,
	dataPremium,
	section,
	practice_prompt,
	continueAttempts = [],
	loadedContinue,
	withContinue,
	isMock = false,
}) => {
	return (
		<>
			{!isLoadingDetails &&
			(dataFree.length || dataPremium.length || continueAttempts.length) ? (
				<Row className="card-wrap">
					{continueAttempts.map((data, index) => (
						<Col
							xs="12"
							md="6"
							lg="4"
							sm="12"
							key={data.id}
							className="questions-card"
						>
							<PracticeCard
								data={data}
								listLength={continueAttempts.length}
								practice_prompt={practice_prompt}
								index={index}
								isFree={true}
								section={section}
								isMock={isMock}
								isContinue={true}
							/>
						</Col>
					))}

					{dataFree.map((data, index) => (
						<Col
							xs="12"
							md="6"
							lg="4"
							sm="12"
							key={data.id}
							className="questions-card"
						>
							<PracticeCard
								data={data}
								listLength={dataFree.length}
								practice_prompt={practice_prompt}
								index={index}
								isFree={true}
								section={section}
								isMock={isMock}
							/>
						</Col>
					))}

					{dataPremium.map((data, index) => (
						<Col
							xs="12"
							md="6"
							lg="4"
							sm="12"
							key={data.id}
							className="questions-card"
						>
							<PracticeCard
								data={data}
								listLength={dataPremium.length}
								practice_prompt={practice_prompt}
								index={index}
								isFree={isPremium}
								section={section}
								isMock={isMock}
							/>
						</Col>
					))}
				</Row>
			) : (
				<Row className="card-wrap">
					{[1, 2, 3].map((item, index) => (
						<Col
							xs="12"
							md="6"
							lg="4"
							sm="12"
							key={index}
							className="questions-card"
						>
							<PracticeCard
								listLength={0}
								isEmpty={true}
								index={index}
								isFree={true}
								section={section}
							/>
						</Col>
					))}
				</Row>
			)}
		</>
	)
}

export default CardList
