import React from 'react';
import s from './HeaderApply.module.scss';

const HeaderApply = () => {
  return (
    <div className={s.headerApply}>
      <div className={s.headerApply__wrap}>
        <div className={s.headerApply__title}>
          <h2>Your Study Abroad Journey </h2>
        </div>
      </div>
    </div>
  );
};

export default HeaderApply;
