import { isApp, isIOSApp } from 'actions/referrer';
import { matchPath, Redirect, Route } from 'react-router-dom';
import Loader from './../pages/Loader';

export const PrivateRoute = ({
  component: Component,
  isAuthenticated,
  isVerifying,
  isOnboarded,
  isOnboardingVerifying,
  ...rest
}) => {
  return (
    <Route
      {...rest}
      render={props =>
        isVerifying || isOnboardingVerifying ? (
          <Loader />
        ) : isAuthenticated ? (
          // isOnboarded === null ? (
          // <Loader />
          // ) :
          isOnboarded === true ? (
            <div>
              <Component {...props} />
            </div>
          ) : isApp() || isIOSApp() ? (
            <Redirect
              to={{
                pathname: '/register-app',
                state: { from: props.location },
              }}
            />
          ) : (
            <Redirect
              to={{
                pathname: '/signup',
                state: { from: props.location },
              }}
            />
          )
        ) : matchPath(window.location.pathname, {
            path: '/admin/video/:videoId',
          }) ? (
          isApp() ? (
            <Redirect
              to={{
                pathname: '/register-app',
                state: { from: props.location },
              }}
            />
          ) : (
            <Redirect
              to={{
                pathname: '/signup',
                state: { from: props.location },
              }}
            />
          )
        ) : matchPath(window.location.pathname, {
            path: '/admin/join_class',
          }) ||
          matchPath(window.location.pathname, {
            path: '/admin/special-offer',
          }) ||
          window.location.hash ? (
          isApp() || isIOSApp() ? (
            <Redirect
              to={{
                pathname: '/register-app',
                state: { from: props.location },
              }}
            />
          ) : (
            <Redirect
              to={{
                pathname: '/signup',
                state: { from: props.location },
              }}
            />
          )
        ) : isApp() || isIOSApp() ? (
          <Redirect
            to={{
              pathname: '/register-app',
              state: { from: props.location },
            }}
          />
        ) : (
          <Redirect
            to={{
              pathname: '/default',
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
};

export default PrivateRoute;
