export const cardIconsReading = {
  'True False Not Given / Yes No Not Given Questions':
    'https://kandor-public.s3.us-east-2.amazonaws.com/public-content/list+selection+-+truefalse.svg',
  'Matching features':
    'https://kandor-public.s3.us-east-2.amazonaws.com/public-content/category+matching.svg',
  'Matching Headings':
    'https://kandor-public.s3.us-east-2.amazonaws.com/public-content/matching+heading.svg',
  'Matching Information':
    'https://kandor-public.s3.us-east-2.amazonaws.com/public-content/matching+paragraph+information.svg',
  'Matching sentence ending':
    'https://kandor-public.s3.us-east-2.amazonaws.com/public-content/sentence+completion.svg',
  'Sentence completion':
    'https://kandor-public.s3.us-east-2.amazonaws.com/public-content/sentence+completion.svg',
  'Short answer questions':
    'https://kandor-public.s3.us-east-2.amazonaws.com/public-content/sentence+completion.svg',
  'Table completion':
    'https://kandor-public.s3.us-east-2.amazonaws.com/public-content/table+completion.svg',
};

export const tagsShotNameReading = {
  'True False Not Given / Yes No Not Given Questions': 'True False Not Given',
  'Matching features': 'Match Features',
  'Matching Headings': 'Match Heading',
  'Matching Information': 'Matching Information',
  'Matching sentence ending': 'Match Endings',
  'Sentence completion': 'Complete Sentence',
  'Short answer questions': 'Short Answer',
  'Table completion': 'Table',
};

export const cardIconsListening = {
  'True False Not Given / Yes No Not Given Questions':
    'https://kandor-public.s3.us-east-2.amazonaws.com/public-content/list+selection+-+truefalse.svg',
  'Matching features':
    'https://kandor-public.s3.us-east-2.amazonaws.com/public-content/category+matching.svg',
  'Matching Headings':
    'https://kandor-public.s3.us-east-2.amazonaws.com/public-content/matching+heading.svg',
  'Matching Information':
    'https://kandor-public.s3.us-east-2.amazonaws.com/public-content/matching+paragraph+information.svg',
  Completion:
    'https://kandor-public.s3.us-east-2.amazonaws.com/public-content/sentence+completion.svg',
  'Sentence completion':
    'https://kandor-public.s3.us-east-2.amazonaws.com/public-content/sentence+completion.svg',
  'Short answer questions':
    'https://kandor-public.s3.us-east-2.amazonaws.com/public-content/sentence+completion.svg',
  'Table completion':
    'https://kandor-public.s3.us-east-2.amazonaws.com/public-content/table+completion.svg',
};

export const cardIconsWriting = {
  Discuss:
    'https://kandor-public.s3.us-east-2.amazonaws.com/writing/discussion.svg',
  Advantage:
    'https://kandor-public.s3.us-east-2.amazonaws.com/writing/advantages.svg',
  Solution:
    'https://kandor-public.s3.us-east-2.amazonaws.com/writing/solutions.svg',
  Opinion:
    'https://kandor-public.s3.us-east-2.amazonaws.com/writing/opinions.svg',
  Barchart:
    'https://kandor-public.s3.us-east-2.amazonaws.com/writing/barchart.svg',
  Diagram:
    'https://kandor-public.s3.us-east-2.amazonaws.com/writing/diagram.svg',
  Table: 'https://kandor-public.s3.us-east-2.amazonaws.com/writing/table.svg',
  Piechart:
    'https://kandor-public.s3.us-east-2.amazonaws.com/writing/piechart.svg',
  Formal:
    'https://kandor-public.s3.us-east-2.amazonaws.com/writing/letter_formal.svg',
  'Semi Formal':
    'https://kandor-public.s3.us-east-2.amazonaws.com/writing/letter_semiformal.svg',
  Informal:
    'https://kandor-public.s3.us-east-2.amazonaws.com/writing/letter_informal.svg',
};

export const counselingBenefitsList1 = [
  'Premium counselors',
  'Scholarships and visa support',
  'Pre IELTS offers',
];

export const dataVideo = [
  {
    body: null,
    contentCategory: 'Dashboard',
    contentId: '1331b797-59a8-11ed-843f-025d14ee7c92',
    created: 'Tue, 01 Nov 2022 05:43:12 GMT',
    isLiked: null,
    isVimeo: 1,
    isVisited: 1,
    isWatched: null,
    media: null,
    metadata: {
      imgUrl: 'intro to kandor.png',
      videoUrl: '765218635',
      type: 'video',
      duration: '1',
      videoNo: 2,
      teacher: 'Ms. Banojyotsna Ganguli',
    },
    numberGroup: 2,
    title: 'Getting started with Kandor',
    titleGroup: 'Getting started with Kandor',
    updated: null,
    userId: '894eIjFhH7M0jT1KydhennxOs8M2',
    'ut.contentCategory': null,
    'ut.contentId': '1331b797-59a8-11ed-843f-025d14ee7c92',
    'ut.created': 'Thu, 01 Dec 2022 19:53:54 GMT',
  },
  {
    body: null,
    contentCategory: 'Writing',
    contentId: '13afc8b4-59a8-11ed-843f-025d14ee7c92',
    created: 'Tue, 01 Nov 2022 05:43:13 GMT',
    isLiked: null,
    isVimeo: 1,
    isVisited: null,
    isWatched: null,
    media: null,
    metadata: {
      imgUrl: 'Writing Lesson 1 - Full Test Introduction.png',
      videoUrl: '765423196',
      type: 'video',
      duration: '6',
      videoNo: 1,
      teacher: 'Ms. Banojyotsna Ganguli',
    },
    numberGroup: 1,
    title: 'Full Test Introduction',
    titleGroup: 'Writing Lesson 1',
    updated: null,
    userId: null,
    'ut.contentCategory': null,
    'ut.contentId': null,
    'ut.created': null,
  },
  {
    body: null,
    contentCategory: 'Writing',
    contentId: '142c992a-59a8-11ed-843f-025d14ee7c92',
    created: 'Tue, 01 Nov 2022 05:43:14 GMT',
    isLiked: null,
    isVimeo: 1,
    isVisited: null,
    isWatched: null,
    media: null,
    metadata: {
      imgUrl: 'Writing Lesson 2 - Guide & Tips.png',
      videoUrl: '765422246',
      type: 'video',
      duration: '14',
      videoNo: 2,
      teacher: 'Ms. Banojyotsna Ganguli',
    },
    numberGroup: 2,
    title: 'Guide & Tips',
    titleGroup: 'Writing Lesson 2',
    updated: null,
    userId: null,
    'ut.contentCategory': null,
    'ut.contentId': null,
    'ut.created': null,
  },
  {
    body: null,
    contentCategory: 'Writing',
    contentId: '14aa4140-59a8-11ed-843f-025d14ee7c92',
    created: 'Tue, 01 Nov 2022 05:43:15 GMT',
    isLiked: null,
    isVimeo: 1,
    isVisited: null,
    isWatched: null,
    media: null,
    metadata: {
      imgUrl: 'Writing Lesson 3 - Task 2 Questions.png',
      videoUrl: '765419903',
      type: 'video',
      duration: '6',
      videoNo: 3,
      teacher: 'Ms. Ligi Elisa Biju',
    },
    numberGroup: 3,
    title: 'Task 2 Questions',
    titleGroup: 'Writing Lesson 3',
    updated: null,
    userId: null,
    'ut.contentCategory': null,
    'ut.contentId': null,
    'ut.created': null,
  },
  {
    body: null,
    contentCategory: 'Writing',
    contentId: '1526aa25-59a8-11ed-843f-025d14ee7c92',
    created: 'Tue, 01 Nov 2022 05:43:16 GMT',
    isLiked: null,
    isVimeo: 1,
    isVisited: null,
    isWatched: null,
    media: null,
    metadata: {
      imgUrl: 'Writing Lesson 4 - Letter Writing - Formal & Informal.png',
      videoUrl: '765420496',
      type: 'video',
      duration: '10',
      videoNo: '4',
      teacher: 'Ms. Banojyotsna Ganguli',
    },
    numberGroup: 4,
    title: 'Letter Writing - Formal & Informal',
    titleGroup: 'Writing Lesson 4',
    updated: null,
    userId: null,
    'ut.contentCategory': null,
    'ut.contentId': null,
    'ut.created': null,
  },
  {
    body: null,
    contentCategory: 'Writing',
    contentId: '15a3c928-59a8-11ed-843f-025d14ee7c92',
    created: 'Tue, 01 Nov 2022 05:43:17 GMT',
    isLiked: null,
    isVimeo: 1,
    isVisited: null,
    isWatched: null,
    media: null,
    metadata: {
      imgUrl: 'Writing Lesson 5 - Essay Structure.png',
      videoUrl: '765423791',
      type: 'video',
      duration: '11',
      videoNo: '5',
      teacher: 'Ms. Banojyotsna Ganguli',
    },
    numberGroup: 5,
    title: 'Essay Structure',
    titleGroup: 'Writing Lesson 5',
    updated: null,
    userId: null,
    'ut.contentCategory': null,
    'ut.contentId': null,
    'ut.created': null,
  },
  {
    body: null,
    contentCategory: 'Writing',
    contentId: '1620c282-59a8-11ed-843f-025d14ee7c92',
    created: 'Tue, 01 Nov 2022 05:43:17 GMT',
    isLiked: null,
    isVimeo: 1,
    isVisited: null,
    isWatched: null,
    media: null,
    metadata: {
      imgUrl: 'Writing Lesson 6 - Bar Graph Essays.png',
      videoUrl: '765421264',
      type: 'video',
      duration: '15',
      videoNo: '6',
      teacher: 'Ms. Banojyotsna Ganguli',
    },
    numberGroup: 6,
    title: 'Bar Graph Essays',
    titleGroup: 'Writing Lesson 6',
    updated: null,
    userId: null,
    'ut.contentCategory': null,
    'ut.contentId': null,
    'ut.created': null,
  },
  {
    body: null,
    contentCategory: 'Writing',
    contentId: '169dd9a1-59a8-11ed-843f-025d14ee7c92',
    created: 'Tue, 01 Nov 2022 05:43:18 GMT',
    isLiked: null,
    isVimeo: 1,
    isVisited: null,
    isWatched: null,
    media: null,
    metadata: {
      imgUrl: 'Writing Lesson 7 - Pie Chart Essays.png',
      videoUrl: '765425139',
      type: 'video',
      duration: '13',
      videoNo: '7',
      teacher: 'Ms. Banojyotsna Ganguli',
    },
    numberGroup: 7,
    title: 'Pie Chart Essays',
    titleGroup: 'Writing Lesson 7',
    updated: null,
    userId: null,
    'ut.contentCategory': null,
    'ut.contentId': null,
    'ut.created': null,
  },
  {
    body: null,
    contentCategory: 'Writing',
    contentId: '171aaded-59a8-11ed-843f-025d14ee7c92',
    created: 'Tue, 01 Nov 2022 05:43:19 GMT',
    isLiked: null,
    isVimeo: 1,
    isVisited: null,
    isWatched: null,
    media: null,
    metadata: {
      imgUrl: 'Writing Lesson 8 - Line Graph Essays.png',
      videoUrl: '765425851',
      type: 'video',
      duration: '8',
      videoNo: '8',
      teacher: 'Ms. Banojyotsna Ganguli',
    },
    numberGroup: 8,
    title: 'Line Graph Essays',
    titleGroup: 'Writing Lesson 8 ',
    updated: null,
    userId: null,
    'ut.contentCategory': null,
    'ut.contentId': null,
    'ut.created': null,
  },
  {
    body: null,
    contentCategory: 'Writing',
    contentId: '1797c39d-59a8-11ed-843f-025d14ee7c92',
    created: 'Tue, 01 Nov 2022 05:43:20 GMT',
    isLiked: null,
    isVimeo: 1,
    isVisited: null,
    isWatched: null,
    media: null,
    metadata: {
      imgUrl: 'Writing Lesson 9 - Tables.png',
      videoUrl: '765425467',
      type: 'video',
      duration: '11',
      videoNo: '9',
      teacher: 'Ms. Banojyotsna Ganguli',
    },
    numberGroup: 9,
    title: '',
    titleGroup: 'Writing Lesson 9',
    updated: null,
    userId: null,
    'ut.contentCategory': null,
    'ut.contentId': null,
    'ut.created': null,
  },
  {
    body: null,
    contentCategory: 'Writing',
    contentId: '180582c9-59a8-11ed-843f-025d14ee7c92',
    created: 'Tue, 01 Nov 2022 05:43:21 GMT',
    isLiked: null,
    isVimeo: 1,
    isVisited: null,
    isWatched: null,
    media: null,
    metadata: {
      imgUrl: 'Writing Lesson 10 - Maps.png',
      videoUrl: '765407572',
      type: 'video',
      duration: '12',
      videoNo: '10',
      teacher: 'Ms. Banojyotsna Ganguli',
    },
    numberGroup: 10,
    title: 'Maps',
    titleGroup: 'Writing Lesson 10',
    updated: null,
    userId: null,
    'ut.contentCategory': null,
    'ut.contentId': null,
    'ut.created': null,
  },
  {
    body: null,
    contentCategory: 'Writing',
    contentId: '18795b3b-59a8-11ed-843f-025d14ee7c92',
    created: 'Tue, 01 Nov 2022 05:43:21 GMT',
    isLiked: null,
    isVimeo: 1,
    isVisited: null,
    isWatched: null,
    media: null,
    metadata: {
      imgUrl: 'Writing Lesson 11 - Life Cycle Essays.png',
      videoUrl: '765409308',
      type: 'video',
      duration: '12',
      videoNo: '11',
      teacher: 'Ms. Banojyotsna Ganguli',
    },
    numberGroup: 11,
    title: 'Life Cycle Essays',
    titleGroup: 'Writing Lesson 11',
    updated: null,
    userId: null,
    'ut.contentCategory': null,
    'ut.contentId': null,
    'ut.created': null,
  },
  {
    body: null,
    contentCategory: 'Writing',
    contentId: '18dfedb4-59a8-11ed-843f-025d14ee7c92',
    created: 'Tue, 01 Nov 2022 05:43:22 GMT',
    isLiked: null,
    isVimeo: 1,
    isVisited: null,
    isWatched: null,
    media: null,
    metadata: {
      imgUrl: 'Writing Lesson 12 - Process Essays.png',
      videoUrl: '765426525',
      type: 'video',
      duration: '9',
      videoNo: '12',
      teacher: 'Ms. Banojyotsna Ganguli',
    },
    numberGroup: 12,
    title: 'Process Essays',
    titleGroup: 'Writing Lesson 12',
    updated: null,
    userId: null,
    'ut.contentCategory': null,
    'ut.contentId': null,
    'ut.created': null,
  },
  {
    body: null,
    contentCategory: 'Writing',
    contentId: '195aeda1-59a8-11ed-843f-025d14ee7c92',
    created: 'Tue, 01 Nov 2022 05:43:23 GMT',
    isLiked: null,
    isVimeo: 1,
    isVisited: null,
    isWatched: null,
    media: null,
    metadata: {
      imgUrl: 'Writing Lesson 13 - Agree Disagree Essays.png',
      videoUrl: '765426761',
      type: 'video',
      duration: '15',
      videoNo: '13',
      teacher: 'Ms. Banojyotsna Ganguli',
    },
    numberGroup: 13,
    title: 'Agree Disagree Essays',
    titleGroup: 'Writing Lesson 13',
    updated: null,
    userId: null,
    'ut.contentCategory': null,
    'ut.contentId': null,
    'ut.created': null,
  },
  {
    body: null,
    contentCategory: 'Writing',
    contentId: '19da4961-59a8-11ed-843f-025d14ee7c92',
    created: 'Tue, 01 Nov 2022 05:43:24 GMT',
    isLiked: null,
    isVimeo: 1,
    isVisited: null,
    isWatched: null,
    media: null,
    metadata: {
      imgUrl: 'Writing Lesson 14 - Adv. & Disadvantage Essays.png',
      videoUrl: '765411703',
      type: 'video',
      duration: '15',
      videoNo: '14',
      teacher: 'Ms. Banojyotsna Ganguli',
    },
    numberGroup: 14,
    title: 'Adv. & Disadvantage Essays',
    titleGroup: 'Writing Lesson 14',
    updated: null,
    userId: null,
    'ut.contentCategory': null,
    'ut.contentId': null,
    'ut.created': null,
  },
  {
    body: null,
    contentCategory: 'Writing',
    contentId: '1a56f81c-59a8-11ed-843f-025d14ee7c92',
    created: 'Tue, 01 Nov 2022 05:43:24 GMT',
    isLiked: null,
    isVimeo: 1,
    isVisited: null,
    isWatched: null,
    media: null,
    metadata: {
      imgUrl: 'Writing Lesson 15 - Discussion & Opinion Essays.png',
      videoUrl: '765418693',
      type: 'video',
      duration: '12',
      videoNo: '15',
      teacher: 'Ms. Banojyotsna Ganguli',
    },
    numberGroup: 15,
    title: 'Discussion & Opinion Essays',
    titleGroup: 'Writing Lesson 15',
    updated: null,
    userId: null,
    'ut.contentCategory': null,
    'ut.contentId': null,
    'ut.created': null,
  },
  {
    body: null,
    contentCategory: 'Writing',
    contentId: '1ac462c0-59a8-11ed-843f-025d14ee7c92',
    created: 'Tue, 01 Nov 2022 05:43:25 GMT',
    isLiked: null,
    isVimeo: 1,
    isVisited: null,
    isWatched: null,
    media: null,
    metadata: {
      imgUrl: 'Writing Lesson 16 - Problem & Solution Essays.png',
      videoUrl: '765426009',
      type: 'video',
      duration: '15',
      videoNo: '16',
      teacher: 'Ms. Banojyotsna Ganguli',
    },
    numberGroup: 16,
    title: 'Problem & Solution Essays',
    titleGroup: 'Writing Lesson 16',
    updated: null,
    userId: null,
    'ut.contentCategory': null,
    'ut.contentId': null,
    'ut.created': null,
  },
  {
    body: null,
    contentCategory: 'Writing',
    contentId: '1b413dc8-59a8-11ed-843f-025d14ee7c92',
    created: 'Tue, 01 Nov 2022 05:43:26 GMT',
    isLiked: null,
    isVimeo: 1,
    isVisited: null,
    isWatched: null,
    media: null,
    metadata: {
      imgUrl: 'Writing Lesson 17 - 2 Types of Questions Essays.png',
      videoUrl: '765423259',
      type: 'video',
      duration: '13',
      videoNo: '17',
      teacher: 'Ms. Banojyotsna Ganguli',
    },
    numberGroup: 17,
    title: '2 Types of Questions Essays',
    titleGroup: 'Writing Lesson 17',
    updated: null,
    userId: null,
    'ut.contentCategory': null,
    'ut.contentId': null,
    'ut.created': null,
  },
  {
    body: null,
    contentCategory: 'Reading',
    contentId: '1d9dad79-97dc-4251-b2f2-6e1ed0227971',
    created: 'Fri, 03 Jun 2022 05:43:16 GMT',
    isLiked: null,
    isVimeo: null,
    isVisited: null,
    isWatched: null,
    media: null,
    metadata: {
      imgUrl: 'reading-diagram-labeling.png',
      videoUrl: '9qI2jAsZk9s',
      type: 'video',
    },
    numberGroup: null,
    title: 'IELTS Reading - Strategies for Diagram Labelling Questions',
    titleGroup: null,
    updated: null,
    userId: null,
    'ut.contentCategory': null,
    'ut.contentId': null,
    'ut.created': null,
  },
  {
    body: null,
    contentCategory: 'Speaking',
    contentId: '3332a475-5dbb-11ed-843f-025d14ee7c92',
    created: 'Sun, 06 Nov 2022 10:10:11 GMT',
    isLiked: null,
    isVimeo: 1,
    isVisited: 1,
    isWatched: 1,
    media: null,
    metadata: {
      imgUrl: 'Speaking Lesson 1 - Full Test Introduction.png',
      videoUrl: 'https://vimeo.com/765270121',
      type: 'video',
      duration: '15',
      videoNo: 1,
      teacher: 'Ms. Banojyotsna Ganguli',
    },
    numberGroup: 1,
    title: 'Full Test Introduction',
    titleGroup: 'Speaking Lesson 1',
    updated: 'Wed, 30 Nov 2022 18:32:43 GMT',
    userId: '894eIjFhH7M0jT1KydhennxOs8M2',
    'ut.contentCategory': null,
    'ut.contentId': '3332a475-5dbb-11ed-843f-025d14ee7c92',
    'ut.created': 'Wed, 30 Nov 2022 18:32:32 GMT',
  },
  {
    body: null,
    contentCategory: 'Speaking',
    contentId: '33bc320c-5dbb-11ed-843f-025d14ee7c92',
    created: 'Sun, 06 Nov 2022 10:10:12 GMT',
    isLiked: null,
    isVimeo: 1,
    isVisited: null,
    isWatched: null,
    media: null,
    metadata: {
      imgUrl: 'Speaking Lesson 2 - Common Tips & Tricks.png',
      videoUrl: 'https://vimeo.com/765346725',
      type: 'video',
      duration: '5',
      videoNo: 2,
      teacher: 'Ms. Banojyotsna Ganguli',
    },
    numberGroup: 2,
    title: 'Common Tips & Tricks',
    titleGroup: 'Speaking Lesson 2',
    updated: null,
    userId: null,
    'ut.contentCategory': null,
    'ut.contentId': null,
    'ut.created': null,
  },
  {
    body: null,
    contentCategory: 'Reading',
    contentId: '33fe69d8-ce1b-46f5-8266-22d99c7dfc8c',
    created: 'Fri, 03 Jun 2022 05:43:17 GMT',
    isLiked: null,
    isVimeo: null,
    isVisited: null,
    isWatched: null,
    media: null,
    metadata: {
      imgUrl: 'reading-mcq.png',
      videoUrl: 'Y_94qv9R8u0',
      type: 'video',
    },
    numberGroup: null,
    title: 'IELTS Reading Strategies - MCQs',
    titleGroup: null,
    updated: null,
    userId: null,
    'ut.contentCategory': null,
    'ut.contentId': null,
    'ut.created': null,
  },
  {
    body: null,
    contentCategory: 'Speaking',
    contentId: '3421ac3d-5dbb-11ed-843f-025d14ee7c92',
    created: 'Sun, 06 Nov 2022 10:10:13 GMT',
    isLiked: null,
    isVimeo: 1,
    isVisited: null,
    isWatched: null,
    media: null,
    metadata: {
      imgUrl: 'Speaking Lesson 3 - Part 1 Full Mock Interview.png',
      videoUrl: 'https://vimeo.com/765343486',
      type: 'video',
      duration: '10',
      videoNo: 3,
      teacher: 'Ms. Banojyotsna Ganguli',
    },
    numberGroup: 3,
    title: 'Part 1 Full Mock Interview',
    titleGroup: 'Speaking Lesson 3',
    updated: null,
    userId: null,
    'ut.contentCategory': null,
    'ut.contentId': null,
    'ut.created': null,
  },
  {
    body: null,
    contentCategory: 'Speaking',
    contentId: '3491bfe2-5dbb-11ed-843f-025d14ee7c92',
    created: 'Sun, 06 Nov 2022 10:10:14 GMT',
    isLiked: null,
    isVimeo: 1,
    isVisited: null,
    isWatched: null,
    media: null,
    metadata: {
      imgUrl: 'Speaking Lesson 4 - Part 1 Common Topics.png',
      videoUrl: 'https://vimeo.com/766118751',
      type: 'video',
      duration: '13',
      videoNo: 4,
      teacher: 'Ms. Banojyotsna Ganguli',
    },
    numberGroup: 4,
    title: 'Part 1 Common Topics',
    titleGroup: 'Speaking Lesson 4',
    updated: null,
    userId: null,
    'ut.contentCategory': null,
    'ut.contentId': null,
    'ut.created': null,
  },
  {
    body: null,
    contentCategory: 'Speaking',
    contentId: '3514128d-5dbb-11ed-843f-025d14ee7c92',
    created: 'Sun, 06 Nov 2022 10:10:14 GMT',
    isLiked: null,
    isVimeo: 1,
    isVisited: null,
    isWatched: null,
    media: null,
    metadata: {
      imgUrl: 'Speaking Lesson 5 - Intro to Cue Cards (Part 2).png',
      videoUrl: 'https://vimeo.com/765344535',
      type: 'video',
      duration: '21',
      videoNo: 5,
      teacher: 'Ms. Banojyotsna Ganguli',
    },
    numberGroup: 5,
    title: 'Intro to Cue Cards (Part 2)',
    titleGroup: 'Speaking Lesson 5',
    updated: null,
    userId: null,
    'ut.contentCategory': null,
    'ut.contentId': null,
    'ut.created': null,
  },
  {
    body: null,
    contentCategory: 'Speaking',
    contentId: '3590eeb8-5dbb-11ed-843f-025d14ee7c92',
    created: 'Sun, 06 Nov 2022 10:10:15 GMT',
    isLiked: null,
    isVimeo: 1,
    isVisited: null,
    isWatched: null,
    media: null,
    metadata: {
      imgUrl: 'Speaking Lesson 6 - Cue Cards, Speaking on Things.png',
      videoUrl: 'https://vimeo.com/765262468',
      type: 'video',
      duration: '7',
      videoNo: 6,
      teacher: 'Ms. Banojyotsna Ganguli',
    },
    numberGroup: 6,
    title: 'Cue Cards, Speaking on Things',
    titleGroup: 'Speaking Lesson 6',
    updated: null,
    userId: null,
    'ut.contentCategory': null,
    'ut.contentId': null,
    'ut.created': null,
  },
  {
    body: null,
    contentCategory: 'Speaking',
    contentId: '360de3a4-5dbb-11ed-843f-025d14ee7c92',
    created: 'Sun, 06 Nov 2022 10:10:16 GMT',
    isLiked: null,
    isVimeo: 1,
    isVisited: null,
    isWatched: null,
    media: null,
    metadata: {
      imgUrl: 'Speaking Lesson 7 - Cue Cards, Speaking on Places.png',
      videoUrl: 'https://vimeo.com/765273831',
      type: 'video',
      duration: '16',
      videoNo: 7,
      teacher: 'Ms. Banojyotsna Ganguli',
    },
    numberGroup: 7,
    title: 'Cue Cards, Speaking on Places',
    titleGroup: 'Speaking Lesson 7',
    updated: null,
    userId: null,
    'ut.contentCategory': null,
    'ut.contentId': null,
    'ut.created': null,
  },
  {
    body: null,
    contentCategory: 'Speaking',
    contentId: '368b5881-5dbb-11ed-843f-025d14ee7c92',
    created: 'Sun, 06 Nov 2022 10:10:17 GMT',
    isLiked: null,
    isVimeo: 1,
    isVisited: null,
    isWatched: null,
    media: null,
    metadata: {
      imgUrl: 'Speaking Lesson 8 - Part 2 Common Topics.png',
      videoUrl: 'https://vimeo.com/766121275',
      type: 'video',
      duration: '9',
      videoNo: 8,
      teacher: 'Ms. Banojyotsna Ganguli',
    },
    numberGroup: 8,
    title: 'Part 2 Common Topics',
    titleGroup: 'Speaking Lesson 8',
    updated: null,
    userId: null,
    'ut.contentCategory': null,
    'ut.contentId': null,
    'ut.created': null,
  },
  {
    body: null,
    contentCategory: 'Reading',
    contentId: '5a45c606-95e1-4fa7-a08a-fb0665139a4c',
    created: 'Fri, 03 Jun 2022 05:43:18 GMT',
    isLiked: null,
    isVimeo: null,
    isVisited: null,
    isWatched: null,
    media: null,
    metadata: {
      imgUrl: 'reading-summary-based.png',
      videoUrl: 'PPIiGACY2aM',
      type: 'video',
    },
    numberGroup: null,
    title: 'IELTS Reading Strategies - Summary based Questions',
    titleGroup: null,
    updated: null,
    userId: null,
    'ut.contentCategory': null,
    'ut.contentId': null,
    'ut.created': null,
  },
  {
    body: null,
    contentCategory: 'Listening',
    contentId: '608a2ad2-5859-11ed-843f-025d14ee7c92',
    created: 'Sun, 30 Oct 2022 13:47:21 GMT',
    isLiked: null,
    isVimeo: 1,
    isVisited: null,
    isWatched: null,
    media: null,
    metadata: {
      imgUrl: 'IELTS Listening - Section 2 - Part 2.png',
      videoUrl: '765278971',
      type: 'video',
      duration: '23',
      videoNo: 5,
      teacher: 'Ms. Banojyotsna Ganguli',
    },
    numberGroup: 5,
    title: 'Section 2 Guide & Tips Part B',
    titleGroup: 'Listening Lesson 5',
    updated: null,
    userId: null,
    'ut.contentCategory': null,
    'ut.contentId': null,
    'ut.created': null,
  },
  {
    body: null,
    contentCategory: 'Listening',
    contentId: '608b878a-5859-11ed-843f-025d14ee7c92',
    created: 'Sun, 30 Oct 2022 13:47:21 GMT',
    isLiked: null,
    isVimeo: 1,
    isVisited: null,
    isWatched: null,
    media: null,
    metadata: {
      imgUrl: 'IELTS Listening - Section 3.png',
      type: 'video',
      duration: '17',
      videoUrl: '765279050',
      videoNo: 6,
      teacher: 'Ms. Banojyotsna Ganguli',
    },
    numberGroup: 6,
    title: 'Section 3 Guide & Tips',
    titleGroup: 'Listening Lesson 6',
    updated: null,
    userId: null,
    'ut.contentCategory': null,
    'ut.contentId': null,
    'ut.created': null,
  },
  {
    body: null,
    contentCategory: 'Listening',
    contentId: '608ca773-5859-11ed-843f-025d14ee7c92',
    created: 'Sun, 30 Oct 2022 13:47:21 GMT',
    isLiked: null,
    isVimeo: 1,
    isVisited: null,
    isWatched: null,
    media: null,
    metadata: {
      imgUrl: 'IELTS Listening - Section 4.png',
      videoUrl: '765279179',
      type: 'video',
      duration: '15',
      videoNo: 7,
      teacher: 'Ms. Banojyotsna Ganguli',
    },
    numberGroup: 7,
    title: 'Section 4 Guide & Tips',
    titleGroup: 'Listening Lesson 7',
    updated: null,
    userId: null,
    'ut.contentCategory': null,
    'ut.contentId': null,
    'ut.created': null,
  },
  {
    body: null,
    contentCategory: 'Listening',
    contentId: '608da4b3-5859-11ed-843f-025d14ee7c92',
    created: 'Sun, 30 Oct 2022 13:47:21 GMT',
    isLiked: null,
    isVimeo: 1,
    isVisited: 1,
    isWatched: null,
    media: null,
    metadata: {
      imgUrl: 'IELTS Listening - Types of Question.png',
      videoUrl: '765279293',
      type: 'video',
      duration: '11',
      videoNo: 2,
      teacher: 'Ms. Ligi Elisa Biju',
    },
    numberGroup: 2,
    title: 'Different Question Types',
    titleGroup: 'Listening Lesson 2',
    updated: null,
    userId: '894eIjFhH7M0jT1KydhennxOs8M2',
    'ut.contentCategory': null,
    'ut.contentId': '608da4b3-5859-11ed-843f-025d14ee7c92',
    'ut.created': 'Thu, 01 Dec 2022 19:38:31 GMT',
  },
  {
    body: null,
    contentCategory: 'Reading',
    contentId: '7348f254-5dba-11ed-843f-025d14ee7c92',
    created: 'Sun, 06 Nov 2022 10:04:49 GMT',
    isLiked: null,
    isVimeo: 1,
    isVisited: null,
    isWatched: null,
    media: null,
    metadata: {
      imgUrl: 'Reading 1 - Full Test Introduction.png',
      videoUrl: '765387860',
      type: 'video',
      duration: '13',
      videoNo: 1,
      teacher: 'Ms. Ligi Elisa Biju',
    },
    numberGroup: 1,
    title: 'Full Test Introduction',
    titleGroup: 'Reading Lesson 1',
    updated: null,
    userId: null,
    'ut.contentCategory': null,
    'ut.contentId': null,
    'ut.created': null,
  },
  {
    body: null,
    contentCategory: 'Listening',
    contentId: '73e206ac-5247-11ed-843f-025d14ee7c92',
    created: 'Sat, 22 Oct 2022 20:23:55 GMT',
    isLiked: null,
    isVimeo: 1,
    isVisited: 1,
    isWatched: null,
    media: null,
    metadata: {
      type: 'video',
      duration: '12',
      imgUrl: 'IELTS Listening Introduction.png',
      videoUrl: '765279519',
      videoNo: 1,
      teacher: 'Ms. Ligi Elisa Biju',
    },
    numberGroup: 1,
    title: 'Full Test Introduction',
    titleGroup: 'Listening Lesson 1',
    updated: null,
    userId: '894eIjFhH7M0jT1KydhennxOs8M2',
    'ut.contentCategory': null,
    'ut.contentId': '73e206ac-5247-11ed-843f-025d14ee7c92',
    'ut.created': 'Thu, 01 Dec 2022 16:27:30 GMT',
  },
  {
    body: null,
    contentCategory: 'Listening',
    contentId: '73e3f4be-5247-11ed-843f-025d14ee7c92',
    created: 'Sat, 22 Oct 2022 20:23:55 GMT',
    isLiked: null,
    isVimeo: 1,
    isVisited: null,
    isWatched: null,
    media: null,
    metadata: {
      imgUrl: 'IELTS Listening Section 1.png',
      type: 'video',
      videoUrl: '765278607',
      duration: '35',
      videoNo: 3,
      teacher: 'Ms. Banojyotsna Ganguli',
    },
    numberGroup: 3,
    title: 'Section 1 Guide & Tips',
    titleGroup: 'Listening Lesson 3',
    updated: null,
    userId: null,
    'ut.contentCategory': null,
    'ut.contentId': null,
    'ut.created': null,
  },
  {
    body: null,
    contentCategory: 'Listening',
    contentId: '73e4d502-5247-11ed-843f-025d14ee7c92',
    created: 'Sat, 22 Oct 2022 20:23:55 GMT',
    isLiked: null,
    isVimeo: 1,
    isVisited: null,
    isWatched: null,
    media: null,
    metadata: {
      imgUrl: 'IELTS Listening Section 2 Part 1.png',
      videoUrl: '765278789',
      type: 'video',
      duration: '28',
      videoNo: 4,
      teacher: 'Ms. Banojyotsna Ganguli',
    },
    numberGroup: 4,
    title: 'Section 2 Guide & Tips Part A',
    titleGroup: 'Listening Lesson 4',
    updated: null,
    userId: null,
    'ut.contentCategory': null,
    'ut.contentId': null,
    'ut.created': null,
  },
  {
    body: null,
    contentCategory: 'Reading',
    contentId: '9670b053-ce51-4080-b536-e211cbf5c489',
    created: 'Fri, 03 Jun 2022 05:43:17 GMT',
    isLiked: null,
    isVimeo: null,
    isVisited: null,
    isWatched: null,
    media: null,
    metadata: {
      imgUrl: 'reading-sentence-completion.png',
      videoUrl: 'mV009RBbM',
      type: 'video',
    },
    numberGroup: null,
    title: 'IELTS Reading Strategies - Sentence Completion',
    titleGroup: null,
    updated: null,
    userId: null,
    'ut.contentCategory': null,
    'ut.contentId': null,
    'ut.created': null,
  },
  {
    body: null,
    contentCategory: 'Reading',
    contentId: '995fa0cc-5dba-11ed-843f-025d14ee7c92',
    created: 'Sun, 06 Nov 2022 10:05:53 GMT',
    isLiked: null,
    isVimeo: 1,
    isVisited: null,
    isWatched: null,
    media: null,
    metadata: {
      imgUrl: 'Reading 2 - Types of Questions.png',
      videoUrl: '765348297',
      type: 'video',
      duration: '13',
      videoNo: 2,
      teacher: 'Ms. Ligi Elisa Biju',
    },
    numberGroup: 2,
    title: 'Types of Questions',
    titleGroup: 'Reading Lesson 2',
    updated: null,
    userId: null,
    'ut.contentCategory': null,
    'ut.contentId': null,
    'ut.created': null,
  },
  {
    body: null,
    contentCategory: 'Reading',
    contentId: '99dce1e1-5dba-11ed-843f-025d14ee7c92',
    created: 'Sun, 06 Nov 2022 10:05:54 GMT',
    isLiked: null,
    isVimeo: 1,
    isVisited: null,
    isWatched: null,
    media: null,
    metadata: {
      imgUrl: 'Reading 3 - Types of Passages.png',
      videoUrl: '765390556',
      type: 'video',
      duration: '8',
      videoNo: 3,
      teacher: 'Ms. Nitanmala',
    },
    numberGroup: 3,
    title: 'Types of Passages',
    titleGroup: 'Reading Lesson 3',
    updated: null,
    userId: null,
    'ut.contentCategory': null,
    'ut.contentId': null,
    'ut.created': null,
  },
  {
    body: null,
    contentCategory: 'Reading',
    contentId: '9a59f154-5dba-11ed-843f-025d14ee7c92',
    created: 'Sun, 06 Nov 2022 10:05:55 GMT',
    isLiked: null,
    isVimeo: 1,
    isVisited: null,
    isWatched: null,
    media: null,
    metadata: {
      imgUrl: 'Reading 4 - True, False, Not Given.png',
      videoUrl: '765348069',
      type: 'video',
      duration: '14',
      videoNo: 4,
      teacher: 'Ms. Nitanmala',
    },
    numberGroup: 4,
    title: 'True, False, Not Given',
    titleGroup: 'Reading Lesson 4',
    updated: null,
    userId: null,
    'ut.contentCategory': null,
    'ut.contentId': null,
    'ut.created': null,
  },
  {
    body: null,
    contentCategory: 'Reading',
    contentId: '9ad79c68-5dba-11ed-843f-025d14ee7c92',
    created: 'Sun, 06 Nov 2022 10:05:56 GMT',
    isLiked: null,
    isVimeo: 1,
    isVisited: null,
    isWatched: null,
    media: null,
    metadata: {
      imgUrl: 'Reading 5 - Yes, No, Not Given.png',
      videoUrl: '765393256',
      type: 'video',
      duration: '9',
      videoNo: 5,
      teacher: 'Ms. Nitanmala',
    },
    numberGroup: 5,
    title: 'Yes, No, Not Given',
    titleGroup: 'Reading Lesson 5',
    updated: null,
    userId: null,
    'ut.contentCategory': null,
    'ut.contentId': null,
    'ut.created': null,
  },
  {
    body: null,
    contentCategory: 'Reading',
    contentId: '9b63cdc3-5dba-11ed-843f-025d14ee7c92',
    created: 'Sun, 06 Nov 2022 10:05:57 GMT',
    isLiked: null,
    isVimeo: 1,
    isVisited: null,
    isWatched: null,
    media: null,
    metadata: {
      imgUrl: 'Reading 6 - Short Answer Questions.png',
      videoUrl: '765348490',
      type: 'video',
      duration: '7',
      videoNo: 6,
      teacher: 'Ms. Nitanmala',
    },
    numberGroup: 6,
    title: 'Short Answer Questions',
    titleGroup: 'Reading Lesson 6',
    updated: null,
    userId: null,
    'ut.contentCategory': null,
    'ut.contentId': null,
    'ut.created': null,
  },
  {
    body: null,
    contentCategory: 'Reading',
    contentId: '9bd17a0b-5dba-11ed-843f-025d14ee7c92',
    created: 'Sun, 06 Nov 2022 10:05:57 GMT',
    isLiked: null,
    isVimeo: 1,
    isVisited: null,
    isWatched: null,
    media: null,
    metadata: {
      imgUrl: 'Reading 7 - Matching Headings.png',
      videoUrl: '765386008',
      type: 'video',
      duration: '9',
      videoNo: 7,
      teacher: 'Ms. Nitanmala',
    },
    numberGroup: 7,
    title: 'Matching Headings',
    titleGroup: 'Reading Lesson 7',
    updated: null,
    userId: null,
    'ut.contentCategory': null,
    'ut.contentId': null,
    'ut.created': null,
  },
  {
    body: null,
    contentCategory: 'Reading',
    contentId: '9c8cb81f-5dba-11ed-843f-025d14ee7c92',
    created: 'Sun, 06 Nov 2022 10:05:59 GMT',
    isLiked: null,
    isVimeo: 1,
    isVisited: null,
    isWatched: null,
    media: null,
    metadata: {
      imgUrl: 'Reading 8 - Sentence Completion.png',
      videoUrl: '765348574',
      type: 'video',
      duration: '23',
      videoNo: 8,
      teacher: 'Ms. Nitanmala',
    },
    numberGroup: 8,
    title: 'Sentence Completion',
    titleGroup: 'Reading Lesson 8',
    updated: null,
    userId: null,
    'ut.contentCategory': null,
    'ut.contentId': null,
    'ut.created': null,
  },
  {
    body: null,
    contentCategory: 'Reading',
    contentId: '9d0d6984-5dba-11ed-843f-025d14ee7c92',
    created: 'Sun, 06 Nov 2022 10:05:59 GMT',
    isLiked: null,
    isVimeo: 1,
    isVisited: null,
    isWatched: null,
    media: null,
    metadata: {
      imgUrl: 'Reading 9 - Matching Information.png',
      videoUrl: '765348800',
      type: 'video',
      duration: '8',
      videoNo: 9,
      teacher: 'Ms. Nitanmala',
    },
    numberGroup: 9,
    title: 'Matching Information',
    titleGroup: 'Reading Lesson 9',
    updated: null,
    userId: null,
    'ut.contentCategory': null,
    'ut.contentId': null,
    'ut.created': null,
  },
  {
    body: null,
    contentCategory: 'Reading',
    contentId: '9da59a06-5dba-11ed-843f-025d14ee7c92',
    created: 'Sun, 06 Nov 2022 10:06:00 GMT',
    isLiked: null,
    isVimeo: 1,
    isVisited: null,
    isWatched: null,
    media: null,
    metadata: {
      imgUrl: 'Reading 10 - Summary Completion.png',
      videoUrl: '765391271',
      type: 'video',
      duration: '10',
      videoNo: 10,
      teacher: 'Ms. Nitanmala',
    },
    numberGroup: 10,
    title: 'Summary Completion',
    titleGroup: 'Reading Lesson 10',
    updated: null,
    userId: null,
    'ut.contentCategory': null,
    'ut.contentId': null,
    'ut.created': null,
  },
  {
    body: null,
    contentCategory: 'Reading',
    contentId: '9e229f78-5dba-11ed-843f-025d14ee7c92',
    created: 'Sun, 06 Nov 2022 10:06:01 GMT',
    isLiked: null,
    isVimeo: 1,
    isVisited: null,
    isWatched: null,
    media: null,
    metadata: {
      imgUrl: 'Reading 11 - Diagram Labeling.png',
      videoUrl: '765349820',
      type: 'video',
      duration: '9',
      videoNo: 11,
      teacher: 'Ms. Nitanmala',
    },
    numberGroup: 11,
    title: 'Diagram Labeling',
    titleGroup: 'Reading Lesson 11',
    updated: null,
    userId: null,
    'ut.contentCategory': null,
    'ut.contentId': null,
    'ut.created': null,
  },
  {
    body: null,
    contentCategory: 'Reading',
    contentId: '9e8d04bd-5dba-11ed-843f-025d14ee7c92',
    created: 'Sun, 06 Nov 2022 10:06:02 GMT',
    isLiked: null,
    isVimeo: 1,
    isVisited: null,
    isWatched: null,
    media: null,
    metadata: {
      imgUrl: 'Reading 12 - Matching Features.png',
      videoUrl: '765350153',
      type: 'video',
      duration: '10',
      videoNo: 12,
      teacher: 'Ms. Nitanmala',
    },
    numberGroup: 12,
    title: 'Matching Features',
    titleGroup: 'Reading Lesson 12',
    updated: null,
    userId: null,
    'ut.contentCategory': null,
    'ut.contentId': null,
    'ut.created': null,
  },
  {
    body: null,
    contentCategory: 'Reading',
    contentId: '9f163a24-5dba-11ed-843f-025d14ee7c92',
    created: 'Sun, 06 Nov 2022 10:06:03 GMT',
    isLiked: null,
    isVimeo: 1,
    isVisited: null,
    isWatched: null,
    media: null,
    metadata: {
      imgUrl: 'Reading 13 - Note Completion.png',
      videoUrl: '765350571',
      type: 'video',
      duration: '12',
      videoNo: 13,
      teacher: 'Ms. Nitanmala',
    },
    numberGroup: 13,
    title: 'Note Completion',
    titleGroup: 'Reading Lesson 13',
    updated: null,
    userId: null,
    'ut.contentCategory': null,
    'ut.contentId': null,
    'ut.created': null,
  },
  {
    body: null,
    contentCategory: 'Reading',
    contentId: '9f7a8fcf-5dba-11ed-843f-025d14ee7c92',
    created: 'Sun, 06 Nov 2022 10:06:03 GMT',
    isLiked: null,
    isVimeo: 1,
    isVisited: null,
    isWatched: null,
    media: null,
    metadata: {
      imgUrl: 'Reading 14 - Matching Sentence Endings.png',
      videoUrl: '765351002',
      type: 'video',
      duration: '12',
      videoNo: 14,
      teacher: 'Ms. Nitanmala',
    },
    numberGroup: 14,
    title: 'Matching Sentence Endings',
    titleGroup: 'Reading Lesson 14',
    updated: null,
    userId: null,
    'ut.contentCategory': null,
    'ut.contentId': null,
    'ut.created': null,
  },
  {
    body: null,
    contentCategory: 'Reading',
    contentId: 'a006e5d6-5dba-11ed-843f-025d14ee7c92',
    created: 'Sun, 06 Nov 2022 10:06:04 GMT',
    isLiked: null,
    isVimeo: 1,
    isVisited: null,
    isWatched: null,
    media: null,
    metadata: {
      imgUrl: 'Reading 15 - Table Completion.png',
      videoUrl: '765392365',
      type: 'video',
      duration: '12',
      videoNo: 15,
      teacher: 'Ms. Nitanmala',
    },
    numberGroup: 15,
    title: 'Table Completion',
    titleGroup: 'Reading Lesson 15',
    updated: null,
    userId: null,
    'ut.contentCategory': null,
    'ut.contentId': null,
    'ut.created': null,
  },
  {
    body: null,
    contentCategory: 'Reading',
    contentId: 'a07b23f1-5dba-11ed-843f-025d14ee7c92',
    created: 'Sun, 06 Nov 2022 10:06:05 GMT',
    isLiked: null,
    isVimeo: 1,
    isVisited: null,
    isWatched: null,
    media: null,
    metadata: {
      imgUrl: 'Reading 16 - Flowchart Completion.png',
      videoUrl: '765385345',
      type: 'video',
      duration: '9',
      videoNo: 16,
      teacher: 'Ms. Nitanmala',
    },
    numberGroup: 16,
    title: 'Flowchart Completion',
    titleGroup: 'Reading Lesson 16',
    updated: null,
    userId: null,
    'ut.contentCategory': null,
    'ut.contentId': null,
    'ut.created': null,
  },
  {
    body: null,
    contentCategory: 'Reading',
    contentId: 'a1d8554f-5395-42df-9b23-73982e385185',
    created: 'Fri, 03 Jun 2022 05:43:16 GMT',
    isLiked: null,
    isVimeo: null,
    isVisited: null,
    isWatched: null,
    media: null,
    metadata: {
      imgUrl: 'reading-match-the-heading.png',
      videoUrl: 'IXi_J0ih_sI',
      type: 'video',
    },
    numberGroup: null,
    title: 'IELTS Reading Strategies - Match the Headings',
    titleGroup: null,
    updated: null,
    userId: null,
    'ut.contentCategory': null,
    'ut.contentId': null,
    'ut.created': null,
  },
  {
    body: null,
    contentCategory: 'Speaking',
    contentId: 'bde1f446-5ac4-4ff5-8c3d-4f1d1e7598b4',
    created: 'Fri, 03 Jun 2022 05:43:14 GMT',
    isLiked: null,
    isVimeo: null,
    isVisited: null,
    isWatched: null,
    media: null,
    metadata: {
      imgUrl: 'speaking-best-practices.png',
      videoUrl: 'm80afkegskU',
      type: 'video',
    },
    numberGroup: null,
    title: 'Best Practices for Exam Day - IELTS Speaking',
    titleGroup: null,
    updated: null,
    userId: null,
    'ut.contentCategory': null,
    'ut.contentId': null,
    'ut.created': null,
  },
  {
    body: null,
    contentCategory: 'Writing',
    contentId: 'd0f720dd-68f4-42b7-99dd-af5cc5ad5f2c',
    created: 'Fri, 03 Jun 2022 05:43:15 GMT',
    isLiked: null,
    isVimeo: null,
    isVisited: null,
    isWatched: null,
    media: null,
    metadata: {
      imgUrl: 'writing-best-practices.png',
      videoUrl: '9IKsanxpp84',
      type: 'video',
    },
    numberGroup: null,
    title: 'Exam Day Best Practices for the IELTS Writing Test',
    titleGroup: null,
    updated: null,
    userId: null,
    'ut.contentCategory': null,
    'ut.contentId': null,
    'ut.created': null,
  },
  {
    body: null,
    contentCategory: 'Reading',
    contentId: 'd535be35-80cb-49a0-aaf8-65a76f04bbfa',
    created: 'Fri, 03 Jun 2022 05:43:19 GMT',
    isLiked: null,
    isVimeo: null,
    isVisited: null,
    isWatched: null,
    media: null,
    metadata: {
      imgUrl: 'reading-table-completion.png',
      videoUrl: 'Z2QLJx4ny7Q',
      type: 'video',
    },
    numberGroup: null,
    title: 'IELTS Reading Strategies - Table Completion',
    titleGroup: null,
    updated: null,
    userId: null,
    'ut.contentCategory': null,
    'ut.contentId': null,
    'ut.created': null,
  },
  {
    body: null,
    contentCategory: 'Speaking',
    contentId: 'db49681b-cf17-4d51-80de-a943a9bcff1a',
    created: 'Fri, 03 Jun 2022 05:43:14 GMT',
    isLiked: null,
    isVimeo: null,
    isVisited: null,
    isWatched: null,
    media: null,
    metadata: {
      imgUrl: 'speaking-exam-format.png',
      videoUrl: 'wUCH7RLXIBA',
      type: 'video',
    },
    numberGroup: null,
    title: 'IELTS Speaking Test Detailed Format',
    titleGroup: null,
    updated: null,
    userId: null,
    'ut.contentCategory': null,
    'ut.contentId': null,
    'ut.created': null,
  },
  {
    body: null,
    contentCategory: 'Writing',
    contentId: 'df707413-aa6f-41aa-a8c3-1a21271c4275',
    created: 'Fri, 03 Jun 2022 05:43:15 GMT',
    isLiked: null,
    isVimeo: null,
    isVisited: null,
    isWatched: null,
    media: null,
    metadata: {
      imgUrl: 'writing-exam-format.png',
      videoUrl: 'rBPD4HChK50',
      type: 'video',
    },
    numberGroup: null,
    title: 'IELTS Writing Test Detailed Format',
    titleGroup: null,
    updated: null,
    userId: null,
    'ut.contentCategory': null,
    'ut.contentId': null,
    'ut.created': null,
  },
  {
    body: null,
    contentCategory: 'Dashboard',
    contentId: 'f33186da-59a7-11ed-843f-025d14ee7c92',
    created: 'Tue, 01 Nov 2022 05:42:19 GMT',
    isLiked: null,
    isVimeo: 1,
    isVisited: 1,
    isWatched: null,
    media: null,
    metadata: {
      imgUrl: 'intro to kandor.png',
      videoUrl: '765226404',
      type: 'video',
      duration: '1',
      videoNo: 1,
      teacher: 'Ms. Banojyotsna Ganguli',
    },
    numberGroup: 1,
    title: 'Getting started with Kandor',
    titleGroup: 'Getting started with Kandor',
    updated: null,
    userId: '894eIjFhH7M0jT1KydhennxOs8M2',
    'ut.contentCategory': null,
    'ut.contentId': 'f33186da-59a7-11ed-843f-025d14ee7c92',
    'ut.created': 'Mon, 21 Nov 2022 20:39:17 GMT',
  },
];

export const benefitsData = [
  {
    url: require('assets/story/benefits/slide-1.png'),
    date: '2022/05/11',
    profileImage: require('assets/kandor-img/kandor-logo-loader.png'),
    title: 'Kandor',
    link: '#',
  },
  {
    url: require('assets/story/benefits/slide-2.png'),
    date: '2022/05/11',
    profileImage: require('assets/kandor-img/kandor-logo-loader.png'),
    title: 'Kandor',
    link: '#',
  },
  {
    url: require('assets/story/benefits/slide-3.png'),
    date: '2022/05/11',
    profileImage: require('assets/kandor-img/kandor-logo-loader.png'),
    title: 'Kandor',
    link: '#',
  },
  {
    url: require('assets/story/benefits/slide-4.png'),
    date: '2022/05/11',
    profileImage: require('assets/kandor-img/kandor-logo-loader.png'),
    title: 'Kandor',
    link: '#',
  },
  {
    url: require('assets/story/benefits/slide-5.png'),
    date: '2022/05/11',
    profileImage: require('assets/kandor-img/kandor-logo-loader.png'),
    title: 'Kandor',
    link: '#',
  },
];

export const faqData = [
  {
    question: 'How to avail the Money Back Guarantee?',
    answer:
      'To avail the Money Back Guarantee, you have to raise a request through the app, or mail us at navdeep@trykandor.com.',
  },
  {
    question: 'Are there any conditions to avail the Money Back Guarantee?',
    answer:
      'Refunds will be processed only if you raise a request within 1 day of the purchase. This is per our Fair Usage Policy to ensure no gaming of the system.',
  },
  {
    question: 'How effective are the Kandor results?',
    answer:
      'Reading & Listening are 100% accurate, as they are clear-cut. For Speaking & Writing, we have 90% accuracy, and our tool is the only software on the market providing instant results, detailed feedback and full support for mobile app. It’s also the most accurate.',
  },
  {
    question: 'How does the Kandor Scoring work?',
    answer:
      'Our scoring is powered by AI, or Artificial Intelligence. We have used hundreds of IELTS model answer models and teaching materials to build this software. This eliminates human error and bias in scoring. ',
  },
  {
    question: 'How many people have tried Kandor?',
    answer:
      'Over 30000 IELTS aspirants have used Kandor in the last few months. We have also vetted the product by teachers, and received very favourable feedback from them.',
  },
  {
    question: 'What is the data of saving ₹20,000 on study abroad journey based on?',
    answer:
      'We conducted a survey of hundreds of study abroad aspirants, which revealed that approx 70% students have to repeat the exam due to lower-than-desired scores. The average student spent ₹5,000 on coaching, materials, etc. and the IELTS fee itself is ₹15,000, which adds up to an average spend of ₹20,000 for retaking the IELTS.',
  },
];

export const faqReferralData = [
  {
    question: 'How does the referral program work?',
    answer:
      'The referral program is simple. Share your unique referral link with friends, and when they sign up using your link, you earn referral credits. Reach specific referral counts to unlock free passes.',
  },
  {
    question: ' Can I refer myself?',
    answer:
      'No, self-referrals are not allowed. Referral links are intended for sharing with friends, family, or acquaintances.',
  },
  {
    question:
      'Do my referrals get passed on to the next tier once I claim the first tier?',
    answer:
      'Yes, we value every referral you bring. If you reach the 5-referral mark and claim the free access to the Essentials Pass, your additional referrals will still count towards the next tier. This means you can continue earning towards higher rewards even after claiming the initial tier. Keep referring and unlocking more benefits!',
  },
  {
    question: `What is the validity period for the plan activation once it's claimed?`,
    answer:
      'Your free Essentials Pass or Class Pass activation is valid for one month. Make sure to utilize this period to enjoy all the benefits of your Rewards.',
  },
  {
    question: `What if I face issues with the referral program?`,
    answer:
      'If you encounter any issues or have questions, feel free to reach out to our support team at navdeep@trykandor.com',
  },
];

export const dashboardVideosData = [
  {
    body: null,
    contentCategory: 'Dashboard',
    contentId: '1331b797-59a8-11ed-843f-025d14ee7c92',
    created: 'Tue, 01 Nov 2022 05:43:12 GMT',
    isLiked: null,
    isVimeo: 1,
    isVisited: 1,
    isWatched: null,
    media: null,
    metadata: {
      imgUrl: 'intro to kandor.png',
      videoUrl: '765218635',
      type: 'video',
      duration: '1',
      videoNo: 2,
      teacher: 'Ms. Banojyotsna Ganguli',
    },
    numberGroup: 2,
    title: 'Getting started with Kandor',
    titleGroup: 'Getting started with Kandor',
    updated: null,
    userId: '894eIjFhH7M0jT1KydhennxOs8M2',
  },
  {
    body: null,
    contentCategory: 'Dashboard',
    contentId: 'f33186da-59a7-11ed-843f-025d14ee7c92',
    created: 'Tue, 01 Nov 2022 05:42:19 GMT',
    isLiked: null,
    isVimeo: 1,
    isVisited: 1,
    isWatched: null,
    media: null,
    metadata: {
      imgUrl: 'intro to kandor.png',
      videoUrl: '765226404',
      type: 'video',
      duration: '1',
      videoNo: 1,
      teacher: 'Ms. Banojyotsna Ganguli',
    },
    numberGroup: 1,
    title: 'Getting started with Kandor',
    titleGroup: 'Getting started with Kandor',
    updated: null,
    userId: '894eIjFhH7M0jT1KydhennxOs8M2',
  },
];
