import api from "services/api";

export async function getSchedule() {
  return await api.get("/schedule");
}

export async function getLimitClasses() {
  return await api.get("/schedule/limit");
}

export function trackClass(classId) {
  if (classId == null || classId == undefined) {
    throw new Error("missing required parameters");
  }
  return api.get(`schedule/track/${classId}`);
}

export function registerClass(classId) {
  if (classId == null || classId == undefined) {
    throw new Error("missing required parameters");
  }
  return api.get(`schedule/register/${classId}`);
}

export async function getClassById(classId) {
  if (classId == null || classId == undefined) {
    throw new Error("missing required parameters");
  }
  return api.get(`schedule/${classId}`);
}
