import React from "react";

import s from "../../../pages/styles/UploadForm.module.scss";
import FormUpload from "./Form/Form";

const ModalForm = ({ show, setShow, showTerm, setShowTerm }) => {
  return (
    <>
      <h2 className={s.modal__title}>
        UPLOAD YOUR IELTS <br /> BOOKING CONFIRMATION
      </h2>
      <p>To Claim</p>
      <p>
        Your Free <b> KANDOR PRO </b> membership for 90 Days
      </p>
      <div className={s.modal__price}>
        <div style={{ paddingRight: "50px" }}>
          <p style={{ margin: 0 }}>Actual Price</p>
          <p className={s.modal__price_num}>
            <strike>₹4999</strike>
          </p>
        </div>
        <div>
          <p style={{ margin: 0 }}>Special Offer!</p>
          <p className={s.modal__price_num}>₹0/-</p>
        </div>
      </div>
      <FormUpload
        show={show}
        setShow={setShow}
        showTerm={showTerm}
        setShowTerm={setShowTerm}
      />
    </>
  );
};

export default ModalForm;
