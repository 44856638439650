export const terms = [
  'We need your IELTS Date to make our services better and more customized to every student’s needs, it helps understand where students are in their journey',
  'Personal files are not shared with any third party agencies, however IELTS date may be used for customizing services via partners',
  'By sharing the date, you authorize us/our partners to contact you for our value-added services - like study abroad counseling, information about loan offers, and so on',
  'Only students who have already booked their IELTS date can avail the free upgrade by uploading their confirmation receipt from the button below',
  'The upgrade will be for Kandor Pro plan, worth Rs 4999',
  'Accepted entries will be upgraded manually post verification within 24 hrs, students will be informed via WhatsApp on their registered number',
  'The Pro access will be valid for 60 days from the date of upgrade, and they are subject to standard Fair Use Policies (no account sharing, no DDoS usage of prompts, etc.)',
  'Our discretion about the selection of students will be final',
  'We reserve the right to change details about this offer at any time, and also retract the Pro access to students who we believe are in violation of our Fair Use Policies, without prior information',
];

export const termsForReferral = [
  {
    title: 'Introduction',
    content: [
      'These Terms and Conditions govern your participation in the referral program conducted by Kandor ("Company," "we," "us," or "our").',
      'By participating in the Program, you agree to these Terms and Conditions.',
    ],
  },
  {
    title: 'Eligibility',
    content: [
      'The Program is open to all registered users of the Kandor’s website.',
      'Users must be at least 18 years old to participate.',
    ],
  },
  {
    title: 'Referral Counts',
    content: [
      'A successful referral is counted when a user shares their unique referral link, and the referred friend signs up using that link.',
      'Referrals are counted in the order in which they are received and processed.',
    ],
  },
  {
    title: 'Rewards Tiers',
    content: [
      'Users will be eligible for rewards based on the following referral tiers:',
      '5 Referrals: Free access to the Essentials Pass.',
      '10 Referrals: Free access to the Class Pass.',
    ],
  },
  {
    title: 'Reward Redemption',
    content: [
      'Users reaching the specified referral count will be notified through email and will receive instructions on how to redeem their reward.',
      'Rewards are non-transferable and may not be exchanged for cash or other alternatives.',
    ],
  },
  {
    title: 'Validity',
    content: [
      'The referral program is effective from 10th January 2024 to TBD.',
      'Referrals made within this period will be considered for rewards.',
    ],
  },
  {
    title: 'Fraudulent Activities',
    content: [
      'Kandor reserves the right to disqualify any user found engaging in fraudulent activities, including but not limited to fake referrals and self-referrals.',
      'Any attempts to exploit or manipulate the Program will result in immediate disqualification.',
    ],
  },
  {
    title: 'Modification and Termination',
    content: [
      'Kandor reserves the right to modify, suspend, or terminate the referral program at any time without prior notice.',
      'In the event of termination, any unredeemed rewards will be forfeited.',
    ],
  },
  {
    title: 'Misuse of Program',
    content: [
      'Any attempt to misuse the Program or violate these Terms and Conditions will result in disqualification, and the user may be prohibited from future participation.',
    ],
  },
  {
    title: 'Privacy',
    content: [
      "Referrers are responsible for obtaining their friends' consent to share their contact information for the referral.",
      'Refer to our Privacy Policy for details on how we handle personal information.',
    ],
  },
  {
    title: 'Limitation of Liability',
    content: [
      'Kandor is not liable for any loss, damage, or inconvenience caused due to the referral program.',
      'By participating in the Program, users agree to release and hold Kandor harmless from any claims or damages.',
    ],
  },
  {
    title: 'Disputes',
    content: [
      'Any disputes arising from the referral program will be subject to resolution by Kandor.',
    ],
  },
  {
    title: 'Governing Law',
    content: [
      'These Terms and Conditions are governed by and construed in accordance with the laws of New Jersey.',
      'By participating in the referral program, users agree to abide by these Terms and Conditions.',
    ],
  },
];
