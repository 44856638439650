import React, { useState } from 'react';
import s from './Generation.module.scss';
import Modal from 'react-bootstrap/Modal';
import { Link, useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { ReactComponent as Close } from '../../../../assets/icons/close.svg';
import { ReactComponent as ArrowBack } from '../../../../assets/icons/back.svg';

// import { ReactComponent as Delete } from "../../../../assets/icons/delete.svg";
import { ReactComponent as Edit } from '../../../../assets/icons/edit_card.svg';
import { editSop } from 'api/sopApi';
const GenerationYour = el => {
  const [text, setText] = useState(el.sop);
  const [show, setShow] = useState(false);
  const history = useHistory();
  const type_map = {
    sop: 'college',
    visa: 'visa',
  };

  function convertDateFormat(inputDate) {
    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

    const parts = inputDate.split(' ');
    const day = parts[1];
    const monthIndex = parseInt(months.indexOf(parts[2]));
    const year = parts[3];

    return `${day} ${months[monthIndex]} ${year}`;
  }

  const goToSop = () => {
    const route = type_map[el.type];
    history.push(`/admin/sopchat/${route}/${el.sopid}`);
  };

  return (
    <>
      <div className={s.generation__card_your} onClick={() => setShow(true)}>
        <div className={s.generation__card_wrap}>
          <div className={s.generation__card_action}>
            <button onClick={() => goToSop()} className={s.generation__card_edit}>
              <Edit />
            </button>
          </div>
          <h4 className={s.generation__card_title}>MIT SOP</h4>

          {/* <h4 className={s.generation__card_title}>{convertDateFormat(el.created)}</h4> */}
          <p className={s.generation__card_desc}>{el.sop}</p>
        </div>
      </div>

      <Modal
        dialogClassName={s.myModalStyle}
        contentClassName={s.custom_modal}
        show={show}
        centered
        onHide={() => {
          setShow(false);
        }}>
        <Modal.Header style={{ padding: 0 }} closeVariant="white">
          <button
            className={s.custom__close}
            onClick={() => {
              setShow(false);
            }}>
            <Close />
          </button>
        </Modal.Header>
        <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
          <button onClick={() => setShow(false)} className={s.term__button}>
            <ArrowBack style={{ marginRight: '5px' }} />
            <span style={{ borderBottom: '1px solid #fc8150' }}>Back</span>
          </button>
        </div>
        <div className={s.generation_modal_container}>
          <h3 className={s.generation_modal_title}>{convertDateFormat(el.created)}</h3>
          <div className={s.generation_modal_desc} style={{ overflowX: 'scroll', height: '322px', padding: '10px' }}>
            {text}
          </div>
        </div>
      </Modal>
    </>
  );
};

export default GenerationYour;
