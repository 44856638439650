import { ReactComponent as BUILD } from "assets/icons/marketplace/BUILD.svg";
import { ReactComponent as RATE } from "assets/icons/marketplace/RATE.svg";
import { ReactComponent as RATING } from "assets/icons/marketplace/RATING.svg";
import { ReactComponent as VISA } from "assets/icons/marketplace/VISA.svg";
import ProgressBar from "kandor-components/components/ProgressBar/ProgressBar";
import React from "react";
import Slider from "react-slick";
import StatCard from "../StatCard/StatCard";
import "./StatSlider.scss";

const mockStat = [
  {
    icon: <VISA />,
    bg: "rgba(238, 119, 52, 0.20)",
    value: "97%",
    name: "VISA Acceptance Rate",
  },
  {
    icon: <RATE />,
    bg: "rgba(87, 59, 118, 0.20)",

    value: "2x",
    name: "Improved Admission Rates",
  },
  {
    icon: <RATING />,
    bg: "rgba(71, 186, 82, 0.20)",

    value: "4.08",
    name: "Average Rating",
  },
  {
    icon: <BUILD />,
    bg: "rgba(244, 204, 63, 0.30)",
    value: "3289",
    name: "Admissions Secured",
  },
];

const StatSlider = () => {
  const [progress, setProgress] = React.useState(0);

  const settings = {
    slidesToShow: 1.5,
    slidesToScroll: 1,
    arrows: false,
    infinite: true,
    initialSlide: 0,
    autoplay: true,
    swipeToSlide: true,

    autoplaySpeed: 1500,
    responsive: [
      {
        breakpoint: 913,
        settings: {
          slidesToShow: 1.7,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2.2,
          slidesToScroll: 1,
          arrows: false,
        },
      },
      {
        breakpoint: 614,
        settings: {
          slidesToShow: 1.9,
          slidesToScroll: 1,
          arrows: false,
        },
      },
      {
        breakpoint: 535,
        settings: {
          slidesToShow: 1.3,
          slidesToScroll: 1,
          arrows: false,
        },
      },
      {
        breakpoint: 420,
        settings: {
          slidesToShow: 1.6,
          slidesToScroll: 1,
          arrows: false,
        },
      },
    ],
    afterChange: current => {
      setProgress((100 / mockStat.length) * (current + 1));
    },
  };

  return (
    <>
      <Slider className="stat_slider" {...settings}>
        {mockStat.map(stat => (
          <StatCard key={stat.bg} {...stat} />
        ))}
      </Slider>
      <ProgressBar progress={progress} margin={10} />
    </>
  );
};

export default StatSlider;
