import React, { useState, useEffect } from 'react';
import { getSelfDetails } from 'api/userApi';
import { PopupModal } from 'react-calendly';
import { useSelector } from 'react-redux';
import { Button } from 'reactstrap';
import { ReactComponent as CounselingIcon } from './../assets/kandor-img/counseling-icon.svg';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { ReactComponent as StarIcon } from '../assets/img/bannerForResult/star.svg';

export const CounselingModal = ({
  isOpen,
  onClose,
  linkParent = '',
  isImmigration = false,
  setIsLoadedLink = () => {},
}) => {
  const [dreamCountry, setDreamCountry] = useState('');
  const [calendlyLink, setCalendlyLink] = useState('');
  const TokenId = useSelector(state => state.auth.TokenId);

  useEffect(() => {
    if (TokenId !== null && TokenId != '') {
      getSelfDetails({})
        .then(res => res.data)
        .then(qdata => {
          if (qdata.profile.DreamCountry) {
            setDreamCountry(qdata.profile.DreamCountry);
          } else {
            setDreamCountry('Unsigned');
          }

          setIsLoadedLink(true);
        })
        .catch(res => console.log(res));
    } else {
      setDreamCountry('Unsigned');
      setIsLoadedLink(true);
    }
  }, [TokenId]);

  useEffect(() => {
    if (linkParent) {
      setCalendlyLink(linkParent);
    } else if (isImmigration) {
      setCalendlyLink('https://calendly.com/d/grm-bgr-fng');
    } else {
      setCalendlyLink('https://calendly.com/d/dh9-zvz-hqx');
    }
  }, [linkParent]);

  return (
    <PopupModal url={calendlyLink} onModalClose={onClose} open={isOpen} rootElement={document.getElementById('root')} />
  );
};

export const CounselingButtonWithIcon = ({ isImmigration = false, isWhite = false, clickParent = () => {} }) => {
  const [isOpen, setIsOpen] = useState(false);
  const history = useHistory();

  const handleClick = e => {
    e.preventDefault();
    clickParent();
    history.push('/admin/shortlister');
    //setIsOpen(true)
  };

  return (
    <>
      <Button color="info" className={`p-2 result-btn ${isWhite ? 'result-btn--white' : ''}`} onClick={handleClick}>
        <span className={`result-btn__icon  ${isWhite ? 'result-btn__icon--dark' : ''}`}>
          <StarIcon />
        </span>
        {isImmigration ? 'Immigrate Now' : 'Try College Finder'}
      </Button>
      {/* <CounselingModal
				isOpen={isOpen}
				onClose={() => setIsOpen(false)}
				isImmigration={isImmigration}
			/> */}
    </>
  );
};

const CounselingButton = ({ isImmigration = false, clickParent = () => {} }) => {
  const [isOpen, setIsOpen] = useState(false);
  const history = useHistory();

  const handleClick = e => {
    e.preventDefault();
    clickParent();
    history.push('/admin/counsellors');
    // setIsOpen(true)
  };

  return (
    <>
      <Button color="info" className="p-2 test-footer-btn" onClick={handleClick}>
        Book a counsellor
      </Button>
      {/* <CounselingModal isOpen={isOpen} onClose={() => setIsOpen(false)} /> */}
    </>
  );
};

export default CounselingButton;
