import React from "react";

import s from './GuideSpinner.module.scss'
import { Spinner } from "reactstrap";


const GuideSpinner = () => {
  return (
    <div className={s.spinner}>
      <Spinner color={'info'} />
    </div>
  )
}

export default GuideSpinner