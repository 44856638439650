import {
	EMAIL_REQUEST,
	EMAIL_REQUEST_LOGIN,
	EMAIL_SUCCESS,
	FETCH_STATE,
	LOGIN_FAILURE,
	LOGIN_REQUEST,
	LOGIN_SUCCESS,
	LOGOUT_FAILURE,
	LOGOUT_REQUEST,
	LOGOUT_SUCCESS,
	PHONE_REQUEST_TOKEN,
	RECEIVE_TOKEN,
	RESET_FAILURE,
	RESET_REQUEST,
	RESET_SUCCESS,
	SIGNUP_FAILURE,
	SIGNUP_REQUEST,
	SIGNUP_SUCCESS,
	VERIFY_REQUEST,
	VERIFY_SUCCESS,
	RECEIVE_FCM_TOKEN,
	RESET_ERROR,
	IS_ONBOARDED,
	ONBOARDED_VERIFY_SUCCESS,
	ONBOARDED_REQUEST,
} from '../actions/'

// eslint-disable-next-line import/no-anonymous-default-export
export default (
	state = {
		isLoggingIn: false,
		isSigningUp: false,
		isLoggingOut: false,
		isVerifying: false,
		hasOnboarded: false,
		hasOnboardedVerify: false,
		isVerified: false,
		loginError: false,
		loginErrorMsg: '',
		logoutError: false,
		isAuthenticated: null,
		isEmailVerified: false,
		isReset: false,
		phoneToken: '',
		user: {},
		authMsg: '',
		TokenId: '',
		FCMTokenId: '',
	},
	action
) => {
	switch (action.type) {
		case RESET_ERROR:
			console.log('RESET_ERROR')
			return {
				...state,
				loginErrorMsg: '',
				loginError: false,
			}
		case LOGIN_REQUEST:
			return {
				...state,
				isLoggingIn: true,
				loginError: false,
				loginErrorMsg: '',
			}
		case LOGIN_SUCCESS:
			return {
				...state,
				isLoggingIn: false,
				isAuthenticated: true,
				isVerified: true,
				user: { ...action.user },
				//  authMsg: action.payload
			}
		case LOGIN_FAILURE:
			return {
				...state,
				isLoggingIn: false,
				isAuthenticated: false,
				isVerified: false,
				loginError: true,
				loginErrorMsg: action.payload,
				authMsg: action.payload,
			}
		case SIGNUP_REQUEST:
			return {
				...state,
				isAuthenticated: false,
				isSigningUp: true,
				isVerified: false,
			}
		case SIGNUP_SUCCESS:
			return {
				...state,
				isAuthenticated: true,
				isSigningUp: false,
				isVerified: true,
				user: { ...action.user },
			}
		case EMAIL_SUCCESS:
			return {
				...state,
				isSigningUp: false,
				isEmailVerified: true,
			}
		case EMAIL_REQUEST:
			return {
				...state,
				isSigningUp: false,
				user: { ...action.user },
			}
		case EMAIL_REQUEST_LOGIN:
			return {
				...state,
				isAuthenticated: true,
				isSigningUp: false,
				user: { ...action.user },
			}
		case RECEIVE_TOKEN:
			return {
				...state,
				TokenId: action.payload,
			}
		case RECEIVE_FCM_TOKEN:
			return {
				...state,
				FCMTokenId: action.payload,
			}
		case PHONE_REQUEST_TOKEN:
			return {
				...state,
				phoneToken: action.phoneToken,
			}
		case SIGNUP_FAILURE:
			return {
				...state,
				isAuthenticated: false,
				authMsg: action.payload,
				isSigningUp: false,
			}
		case RESET_REQUEST:
			return {
				...state,
				isReset: true,
				isAuthenticated: false,
			}
		case RESET_SUCCESS:
			return {
				...state,
				isAuthenticated: false,
				isReset: false,
				authMsg: action.payload,
			}
		case RESET_FAILURE:
			return {
				...state,
				isAuthenticated: false,
				isReset: false,
				authMsg: action.payload,
			}
		case LOGOUT_REQUEST:
			return {
				...state,
				isLoggingOut: true,
				logoutError: false,
			}
		case LOGOUT_SUCCESS:
			return {
				...state,
				isVerifying: false,
				hasOnboardedVerify: false,
				hasOnboarded: false,
				isLoggingOut: false,
				isAuthenticated: false,
				user: {},
				authMsg: action.payload,
			}
		case LOGOUT_FAILURE:
			return {
				...state,
				isLoggingOut: false,
				logoutError: true,
				authMsg: action.payload,
			}
		case VERIFY_REQUEST:
			return {
				...state,
				isVerifying: true,
				verifyingError: false,
			}
		case FETCH_STATE:
			return {
				...state,
			}
		case ONBOARDED_REQUEST:
			return {
				...state,
				hasOnboardedVerify: true,
			}
		case IS_ONBOARDED:
			return {
				...state,
				hasOnboarded: true,
			}
		case ONBOARDED_VERIFY_SUCCESS:
			return {
				...state,
				hasOnboardedVerify: false,
			}
		case VERIFY_SUCCESS:
			return {
				...state,
				isVerifying: false,
			}

		default:
			return state
	}
}
