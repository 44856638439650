import React from "react";
import { ReactComponent as Listening } from "../../../../../assets/icons/classes/IconsForType/Listening.svg";
import { ReactComponent as Reading } from "../../../../../assets/icons/classes/IconsForType/Reading.svg";
import { ReactComponent as Speaking } from "../../../../../assets/icons/classes/IconsForType/Spealing.svg";
import { ReactComponent as Writing } from "../../../../../assets/icons/classes/IconsForType/Writing.svg";
import s from './MapOfType.module.scss'


const MapOfType = {
  Speaking: (
    <div className={s.type}>
      <div  className={s.type__container} style={{
        background: "rgba(37, 164, 131, 0.20)",
        border: "1px solid rgba(37, 164, 131, 0.20)",
        color: "#25A483",
      }}>
        <Speaking />
        Speaking
      </div>
    </div>
  ),
  Listening: (
    <div className={s.type}>
      <div className={s.type__container} style={{
        background: "rgba(255, 209, 45, 0.20)",
        border: "1px solid rgba(226, 182, 25, 0.20)",
        color: "#E3A504",
      }}>
      <Listening />
      Listening
      </div>
    </div>
  ),
  Reading: (
    <div className={s.type}>
      <div className={s.type__container} style={{
        background: "rgba(130, 54, 247, 0.20)",
        border: "1px solid rgba(130, 54, 247, 0.20)",
        color: "#8236F7",
      }}>
        <Reading />
        Reading
      </div>
    </div>
  ),
  Writing: (
    <div className={s.type}>
      <div className={s.type__container} style={{
        background: "rgba(207, 87, 132, 0.20)",
        border: "1px solid rgba(207, 87, 132, 0.30)",
        color: "#CF5784",
      }}>
        <Writing />
        Writing
      </div>
    </div>
  ),
};

export default MapOfType
