import api from 'services/api';
export function postTicket(objArgs) {
  if (typeof objArgs !== 'object') {
    throw new Error('missing required parameters');
  }
  return api.post('file/ielts/date', objArgs);
}

export function getTicketInfo(objArgs) {
  if (typeof objArgs !== 'object') {
    throw new Error('missing required parameters');
  }
  return api.get('ielts/exam-date/' + objArgs.ocr_id);
}
