import React, { useState, useCallback, lazy, memo } from "react";
import s from "./ErrorFallbackHeader.module.scss";
import { OverlayTrigger, Popover } from "react-bootstrap";
import mixpanel from "mixpanel-browser";
import Logo from "../../../assets/img/ErrorPage/logo.png";
import { ReactComponent as Close } from "../../../assets/img/ErrorPage/close.svg";
import { ReactComponent as Success } from "../../../assets/img/ErrorPage/success.svg";
import { ReactComponent as Arrow } from "../../../assets/img/ErrorPage/arrow.svg";
import { ReactComponent as Report } from "../../../assets/img/ErrorPage/report.svg";

import { ReactComponent as SendPlane } from "../../../assets/img/ErrorPage/sendPlane.svg";
import { ReactComponent as ErrorDone } from "../../../assets/img/ErrorPage/done_report.svg";
import { ReactComponent as ErrorInfo } from "../../../assets/img/ErrorPage/info_report.svg";
const reportValue = [
  "The loading bar is stuck",
  "Keep seeing this page",
  "Keep getting error message",
  "Stuck here after logging in",
];

const ErrorFallbackHeader = () => {
  const [showPopover, setShowPopover] = useState(false);

  const [selectedReports, setSelectedReports] = useState([]);
  const [selectArea, setSelectArea] = useState("");
  const [statusReport, setStatusReport] = useState(false);

  const handleReportClick = index => {
    setSelectedReports(prevSelectedReports => {
      const updatedSelectedReports = [...prevSelectedReports];
      const indexInArray = updatedSelectedReports.indexOf(index);

      if (indexInArray !== -1) {
        updatedSelectedReports.splice(indexInArray, 1);
      } else {
        updatedSelectedReports.push(index);
      }

      return updatedSelectedReports;
    });
  };

  const onSelectTextArea = e => {
    setSelectArea(e.target.value);
  };

  const onChangeReport = () => {
    setStatusReport(false);
    setSelectArea("");
    setSelectedReports([]);
  };

  const onClose = () => {
    setStatusReport(false);
    setSelectArea("");
    setSelectedReports([]);
    setShowPopover(false);
  };

  const onSendError = useCallback(() => {
    const error = {
      selectedReports,
      textError: selectArea,
    };
    console.log(error);
    setStatusReport(true);
    mixpanel.track("User reported error", { error: error });
  }, [selectedReports, selectArea]);

  const onReportButtonClick = useCallback(
    e => {
      console.log("button refresh");
      e.preventDefault();
      setShowPopover(prevShowPopover => !prevShowPopover);
    },
    [setShowPopover]
  );

  const popoverBottom = (
    <Popover>
      <div className={statusReport ? `${s.popover_success}` : `${s.popover}`}>
        {statusReport ? (
          <>
            <div className={s.header_success}>
              <div style={{ cursor: "pointer" }} onClick={() => onClose()}>
                <Close />
              </div>
            </div>
            <div className={s.content_success}>
              <div className={s.icon_wrap}>
                <Success />
              </div>

              <h5 className={s.title_success}>
                Thank you for reporting the issue!
              </h5>
              <p className={s.text_success}>
                Our best people are on it, we will try to get it fixed as soon
                as possible
              </p>
              <div className={s.button_wrap}>
                <button
                  onClick={() => onChangeReport()}
                  className={s.button_success}
                >
                  <Arrow />
                  Change Response
                </button>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className={s.popover_header}>
              <h4 className={s.popover_header__title}>
                select the relevant option:
              </h4>

              <div style={{ cursor: "pointer" }} onClick={() => onClose()}>
                <Close />
              </div>
            </div>
            <div className={s.report_wrap}>
              {reportValue.map((el, index) => (
                <div
                  key={index}
                  className={`${s.report_value} ${
                    selectedReports.includes(el) ? s.selected : ""
                  }`}
                  onClick={() => handleReportClick(el)}
                >
                  <div>
                    {selectedReports.includes(el) ? (
                      <ErrorDone />
                    ) : (
                      <ErrorInfo />
                    )}
                  </div>

                  <p>{el}</p>
                </div>
              ))}
            </div>
            <div className={s.textarea_wrap}>
              <textarea
                name="report"
                placeholder="Don’t see your issue? type here..."
                className={s.text_area}
                value={selectArea}
                onChange={e => onSelectTextArea(e)}
              ></textarea>
              <button
                disabled={selectedReports.length === 0 && !selectArea}
                onClick={() => onSendError()}
                className={s.send_button}
              >
                Send
                <SendPlane />
              </button>
            </div>
          </>
        )}
      </div>
    </Popover>
  );

  return (
    <header className={s.header_error}>
      <div className={s.header_error__wrap}>
        <img alt="Kandor" loading="lazy" src={Logo} className={s.logo} />
        <div>
          <OverlayTrigger
            trigger="click"
            rootClose
            placement="bottom"
            show={showPopover}
            overlay={popoverBottom}
          >
            <button onClick={onReportButtonClick} className={s.report_button}>
              Report issue
              <Report />
            </button>
          </OverlayTrigger>
        </div>
      </div>
    </header>
  );
};

export default memo(ErrorFallbackHeader);
