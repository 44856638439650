import { createSelector } from "reselect";

const selectSops = state => state.sops.sops;
const selectCategory = state => state.sops.category;

export const filteredSopsSelector = createSelector(
  [selectSops, selectCategory],
  (sops, category) =>
    category === "All" ? sops : sops.filter(sop => sop.category === category)
);
