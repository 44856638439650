import React from 'react';

const CustomDot = ({ onClick, isActive, type }) => {
  const activeClass = isActive ? `slick-active ${type}` : '';
  return (
    <li className={`slick-dot ${activeClass}`} onClick={onClick}>
      <button type="button"></button>
    </li>
  );
};

export default CustomDot;
