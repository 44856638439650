import React from "react";
import { ReactComponent as CardArrow } from "../../../assets/icons/card__arrow.svg";
import { ReactComponent as Crown } from "../../../assets/icons/crown.svg";
import s from "../../../pages/styles/UploadForm.module.scss";
const Slider = () => {
  return (
    <>
      <div
        style={{
          display: "flex",
          alignItems: "baseline",
          justifyContent: "center",
        }}
      >
        <div
          style={{
            marginRight: "15px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Crown />
        </div>
        <h2 className={s.modal__title}>Kandor Pro</h2>
      </div>
      <div>
        <div className={s.modal__card}>
          <div>
            <CardArrow
              style={{ marginRight: "14px", marginLeft: "14px" }}
              size="32px"
            />
          </div>
          <div className={s.modal__card_text}>
            <h4 className={s.modal__card_title}>
              All practice prompts in all 4 sections
            </h4>
            <p className={s.modal__card_desc}>
              Freely choose any prompt according to your liking to practice
            </p>
          </div>
        </div>
        <div className={s.modal__card}>
          <div>
            <CardArrow
              style={{ marginRight: "14px", marginLeft: "14px" }}
              size="32px"
            />
          </div>
          <div className={s.modal__card_text}>
            <h4 className={s.modal__card_title}>All Study material videos</h4>
            <p className={s.modal__card_desc}>
              Unlimited access to all study material/content on Kandor
            </p>
          </div>
        </div>
        <div className={s.modal__card}>
          <div>
            <CardArrow
              style={{ marginRight: "14px", marginLeft: "14px" }}
              size="32px"
            />
          </div>
          <div className={s.modal__card_text}>
            <h4 className={s.modal__card_title}>
              All mock tests in all 4 sections
            </h4>
            <p className={s.modal__card_desc}>
              Practice through our collection of mock tests to prepare yourself
              for the exam environment
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Slider;
