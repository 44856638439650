export const parseFillerWords = (dict) => {
    dict = JSON.parse(dict);
    var space = " ";
    var fullstop = ".";
    var question_mark = "?";
    var exclamation_mark = "!";
    let fillerwords_list = [];
    let fillerWords_count = 0;
    for (var key in dict) {
      fillerWords_count = fillerWords_count + dict[key];
      // do something with "key" and "value" variables

      fillerwords_list.push(space + key + space);
      fillerwords_list.push(fullstop + key + space);
      fillerwords_list.push(question_mark + key + space);
      fillerwords_list.push(exclamation_mark + key + space);
    }
    
    return { "count": fillerWords_count, "list": fillerwords_list };
  };