import MarketplaceFormFinal from "pages/Marketplace/MarketplaceForm/MarketplaceFormFinal";
import MarketPlaceFormPreferences from "pages/Marketplace/MarketplaceForm/MarketPlaceFormPreferences";
import MarketplaceFormTimeLine from "pages/Marketplace/MarketplaceForm/MarketplaceFormTimeline";
import MarketplaceFormTimeSlot from "pages/Marketplace/MarketplaceForm/MarketplaceFormTimeSlot";
import ProgressStepMobile from "pages/Marketplace/ProgressStep/ProgressMobile";
import React from "react";
import Modal from "react-bootstrap/Modal";
import { ReactComponent as Start } from "../../../../assets/icons/marketplace/profile/mobile/arrow.svg";
import s from "../../Marketplace.module.scss";

const ModalMobile = ({
  show,
  handleClose,
  currentStepMobile,
  setData,
  data,
  formErrorsMobile,
  setCurrentStepMobile,
  handleFormSubmitForMobile,
  handleStepChange,
}) => {
  const showFormMobile = step => {
    switch (step) {
      case 0:
        return (
          <>
            <div
              style={{
                padding: "0",
                margin: "0 auto",
                display: "grid",
                justifyContent: "center",
              }}
              className={s.modal__left}
            >
              <h4 className={s.modal__left_title}>
                Expression of Interest for Study Abroad Assistance with Kandor
              </h4>
              <p className={s.modal__left_desc}>
                Kickstart your study abroad journey <br></br> with guidance on universities, <br></br> courses, and loans from our top experts in India.
              </p>
              <img
                style={{ marginLeft: "30px" }}
                alt=""
                src={require("../../../../assets/icons/marketplace/modal/plane.png")}
              />
            </div>
          </>
        );
      case 1:
        return (
          <MarketPlaceFormPreferences
            setData={setData}
            data={data}
            errors={formErrorsMobile}
          />
        );
      case 2:
        return (
          <MarketplaceFormTimeLine
            setData={setData}
            data={data}
            errors={formErrorsMobile}
          />
        );
      case 3:
        return (
          <MarketplaceFormTimeSlot
            setData={setData}
            data={data}
            errors={formErrorsMobile}
          />
        );

      default:
        return <MarketplaceFormFinal />;
    }
  };

  const showButtonMobile = step => {
    if (step === 1 || step === 2) {
      return "Next";
    } else if (step === 3) {
      return "Confirm Booking";
    } else {
      return "Continue Exploring";
    }
  };

  const showStep = step => {
    switch (step) {
      case 0:
        return <></>;
      case 1:
        return <h4 className={s.title__mobile_step}>1.Personal information</h4>;
      case 2:
        return <h4 className={s.title__mobile_step}>2.Preferences</h4>;
      case 3:
        return <h4 className={s.title__mobile_step}>3.Timeline</h4>;
      default:
        return <></>;
    }
  };

  return (
    <Modal
      dialogClassName={s.mobile_modal}
      contentClassName={s.mobile}
      fullscreen={true}
      show={show}
      onHide={handleClose}
      style={{ zIndex: 1300, backgroundColor: "#ffff", paddingLeft: "0" }}
    >
      <div className={s.header__mobile} style={{ padding: 0 }}>
        <div className={s.logo_close}>
          <div className={s.mobile__logo}>
            <img
              alt=""
              className={s.modal__img}
              src={require("../../../../assets/icons/marketplace/modal/small_logo.png")}
            />
            <p style={{ margin: 0 }}>Kandor Counselors</p>
          </div>
          <div
            onClick={() => handleClose()}
            style={{ display: "flex", alignItems: "center" }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="13"
              height="13"
              viewBox="0 0 13 13"
              fill="none"
            >
              <path
                d="M7.65811 6.27557L12.959 11.5785L12.2676 12.2692L6.959 6.97392L1.65041 12.2692L0.958984 11.5785L6.2599 6.27557L0.958984 0.972665L1.65041 0.281982L6.959 5.57721L12.2676 0.281982L12.959 0.972665L7.65811 6.27557Z"
                fill="#1E1E1E"
              />
            </svg>
          </div>
        </div>
        {currentStepMobile <= 3 && (
          <ProgressStepMobile
            currentStep={currentStepMobile}
            handleStepChange={handleStepChange}
          />
        )}
        <div className={s.modal__progress}></div>
        <div
          style={{
            paddingTop: "45px",
            paddingLeft: "25px",
            paddingRight: "25px",
            paddingBottom: "100px",
          }}
        >
          {showStep(currentStepMobile)}
          {showFormMobile(currentStepMobile)}
        </div>
        <div className={s.fixed__actions}>
          {currentStepMobile === 0 && (
            //new logic
            <button
              onClick={() => setCurrentStepMobile(prev => prev + 1)}
              className={s.start__button_mobile}
            >
              Start <Start style={{ marginLeft: "8px" }} />
            </button>
          )}

          {currentStepMobile > 1 && currentStepMobile < 4 && (
            <button
              onClick={() => setCurrentStepMobile(prev => prev - 1)}
              className={s.previous__btn}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="7"
                height="10"
                viewBox="0 0 7 10"
                fill="none"
              >
                <path
                  d="M5.5 9.00012L1.5 5.00005L5.5 0.999969"
                  stroke="#573B76"
                  strokeWidth="1.2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              {`\n`}
              Previous
            </button>
          )}

          {currentStepMobile >= 1 && (
            <button className={s.next__btn} onClick={handleFormSubmitForMobile}>
              {showButtonMobile(currentStepMobile)}{" "}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="6"
                height="10"
                viewBox="0 0 6 10"
                fill="none"
              >
                <path
                  d="M1 0.999906L5 4.99998L1 9.00006"
                  stroke="white"
                  strokeWidth="1.2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </button>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default ModalMobile;
