import { registerClass } from "api/classesApi";
import moment from "moment-timezone";
import React, { useState } from "react";
import { ReactComponent as AllSet } from "../../../../../assets/icons/classes/allSet.svg";
import { ReactComponent as Person } from "../../../../../assets/icons/classes/person.svg";
import { ReactComponent as Clock } from "../../../../../assets/icons/classes/clock.svg";
import { ReactComponent as Notice } from "../../../../../assets/icons/classes/notice.svg";
import s from "./UpcomingCard.module.scss";
import mixpanel from "mixpanel-browser";
import MapOfType from "../MapOfType/MapOfType";
import clevertap from "clevertap-web-sdk";

const UpcomingCard = ({
  id,
  type,
  topic,
  teacher,
  startTime,
  image,
  isNotify,
  filter,
}) => {
  const [notified, setNotified] = useState(isNotify);
  const renderTeacher = teacher => {
    if (!teacher) {
      return "";
    } else {
      return JSON.parse(teacher).name;
    }
  };

  const register = () => {
    clevertap.event.push("Clicked on notify for class");
    mixpanel.track("Clicked on notify for class");
    registerClass(id).then(res => {
      setNotified(true);
    });
  };

  const parseAndFormatTimeRange = startTime => {
    const startTimeF = moment.tz(
      startTime.replace("GMT", "+0530"),
      "ddd, DD MMM YYYY HH:mm:ss ZZ",
      "Asia/Kolkata"
    );

    // Calculate the end time by adding 1 hour to the start time
    const endTimeF = startTimeF.clone().add(1, "hours");

    // Format both start and end times in "hh:mm A" format
    const formattedStartTime = startTimeF.format("hh:mm A");
    const formattedEndTime = endTimeF.format("hh:mm A");

    // Combine start and end times into a string
    return `${formattedStartTime} - ${formattedEndTime}`;
  };

  const TodayFormatter = time => {
    const startTimeF = moment.tz(
      startTime.replace("GMT", "+0530"),
      "ddd, DD MMM YYYY HH:mm:ss ZZ",
      "Asia/Kolkata"
    );

    // Calculate the end time by adding 1 hour to the start time

    // Format both start and end times in "hh:mm A" format
    const formattedStartTime = startTimeF.format("hh:mm A");
    return formattedStartTime;
  };
  const TimeFormatter = time => {
    const startTimeF = moment.tz(
      time.replace("GMT", "+0530"),
      "ddd, DD MMM YYYY HH:mm:ss ZZ",
      "Asia/Kolkata"
    );

    // Format the time in "Do MMMM, h:mm A" format
    const formattedTime = startTimeF.format("Do MMMM, h:mm A");

    return formattedTime;
  };

  return (
    <div className={s.upcomings}>
      {filter === "Today" ? (
        <h4 className={s.title__time}>{TodayFormatter(startTime)}</h4>
      ) : (
        <h4 className={s.title__time}>{TimeFormatter(startTime)}</h4>
      )}
      <div class={s.upcoming__card}>
        <div className={s.upcoming__card_block}>
          <div className={s.gradient}></div>
          <img
            style={{ width: "100%", height: "100%", objectFit: "cover" }}
            src={image}
          />
        </div>
        <div className={s.upcoming__card_content}>
          <h4 className={s.upcoming__card__title}>
            {type}: {topic}
          </h4>
          <div className={s.person__details}>
            <h4 className={s.person__name}>
              <span className={s.person__icon}>
                <Person />
              </span>
              {renderTeacher(teacher)}
            </h4>
            <h4 className={s.person__time}>
              <span className={s.person__icon}>
                <Clock />
              </span>
              {parseAndFormatTimeRange(startTime)}
            </h4>
          </div>
          <div className={s.upcoming__card_action}>
            <button
              className={s.live__button}
              onClick={() => register()}
              disabled={notified}
            >
              {notified ? `All Set!` : `Notify me`}
              {notified ? <AllSet /> : <Notice />}
            </button>
            {MapOfType[type]}
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpcomingCard;
