import React from 'react';
import s from './Stories.module.scss';
const Stories = ({ testimonials }) => {
  const JsonParse = JSON.parse(testimonials);

  const getInitials = name => {
    const names = name.split(' ');
    const initials = names.map(n => n.charAt(0).toUpperCase()).join('');
    return initials.slice(0, 2); // return first two initials
  };

  return (
    <section className={s.stories__section}>
      <div className={s.stories}>
        <h3 className={s.stories__title}>Student Stories</h3>
      </div>
      <div className={s.stories}>
        {testimonials !== null ? (
          JsonParse.map(el => (
            <div className={s.stories_card}>
              <div className={s.stories_card__info}>
                <div className={s.stories_card__photo}>
                  {el.photo === 'No image' ? (
                    <div
                      style={{
                        borderRadius: '50%',
                        backgroundColor: '#573b76',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        fontWeight: 'bold',
                        color: '#fff',
                        width: '100%',
                        height: '100%',
                      }}
                    >
                      {getInitials(el.name)}
                    </div>
                  ) : (
                    <img
                      style={{ objectFit: 'cover', borderRadius: '50%' }}
                      src={el.photo}
                      onError={e => {
                        e.target.onerror = null;
                        e.target.style.display = 'none';
                      }}
                    />
                  )}
                </div>
                <div>
                  <h4 className={s.stories_card__name}>{el.name}</h4>
                  {/* <p className={s.stories_card__sub}>{el.university}</p> */}
                </div>
              </div>

              <p className={s.stories__desc}>{el.description}</p>
            </div>
          ))
        ) : (
          <></>
        )}
      </div>
    </section>
  );
};

export default Stories;
