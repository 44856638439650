import { getBlogs } from 'api/blogsApi';
import { getBlogsCategory } from 'api/blogsApi';

export const LOADING_ARTICLES_START = 'LOADING_ARTICLES_START';
export const LOADING_ARTICLES_SUCCESS = 'LOADING_ARTICLES_SUCCESS';
export const LOADING_ARTICLES_FAILED = 'LOADING_ARTICLES_FAILED';

export const SET_CATEGORIES_ARTICLES = 'SET_CATEGORIES_ARTICLES';

// filter

export const FILTER_BY_CATEGORY_ARTICLES = 'FILTER_BY_CATEGORY_ARTICLES';

export const loadingArticlesStartAction = () => {
  return {
    type: LOADING_ARTICLES_START,
  };
};

export const loadingArticlesSucessAction = articles => {
  return {
    type: LOADING_ARTICLES_SUCCESS,
    payload: articles,
  };
};

export const loadingArticlesFailedAction = e => {
  return {
    type: LOADING_ARTICLES_FAILED,
    payload: e,
  };
};

export const setCategoriesArticles = data => ({
  type: SET_CATEGORIES_ARTICLES,
  payload: data,
});

export const filterByCategoryArticles = selectedCategory => ({
  type: FILTER_BY_CATEGORY_ARTICLES,
  payload: selectedCategory,
});

export const getArticles = () => async dispatch => {
  try {
    dispatch(loadingArticlesStartAction());

    const res = await getBlogs({}); // Call your API function here
    dispatch(loadingArticlesSucessAction(res.data));
  } catch (error) {
    console.error('Error fetching Articles:', error);

    dispatch(loadingArticlesFailedAction(error));
  }
};

export const getArticlesCategories = () => async dispatch => {
  try {
    const resCategories = await getBlogsCategory({});
    dispatch(setCategoriesArticles(resCategories.data));
    // Call your API function here
  } catch (error) {
    console.error('Error fetching Articles:', error);
  }
};
