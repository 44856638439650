import React from "react";

const ProgressBar = ({ progress, width = 100, margin }) => {
  return (
    <div
      style={{
        borderRadius: "10px",
        width: `${width}px`,
        height: "4px",
        margin: `${margin}px auto 0`,
        position: "relative",
        background: "#DCD7E3",
      }}
    >
      <div
        style={{
          background: "#573B76",
          width: `${progress}%`,
          height: "4px",
          borderRadius: "10px",
          transition: "all 0.3s",
        }}
      ></div>
    </div>
  );
};

export default ProgressBar;
