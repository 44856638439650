import React, { useState } from 'react';
import BannerContent from '../BannerContent/BannerContent';
import s from './BannerFinalApply.module.scss';
import { ReactComponent as ArrowIcon } from '../../../assets/icons/applyBanner/arrow-icon.svg';
import { ReactComponent as DocsIcon } from '../../../assets/icons/applyBanner/document-text.svg';
import { ReactComponent as Arrow } from '../../../assets/icons/applyBanner/ArrowApply.svg';

import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { removeCourseFromList } from 'actions/wishlistActions';
import mixpanel from 'mixpanel-browser';

const mockData = [
  {
    id: 1,
    text: '12th, UP, PG Transcript ',
  },
  {
    id: 2,
    text: 'SOP, LOR and Essay',
  },
  {
    id: 3,
    text: 'IELTS, GRE marksheet',
  },
  {
    id: 4,
    text: 'Other',
  },
];

const BannerFinalApply = () => {
  const isMobile = useMediaQuery({ query: '(max-width:768px)' });

  const dispatch = useDispatch();

  const wishlist = useSelector(state => state.wishlist.wishlist);
  const documents = useSelector(state => state.documents.documents);

  const [isCollapsedCourses, setIsCollapsedCourses] = useState(false);
  const [isCollapsedDocs, setIsCollapsedDocs] = useState(false);

  const handleCollapseToggle = () => {
    setIsCollapsedCourses(prevState => !prevState);
  };

  const handleCollapseToggleDocs = () => {
    setIsCollapsedDocs(prevState => !prevState);
  };

  const removeFromList = (id) => {
    dispatch(removeCourseFromList(id));
  }

  return (
    <div className={s.bannerFinalApply}>
      <div className={s.banner__content}>
        <BannerContent
          title={isMobile ? 'Apply Now!' : 'Ready to Apply?'}
          desc={
            isMobile
              ? 'Check Your Shortlist and Documents Carefully one last time.'
              : `Check Your Shortlist and Documents Carefully one last time. 
Ready? Apply to all your shortlisted Colleges in One Click!`
          }
        />
      </div>
      <div className={s.collapse__container}>
        <div className={s.wrap__collapse}>
          <div className={s.required__block_content}>
            <div className={s.container__image}>
              <img className={s.img} src={require('../../../assets/icons/applyBanner/doc-p.png')} />
              <h2 className={s.title}>Colleges & Courses</h2>
            </div>
            <button className={s.collapse_button} onClick={handleCollapseToggle}>
              <ArrowIcon className={isCollapsedCourses ? s.icon__collapsed : s.icon__collapse} />
            </button>
          </div>
          <ul className={`${s.docs__container} ${!isCollapsedCourses ? s.docs__container_expanded : ''}`}>
            {wishlist.map(el => (
              <div className={s.short_card} key={el.id}>
                <div className={s.wrap}>
                  <div className={s.content}>
                    <div className={s.img}>
                      <img src={require('../../../assets/icons/applyBanner/Harvard-Logo 1.png')} />
                    </div>
                    <div className={s.info}>
                      <h2 className={s.name}>{el?.title}</h2>
                      <p className={s.desc}>{el?.courses?.course_name}</p>
                      <p className={s.intake}>Fall Intake {el?.courses?.intake_session}</p>
                    </div>
                  </div>
                  <button className={s.circle} onClick={() => removeFromList(el?.course_id)}>
                    <div className={s.line}></div>
                  </button>
                </div>
              </div>
            ))}
          </ul>
        </div>

        <div className={s.wrap__collapse}>
          <div className={s.required__block_content}>
            <div className={s.container__image}>
              <img className={s.img} src={require('../../../assets/icons/applyBanner/doc-p.png')} />
              <h2 className={s.title}>Review Documents</h2>
            </div>
            <button className={s.collapse_button} onClick={handleCollapseToggleDocs}>
              <ArrowIcon className={isCollapsedDocs ? s.icon__collapsed : s.icon__collapse} />
            </button>
          </div>

          <ul className={`${s.docs__container} ${!isCollapsedDocs ? s.docs__container_expanded : ''}`}>
            {documents.map(el => (
              <div className={s.docItem} key={el.id}>
                <div className={s.docItemWrap}>
                  <div className={s.doc__icon}>
                    <DocsIcon />
                    <div style={{ display: 'flex', flexDirection: 'column', gap: '3px' }}>
                      <h2 className={s.docItem__title}>{el.name}</h2>
                      <p className={s.doc_desc}></p>
                    </div>
                  </div>

                  {/* <button className={s.circle}>
                    <div className={s.line}></div>
                  </button> */}
                </div>
              </div>
            ))}
          </ul>
        </div>
      </div>
      <div className={s.action__container}>
        <h2 className={s.action__title}>Ready? Apply to all your shortlisted </h2>
        <p className={s.action__desc}>Colleges in One Click!</p>
        <button className={s.action__button} onClick={() => mixpanel.track('Clicked on submit application')}>
          Submit Application <Arrow />
        </button>
      </div>
    </div>
  );
};

export default BannerFinalApply;
