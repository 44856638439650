import React, {useState, useEffect} from "react";
import s from "./SopSlider.module.scss";
import Slider from "react-slick";
import Ai from "../../../../assets/img/Kando AI.png";
import { BsSkipForward } from "react-icons/bs";
import { ReactComponent as Persona } from "../../../../assets/icons/chat/Test.svg";
import { useMediaQuery } from "react-responsive";
import LoaderImg from "kandor-components/components/LoaderImg/LoaderImg";

const SopSlider = ({ test, setTest, setShow, currentSlide, setCurrentSlide }) => {
  const [isLastSlide, setIsLastSlide] = useState(false)
  const [isLoadedKodaImg, setIsLoadedKodaImg] = useState(false)
  const [isInitSlider, setIsInitSlider] = useState(false)
  const sliderRef = React.useRef(null);

  const settingForModal = {
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    dots: true,
    infinite: true,
    initialSlide: 0,
    autoplay: false,
    autoplaySpeed: 3000,
    beforeChange: (current, next) => {
      setCurrentSlide(next);
      if (next == 3) {
        setIsLastSlide(true)
      } else {
        setIsLastSlide(false)
      }
    },
    customPaging: function (i) {
      return (
        <div className="dot">
          {currentSlide === i ? (
            <>
              <span style={{ marginRight: "1px" }}>{i + 1}</span>/4
            </>
          ) : null}
        </div>
      );
    },
    dotsClass: "slick-dots slick-thumb",
    responsive: [
      {
        breakpoint: 767,
        settings: {
          arrows: false,
        },
      },
    ],
  };

  const goToNextSlide = () => {
    sliderRef.current.slickNext();
  };

  const goToLastSlide = () => {
    const slider = sliderRef.current;
    if (slider) {
      const lastIndex = slider.props.children.length - 1;
      slider.slickGoTo(lastIndex);
    }
  };

  const onLoadedAi = () => {
    setIsLoadedKodaImg(true)
  }

  useEffect(() => {
    const timeout = setTimeout(() => {
      setIsInitSlider(true)
    }, 200);

    return () => {
      clearTimeout(timeout)
    }
  },[])

  return (
    <>
      {!isInitSlider && (
        <div className={s.skeleton}>
          <div className={s.skeleton__wrap}>
            <div className={s.skeleton__btn}>
              <LoaderImg />
            </div>
            <div className={s.skeleton__row}>
              <div className={s.skeleton__photo}>
                <LoaderImg />
              </div>
              <div className={s.skeleton__descr}>
                <LoaderImg />
              </div>
            </div>
            <div className={s.skeleton__subtitle}>
              <LoaderImg />
            </div>
            <div className={s.skeleton__title}>
              <LoaderImg />
            </div>
            <div className={s.skeleton__text}>
              <LoaderImg />
            </div>
            <div className={s.skeleton__next}>
              <LoaderImg />
            </div>
            <div className={s.skeleton__nav}>
              <div className={s.skeleton__count}>
                  <LoaderImg />
                </div>
              <div className={s.skeleton__tab}>
                <LoaderImg />
              </div>
              <div className={s.skeleton__tab}>
                <LoaderImg />
              </div>
              <div className={s.skeleton__tab}>
                <LoaderImg />
              </div>
              <div className={s.skeleton__tab}>
                <LoaderImg />
              </div>
            </div>
            <div className={s.skeleton__arrowleft}>
              <LoaderImg />
            </div>
            <div className={s.skeleton__arrowright}>
              <LoaderImg />
            </div>
          </div>
        </div>
      )}
      <div className={s.button_block}>
        <button
          onClick={() => {
            if (isLastSlide) {
              setShow(false)
              setTest(false)
            } else {
              goToLastSlide();
            }
          }}
          className={s.sop__skip}
        >
          {isLastSlide ? (
            <>
              Close <span className={s.sop__close}></span>
            </>
          ) : (
              <>
                Skip Tour <BsSkipForward />
              </>
          )}
        </button>
      </div>
      <Slider ref={sliderRef} className="sop_slider" {...settingForModal}>
        {/*   1 SLIDE  */}
        <div className={s.sop__slider_block}>
          <div className={s.message__container}>
            <div className={s.ai_sop}>
              {!isLoadedKodaImg && (<div className={s.ai_sop__skeleton}>
                <LoaderImg />
              </div>)}
              <img src={Ai} onLoad={onLoadedAi}/>
            </div>
            <div className={s.ai_sop_img}>
              <h3 className={s.message}>
                Hey! I am excited to get to know you more!
              </h3>
            </div>
          </div>
          <h5 className={s.sop__subTitle}>
            One stop solution for everything SOP
          </h5>
          <h3 className={s.sop__title}>SOP GENERATOR</h3>
          <p className={s.sop__desc}>
            Unlock the doors to your dream colleges with our SOP Generator.
            Start creating compelling, impactful, and unforgettable essays that
            set you apart from the crowd. Your journey to academic success
            begins here!
          </p>
          <button
            onClick={() => goToNextSlide()}
            // onClick={() => setTest(true)}
            className={s.modal_sop__button}
          >
            Next
          </button>
        </div>
        {/*   2 SLIDE  */}

        <div className={s.sop__slider_block}>
          <div className={s.message__container}>
            <div className={s.ai_sop}>
              <img src={Ai} />
            </div>
            <div className={s.ai_sop_img}>
              <h3 className={s.message}>
                I Safekeep your stellar SOPs, for they're destined to dazzle
                admission committees like shooting stars!
              </h3>
            </div>
          </div>
          <h5 className={s.sop__subTitle}>Keep all your work saved</h5>
          <h3 className={s.sop__title}>SOP VAULT</h3>
          <p className={s.sop__desc}>
            A feature that empowers aspiring college students to save and manage
            multiple Statement of Purpose (SOP) documents effortlessly. 
          </p>
          <button
            onClick={() => goToNextSlide()}
            // onClick={() => setTest(true)}
            className={s.modal_sop__button}
          >
            Next
          </button>
        </div>
        {/*   3 SLIDE  */}
        <div className={s.sop__slider_block}>
          <div className={s.message__container}>
            <div className={s.ai_sop}>
              <img src={Ai} />
            </div>
            <div className={s.ai_sop_img}>
              <h3 className={s.message}>
                Well, I am just GPUs and processors at the end of the day. So,
                you should always recheck my work!
              </h3>
            </div>
          </div>
          <h5 className={s.sop__subTitle}>
            Make sure your SOP is authentic with
          </h5>
          <h3 className={s.sop__title}>PLAGIARISM CHECKER</h3>
          <p className={s.sop__desc}>
            Craft an outstanding SOP and submit it with confidence, knowing that
            your writing is exceptional and truly represents your voice, with
            the assurance of originality using our innovative Plagiarism Checker
          </p>
          <button
            onClick={() => goToNextSlide()}
            // onClick={() => setTest(true)}
            className={s.modal_sop__button}
          >
            Next
          </button>
        </div>
        {/*  4 SLIDE  */}

        <div className={s.sop__slider_block}>
          <div
            style={{ marginBottom: "10px" }}
            className={s.message__container}
          >
            <div className={s.ai_sop}>
              <img src={Ai} />
            </div>
            <div>
              <h3 className={s.think}>
                I can customize your SOP to your personality!
              </h3>

              <div className={s.ai_sop_img}>
                <h3 className={s.message}>Isn’t that very cool?!</h3>
              </div>
            </div>
          </div>
          <h5 style={{ marginTop: "20px" }} className={s.sop__subTitle}>
            Stand out from the crowd with,
          </h5>

          <div className={s.sop__test_action}>
            <Persona style={{ marginRight: "14px" }} />
            <div>
              <h3 style={{ margin: 0 }} className={s.sop__title}>
                {" "}
                PERSONALITY TEST
              </h3>
              <div style={{ display: "flex" }}>
                <div className={s.sop__test_info}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="15"
                    height="15"
                    viewBox="0 0 15 15"
                    fill="none"
                  >
                    <path
                      d="M7.5 0.75C3.375 0.75 0 3.45 0 6.75C0 8.325 0.75 9.825 2.1 10.875C2.1 11.325 1.8 12.525 0 14.25C0 14.25 2.625 14.25 4.875 12.375C5.7 12.6 6.6 12.75 7.5 12.75C11.625 12.75 15 10.05 15 6.75C15 3.45 11.625 0.75 7.5 0.75ZM8.25 9.75H6.75V8.25H8.25V9.75ZM9.6 6C9.375 6.3 9.075 6.45 8.775 6.6C8.55 6.75 8.475 6.825 8.4 6.975C8.25 7.125 8.25 7.275 8.25 7.5H6.75C6.75 7.125 6.825 6.9 6.975 6.675C7.125 6.525 7.425 6.3 7.8 6.075C8.025 6 8.175 5.85 8.25 5.7C8.325 5.55 8.4 5.325 8.4 5.175C8.4 4.95 8.325 4.8 8.175 4.65C8.025 4.5 7.8 4.425 7.575 4.425C7.35 4.425 7.2 4.5 7.05 4.575C6.9 4.65 6.825 4.8 6.825 5.025H5.325C5.4 4.5 5.625 4.05 6 3.75C6.375 3.45 6.9 3.375 7.575 3.375C8.25 3.375 8.85 3.525 9.225 3.825C9.6 4.125 9.825 4.575 9.825 5.1C9.9 5.4 9.825 5.7 9.6 6Z"
                      fill="#CDCDCD"
                    />
                  </svg>

                  <p style={{ marginLeft: "7px" }} className={s.sop__test_text}>
                    5 Questions
                  </p>
                </div>
                <div className={s.sop__test_info}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="17"
                    height="17"
                    viewBox="0 0 17 17"
                    fill="none"
                  >
                    <path
                      d="M8.5 0.5C6.91775 0.5 5.37104 0.969192 4.05544 1.84824C2.73985 2.72729 1.71447 3.97672 1.10897 5.43853C0.503466 6.90034 0.34504 8.50887 0.653721 10.0607C0.962403 11.6126 1.72433 13.038 2.84315 14.1569C3.96197 15.2757 5.38743 16.0376 6.93928 16.3463C8.49113 16.655 10.0997 16.4965 11.5615 15.891C13.0233 15.2855 14.2727 14.2602 15.1518 12.9446C16.0308 11.629 16.5 10.0823 16.5 8.5C16.4978 6.37896 15.6542 4.34543 14.1544 2.84562C12.6546 1.34581 10.621 0.50224 8.5 0.5ZM12.8077 9.11539H8.5C8.33679 9.11539 8.18027 9.05055 8.06486 8.93514C7.94945 8.81974 7.88462 8.66321 7.88462 8.5V4.19231C7.88462 4.0291 7.94945 3.87257 8.06486 3.75717C8.18027 3.64176 8.33679 3.57692 8.5 3.57692C8.66321 3.57692 8.81974 3.64176 8.93515 3.75717C9.05055 3.87257 9.11539 4.0291 9.11539 4.19231V7.88462H12.8077C12.9709 7.88462 13.1274 7.94945 13.2428 8.06486C13.3582 8.18026 13.4231 8.33679 13.4231 8.5C13.4231 8.66321 13.3582 8.81974 13.2428 8.93514C13.1274 9.05055 12.9709 9.11539 12.8077 9.11539Z"
                      fill="#CDCDCD"
                    />
                  </svg>

                  <p style={{ marginLeft: "7px" }} className={s.sop__test_text}>
                    1 minutes
                  </p>
                </div>
              </div>
            </div>
          </div>
          <p
            style={{ marginTop: "16px", marginBottom: "11px" }}
            className={s.sop__desc}
          >
            Get a personalised SOP and Say goodbye to generic statements and
            embrace a SOP that reflects who you truly are.
          </p>
          <button
            onClick={() => setTest(!test)}
            // onClick={() => setTest(true)}
            className={s.modal_sop__button}
          >
            Start Quiz
          </button>
        </div>
      </Slider>
    </>
  );
};

export default SopSlider;
