import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { Button } from 'reactstrap'
import {
	practicePromptReading,
	practicePromptSpeaking,
	practicePromptMockSpeaking,
	practicePromptListening,
	practicePromptWriting,
} from './helpers/startPractice'
import {
	fetchAllRecordingsReading,
	fetchAllRecordingsSpeaking,
	fetchAllRecordingsMockSpeaking,
	fetchAllRecordingsListening,
	fetchAllRecordingsWriting,
} from './helpers/seeResult'

import './newChanges/PreviousAttemptCard.scss'

const PreviousAttemptCard = ({
	name,
	data,
	index,
	practiceAgain,
	fetchAllRecordings,
	isPremium,
	isMock = false,
	section = '',
}) => {
	const history = useHistory()
	const [title, setTitle] = useState('')

	useEffect(() => {
		if (section == 'Speaking') {
			if (data?.name) {
				setTitle(data.name)
			}
		} else if (section == 'Writing') {
			if (data?.keyword) {
				setTitle(data.keyword)
			}
		} else if (section == 'Reading' || section == 'Listening') {
			if (data?.title) {
				setTitle(data.title)
			}
		}
	}, [data])

	return (
		<div className="question-task">
			<div className="question-task__head">
				<p className="question-task__text">
					{name} {index + 1}{' '}
					{isMock && section == 'Speaking' ? (
						''
					) : (
						<>
							: <span className="title-gradient">{title}</span>
						</>
					)}
				</p>
			</div>
			<div className="question-task__footer">
				<div className="question-task__col">
					{data?.free || isPremium ? (
						<Button
							className="question-task__btn btn-link subtitle-gradient"
							color="info"
							onClick={() => {
								const id =
									section == 'Reading' || section == 'Listening'
										? data?.testid
										: data?.id

								console.log('section', section)
								console.log('data', data)

								if (section == 'Reading') {
									practicePromptReading({ id, isMock, history })
								} else if (section == 'Listening') {
									practicePromptListening({ id, isMock, history })
								} else if (section == 'Speaking') {
									if (isMock) {
										practicePromptMockSpeaking({ id, history })
									} else {
										practicePromptSpeaking({ id, history })
									}
								} else if (section == 'Writing') {
									practicePromptWriting({ id, history })
								}
							}}
						>
							<span className="arrow-right">Practice Again</span>
						</Button>
					) : (
						''
					)}
				</div>{' '}
				<div className="question-task__col">
					<Button
						className="question-task__btn btn-link subtitle-gradient"
						color="info"
						onClick={() => {
							const id = section == 'Writing' ? data?.assesment_id : data?.id
							let testId = null
							let attemptId = null

							if (section == 'Speaking') {
								if (isMock) {
									testId = data.mock_id
								} else {
									testId = data.id
								}
							} else if (section == 'Writing') {
								testId = data.id
							} else {
								testId = data.testid
							}

							if (section == 'Writing') {
								attemptId = data.assesment_id
							} else {
								attemptId = data.id
							}

							if (section == 'Reading') {
								fetchAllRecordingsReading({
									testId,
									attemptId,
									isMock,
									history,
								})
							} else if (section == 'Listening') {
								fetchAllRecordingsListening({
									testId,
									attemptId,
									isMock,
									history,
								})
							} else if (section == 'Speaking') {
								if (isMock) {
									fetchAllRecordingsMockSpeaking({
										testId,
										attemptId,
										history,
									})
								} else {
									fetchAllRecordingsSpeaking({ testId, history })
								}
							} else if (section == 'Writing') {
								fetchAllRecordingsWriting({ attemptId, history })
							}
						}}
					>
						<span className="arrow-right">See result</span>
					</Button>
				</div>
			</div>
		</div>
	)
}

export default PreviousAttemptCard
