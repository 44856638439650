import React from 'react';
import { ReactComponent as Calendar } from '../../../../assets/icons/banner/calendar.svg';

const BannerWebinar = () => {
  return (
    <div className="intro-banner intro-banner--fourth">
      <div className="intro-banner__wrap">
        <h5 className="intro-banner__title">Kandor Events</h5>

        <p className="intro-banner__text intro-banner__text--white">Where universities meet students</p>

        <div className="intro-banner__info">
          <button type="button" className="intro-banner__inform">
            <Calendar /> Masters in Australia data science
          </button>
          <button type="button" className="intro-banner__inform">
            <Calendar /> Bachelors in USA
          </button>
        </div>

        <div style={{ marginTop: '8px' }}>
          <button className="intro-banner__btn btn-hover">Explore all</button>
        </div>
      </div>
    </div>
  );
};

export default BannerWebinar;
