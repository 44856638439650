import React, { memo, useEffect } from 'react';
import s from './WithReferral.module.scss';
import { ReactComponent as RefLogoMobile } from '../../../assets/icons/price/ref_logoMobile.svg';
import { ReactComponent as WhatsApp } from '../../../assets/icons/whatsapp-white.svg';
import { ReactComponent as Facebook } from '../../../assets/icons/fb-white.svg';
import PassWithReferral from './PassWithReferral/PassWithReferral';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { WhatsappShareButton, FacebookShareButton } from 'react-share';
import CopyReferral from 'kandor-components/components/CopyReferral/CopyReferral';

const WithReferral = ({ referral, referralLink }) => {
  const history = useHistory();
  const titleSms = `Hey!😄 I’ve been practicing for my IELTS on Kandor and it’s really useful and effective.🎓
  `
  
  const textSms = `
    1. They have 100+  mocktests and practice tests with advanced AI integration 🎯
    2. Free Expert AI analysis on all my strengths and weaknesses  📝
    3. Innovative Classes with seasoned Teachers and proven track record 🗣
    4. Free Access to Advanced AI College Shortlister and SOP Writer, for beyond your IELTS🆓
    5. Koda AI counselor, helping you with every doubt and query, providing expert guidance!

    Lets ACE our IELTS together, join me now on Kandor. Use my personal referral code here to unlock exclusive practice content + instant discount and priority on all their premium plan registrations (Limited): 

  `

  useEffect(() => {
    console.log('referralLink', referralLink);
  }, [referralLink])

  const onRefPage = () => {
    history.push('/admin/referral');
  };

  return (
    <section className={s.withReferral}>
      <header onClick={() => onRefPage()} className={s.withReferral__header}>
        <h2 className={s.withReferral__title}>
          <RefLogoMobile />
          Kandor Referral Program
        </h2>
      </header>
      <main className={s.withReferral__content}>
        <div className={s.withReferral__received}>
          <p> {referral && referral[0].actual}</p> <h4>Referrals Received</h4>
        </div>

        <div className={s.withReferral__container}>
          {referral && referral.map(el => <PassWithReferral key={el.achievement_id} {...el} />)}
        </div>
        <div className={s.withReferral__footer}>
          <WhatsappShareButton url={referralLink} title={titleSms} separator={textSms} style={{ width: '100%' }}>
            <button className={s.withReferral__button}>
              Share on
              <WhatsApp/>
            </button>
          </WhatsappShareButton>

          <FacebookShareButton url={referralLink} style={{ width: '100%' }}>
            <button className={s.withReferral__button}>
              Share on
              <Facebook/>
            </button>
          </FacebookShareButton>
        </div>
        <div className={s.withReferral__link}>
          <CopyReferral referralLink={referralLink} />
         </div>
      </main>
    </section>
  );
};

export default memo(WithReferral);
