import * as Actions from "../actions/newsAction";

const initialState = {
  news: [],
  status: "idle" | "success" | "error",
};

const NewsReducer = (state = initialState, action) => {
  switch (action.type) {
    case Actions.LOADING_NEWS_START: {
      return {
        ...state,
        status: "idle",
      };
    }

    case Actions.LOADING_NEWS_SUCCESS: {
      return {
        ...state,
        news: action.payload,
        status: "success",
      };
    }
    case Actions.LOADING_NEWS_FAILED: {
      return {
        ...state,
        status: "error",
      };
    }

    default:
      return state;
  }
};

export default NewsReducer;
