import React from 'react';
import s from './ShortlisterBanner.module.scss';
import { ReactComponent as Arrow } from '../../assets/icons/shortlisterBanner/arrow.svg';
import { ReactComponent as Star } from '../../assets/icons/shortlisterBanner/star-icon.svg';
import { ReactComponent as Shortlister } from '../../assets/icons/shortlisterBanner/solar_bill-list-bold-duotone.svg';
import { ReactComponent as ArrowIcon } from '../../assets/icons/shortlisterBanner/arrow-icon.svg';

import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

const ShortlisterBanner = () => {
  const myShortListers = useSelector(state => state.shortlisters.myShortListers);

  return (
    <div
      // style={myShortListers.length > 0 ? { background: '#FFFFFF', border: '2px solid #573B76' } : null}
      className={s['shortlister-banner']}>
      {/* {myShortListers.length > 0 ? <ShortlistBannerContent /> : <EmptyShortlistBanner />} */}

      <EmptyShortlistBanner />
    </div>
  );
};

export default ShortlisterBanner;

const ShortlistBannerContent = () => {
  const myShortListers = useSelector(state => state.shortlisters.myShortListers);

  return (
    <div className={s['shortlister-banner__container']}>
      <div className={s['shortlister-banner__bar']}>
        <div className={s['shortlister-banner__bar_info']}>
          <div className={s['shortlister-banner__info_details']}>
            <Shortlister />
            <h2 className={s['shortlister-banner__info_title']}>My Shortlist</h2>
          </div>
          <h3 className={s['shortlister-banner__info_count']}>{myShortListers.length} Colleges</h3>
        </div>
        <button className={s['shortlister-banner__info_button']}>View all</button>
      </div>
      <div className={s['shortlister-banner__koda']}>
        <div className={s['shortlister-banner__block']}>
          <div className={s['shortlister-banner__koda_icon']}>
            <img src={require('../../assets/img/aiKandor.png')} alt="AI Kandor" />
          </div>
          <p className={s['shortlister-banner__koda_desc']}>It is advised to have at least 15 Colleges in your List </p>
        </div>
        <button className={s['shortlister-banner__koda_button']}>
          Continue shortlisting <ArrowIcon />
        </button>
      </div>
    </div>
  );
};

const EmptyShortlistBanner = () => {
  const history = useHistory();

  const goToShortlister = () => {
    history.push('/admin/shortlister');
  };

  return (
    <div className={s['shortlister-banner__wrap']}>
      <div className={s['shortlister-banner__wrap_content']}>
        <img
          src={require('../../assets/img/shorlisterBanner/university.png')}
          alt="university"
          className={s['shortlister-banner__photo']}
        />
        <div className={s['shortlister-banner__info']}>
          <h2 className={s['new__tag']}>NEW*</h2>
          <div className={s['shortlister-banner__content']}>
            <h2 className={s['shortlister-banner__title']}>AI COLLEGE SHORTLISTER</h2>
            <p className={s['shortlister-banner__desc']}>
              Find your perfect college match in just 2 minutes with advanced AI
            </p>
          </div>
        </div>
      </div>
      <div className={s['shortlister-banner__wrap_button']}>
        <Star />
        <button onClick={() => goToShortlister()} className={s['shortlister-banner__button']}>
          START NOW <Arrow />
        </button>
      </div>
    </div>
  );
};
