import React, { useCallback, useState } from "react";
import { renderCountry } from "utils/renderCountry";
import { showName } from "utils/showName";
import { ReactComponent as Location } from "../../../assets/icons/location.svg";
// import { ReactComponent as Bag } from "../../../assets/icons/bag.svg";
import { ReactComponent as Online } from "../../../assets/icons/online.svg";
import { ReactComponent as OnSite } from "../../../assets/icons/onsite.svg";
import { ReactComponent as Rating } from "../../../assets/icons/Star 16.svg";
import { ReactComponent as Star } from "../../../assets/icons/star.svg";
import DrawerProfile from "../DrawerProfile/DrawerProfile";
import s from "./Counsellor.module.scss";
import mixpanel from "mixpanel-browser";

const Counsellor = React.memo(
  ({
    id,
    name,
    photo,
    location,
    experience,
    rating,
    countries,
    toggleShow,
    isBooked,
    isStretch = false,
    isSlider = false
  }) => {
    const [open, setOpen] = useState(false);

    const showDrawer = () => {
      mixpanel.track("Clicked on counselor profile", {counselor_name: name});
      setOpen(true);
    };

    const onClose = () => {
      setOpen(false);
    };

    const showBooking = useCallback(
      e => {
        e.stopPropagation();
        mixpanel.track("Clicked on book counselor", {counselor_name: name});
        setOpen(false);
        toggleShow(id);
      },
      [toggleShow, id]
    );

    return (
      <>
        <div onClick={() => showDrawer()} className={`${s.counsellors__card} ${isStretch ? s.stretch : ''} ${isSlider ? s.slider : ''}`}>
          <div className={s.counsellors__card_status}>
            PREMIUM <Star style={{ marginLeft: "5px" }} />
          </div>
          <div className={s.counsellors__card_content}>
            <div className={s.counsellors__card_container}>
              <div className={s.counsellors_card__items}>
                <div className={s.counsellors__card_container_photo}>
                  <img
                    alt=""
                    className={s.counsellors__card_photo}
                    src={photo}
                  />
                </div>
                <div className={s.counsellors__card_block}>
                  <h4 className={s.counsellors__card_name}>{showName(name)}</h4>
                  <p className={s.counsellors__card_location}>
                    <Location style={{ marginRight: "5px" }} />
                    {location}
                  </p>
                  <p className={s.counsellors__card_exp}>
                    {/* <Bag style={{ marginRight: "5px" }} /> */}
                    {experience} years of experience
                  </p>
                </div>
              </div>
              <div className={s.counsellors__card_rate}>
                <Rating />
                {rating ? rating.substring(0, 3) : ""}
              </div>
            </div>
            <div className={s.card_line}></div>
            <div className={s.counsellors__card_subContainer}>
              <div
                style={{ gap: "12px" }}
                className={s.counsellors__card_format}
              >
                {countries && renderCountry(countries).all}
                {renderCountry(countries).remainingCountriesLength !== 0 && (
                  <p className={s.other}>
                    + {renderCountry(countries).remainingCountriesLength}
                  </p>
                )}
              </div>
              <div
                style={{
                  height: "30px",
                  background: "#a7a7a7",
                  width: "1px",
                  opacity: "0.2",
                }}
              ></div>
              <div
                style={{ gap: "22px", marginLeft: "15px" }}
                className={s.counsellors__card_format}
              >
                <p className={s.counsellors__card_format_status}>
                  <Online style={{ marginRight: "4px" }} /> online
                </p>
                {location !== "Virtual" ? (
                  <p className={s.counsellors__card_format_status}>
                    <OnSite style={{ marginRight: "4px" }} /> in office
                  </p>
                ) : (
                  ""
                )}
              </div>
            </div>
            <div className={s.card_line}></div>
            <div className={s.counsellors__action}>
              <button
                onClick={showDrawer}
                className={s.counsellors__card_profile_button}
              >
                View Profile
              </button>
              <button
                onClick={showBooking}
                className={s.counsellors__card_book_button}
                disabled={isBooked}
              >
                {isBooked ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                  >
                    <g clipPath="url(#clip0_116_1027)">
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M0 9C0 6.61305 0.948212 4.32387 2.63604 2.63604C4.32387 0.948212 6.61305 0 9 0C11.3869 0 13.6761 0.948212 15.364 2.63604C17.0518 4.32387 18 6.61305 18 9C18 11.3869 17.0518 13.6761 15.364 15.364C13.6761 17.0518 11.3869 18 9 18C6.61305 18 4.32387 17.0518 2.63604 15.364C0.948212 13.6761 0 11.3869 0 9ZM8.4864 12.852L13.668 6.3744L12.732 5.6256L8.3136 11.1468L5.184 8.5392L4.416 9.4608L8.4864 12.8532V12.852Z"
                        fill="white"
                        fillOpacity="0.7"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_116_1027">
                        <rect width="18" height="18" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                ) : null}
                {isBooked ? `Session Booked` : `Book Session`}
              </button>
            </div>
          </div>
        </div>
        {open ? (
          <DrawerProfile
            open={open}
            onClose={onClose}
            id={id}
            showBooking={showBooking}
          />
        ) : (
          ""
        )}
      </>
    );
  }
);

export default Counsellor;
