import { getSelfDetails } from 'api/userApi';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import ProfileInfo from '../../kandor-components/components/Profile/Profile';
import BookModal from './BookModal/BookModal';
import Counsellor from './Counsellor/Counsellor';
import s from './Marketplace.module.scss';
import MarketplaceFilter from './MarketplaceFilter/MarketplaceFilter';
import StatBlock from './StatBlock/StatBlock';
import StatSlider from './StatBlock/StatSlider/StatSlider';
import ReferralWidget from './../../kandor-components/ReferralWidget/ReferralWidget';
import SkeletonCounselorCard from 'kandor-components/components/Skeletons/SkeletonCounselorCard/SkeletonCounselorCard';

const Marketplace = () => {
  const isMobile = useMediaQuery({ query: '(max-width:767px)' });
  const counselors_admin = useSelector(state => state.counselors.counselors_admin);
  const [visCounselors, setVisCounselors] = useState([]);
  const [isLoadedCounselors, setIsLoadedCounselors] = useState(false)
  const [cities, setCities] = useState([]);
  const [countries, setCountries] = useState([]);

  const [show, setShow] = useState(false);

  const [counselorBookId, setCounselorBookId] = useState(null);

  const [data, setData] = useState({
    Place: '',
    Course: '',
    Target: '',
    BookDate: '',
    Date: '',
  });

  useEffect(() => {
    const getSelfInfo = () => {
      getSelfDetails({})
        .then(res => res.data)
        .then(qdata => {
          const nameParts = qdata.userName.split(' ');
          let firstName = nameParts[0];
          let lastName = nameParts.length > 1 ? nameParts.slice(1).join(' ') : '';
          setData(prevData => ({
            ...prevData,
            Place: qdata.profile.DreamCountry,
          }));
        })
        .catch(res => console.log(res));
    };
    // fetchData();
    getSelfInfo();
  }, []);

  useEffect(() => {
    const locs = new Set();
    const cnts = new Set();

    if (counselors_admin != null) {
      counselors_admin.forEach(counselor => {
        locs.add(counselor.location);

        const parsedCountries = JSON.parse(counselor.countries);
        if (parsedCountries != null) {
          parsedCountries.forEach(country => cnts.add(country));
        }
      });
    }
    setCities(['All Cities', ...Array.from(locs).sort()]);
    setCountries(['All Countries', ...Array.from(cnts).sort()]);
  }, [counselors_admin]);

  const toggleShow = id => {
    setCounselorBookId(id);
    setShow(s => !s);
  };

  return (
    <>
      <BookModal data={data} setData={setData} show={show} setShow={setShow} counselorBookId={counselorBookId} />
      <div className="content-myielts content content--footer">
        <div className="left__side">
          <div className="left__side-container">
            <div className={s.marketplace__container}>
              <div className={s.marketplace__nav}>
                <div className="hello">
                  <h3 className="hello__user">Counselors</h3>
                  <p className="hello__desc">Our experts at your service</p>
                </div>
                <ProfileInfo />
              </div>
              {!isMobile ? <StatBlock /> : <StatSlider />}
              <MarketplaceFilter
                cities={cities}
                countries={countries}
                visCounselors={visCounselors}
                setVisCounselors={setVisCounselors}
                counsellors={counselors_admin}
                setIsLoadedCounselors={setIsLoadedCounselors}
              />
              <div className={s.premium__counsellors}>
                <div className={s.premium__counsellors_container}>
                  <h4 className={s.premium__counsellors_title}>Premium Counselors</h4>
                  {counselors_admin.length && isLoadedCounselors ?
                    (visCounselors ? (
                      <div className={s.counsellors__container}>
                        {visCounselors.map((element, idx) => (
                          <Counsellor key={element.id} {...element} toggleShow={toggleShow} />
                        ))}
                      </div>
                    ) : (
                      ''
                  )) : (
                    <div className={s.counsellors__container}>
                      {[1, 2, 3, 4, 5, 6].map(el => (
                        <SkeletonCounselorCard key={el} />
                      ))}
                    </div>
                  )}
                </div>
              </div>

              {isMobile && (
                <div className="mt-3 referral-wrap">
                  <ReferralWidget />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Marketplace;
