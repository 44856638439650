import { isIOS, isIOSApp, isMac } from 'actions/referrer';
import React, { useEffect } from 'react';
import { useMediaQuery } from 'react-responsive';
import Slider from 'react-slick';
import './Banner.scss';
import BannerReferral from './Banners/BannerReferral';
import BannerMarket from './Banners/BannerMarket';
import BannerChat from './Banners/BannerChat';
import BannerAppAndroid from './Banners/BannerAppIos';
import BannerAppIos from './Banners/BannerAppIos';
import BannerWebinar from './Banners/BannerWebinar';
import LoaderImg from '../LoaderImg/LoaderImg';

const Banner = ({ openChatHandler, sendChatMessageHandler }) => {
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });
  const isTablet = useMediaQuery({ query: '(max-width: 992px)' });

  const [show, setShow] = React.useState(false);
  const [appURL, setAppURL] = React.useState('');

  const [isOpen, setIsOpen] = React.useState(false);
  const [isLoadedLink, setIsLoadedLink] = React.useState(false);
  const [hideDownload, setHideDownload] = React.useState(false);
  const slickWrap = document.querySelector('.slick-slider.slick-initialized')

  useEffect(() => {
    if (!isIOS() && !isMac() && !isIOSApp()) {
      setHideDownload(true);
      setAppURL('https://play.google.com/store/apps/details?id=com.trykandor.twa');
    } else {
      setAppURL('https://apps.apple.com/in/app/kandor-ielts/id6446410798');
    }
  }, []);

  const settings = {
    className: 'center banner_slider',
    centerMode: true,
    pauseOnDotsHover: true,
    pauseOnFocus: true,
    pauseOnHover: true,
    autoplay: true,
    autoplaySpeed: 4000,
    infinite: true,
    speed: 500,
    dots: false,
    slidesToScroll: 1,
    slidesToShow: 1,
    variableWidth: true,
    centerPadding: 0,
  };

  const settingsForTabletAndMobile = {
    className: 'center banner_slider',
    centerMode: true,
    pauseOnDotsHover: true,
    pauseOnFocus: true,
    pauseOnHover: true,
    autoplay: true,
    autoplaySpeed: 3000,
    infinite: true,
    speed: 500,
    dots: false,
    slidesToScroll: 1,
    slidesToShow: 1,
    variableWidth: true,
    centerPadding: 0,
  };

  const openChatWithMess = text => {
    openChatHandler();
    sendChatMessageHandler(text);
  };

  return (
    <>
      <div>
        {!isTablet ? (
          <Slider {...settings}>
            <BannerReferral />
            <BannerMarket />
            <BannerChat openChatWithMess={openChatWithMess} openChatHandler={openChatHandler} />
            <BannerWebinar />
          </Slider>
        ) : (
          <Slider className="banner_slider" {...settingsForTabletAndMobile}>
            <BannerReferral />
            <BannerMarket />
            <BannerChat openChatWithMess={openChatWithMess} openChatHandler={openChatHandler} />
            <BannerWebinar />
            {/* {(!isMobile && !hideDownload) || true ? (
                ? (
                <BannerAppIos appURL={appURL} />
              ) : (<BannerAppAndroid appURL={appURL} />)
                
            ) : (
              ""
            )} */}

            {!isMobile && !hideDownload ? (
              <div className="bannerMobile">
                <div className="banner__container">
                  <div>
                    <h5 className="banner_title">
                      DOWNLOAD
                      <br></br>KANDOR APP
                    </h5>
                    <p className="banner_desc">Get the Kandor APP and supercharge your preparation</p>
                    <a href={appURL}>
                      <button className="banner_button">Download Now</button>
                    </a>
                  </div>
                  <div>
                    <img className="bannerMobile_img" src={require('../../../assets/img/banner/phones.png')} />
                  </div>
                </div>
              </div>
            ) : (
              ''
            )}
          </Slider>
        )}
      </div>
    </>
  );
};

export default Banner;
