import { createSelector } from 'reselect';
import moment from 'moment';

const getWebinars = state => state.webinars.webinars;

export const filteredWebinarsForToday = createSelector([getWebinars], webinars => {
  return webinars.filter(webinar => {
    const startTime = moment(webinar.startTime, 'ddd, DD MMM YYYY HH:mm:ss ZZ');
    return moment().isSame(startTime, 'day');
  });
});
