import React, { useCallback } from "react";
import styles from "./MarketplaceForm.module.scss";

const InputField = React.memo(({ value, onChange, placeholder }) => (
  <input
    value={value}
    onChange={onChange}
    type="text"
    className={styles.inputFullWidth}
    placeholder={placeholder}
  />
));

const countries = ["Canada", "USA", "UK", "Australia", "Germany", "Other"];

const MarketPlaceFormPreferences = React.memo(({ setData, data, errors }) => {
  const handleOptionPlaceChange = event => {
    const newValue = event.target.value;
    if (data.Place !== newValue) {
      setData(prev => ({ ...prev, Place: newValue }));
    }
  };

  const handleInputCoursesChange = event => {
    const newValue = event.target.value;
    if (data.Course !== newValue) {
      setData(prev => ({ ...prev, Course: newValue }));
    }
  };

  return (
    <>
      <form className={styles.radioButtonForm}>
        <div style={{ marginBottom: "15px" }} className={styles.formGroup}>
          <span
            style={errors.Place && !data.Place  ? {} : { opacity: 0 }}
            className={styles.error}
          >
            * This is a compulsory field
          </span>
          <label className={styles.label}>
            Which country is your first preference for <br></br> college
            admissions? *
          </label>
          <div className={styles.radioGroup}>
            {countries.map(option => (
              <label key={option} className={styles.radioLabel}>
                <input
                  checked={data.Place === option}
                  type="radio"
                  onChange={handleOptionPlaceChange}
                  value={option}
                />
                {option}
              </label>
            ))}
          </div>
        </div>
      </form>
      <form className={styles.inputForm}>
        <div className={styles.inputGroup}>
          <span
            style={errors.Course && !data.Course  ? {} : { opacity: 0 }}
            className={styles.error}
          >
            * This is a compulsory field
          </span>
          <label className={styles.label}>
            Which course(s) are you targeting? *
          </label>
          <InputField value={data.Course} onChange={handleInputCoursesChange} placeholder={"Bachelor's in Computer Science, Master's in Biotechnology..."}/>
        </div>
      </form>
    </>
  );
});

export default MarketPlaceFormPreferences;
