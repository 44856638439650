import { getSelfDetails } from "api/userApi";
import mixpanel from "mixpanel-browser";
import React, { useEffect, useState } from "react";
import Avatar from "react-avatar";
import { useSelector } from "react-redux";
// import { ReactComponent as PremiumWhiteIcon } from "../assets/kandor-img/premium-icon-white.svg";
import { useHistory } from "react-router-dom";
import { ReactComponent as BlueBell } from "../../../assets/icons/bluebell.svg";
import { ReactComponent as Pro } from "../../../assets/icons/profile/PRO.svg";

import s from "./Profile.module.scss";
import LoaderImg from "../LoaderImg/LoaderImg";
const ProfileInfo = () => {
  const history = useHistory();

  const TokenId = useSelector(state => state.auth.TokenId);

  const [isPremium, setIsPremium] = useState(false);
  const [userName, setUserName] = useState(null);
  const [isLoadedDetails, setIsLoadedDetails] = useState(false)
  useEffect(() => {
    getSelfDetails({})
      .then(res => res.data)
      .then(qdata => {
        setIsPremium(qdata.tier != "free");
        setUserName(qdata.userName);
        setIsLoadedDetails(true)
      });
  }, [TokenId]);

  const routeToProfile = () => {
    mixpanel.track("Go to Profile Page", {});
    history.push("/admin/profile");
  };

  return (
    <>
      <div className={s.profile__section}>
        {!isLoadedDetails   ? (
          <div className={s.profile__btn_skeleton}>
            <LoaderImg/>
          </div>
        ):
          !isPremium  ? (
          <button className={s.profile__section_button}
          onClick={e => {
            e.preventDefault();
            mixpanel.track(`Upgrade Plan on Navbar Dashboard`, {});
            history.push(`/admin/plan-information`);
          }}
          >
            Kandor Pro @ 999
          </button>
        ) : (
          <button className={s.pro__section_button}>
            {" "}
            <Pro style={{ marginRight: "8px" }} />
            Pro Member
          </button>
        )}
        <div style={{ margin: "0 30px" }}>
          <BlueBell />
        </div>
        <div
          onClick={() => routeToProfile()}
          style={{ cursor: "pointer" }}
          className={s.photo}
        >
          <Avatar
            className={s.avatar}
            color="#EE7734"
            fgColor="#FFFFFF"
            value={!userName ? "U" : userName.substring(0, 1)}
            size={30}
            round={true}
          />
        </div>
      </div>
    </>
  );
};

export default ProfileInfo;
