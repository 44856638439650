import React, { useState, useEffect } from "react";
import { ReactComponent as MedalIcon } from "../assets/icons/medal.svg";
import { ReactComponent as ArrowIcon } from "../assets/kandor-img/arrow-right.svg";
import { getLeaderboardCurrentUser } from "api/userApi";
import { useHistory } from "react-router-dom";

import "./newChanges/LeaderInfo.scss";

import mixpanel from "mixpanel-browser";
import ArrowRight from "svgIcon/ArrowRight";
import { useMediaQuery } from "react-responsive";

const LeaderInfo = ({ isAdmin = false }) => {
  const isMobile = useMediaQuery({ query: "(max-width:767px)" });
  const history = useHistory();
  const [currentData, setCurrentData] = useState({});

  useEffect(() => {
    getLeaderboardCurrentUser({})
      .then(res => res.data)
      .then(qdata => {
        setCurrentData(qdata);
      });
  }, []);

  return (
    <div
      className={`leader-info ${isAdmin ? "leader-info--big" : ""}`}
      onClick={e => {
        e.preventDefault();
        mixpanel.track("Open Community page", {});
        history.push("/admin/community");
      }}
    >
      <div className="leader-info__icon">
        <MedalIcon />
      </div>
      <div className="leader__info-block">
        <div className="leader-info__inner">
          <p className="leader-info__num">Rank</p>
          <span className="rank_num">
            {currentData?.overall_rank ? (
              currentData.overall_rank
            ) : (
              <span className="leader-info__loader">10</span>
            )}
          </span>
        </div>
        <button
          className="leader-info__btn"
          onClick={e => {
            e.preventDefault();
          }}
        >
          Check Leaderboard {isMobile && <ArrowRight color="#573B76" />}{" "}
        </button>
      </div>
    </div>
  );
};

export default LeaderInfo;
