import React from 'react';
import s from './BannerForApplyPC.module.scss';
import { ReactComponent as ArrowIcon } from '../../../../assets/icons/applyBanner/ArrowApply.svg';
const BannerForApplyPC = ({
  title,
  desc,
  buttonText,
  img,
  onClick,
  onStartApplication,
  baseline = null,
  info = null,
  thumbnail = null,
  padding = null,
  onStartApplicationProcess,
  startApplicationProcess,
}) => {
  return (
    <div className={s.bannerForApplyPc}>
      <div style={padding ? { paddingLeft: '70px', paddingRight: '70px' } : {}} className={s.bannerForApplyPc__wrap}>
        <div className={s.photo}>
          <img src={img} alt={title} />
        </div>

        <div className={s.content}>
          <h2 className={s.title}>{title}</h2>
          <p className={s.desc}>{desc}</p>

          {info && info.length && (
            <div className={s.info__container}>
              {info.map(el => (
                <div key={el} className={s.info__block}>
                  {el}
                </div>
              ))}
            </div>
          )}

          {buttonText && (
            <div style={{ display: 'flex', alignItems: 'center', gap: '24px' }}>
              <button onClick={() => onClick()} className={s.action__button}>
                {buttonText} <ArrowIcon />
              </button>
              {startApplicationProcess && (
                <button onClick={() => onStartApplicationProcess()} className={s.addMore}>
                  Add more colleges
                </button>
              )}
            </div>
          )}

          {onStartApplication && (
            <div onClick={() => onStartApplicationProcess()} className={s.start__application}>
              <p>Ready with the shortlist?</p>
              <button>Proceed to Application Process</button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default BannerForApplyPC;
