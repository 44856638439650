import React from "react";
import s from "./StatCard.module.scss";
const StatCard = ({ icon, name, bg, value }) => {
  return (
    <div className={s.stat_card}>
      <div className={s.stat_card__container}>
        <div style={{ background: bg }} className={s.icon_block}>
          {icon}
        </div>
        <h4 className={s.stat_card__title}>{value}</h4>
      </div>
      <h5 className={s.stat_card__name}>{name}</h5>
    </div>
  );
};

export default StatCard;
