import React, { Suspense } from "react";
import ImproveSection from "./ImproveSection/ImoroveSection";
import "./index.css";
import "./../index.scss";
import Statement from "./Statement/index.js";

import lazyRetry from "./../../utils/lazyRetry";

const FlexibleGraphic = React.lazy(() =>
  lazyRetry(() => import("./FlexibleGraphic/index.js"), "FlexibleGraphic")
);
const StressFree = React.lazy(() =>
  lazyRetry(() => import("./StressFree/index.js"), "StressFree")
);
const EnjoyableLearning = React.lazy(() =>
  lazyRetry(() => import("./EnjoyableLearning/index.js"), "EnjoyableLearning")
);
//const Testimonial = React.lazy(() => import('./Testimonial/index.js'))
//const Universities = React.lazy(() => import('./Universities/index.js'))
const Process = React.lazy(() => lazyRetry(() => import("./Process/index.js")));
const Plan = React.lazy(() => lazyRetry(() => import("./Plan/index.js")));
//const MakeRightChoice = React.lazy(() => import('./MakeRightChoice/index.js'))
const Questions = React.lazy(() =>
  lazyRetry(() => import("./Questions/index.js"), "Questions")
);
const Trainer = React.lazy(() =>
  lazyRetry(() => import("./Trainer/index.js"), "Trainer")
);
const LearnSection = React.lazy(() =>
  lazyRetry(() => import("./Learn/LearnSection.js"), "LearnSection")
);
const CounselingSection = React.lazy(() =>
  lazyRetry(
    () => import("./CounselingSection/CounselingSection.js"),
    "CounselingSection"
  )
);
const Review = React.lazy(() =>
  lazyRetry(() => import("./Review/Review.js"), "Review")
);
const StartSection = React.lazy(() =>
  lazyRetry(() => import("./StartSection/StartSection.js"), "StartSection")
);

const Home = () => {
  return (
    <div
      style={{ overflow: "hidden" }}
      className="body-style-akbar landing-wrap"
    >
      <ImproveSection />

      <Statement />
      <Suspense fallback={<div></div>}>
        <FlexibleGraphic />
        <StressFree />
        <EnjoyableLearning />
        {/* <Testimonial /> */}
        {/* <Universities /> */}
        <LearnSection />
        <CounselingSection />
        <Trainer />
        <Review />
        <StartSection />
        {/* <Process /> */}
        {/* <Plan /> */}
        <Questions />
        {/* <MakeRightChoice /> */}
      </Suspense>
    </div>
  );
};

export default Home;
