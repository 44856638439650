import React, { useState, useEffect } from 'react'
import { Modal, ModalBody, Spinner, Row, Col, Button } from 'reactstrap'
import './newChanges/StoriesInstagram.scss'
import Vimeo from '@u-wave/react-vimeo'
import { updateContentTracker } from '../api/userApi.js'
import { ReactComponent as PlayIcon } from '../assets/kandor-img/play-white.svg'
import { ReactComponent as ReplayIcon } from '../assets/kandor-img/replay-icon.svg'
import { useHistory } from 'react-router-dom'
import { ReactComponent as PeopleIcon } from '../assets/kandor-img/people.svg'
import './newChanges/LearnVideo.scss'

const Stories = ({
	storiesList,
	muted,
	handleEndStories,
	withFooter,
	withModal,
	widthVideoCard,
	setWithFooter,
	setThisIsModal,
	widthPracticeCard,
	practiceData,
	videoData,
	handleCloseThis,
	openSecondVideo,
	setTip,
	withNavBar,
	setIsWatched,
}) => {
	const [currentStory, setCurrentStory] = useState(0)
	const [VideoUrl, setVideoUrl] = useState('')

	const [isVideoPlay, setIsVideoPlay] = useState(true)
	const [startStory, setStartStory] = useState(0)

	const [endStories, setEndStories] = useState(false)

	const [player, setPlayer] = useState('')
	const [storiesId, setStoriesId] = useState('')

	const [showEndModal, setShowEndModal] = useState(false)
	const [isOpenedModal, setIsOpenedModal] = useState(false)

	const newWidth =
		window.innerWidth > 0 ? window.innerWidth : window.screen.width
	const [width, setWidth] = useState(newWidth)

	const isMobile = width <= 768

	useEffect(() => {
		const changeWidth = () => {
			window.innerWidth > 0
				? setWidth(window.innerWidth)
				: setWidth(window.screen.width)
		}
		window.addEventListener('resize', changeWidth)
		return () => window.removeEventListener('resize', changeWidth)
	}, [])

	useEffect(() => {
		if (!storiesList.length) return

		setStoriesId(storiesList[currentStory].id)
		setVideoUrl(storiesList[currentStory].url)
	}, [storiesList, currentStory])

	const videoReady = async (e) => {
		console.log('videoReady', e)
		setPlayer(e)

		await updateContentTracker({
			contentId: storiesId,
			isVisited: true,
		})

		setIsVideoPlay(true)
	}

	const videoPause = () => {
		//player.unMute()
	}

	const videoResume = () => {
		console.log('resume')
	}

	const videoUpdate = async (e) => {
		const time = e.seconds
		const duration = e.duration

		if (duration - time <= 10 && !isOpenedModal) {
			setIsOpenedModal(true)
			setShowEndModal(true)
			setEndStories(true)
			handleEndStories(true)
			setIsWatched()
			console.log('update video')

			await updateContentTracker({
				contentId: storiesId,
				isWatched: true,
			})
		}
	}

	const videoEnd = async () => {
		await updateContentTracker({
			contentId: storiesId,
			isWatched: true,
		})

		console.log('end video')

		setShowEndModal(true)
		setEndStories(true)
		handleEndStories(true)
		setIsVideoPlay(false)
		setIsWatched()
	}

	const onReplay = () => {
		setStartStory(0)
		setIsVideoPlay(true)
		setShowEndModal(false)
	}

	const [srcImg, setSrcImg] = useState('')
	const [srcWebp, setSrcWebp] = useState('')

	useEffect(() => {
		if (!videoData) return

		const getImg = async () => {
			let resource = ''
			let resourceWebp = ''
			let nameImg = videoData.metadata.imgUrl.replace('.png', '')
			const nameWebp = nameImg.split(' ')
			const newArr = nameWebp.filter((name) => name != ' ')
			const imgNameUrl = newArr.join('')

			resource = await import(`../assets/thumbnails/${imgNameUrl}.png`)
			resourceWebp = await import(`../assets/thumbnails/${imgNameUrl}.webp`)

			setSrcWebp(resourceWebp?.default)
			setSrcImg(resource?.default)
		}

		getImg()
	}, [videoData])

	const [isImgLoaded, setIsImgLoaded] = useState(false)

	const handleLoadedImg = () => {
		setIsImgLoaded(true)
	}

	const [peoplePracticing, setpeoplePracticing] = useState([])

	function getRandomInt(min, max) {
		min = Math.ceil(min)
		max = Math.floor(max)
		return Math.floor(Math.random() * (max - min + 1)) + min
	}

	useEffect(() => {
		const len = 4
		let temp = []
		var i
		for (i = 0; i < len; i++) {
			var j = getRandomInt(3000, 5000)
			temp.push(j)
		}
		setpeoplePracticing(temp)
	}, [])

	useEffect(() => {
		console.log('widthPracticeCard', widthPracticeCard)
	}, [widthPracticeCard])

	return (
		<div
			className={`stories ${withFooter && endStories ? 'stories--small' : ''}`}
		>
			<div className="stories__content">
				{VideoUrl && (
					<Vimeo
						video={VideoUrl}
						autoplay={true}
						start={startStory}
						paused={!isVideoPlay}
						className="stories__video height-full"
						onReady={videoReady}
						onPlay={videoResume}
						onPause={videoPause}
						onEnd={videoEnd}
						onTimeUpdate={videoUpdate}
						onError={(err) => {
							console.log('error', err)
						}}
					/>
				)}
			</div>

			{withModal ? (
				<Modal
					className={`replay-popup ${!withNavBar ? 'top' : ''}`}
					isOpen={showEndModal}
					toggle={() => setShowEndModal(false)}
				>
					<ModalBody>
						<button
							className="modal-close"
							onClick={() => setShowEndModal(false)}
						></button>
						<button className="replay-btn" type="button" onClick={onReplay}>
							<span className="replay-btn__icon">
								<ReplayIcon />
							</span>
							<span className="replay-btn__text">Replay</span>
						</button>
						<div className="replay-popup__card">
							{showEndModal ? (
								<>
									{widthVideoCard || !isMobile ? (
										<>
											{videoData ? (
												<div className={`learn-card learn-card--small`}>
													<div className="learn-card__img">
														<picture>
															<source type="image/webp" srcSet={srcWebp} />
															<img
																className={`${isImgLoaded ? 'active' : ''}`}
																src={srcImg}
																alt="img"
																onLoad={handleLoadedImg}
															/>
														</picture>
														{!isImgLoaded ? (
															<div className="learn-card__loader"></div>
														) : (
															''
														)}
													</div>
													<div className="learn-card__inner">
														<div className="learn-card__col">
															<span className="learn-card__title">
																Start {videoData.titleGroup}
															</span>
															<span className="learn-card__subtitle">
																Understand how to IELTS Writing test...
															</span>
														</div>
														<Button
															className="learn-card__btn"
															color="info"
															onClick={() => {
																setEndStories(false)
																setStoriesId(videoData.contentId)
																setVideoUrl(videoData.metadata.videoUrl)
																setShowEndModal(false)
																setWithFooter(true)
																setThisIsModal(false)
																openSecondVideo()
																setTip(videoData.contentCategory)
															}}
														>
															<span className="learn-card__btn-icon">
																<PlayIcon />
															</span>
															<span className="learn-card__btn-text">
																Continue
															</span>
														</Button>
													</div>
												</div>
											) : (
												''
											)}
										</>
									) : widthPracticeCard ? (
										<>
											{practiceData ? (
												<div
													className="start-pract"
													onClick={(e) => {
														practiceData.practicePrompt(practiceData.data.id)
													}}
												>
													<div className="start-pract__img">
														<img src={practiceData.data.media} alt="" />
													</div>
													<div className="start-pract__descr">
														<span className="start-pract__title">
															{practiceData.data.name}
														</span>
														<div className="start-pract__people">
															<span className="start-pract__icon">
																<PeopleIcon />
															</span>
															<span className="start-pract__text">
																{peoplePracticing[0]} people practiced this
																prompt{' '}
															</span>
														</div>

														<Button className="start-pract__btn" color="info">
															Start Test
														</Button>
													</div>
												</div>
											) : (
												''
											)}
										</>
									) : (
										''
									)}
								</>
							) : (
								''
							)}
						</div>
					</ModalBody>
				</Modal>
			) : (
				''
			)}
		</div>
	)
}

const VimeoModal = ({
	stories,
	show,
	handleClose,
	practiceData,
	videoData,
	withFooter,
	currentTip,
	withModal = false,
	withNavBar = true,
	openSecondVideo = () => {},
	handlePractice = () => {},
	handleMore = () => {},
	handleEndVideo = () => {},
	muted = false,
	widthVideoCard = false,
	widthPracticeCard = false,
}) => {
	const history = useHistory()

	const [storiesEnd, setStoriesEnd] = useState(false)
	const [thisIsFooter, setThisIsFooter] = useState(withFooter)
	const [thisIsModal, setThisIsModal] = useState(withModal)
	const [isWatched, setIsWatched] = useState(false)

	const [tip, setTip] = useState(currentTip)

	const studyMaterial = (tips) => {
		handleClose()
		handleEndVideo()
		if (tips == 'Speaking') {
			history.push('/admin/study/speaking')
		} else if (tips == 'Writing') {
			history.push('/admin/study/writing')
		} else if (tips == 'Reading') {
			history.push('/admin/study/reading')
		} else if (tips == 'Listening') {
			history.push('/admin/study/listening')
		}
	}

	const startPractice = (tips) => {
		handleClose()
		handleEndVideo()
		if (tips == 'Speaking') {
			history.push('/admin/practice/speaking')
		} else if (tips == 'Writing') {
			history.push('/admin/practice/writing')
		} else if (tips == 'Reading') {
			history.push('/admin/practice/reading')
		} else if (tips == 'Listening') {
			history.push('/admin/practice/listening')
		}
	}

	useEffect(() => {
		console.log('show', show)
	}, [show])

	return (
		<Modal
			className={`stories-popup ${
				!withNavBar ? 'top' : ''
			} stories-popup--youtube`}
			isOpen={show}
			toggle={() => {
				console.log('isWatched', isWatched)
				if (isWatched) {
					handleEndVideo()
				}
				handleClose()
			}}
		>
			<ModalBody>
				<button
					className="modal-close"
					onClick={() => {
						console.log('isWatched', isWatched)
						if (isWatched) {
							handleEndVideo()
						}
						handleClose()
					}}
				></button>
				<Stories
					storiesList={stories}
					muted={muted}
					handleEndStories={setStoriesEnd}
					withFooter={thisIsFooter}
					setWithFooter={setThisIsFooter}
					withModal={thisIsModal}
					setThisIsModal={setThisIsModal}
					widthVideoCard={widthVideoCard}
					widthPracticeCard={widthPracticeCard}
					videoData={videoData}
					practiceData={practiceData}
					handleCloseThis={handleClose}
					openSecondVideo={openSecondVideo}
					setTip={setTip}
					withNavBar={withNavBar}
					setIsWatched={() => setIsWatched(true)}
				/>

				{storiesEnd && thisIsFooter ? (
					<div className="stories-footer">
						<Row className="mx-n1" style={{ flex: 'auto' }}>
							<Col xs="6" className="px-1">
								<Button
									color="info"
									className="stories-footer__btn"
									onClick={() => {
										console.log('currentTip', currentTip)

										if (tip) {
											startPractice(tip)
										} else {
											handlePractice()
										}
									}}
								>
									Start practice
								</Button>
							</Col>
							<Col xs="6" className="px-1">
								<Button
									color="info"
									className="stories-footer__btn"
									onClick={() => {
										if (tip) {
											studyMaterial(tip)
										} else {
											handleMore()
										}
									}}
								>
									More videos
								</Button>
							</Col>
						</Row>
					</div>
				) : (
					<div></div>
				)}
			</ModalBody>
		</Modal>
	)
}

export default VimeoModal
