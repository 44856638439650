import React, { useState, useEffect } from 'react';
import s from './WebinarCard.module.scss';
import moment from 'moment-timezone';
import { ReactComponent as University } from '../../../assets/icons/webinar/university.svg';
import { ReactComponent as Whatsapp } from '../../../assets/icons/webinar/whatsapp.svg';
import { ReactComponent as Zoom } from '../../../assets/icons/webinar/zoom.svg';
import { ReactComponent as ZoomText } from '../../../assets/icons/webinar/zoom-text.svg';
import { ReactComponent as Calendar } from '../../../assets/icons/webinar/calendar.svg';
import { ReactComponent as Fast } from '../../../assets/icons/webinar/fast.svg';
import { ReactComponent as Time } from '../../../assets/icons/webinar/time.svg';
import Timer from 'kandor-components/components/Classes/Next/Timer/Timer';
import TimerStart from 'kandor-components/components/Timer/TimerStart';
import { registerEvent } from 'api/webinarApi';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import HomeSection from 'pages/LandingPageCounselor/HomeSection/HomeSection';
import clevertap from 'clevertap-web-sdk'

const WebinarCard = ({ id, startTime, image, topic, isNotify, host }) => {
  const history = useHistory();
  const [notified, setNotified] = useState(isNotify);
  const [day, setDay] = useState('');
  const [daySup, setDaySup] = useState('');
  const [month, setMonth] = useState('');
  const [timeIsOver, setTimeIsOver] = useState(null);
  const register = () => {
    registerEvent(id).then(res => {
      setNotified(true);
    }).then(
      clevertap.event.push('webinar register button')
    );
  };

  const onRedirectToWebinarPage = () => {
    history.push('/admin/join_webinar');
  };

  const parseAndFormatTimeRange = startTime => {
    const startTimeF = moment.tz(startTime.replace('GMT', '+0530'), 'ddd, DD MMM YYYY HH:mm:ss ZZ', 'Asia/Kolkata');

    // Calculate the end time by adding 1 hour to the start time
    const endTimeF = startTimeF.clone().add(1, 'hours');

    // Format both start and end times in "hh:mm A" format
    const formattedStartTime = startTimeF.format('hh:mm A');
    const formattedEndTime = endTimeF.format('hh:mm A');

    // Combine start and end times into a string
    return `${formattedStartTime} - ${formattedEndTime}`;
  };

  const dateFormatter = time => {
    const startTimeF = moment.tz(time.replace('GMT', '+0530'), 'ddd, DD MMM YYYY HH:mm:ss ZZ', 'Asia/Kolkata');

    const currentDay = startTimeF.format('DD');
    const currentMonth = startTimeF.format('MMMM');
    const currentDaySup = startTimeF.format('Do').replace(/[0-9,\s]/g, '');

    setDay(currentDay);
    setMonth(currentMonth);
    setDaySup(currentDaySup);
  };

  useEffect(() => {
    dateFormatter(startTime);
  }, [startTime]);

  // const parsedName = JSON.parse(host);
  const parsedName = JSON.parse(host);
  return (
    <div className={s.webinar}>
      <div className={s.webinar__wrap}>
        <div className={s.webinar__head}>
          <div className={s.webinar__headuniversity}>
            {/* <div className={s.webinar__headicon}>
              <img src={image} />
            </div> */}
            <span className={s.webinar__headtext}>{topic}</span>
          </div>
          <div className={s.webinar__headnav}>
            <div className={s.zoom}>
              <Zoom />
              <ZoomText />
            </div>
            <div className={s.share}>
              <div className={s.share__icon}>
                <Whatsapp />
              </div>
              <span className={s.share__text}>Share</span>
            </div>
          </div>
        </div>
        <div className={s.webinar__body}>
          <div className={s.webinar__video}>
            <img alt="webinar" src={image} />
            <div className={s.counselor}>
              <div className={s.counselor__wrap}>
                {/* <div className={s.counselor__img}>
                  <img alt="Navdeep" src={require('assets/img/Navdeep.png')} />
                </div> */}
                <div className={s.counselor__inner}>
                  <span className={s.counselor__title}>{parsedName.name}</span>
                  {/* <span className={s.counselor__subtitle}>CEO, Kandor</span> */}
                </div>
              </div>
            </div>
          </div>
          <div className={s.webinar__data}>
            <h3 className={s.webinar__title}>{topic}</h3>
            <div className={s.webinar__row}>
              <div className={s.webinar__date}>
                <div className={s.webinar__dateicon}>
                  <Calendar />
                </div>
                <span className={s.webinar__datetext}>
                  {day}
                  <sup>{daySup}</sup> {month}
                </span>
              </div>
              <span className={s.webinar__datetext}>{parseAndFormatTimeRange(startTime)}</span>
            </div>
            <div className={s.webinar__tags}>
              <div className={s.webinar__fast}>
                <Fast />
                Fast filling
              </div>
              {/* <span className={s.webinar__tag}>USA</span>
              <span className={s.webinar__tag}>Masters</span> */}
            </div>
            <div className={s.webinar__footerwrap}>
              <div className={s.webinar__footer}>
                {timeIsOver ? (
                  <>
                    <button type="button" className={s.webinar__btn} onClick={() => onRedirectToWebinarPage()}>
                      Join now
                    </button>
                  </>
                ) : (
                  <>
                    <button type="button" className={s.webinar__btn} onClick={() => register()}>
                      {notified ? `Registered` : `Register Now`}
                    </button>
                    <div className={s.webinar__time}>
                      <TimerStart startTime={startTime} setTimeIsOver={setTimeIsOver} />
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WebinarCard;
