import React from 'react';
import s from './BannerForApply.module.scss';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
const BannerForApply = ({
  type,
  title,
  desc = null,
  subContent = null,
  button,
  startApplicationProcess,
  onStartApplicationProcess,
  tryButton,
  thumbnail,
  paddingBottom,
  centred,
  info = null,
  goToAiTool,
  img = null,
}) => {
  const history = useHistory();
  return (
    <>
      <div className={`${s.bannerForApply} ${s[type]}`}>
        <div style={paddingBottom ? { paddingBottom: `${paddingBottom}px` } : null} className={s.bannerForApply__wrap}>
          <h2 style={centred ? { textAlign: 'center' } : null} className={`${s.banner__title} ${s[type]}`}>
            {title}
          </h2>

          {info && info.length && (
            <div className={s.info__container}>
              {info.map(el => (
                <div key={el} className={s.info__block}>
                  {el}
                </div>
              ))}
            </div>
          )}

          {thumbnail && (
            <>
              <div className={s.thumbnail__container}>
                <div className={s.thumbnail}>
                  <img src={require('../../../assets/icons/applyBanner/avatar.png')} />
                </div>
              </div>
              <h2 className={s.name}>Neha Sharma</h2>
              <p className={s.course}>MS in Business Administration</p>

              <h2 className={`${s.banner__title_small} ${s[type]}`}>Boston University </h2>
            </>
          )}
          {desc && (
            <p
              style={startApplicationProcess ? { fontSize: '12px', lineHeight: '27px' } : null}
              className={`${s.banner__desc} ${s[type]}`}>
              {desc}
            </p>
          )}

          {img && (
            <div className={s.img__container}>
              <img src={img} alt="expert" />
            </div>
          )}
          <div className={s.containerForMore}>
            {button}
            {startApplicationProcess && (
              <button onClick={() => history.push('/admin/shortlister')} className={s.moreCollege}>
                Add more colleges
              </button>
            )}
          </div>
          {tryButton && (
            <button onClick={() => goToAiTool()} className={s.try__button}>
              Try it out
            </button>
          )}
        </div>
      </div>
    </>
  );
};

export default BannerForApply;
