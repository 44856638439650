import React from 'react';
import s from './EmptyBanner.module.scss';
import MainEmptyPhoto from '../../../../../assets/icons/applyBanner/main-p.jpg';

const EmptyBanner = ({ goToDocumentPage }) => {
  return (
    <div className={s.empty__banner}>
      <div className={s.empty__banner_wrap}>
        <h2 className={s.banner__title}>Its seems Lonely here!</h2>
        <div className={s.banner__photo_container}>
          <img src={MainEmptyPhoto} alt="main" />
        </div>
        <div className={s.button__container}>
          <button onClick={() => goToDocumentPage()}>Start Uploading Document</button>
        </div>
      </div>
    </div>
  );
};

export default EmptyBanner;
