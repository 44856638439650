import React from "react";
import s from "./Profile.module.scss";

import { useMediaQuery } from "react-responsive";

const Profile = ({ photo, name, location, experience_years, is_booked, showBooking, rating }) => {
  const isMobile = useMediaQuery({ query: "(max-width:767px)" });

  return (
    <section className={s.profile__section}>
      <div className={s.profile}>
        <div className={s.profile__container_photo}>
          <img alt="" className={s.profile_photo} src={photo} />
          <div className={s.tir}>Premium</div>
        </div>
        <div className={s.profile__info}>
          <h4 className={s.profile__info_title}>{name}</h4>
          <div className={s.profile__info_location}>
            <p className={s.profile_location}>
              <div
                style={{
                  marginRight: "3px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                {isMobile ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="17"
                    viewBox="0 0 16 17"
                    fill="none"
                  >
                    <path
                      d="M12.5379 6.71004C12.5379 6.10901 12.4196 5.51387 12.1896 4.9586C11.9596 4.40332 11.6224 3.89878 11.1975 3.4738C10.7725 3.04881 10.2679 2.71169 9.71266 2.48168C9.15739 2.25168 8.56225 2.1333 7.96123 2.1333C7.36021 2.1333 6.76507 2.25168 6.2098 2.48168C5.65453 2.71169 5.14999 3.04881 4.72501 3.4738C4.30002 3.89878 3.9629 4.40332 3.7329 4.9586C3.5029 5.51387 3.38452 6.10901 3.38452 6.71004C3.38452 7.61688 3.65193 8.46031 4.10699 9.17167H4.10176L7.96123 15.2097L11.8207 9.17167H11.8161C12.2874 8.43715 12.538 7.58276 12.5379 6.71004ZM7.96123 8.6715C7.44102 8.6715 6.94212 8.46484 6.57428 8.097C6.20643 7.72915 5.99978 7.23025 5.99978 6.71004C5.99978 6.18983 6.20643 5.69092 6.57428 5.32308C6.94212 4.95523 7.44102 4.74858 7.96123 4.74858C8.48144 4.74858 8.98034 4.95523 9.34818 5.32308C9.71602 5.69092 9.92268 6.18983 9.92268 6.71004C9.92268 7.23025 9.71602 7.72915 9.34818 8.097C8.98034 8.46484 8.48144 8.6715 7.96123 8.6715Z"
                      fill="#909090"
                    />
                  </svg>
                ) : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="25"
                    viewBox="0 0 24 25"
                    fill="none"
                  >
                    <path
                      d="M18.6352 9.72915C18.6352 8.82757 18.4577 7.93481 18.1126 7.10185C17.7676 6.26889 17.2619 5.51205 16.6244 4.87453C15.9869 4.23701 15.23 3.7313 14.3971 3.38628C13.5641 3.04126 12.6714 2.86368 11.7698 2.86368C10.8682 2.86368 9.97542 3.04126 9.14247 3.38628C8.30951 3.7313 7.55267 4.23701 6.91515 4.87453C6.27763 5.51205 5.77192 6.26889 5.4269 7.10185C5.08188 7.93481 4.9043 8.82757 4.9043 9.72915C4.9043 11.0895 5.30544 12.3547 5.98806 13.4218H5.98021L11.7698 22.4793L17.5593 13.4218H17.5525C18.2595 12.32 18.6353 11.0383 18.6352 9.72915ZM11.7698 12.6715C10.9894 12.6715 10.241 12.3615 9.68922 11.8097C9.13742 11.2579 8.82742 10.5095 8.82742 9.72915C8.82742 8.94879 9.13742 8.2004 9.68922 7.6486C10.241 7.0968 10.9894 6.78681 11.7698 6.78681C12.5501 6.78681 13.2985 7.0968 13.8503 7.6486C14.4021 8.2004 14.7121 8.94879 14.7121 9.72915C14.7121 10.5095 14.4021 11.2579 13.8503 11.8097C13.2985 12.3615 12.5501 12.6715 11.7698 12.6715Z"
                      fill="#909090"
                    />
                  </svg>
                )}
              </div>
              {location}
            </p>
            <p className={s.profile_location}>
              <div
                style={{
                  marginRight: "8px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                {isMobile ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="17"
                    viewBox="0 0 16 17"
                    fill="none"
                  >
                    <path
                      d="M12.5379 6.71004C12.5379 6.10901 12.4196 5.51387 12.1896 4.9586C11.9596 4.40332 11.6224 3.89878 11.1975 3.4738C10.7725 3.04881 10.2679 2.71169 9.71266 2.48168C9.15739 2.25168 8.56225 2.1333 7.96123 2.1333C7.36021 2.1333 6.76507 2.25168 6.2098 2.48168C5.65453 2.71169 5.14999 3.04881 4.72501 3.4738C4.30002 3.89878 3.9629 4.40332 3.7329 4.9586C3.5029 5.51387 3.38452 6.10901 3.38452 6.71004C3.38452 7.61688 3.65193 8.46031 4.10699 9.17167H4.10176L7.96123 15.2097L11.8207 9.17167H11.8161C12.2874 8.43715 12.538 7.58276 12.5379 6.71004ZM7.96123 8.6715C7.44102 8.6715 6.94212 8.46484 6.57428 8.097C6.20643 7.72915 5.99978 7.23025 5.99978 6.71004C5.99978 6.18983 6.20643 5.69092 6.57428 5.32308C6.94212 4.95523 7.44102 4.74858 7.96123 4.74858C8.48144 4.74858 8.98034 4.95523 9.34818 5.32308C9.71602 5.69092 9.92268 6.18983 9.92268 6.71004C9.92268 7.23025 9.71602 7.72915 9.34818 8.097C8.98034 8.46484 8.48144 8.6715 7.96123 8.6715Z"
                      fill="#909090"
                    />
                  </svg>
                ) : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="23"
                    height="15"
                    viewBox="0 0 23 15"
                    fill="none"
                  >
                    <path
                      d="M16.169 11.3813C16.169 12.1882 15.7912 12.9622 15.1187 13.5328C14.4462 14.1034 13.5341 14.4239 12.583 14.4239H4.30766C3.3566 14.4239 2.44449 14.1034 1.77199 13.5328C1.09949 12.9622 0.72168 12.1882 0.72168 11.3813V3.42357C0.72168 2.61661 1.09949 1.8427 1.77199 1.27209C2.44449 0.701485 3.3566 0.38092 4.30766 0.38092H12.583C13.5341 0.38092 14.4462 0.701485 15.1187 1.27209C15.7912 1.8427 16.169 2.61661 16.169 3.42357V11.3813ZM22.5267 1.68505C22.6963 1.85441 22.7894 2.06945 22.7893 2.29171V12.5132C22.7894 12.6919 22.7291 12.867 22.6156 13.0176C22.5022 13.1683 22.3402 13.2882 22.149 13.3632C21.9577 13.4383 21.7451 13.4653 21.5364 13.4411C21.3276 13.4168 21.1314 13.3424 20.9709 13.2265L17.2724 10.5574V4.24556L20.9709 1.57832C21.0813 1.49862 21.2091 1.43816 21.3471 1.40039C21.485 1.36262 21.6304 1.34828 21.7749 1.35819C21.9193 1.3681 22.0601 1.40207 22.1891 1.45815C22.3181 1.51424 22.4328 1.59134 22.5267 1.68505Z"
                      fill="#909090"
                    />
                  </svg>
                )}
              </div>
              Video consult
            </p>
          </div>
          <div className={s.profile__info_experience}>
            <p className={s.profile_experience}>
              <span>{experience_years}+ </span>
              Years experience
            </p>
            <div className={s.profile__experience_rate}>
              {isMobile ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="12"
                  height="12"
                  viewBox="0 0 12 12"
                  fill="none"
                >
                  <path
                    d="M5.14308 1.14281C5.37674 0.584941 6.16715 0.584941 6.40081 1.14281L7.41657 3.56801C7.51487 3.8027 7.73569 3.96313 7.98927 3.98409L10.6097 4.20072C11.2124 4.25055 11.4567 5.00228 10.9983 5.39689L9.00571 7.11236C8.81287 7.27838 8.72853 7.53796 8.78696 7.78561L9.39068 10.3447C9.52956 10.9333 8.8901 11.3979 8.37315 11.084L6.12589 9.71898C5.90841 9.58689 5.63548 9.58689 5.418 9.71898L3.17074 11.084C2.6538 11.3979 2.01434 10.9333 2.15321 10.3447L2.75694 7.78561C2.81536 7.53796 2.73102 7.27838 2.53819 7.11236L0.545579 5.39689C0.0872138 5.00228 0.331466 4.25055 0.93424 4.20072L3.55462 3.98409C3.80821 3.96313 4.02902 3.8027 4.12732 3.56801L5.14308 1.14281Z"
                    fill="white"
                  />
                </svg>
              ) : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="15"
                  height="14"
                  viewBox="0 0 15 14"
                  fill="none"
                >
                  <path
                    d="M6.86672 1.13577C7.16312 0.428094 8.16578 0.428093 8.46218 1.13577L9.75069 4.21217C9.87539 4.50988 10.1555 4.71339 10.4772 4.73998L13.8012 5.01477C14.5658 5.07798 14.8756 6.03157 14.2942 6.53214L11.7665 8.70825C11.5219 8.91884 11.4149 9.24812 11.489 9.56227L12.2549 12.8085C12.4311 13.5552 11.6199 14.1446 10.9641 13.7463L8.11344 12.0148C7.83757 11.8472 7.49134 11.8472 7.21547 12.0148L4.36477 13.7463C3.70902 14.1446 2.89785 13.5552 3.07402 12.8085L3.83985 9.56228C3.91397 9.24812 3.80698 8.91884 3.56237 8.70825L1.03471 6.53214C0.453262 6.03157 0.7631 5.07798 1.52773 5.01477L4.85173 4.73998C5.17341 4.71339 5.45351 4.50988 5.57821 4.21217L6.86672 1.13577Z"
                    fill="white"
                  />
                </svg>
              )}
              {rating ? rating.substring(0, 3) : ""}
            </div>
          </div>
          {!isMobile && (
            <button className={s.profile__info_book} onClick={showBooking} disabled={is_booked}>
              {is_booked? `Session Booked` : `Book Session`}
            </button>
          )}
        </div>
      </div>
    </section>
  );
};

export default Profile;
