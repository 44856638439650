import * as Actions from '../actions/shortlistersAction';

const initialState = {
  shortlisters: null,
  myShortListers: [],
  status: 'idle',
};

const ShortlistersReducer = (state = initialState, action) => {
  switch (action.type) {
    case Actions.LOADING_SHORTLISTERS_START: {
      return {
        ...state,
        status: 'idle',
      };
    }

    case Actions.LOADING_SHORTLISTERS_SUCCESS: {
      return {
        ...state,
        shortlisters: action.payload,
        status: 'success',
      };
    }
    case Actions.LOADING_SHORTLISTERS_FAILED: {
      return {
        ...state,
        status: 'error',
      };
    }
    case Actions.ADDED_FOR_SHORTLISTERS: {
      return {
        ...state,
        myShortListers: [...state.myShortListers, ...action.payload],
      };
    }

    case Actions.ADDED_NEW_HISTORY: {
      return {
        ...state,
        myShortListers: [...state.myShortListers, action.payload],
      };
    }

    case Actions.CLEAR_SHORTLISTERS: {
      return {
        ...state,
        shortlisters: null,
        status: 'idle',
      };
    }

    case Actions.ADD_TO_LIST: {
      const updateList = list => {
        return list.map(item => (item.courses.course_id === action.payload ? { ...item, addToList: true } : item));
      };

      return {
        ...state,
        shortlisters: {
          ...state.shortlisters,
          dreamList: updateList(state.shortlisters.dreamList),
          targetList: updateList(state.shortlisters.targetList),
          safeList: updateList(state.shortlisters.safeList),
        },
      };
    }

    default:
      return state;
  }
};

export default ShortlistersReducer;
