import { getShortlisterById } from 'api/shortlisterApi';
import { getAllShortlisters } from 'api/shortlisterApi';
import { getAllCoursesFromShortlistUni } from 'api/shortlisterApi';
import { getWishList } from 'api/shortlisterApi';

// all universities

export const LOADING_SHORTLISTERS_START = 'LOADING_SHORTLISTERS_START';
export const LOADING_SHORTLISTERS_SUCCESS = 'LOADING_SHORTLISTERS_SUCCESS';
export const LOADING_SHORTLISTERS_FAILED = 'LOADING_SHORTLISTERS_FAILED';

export const ADDED_FOR_SHORTLISTERS = 'ADDED_FOR_SHORTLISTERS';
export const CLEAR_SHORTLISTERS = 'CLEAR_SHORTLISTERS';

export const ADDED_NEW_HISTORY = 'ADDED_NEW_HISTORY';
export const ADD_TO_LIST = 'ADD_TO_LIST';

export const addedForMyShortlist = shortlister => {
  return {
    type: ADDED_FOR_SHORTLISTERS,
    payload: shortlister,
  };
};

export const clearShortlistersAction = () => ({
  type: CLEAR_SHORTLISTERS,
});

export const loadingShortlistersStartAction = () => {
  return {
    type: LOADING_SHORTLISTERS_START,
  };
};

export const loadingShortlistersSuccessAction = shortlisters => {
  return {
    type: LOADING_SHORTLISTERS_SUCCESS,
    payload: shortlisters,
  };
};

export const createdNewHistoryItem = historyItem => {
  return {
    type: ADDED_NEW_HISTORY,
    payload: historyItem,
  };
};

export const addStatusToList = item => {
  return {
    type: ADD_TO_LIST,
    payload: item,
  };
};

export const loadingShortlistersFailedAction = e => {
  return {
    type: LOADING_SHORTLISTERS_FAILED,
    payload: e,
  };
};

export const getShortlister = id => async dispatch => {
  dispatch(loadingShortlistersStartAction());

  try {
    const [response, res] = await Promise.all([getWishList(), getShortlisterById(id)]);

    const wishlistArray = combineArrays(response.data);
    const firstTenShortlisters = res.data.slice(0, 10);
    const dreamQuantity = Math.round((firstTenShortlisters.length * 30) / 100);
    const targetQuantity = Math.round((firstTenShortlisters.length * 50) / 100);

    const shortlistersWithData = await Promise.all(
      firstTenShortlisters.map(async el => {
        const courses = await getAllCoursesFromShortlistUni(id, el.id);
        const uniqueCoursesSet = new Set();
        const uniqueCoursesData = [];

        courses.data.forEach(course => {
          if (!uniqueCoursesSet.has(course.course_id)) {
            uniqueCoursesSet.add(course.course_id);
            uniqueCoursesData.push(course);
          }
        });

        const course = uniqueCoursesData[0];
        var addToList = [];
        try{
          if(course && wishlistArray){
            addToList = wishlistArray.some(wish => wish.course_id === course.course_id);
          }
        } catch (error) {
          console.log(error);
        }

        return { ...el, courses: course, addToList, coursesList: uniqueCoursesData };
      })
    );

    const dreamList = [];
    const targetList = [];
    const safeList = [];

    shortlistersWithData.forEach((el, i) => {
      if (i < dreamQuantity) {
        dreamList.push(el);
      } else if (i < dreamQuantity + targetQuantity) {
        targetList.push(el);
      } else {
        safeList.push(el);
      }
    });

    dispatch(
      loadingShortlistersSuccessAction({
        id,
        dreamList,
        targetList,
        safeList,
        shortlistLength: firstTenShortlisters.length,
      })
    );
  } catch (error) {
    dispatch(loadingShortlistersFailedAction(error));
  }
};

export const getAllHistory = () => async dispatch => {
  const res = await getAllShortlisters();
  console.log('RES ALL SHORTLISTERS', res);
  const arrayOfIds = res.data.map((obj, index) => ({
    id: obj.id,
    name: `Shortlist #${index + 1}`,
    query: obj.query,
    date: obj.date_created,
  }));

  dispatch(addedForMyShortlist(arrayOfIds));
};

const combineArrays = ({ dream = [], safe = [], target = [] }) => {
  return [...dream, ...safe, ...target];
};
