import {
	createReadingTestAttempt,
	getSpeakingMockTestAttempts,
	startSpeakingMockTest,
	createListeningTestAttempt,
} from 'api/ieltsApi'
import { startAssesment } from 'api/writingApi'

import mixpanel from 'mixpanel-browser'
import clevertap from 'clevertap-web-sdk'

export const practicePromptReading = async ({
	id,
	isMock,
	history,
	attemptId = null,
}) => {
	if (attemptId) {
		if (isMock) {
			mixpanel.track('Mock Practice Prompts Continue Reading', {})
			history.push('/readingielts-mock/' + id + '/attempt/' + attemptId)
		} else {
			mixpanel.track('Round Practice Prompts Continue Reading', {})
			history.push('/readingielts/' + id + '/attempt/' + attemptId)
		}
	} else {
		var attempt_id = (await (await createReadingTestAttempt({ id: id })).data)
			.id

		clevertap.event.push('anypractice')
		clevertap.event.push('Practice Prompts Reading', {is_mock: isMock})
		if (isMock) {
			mixpanel.track('Mock Practice Prompts Reading', {})
			history.push('/readingielts-mock/' + id + '/attempt/' + attempt_id)
		} else {
			mixpanel.track('Round Practice Prompts Reading', {})
			history.push('/readingielts/' + id + '/attempt/' + attempt_id)
		}
	}
}

export const practicePromptSpeaking = ({ id, history }) => {
	mixpanel.track('Practice Prompt Speaking', {})
	clevertap.event.push('anypractice')
	clevertap.event.push('Practice Prompts Speaking', {is_mock: false})
	history.push('/recordingielts/' + id)
}

export const practicePromptMockSpeaking = async ({ id, history }) => {
	mixpanel.track('Mock Practice Prompts Speaking Mock', {})
	clevertap.event.push('anypractice')
	clevertap.event.push('Practice Prompts Speaking', {is_mock: true})
	var attemptId = null
	try {
		var existing_mock_response = await startSpeakingMockTest({
			id,
		})

		var existing_attempts_response = await getSpeakingMockTestAttempts({
			id,
		})
		var existing_attempts = await existing_attempts_response.data
		var non_complete = existing_attempts.filter(
			(el) => el.status !== 'COMPLETE'
		)
		attemptId = non_complete[non_complete.length - 1].id
	} catch (ex) {
		//could not reach api / 404
		console.log(ex)
	}

	history.push('/recording-mock/' + id + '/' + attemptId)
}

export const practicePromptListening = async ({
	id,
	isMock,
	history,
	attemptId = null,
}) => {
	if (attemptId) {
		if (isMock) {
			mixpanel.track('Mock Practice Prompts Continue Listening', {})
			history.push('/listeningielts-mock/' + id + '/attempt/' + attemptId)
		} else {
			mixpanel.track('Practice Prompts Continue Listening', {})
			history.push('/listeningielts/' + id + '/attempt/' + attemptId)
		}
	} else {
		var attempt_id = (await (await createListeningTestAttempt({ id: id })).data)
			.id
		clevertap.event.push('anypractice')
		clevertap.event.push('Practice Prompts Listening', {is_mock: isMock})
		if (isMock) {
			mixpanel.track('Mock Practice Prompts Listening ', {})
			history.push('/listeningielts-mock/' + id + '/attempt/' + attempt_id)
		} else {
			mixpanel.track('Practice Prompts Listening', {})
			history.push('/listeningielts/' + id + '/attempt/' + attempt_id)
		}
	}
}

export const practicePromptWriting = async ({
	id,
	history,
	attemptId = null,
}) => {
	if (attemptId) {
		mixpanel.track('Clicked Continue Writing test', {})

		history.push({
			pathname: '/writingielts/' + id + '/' + attemptId,
		})
	} else {
		mixpanel.track('Practice Prompts on Writing ', {})
		clevertap.event.push('anypractice')
		clevertap.event.push('Practice Prompts on Writing')
		startAssesment({ questionId: id })
			.then((res) => res.data)
			.then((data) => {
				history.push('/writingielts/' + id + '/' + data.assesment_id)
			})
	}
}
