import api from "services/api";

export function getNews(objArgs) {
  if (typeof objArgs !== "object") {
    throw new Error("missing required parameters");
  }
  return api.get("/news");
}

export function getNewsCategory(objArgs) {
  if (typeof objArgs !== "object") {
    throw new Error("missing required parameters");
  }
  return api.get("/news/categories");
}
