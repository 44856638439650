import React, {useState, useEffect} from "react";
import { useMediaQuery } from "react-responsive";
import UpcomingCardMobile from "../UpcomingCardMobile/UpcomingCardMobile";
import s from "./Upcoming.module.scss";
import UpcomingCard from "./UpcomingCard/UpcomingCard";
import ButtonMore from "kandor-components/components/ButtonMore/ButtonMore";

// const classes = [
//     {
//         "details": null,
//         "id": "c79b12e3-7c63-4f50-8e5b-a80940d88d35",
//         "image": "https://kandor-public.s3.amazonaws.com/c79b12e3-7c63-4f50-8e5b-a80940d88d35.png",
//         "isNotify": 0,
//         "meetingId": "88399669236",
//         "meetingInvitation": "Kandor Communication Inc is inviting you to a scheduled Zoom meeting.\r\n\r\nTopic: Advantage Disadvantage Essay + Problem Solution Essay\r\nTime: Jan 5, 2024 19:00 India\r\n\r\nJoin Zoom Meeting\r\nhttps://us06web.zoom.us/j/88399669236?pwd=iXNbeUfDMzcu9B8Na6Hab9v3YFeIln.1\r\n\r\nMeeting ID: 883 9966 9236\r\nPasscode: 3YNICS\r\n\r\n",
//         "meetingJoinURL": "https://us06web.zoom.us/j/88399669236?pwd=iXNbeUfDMzcu9B8Na6Hab9v3YFeIln.1",
//         "meetingPassword": "3YNICS",
//         "startTime": "Fri, 09 Jan 2024 19:00:00 GMT",
//         "teacher": "{\"name\": \"Ritika Garg\"}",
//         "topic": "Advantage Disadvantage Essay + Problem Solution Essay",
//         "type": "Writing"
//     },
//     {
//         "details": null,
//         "id": "578354bd-8102-4dd1-aa0b-b1b1fd41f06c",
//         "image": "https://kandor-public.s3.amazonaws.com/578354bd-8102-4dd1-aa0b-b1b1fd41f06c.png",
//         "isNotify": 0,
//         "meetingId": "89487341017",
//         "meetingInvitation": "Kandor Communication Inc is inviting you to a scheduled Zoom meeting.\r\n\r\nTopic: Listening Practice 2\r\nTime: Jan 5, 2024 20:00 India\r\n\r\nJoin Zoom Meeting\r\nhttps://us06web.zoom.us/j/89487341017?pwd=Tlc85v7luaoSAbnMgqVDFrBhrl2iiu.1\r\n\r\nMeeting ID: 894 8734 1017\r\nPasscode: Y5SHI7\r\n\r\n",
//         "meetingJoinURL": "https://us06web.zoom.us/j/89487341017?pwd=Tlc85v7luaoSAbnMgqVDFrBhrl2iiu.1",
//         "meetingPassword": "Y5SHI7",
//         "startTime": "Fri, 08 Jan 2024 20:00:00 GMT",
//         "teacher": "{\"name\": \"Ritika Garg\"}",
//         "topic": "Listening Practice 2",
//         "type": "Listening"
//     }
// ]


//{ classes, filter, show }
const Upcoming = ({classes, filter, show }) => {
  const isMobile = useMediaQuery({ query: "(max-width:500px)" });

  const [classesList, setClassesList] = useState([])
  const [currentI, setCurrentI] = useState(0);
  const [isShowMore, setIsShowMore] = useState(classes?.length)

  const addToClassesList = (upcoming, startIndex) => {
    const cardsToShow = 3;
    const endIndex = startIndex + cardsToShow;
    const newUpcoming = [...upcoming]

    if (endIndex >= newUpcoming.length) {
      setClassesList(newUpcoming)
      setIsShowMore(false)
      return
    }

    setIsShowMore(true)
    const newEl = newUpcoming?.slice(startIndex, endIndex)

    if (startIndex) {
      setClassesList((prev) => [
        ...prev,
        ...newEl,
      ]);
    } else {
      setClassesList(newEl)
    }
    setCurrentI(endIndex);

    if (endIndex >= upcoming.length) {
      setIsShowMore(false);
    }
  };
  
  useEffect(() => {
    setClassesList([])
    setCurrentI(0)
    addToClassesList(classes, 0)
  }, [classes, filter])
  

  return (
    <div className={s.upcoming}>
      {classesList.length === 0 && show &&(
        <div style={{ height: "125px", width: "100%" }}></div>
      )}
      {!isMobile
        ? classesList &&
          classesList?.map(el => (
            <UpcomingCard key={el.id} {...el} filter={filter} />
          ))
        : classesList &&
          classesList?.map(el => (
            <UpcomingCardMobile key={el.id} {...el} filter={filter} />
          ))}
      
      {isShowMore ? (
        <div className={s.upcoming__footer}>
          <ButtonMore onClick={() => {
            addToClassesList(classes, currentI)
          }} />
        </div>
        
      ) : ''}
      
    </div>
  );
};

export default Upcoming;
