/*!

=========================================================
* BLK Design System PRO React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/blk-design-system-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
//import "assets/scss/black-dashboard-pro-react.scss";
import { getSelfDetails } from 'api/userApi';
import NavBar from './newChanges/NavBar';
import 'assets/css/select-dropdown.css';
import classNames from 'classnames';
import mixpanel from 'mixpanel-browser';
import React from 'react';
import Avatar from 'react-avatar';
import { withRouter } from 'react-router-dom';
// reactstrap components
import {
  Button,
  Collapse,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Input,
  Modal,
  Nav,
  Navbar,
  NavItem,
  UncontrolledDropdown,
} from 'reactstrap';
import UnlockBtn from './components/UnlockBtn/UnlockBtn';

class ColorNavbar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      collapseOpen: false,
      modalSearch: false,
      color: 'navbar-transparent',
      tier: '-',
      isLoadTier: false,
    };
    this.getSelfInfo();
  }

  getSelfInfo = () => {
    getSelfDetails({})
      .then(res => res.data)
      .then(qdata => {
        this.setState(state => ({
          ...state,
          tier: qdata.tier,
          isLoadTier: true,
        }));
      })
      .catch(res => console.log(res));
  };

  componentDidMount() {
    window.addEventListener('scroll', this.changeNavbarColor);
    window.addEventListener('resize', this.updateColor);
  }
  componentWillUnmount() {
    window.removeEventListener('scroll', this.changeNavbarColor);
    window.removeEventListener('resize', this.updateColor);
  }
  changeNavbarColor = () => {
    if (document.documentElement.scrollTop > 299 || document.body.scrollTop > 299) {
      this.setState({
        navbarColor: 'bg-info',
      });
    } else if (document.documentElement.scrollTop < 300 || document.body.scrollTop < 300) {
      this.setState({
        navbarColor: 'navbar-transparent',
      });
    }
  };

  updateColor = () => {
    if (window.innerWidth < 993 && this.state.collapseOpen) {
      this.setState({
        navbarColor: 'bg-white',
      });
    } else {
      this.setState({
        navbarColor: 'navbar-transparent',
      });
    }
  };
  toggleModalSearch = () => {
    this.setState({
      modalSearch: !this.state.modalSearch,
    });
  };
  toggleCollapse = () => {
    if (this.state.collapseOpen) {
      this.setState({
        navbarColor: 'navbar-transparent',
      });
    } else {
      this.setState({
        navbarColor: 'bg-white',
      });
    }
    this.setState({
      collapseOpen: !this.state.collapseOpen,
    });
  };

  renderSwitch(param) {
    switch (param) {
      case 'YVACE':
        return <img alt="..." src={require('assets/kandor-img/yvaceLogo.png')} height="30px" />;
      case 'LEARNEX':
        return <img alt="..." src={require('assets/kandor-img/learnex.png')} height="25px" />;
      case 'SEP':
        return <img alt="..." src={require('assets/kandor-img/SEP.jpg')} height="32px" />;
      case 'free':
        return (
          <Button
            size="sm"
            color="black"
            style={{
              color: '#000000',
              marginLeft: '-0.00rem',
              padding: '0.25rem',
              display: 'none',
            }}
            onClick={e => {
              e.preventDefault();
              this.props.history.push('/admin/plan-information');
            }}>
            <img
              src={require('assets/kandor-img/free-plan.png')}
              style={{
                height: '1rem',
                width: '1rem',
                marginTop: '-0.25rem',
                display: 'none',
              }}
              alt="..."
            />
            Trial
          </Button>
        );
      default:
        return (
          <Button
            size="sm"
            color="black"
            style={{
              color: '#000000',
              marginLeft: '-0.0rem',
              padding: '0.25rem',
              display: 'none',
            }}
            onClick={e => {
              e.preventDefault();
              this.props.history.push('/admin/plan-information');
            }}>
            <img
              src={require('assets/kandor-img/paid-plan.png')}
              style={{
                height: '1rem',
                width: '1rem',
                marginTop: '-0.25rem',
                display: 'none',
              }}
              alt="..."
            />{' '}
            Paid
          </Button>
        );
    }
  }

  render() {
    const { classes } = this.props;
    if (this.props.isAuthenticated) {
      return (
        <>
          <Navbar
            // className={classNames("navbar-absolute", "sticky-top")}
            expand="lg"
            fixed="top"
            style={{
              background: '#FFFFFF',
              boxShadow: '0px -5px 18px rgba(6, 0, 46, 0.12)',
              zIndex: 1030,
            }}>
            <Container fluid className="px-0 d-flex justify-content-between align-items-center">
              <div className={`navbar-wrapper`}>
                <div className="navbar-minimize d-inline"></div>
                <div>
                  <button
                    className={classNames('navbar-toggler', {
                      toggled: this.props.sidebarOpened,
                    })}
                    type="button"
                    onClick={this.props.toggleSidebar}>
                    <span className="navbar-toggler-bar bar1" />
                    <span className="navbar-toggler-bar bar2" />
                    <span className="navbar-toggler-bar bar3" />
                  </button>
                </div>
              </div>
              <div className="">
                {' '}
                <a
                  href="#"
                  className="sidebar-logo"
                  onClick={e => {
                    e.preventDefault();
                    this.props.history.push('/admin/myIelts');
                  }}>
                  <img alt="..." src={require('assets/kandor-img/kandor logo_black font.png')} height="31px" />
                  {this.state.tier != 'free' && this.state.tier != '-' && this.state.isLoadTier ? (
                    <span className="sidebar-logo__pro"> Pro</span>
                  ) : (
                    ''
                  )}
                </a>
                {this.props.isComplete && <>{this.renderSwitch(this.props.plan)}</>}
              </div>
              <DropdownToggle
                caret
                className="navbar-toggler"
                color="default"
                data-toggle="dropdown"
                nav
                onClick={this.toggleCollapse}>
                <div className="photo">
                  <Avatar
                    color="#EE7734"
                    fgColor="#FFFFFF"
                    value={this.props.displayName === null ? 'U' : this.props.displayName.substring(0, 1)}
                    size={30}
                    round={true}
                  />
                </div>
              </DropdownToggle>
              <Collapse navbar isOpen={this.state.collapseOpen} className="color-navbar">
                <Nav className="ml-auto align-items-start" navbar>
                  <NavItem style={{ minWidth: '300px' }} className="lg-mobil changing_display mt-3">
                    <span
                      onClick={e => {
                        e.preventDefault();
                        this.toggleCollapse();
                        mixpanel.track('Go to Profile Page', {});
                        this.props.history.push('/admin/profile');
                      }}
                      style={{
                        background: 'linear-gradient(286.49deg, #3D0F65 -8.45%, #140237 106%)',
                        backgroundClip: 'text',
                        WebkitBackgroundClip: 'text',
                        color: 'transparent',
                        fontFamily: 'Open Sans',
                        fontSize: '16px',
                        lineHeight: ' 24px',
                        letterSpacing: '0.03em',
                        width: '100%',
                        display: 'block',
                      }}
                      className="changing_display">
                      Profile
                    </span>
                  </NavItem>
                  <NavItem style={{ minWidth: '300px' }} className="lg-mobil changing_display mt-3">
                    <a
                      onClick={() => {
                        mixpanel.track('Clicked on log out', {});
                        this.toggleCollapse();
                        this.props.LoggingOut();
                      }}
                      style={{
                        background: 'linear-gradient(286.49deg, #3D0F65 -8.45%, #140237 106%)',
                        backgroundClip: 'text',
                        WebkitBackgroundClip: 'text',
                        color: 'transparent',
                        fontFamily: 'Open Sans',
                        fontSize: '16px',
                        lineHeight: ' 24px',
                        letterSpacing: '0.03em',
                      }}
                      className="changing_display">
                      Log out
                    </a>
                  </NavItem>
                  <NavItem className="d-lg-flex align-items-center justify-content-start justify-content-lg-center mt-3 mt-lg-0 d-none d-md-block">
                    <Button
                      color="info"
                      className="bold m-0 app-button"
                      style={{ minHeight: '40px' }}
                      onClick={() => {
                        mixpanel.track('Go to Counseling Page', {});
                        this.props.history.push('/admin/counseling');
                      }}>
                      Study Abroad
                    </Button>
                  </NavItem>
                  {this.state.tier == 'free' && this.state.isLoadTier ? (
                    <NavItem className="d-lg-flex align-items-center justify-content-start justify-content-lg-center mt-3 mt-lg-0">
                      <UnlockBtn
                        isBig={true}
                        claz={'m-0 px-3'}
                        onClick={e => {
                          e.preventDefault();
                          mixpanel.track('Clicked on upgrade from navbar', {});
                          this.toggleCollapse();
                        }}
                      />
                    </NavItem>
                  ) : (
                    ''
                  )}

                  <UncontrolledDropdown id="mobile_absent" nav className="desktop">
                    <DropdownToggle caret color="default" data-toggle="dropdown" nav onClick={e => e.preventDefault()}>
                      <div className="photo">
                        <Avatar
                          color="#EF3616"
                          fgColor="#FFFFFF"
                          value={this.props.displayName === null ? 'U' : this.props.displayName.substring(0, 1)}
                          size={30}
                          round={true}
                        />
                      </div>
                      <b className="caret d-none d-lg-block d-xl-block" />
                      <p
                        nav="true"
                        className="d-lg-none"
                        onClick={() => {
                          mixpanel.track('Clicked on log out', {});
                          this.props.LoggingOut();
                        }}>
                        Log out
                      </p>
                    </DropdownToggle>
                    <DropdownMenu
                      className="dropdown-navbar"
                      right-end="true"
                      tag="ul"
                      // id="mobile_absent"
                    >
                      <li
                        onClick={e => {
                          e.preventDefault();
                          mixpanel.track('Go to Profile Page', {});
                          this.props.history.push('/admin/profile');
                        }}>
                        <DropdownItem className="nav-item"> Profile </DropdownItem>
                      </li>

                      <li
                        onClick={e => {
                          e.preventDefault();
                          this.props.history.push('');
                        }}>
                        <DropdownItem
                          className="nav-item"
                          onClick={() => {
                            mixpanel.track('Clicked on log out', {});
                            this.props.LoggingOut();
                          }}>
                          Log out
                        </DropdownItem>
                      </li>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                  <li className="separator d-lg-none" />
                </Nav>
              </Collapse>
            </Container>
          </Navbar>
          <Modal modalClassName="modal-search" isOpen={this.state.modalSearch} toggle={this.toggleModalSearch}>
            <div className="modal-header">
              <Input id="inlineFormInputGroup" placeholder="SEARCH" type="text" />
              <button
                aria-label="Close"
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={this.toggleModalSearch}>
                <i className="tim-icons icon-simple-remove" />
              </button>
            </div>
          </Modal>
        </>
      );
    } else {
      return (
        <>
          <NavBar />
        </>
      );
    }
  }
}

export default withRouter(ColorNavbar);

//<Button
//  color="link"
//  data-target="#searchModal"
//  data-toggle="modal"
//  id="search-button"
//  onClick={this.toggleModalSearch}
//>
//  <i className="tim-icons icon-zoom-split" />
//  <span className="d-lg-none d-md-block">Search</span>
//</Button>

/*
<NavItem style={{ "margin-top": "0.70rem" }}>
  <a
    href="https://docs.google.com/forms/d/e/1FAIpQLScztzkAFF2H_J1FanUswJR0ejHTlx_jq8gNDwI4WU7DUsL8kQ/viewform?usp=pp_url"
    target="_blank"
    style={{
      "margin-top": "2rem",
      color: "black",
      fontSize: "small",
      fontWeight: "500",
    }}
  >
    <img
      src={require("assets/kandor-img/icons8-friends-96.png")}
      style={{
        height: "1.5rem",
        width: "1.5rem",
        "margin-top": "0rem",
        display:'none'
      }}
      alt="..."
    />{" "}
    Looking for Speaking Partner{" "}
    <img
      src={require("assets/kandor-img/dslackpopup.png")}
      style={{
        height: "1rem",
        width: "1rem",
        "margin-top": "-0.2rem",
      }}
      alt="..."
    />{" "}
  </a>
</NavItem>

*/
