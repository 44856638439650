import React from 'react'
import { ReactComponent as BannerBenefits1 } from './../../../assets/kandor-img/banner-benefits-1.svg'
import { ReactComponent as BannerBenefits2 } from './../../../assets/kandor-img/banner-benefits-2.svg'
import { ReactComponent as BannerBenefits3 } from './../../../assets/kandor-img/banner-benefits-3.svg'
import { ReactComponent as BannerBenefits4 } from './../../../assets/kandor-img/banner-benefits-4.svg'

import './style.scss'

const BannerBenefits = () => {
	return (
		<div className="banner-benefits">
			<div className="banner-benefits__item">
				<div className="banner-benefits__icon">
					<BannerBenefits1 />
				</div>
				<span className="banner-benefits__text">
					Built by IITians and Ivy league (US) students
				</span>
			</div>
			<div className="banner-benefits__item">
				<div className="banner-benefits__icon">
					<BannerBenefits2 />
				</div>
				<span className="banner-benefits__text">
					80,000+ students registered
				</span>
			</div>
			<div className="banner-benefits__item">
				<div className="banner-benefits__icon">
					<BannerBenefits3 />
				</div>
				<span className="banner-benefits__text">400,000+ tests taken</span>
			</div>
			<div className="banner-benefits__item">
				<div className="banner-benefits__icon">
					<BannerBenefits4 />
				</div>
				<span className="banner-benefits__text">
					3,000,000+ minutes of practice
				</span>
			</div>
		</div>
	)
}

export default BannerBenefits
