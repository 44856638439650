import React from "react";
import s from "./WithoutReferral.module.scss";
import { ReactComponent as RefLogoMobile } from "../../../assets/icons/price/ref_logoMobile.svg";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const WithoutReferral = () => {
  const history = useHistory();

  const onRefPage = () => {
    history.push("/admin/referral");
  };

  return (
    <div className={s.withoutReferral}>
      <div className={s.withoutReferral__header} onClick={() => onRefPage()}>
        <h4 className={s.withoutReferral__title}>
          <RefLogoMobile /> Kandor Referral Program
        </h4>
      </div>
      <div className={s.withoutReferral__content}>
        <h5 className={s.withoutReferral__subtitle}>
          REFER & GET KANDOR PRO WORTH Rs 4999/-
        </h5>
        <p className={s.withoutReferral__descr}>
          Learning is more fun when done together, invite your friends today!
        </p>
        <button
          onClick={() => onRefPage()}
          className={s.withoutReferral__button}
        >
          Send Referrals
        </button>
      </div>
    </div>
  );
};

export default WithoutReferral;
